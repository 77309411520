import React from 'react';

import { Box, Select } from '@attentive/picnic';

import {
  ActionChannel,
  CreativeCondition,
  KeywordCondition,
  SourceType,
  StringValueDisplayableOption,
} from '../../../constants';

import SourceCreativeSelector from './SourceCreativeSelector';
import SourceKeywordSelector from './SourceKeywordSelector';

interface SourceKeywordValue {
  fieldValues?: string[];
  propertyAttribute: SourceType.KEYWORD;
  propertyAttributeCondition: KeywordCondition;
}

interface SourceCreativeValue {
  fieldValues?: string[];
  propertyAttribute: SourceType.CREATIVE;
  propertyAttributeCondition: CreativeCondition;
}

interface SourceOtherValue {
  propertyAttribute: SourceType.CHECKOUT | SourceType.MANUAL | SourceType.API;
}

export type SourceValue = SourceKeywordValue | SourceCreativeValue | SourceOtherValue;

interface SourceSelectorProps {
  values: SourceValue;
  creatives: Record<string, number>;
  keywords: string[];
  onChange: (value: SourceValue) => void;
  subscriberActionChannel?: ActionChannel;
}

const textOptions: StringValueDisplayableOption[] = [
  { optionValue: SourceType.KEYWORD, displayName: 'Keyword' },
  { optionValue: SourceType.CREATIVE, displayName: 'Sign-up unit' },
  { optionValue: SourceType.CHECKOUT, displayName: 'Checkout' },
  { optionValue: SourceType.MANUAL, displayName: 'Manual upload' },
  { optionValue: SourceType.API, displayName: 'External API' },
];

const emailOptions: StringValueDisplayableOption[] = [
  { optionValue: SourceType.CREATIVE, displayName: 'Sign-up unit' },
];

const SourceSelector = ({
  values,
  keywords,
  creatives,
  onChange,
  subscriberActionChannel,
}: SourceSelectorProps) => {
  const options = subscriberActionChannel === ActionChannel.TEXT ? textOptions : emailOptions;

  return (
    <>
      <Box>
        <Select
          value={values.propertyAttribute}
          onChange={(newAttr: string) => {
            const propertyAttribute = newAttr as SourceType;
            switch (propertyAttribute) {
              case SourceType.CREATIVE: {
                onChange({
                  propertyAttribute,
                  propertyAttributeCondition: CreativeCondition.ANY_MOBILE_CREATIVE,
                });
                break;
              }
              case SourceType.KEYWORD: {
                onChange({
                  propertyAttribute,
                  propertyAttributeCondition: KeywordCondition.ANY_KEYWORD,
                });
                break;
              }
              default:
                onChange({
                  propertyAttribute,
                });
            }
          }}
          size="small"
        >
          {options.map((option) => (
            <Select.Item key={option.optionValue} value={option.optionValue}>
              {option.displayName}
            </Select.Item>
          ))}
        </Select>
      </Box>
      {values.propertyAttribute === SourceType.KEYWORD && (
        <SourceKeywordSelector
          value={{
            condition: values.propertyAttributeCondition,
            keywords: values.fieldValues,
          }}
          keywordOptions={keywords}
          onChange={({ condition: propertyAttributeCondition, keywords: fieldValues }) =>
            onChange({ ...values, propertyAttributeCondition, fieldValues })
          }
        />
      )}
      {values.propertyAttribute === SourceType.CREATIVE && (
        <SourceCreativeSelector
          value={{
            condition: values.propertyAttributeCondition,
            creatives: values.fieldValues,
          }}
          creativeOptions={creatives}
          onChange={({ condition: propertyAttributeCondition, creatives: fieldValues }) =>
            onChange({ ...values, propertyAttributeCondition, fieldValues })
          }
          subscriberActionChannel={subscriberActionChannel}
        />
      )}
    </>
  );
};

export default SourceSelector;
