import { ActionFilter, SegmentParameters } from '../../constants';

import { frequencyComparatorIsInvalid } from './frequency';
import { productDataIsInvalid } from './productData';
import { quantityIsInvalid } from './quantity';
import { MAX_DAYS, timeComparatorIsInvalid } from './time';

export function bazaarvoiceEventIsInvalid(parameters: SegmentParameters) {
  const { subscriberAction, frequency, frequencyComparator } = parameters;

  switch (subscriberAction) {
    case ActionFilter.COMPLETED_REVIEW:
      return Boolean(
        frequencyComparatorIsInvalid(frequencyComparator, frequency) ||
          timeComparatorIsInvalid(parameters, MAX_DAYS) ||
          productDataIsInvalid(parameters.productDataAttributeValues)
      );
    case ActionFilter.LEFT_A_RATING:
      return Boolean(
        frequencyComparatorIsInvalid(frequencyComparator, frequency) ||
          timeComparatorIsInvalid(parameters, MAX_DAYS) ||
          bazaarvoiceRatingIsInvalid(parameters)
      );
    default:
      return true;
  }
}

function bazaarvoiceRatingIsInvalid({ reviewFilter }: SegmentParameters) {
  if (!reviewFilter || !reviewFilter.ratingComparator) return true;
  const { comparator, quantity, quantityEnd } = reviewFilter.ratingComparator;

  return quantityIsInvalid(quantity, comparator, quantityEnd);
}
