import React, { FC, Suspense, useContext } from 'react';

import { ErrorBoundary } from '@attentive/acore-utils';
import { Box } from '@attentive/picnic';

import { SelectComponentFallback } from '../../../SelectComponentFallback';
import { SegmentCreateEditQueryContext } from '../../useSegmentCreateEditQuery';

import { FilterByActivitySelector } from './FilterByActivitySelector';

const PU_MINIMUM_FILTER_SELECTOR_WIDTH = '250px';

type FilterByActivitySelectorWrapperProps = Omit<
  React.ComponentProps<typeof FilterByActivitySelector>,
  'queryRef'
>;

export const FilterByActivitySelectorWrapper: FC<FilterByActivitySelectorWrapperProps> = (
  props
) => {
  const { queryRef } = useContext(SegmentCreateEditQueryContext);

  return (
    <ErrorBoundary>
      <Box css={{ minWidth: PU_MINIMUM_FILTER_SELECTOR_WIDTH }}>
        <Suspense
          fallback={<SelectComponentFallback placeholder="Loading activities..." size="small" />}
        >
          {queryRef && <FilterByActivitySelector queryRef={queryRef} {...props} />}
        </Suspense>
      </Box>
    </ErrorBoundary>
  );
};
