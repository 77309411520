import React from 'react';

import { FormField, SearchableSelect } from '@attentive/picnic';

import { PRODUCT_PRICE_DISPLAY, StringValueDisplayableOption } from '../../../../constants';
import { PRODUCT_OPTION_ATTR } from '../../constants';
import { isPriceAttribute } from '../../utils/filterType';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface ProductDataAttributeSelectorProps {
  attribute?: string;
  name?: string;
  options: StringValueDisplayableOption[];
  onChange: (value: string) => void;
}

const ProductDataAttributeSelector = ({
  attribute,
  name,
  options,
  onChange,
}: ProductDataAttributeSelectorProps) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !attribute;
  const optionsFailed = options.length === 0;
  const formattedAttribute = attribute === PRODUCT_OPTION_ATTR ? `${attribute}:${name}` : attribute;
  const placeholder = optionsFailed && formattedAttribute ? formattedAttribute : 'Choose Property';

  return (
    <FormField>
      <SearchableSelect
        value={formattedAttribute}
        placeholder={placeholder}
        state={error || optionsFailed ? 'error' : 'normal'}
        disabled={optionsFailed}
        onChange={(newValue: string) => onChange(newValue)}
        size="small"
      >
        {options.map((option) => {
          const value =
            option.optionValue === PRODUCT_OPTION_ATTR
              ? `${option.optionValue}:${option.displayName}`
              : option.optionValue;
          const display = isPriceAttribute(option.optionValue)
            ? PRODUCT_PRICE_DISPLAY
            : option.displayName;
          return (
            <SearchableSelect.Item key={value} value={value}>
              {display}
            </SearchableSelect.Item>
          );
        })}
      </SearchableSelect>
      {error && <FormField.ErrorText>Please choose a property.</FormField.ErrorText>}
      {optionsFailed && <FormField.ErrorText>Product options failed to load</FormField.ErrorText>}
    </FormField>
  );
};

export default ProductDataAttributeSelector;
