import React from 'react';

import { Button, Card, Heading, IconButton, Stack, Text, styled } from '@attentive/picnic';

import SuccessImg from '../../../../assets/feedbackSuccess.png';

const Image = styled('img', { width: '100px' });

export const FeedbackSubmitted: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Card
      css={{
        backgroundColor: '$lavenderPurple700',
        position: 'relative',
        color: 'white',
      }}
    >
      <IconButton
        iconName="X"
        description="Close segment builder feedback form"
        onClick={onClose}
        css={{ color: 'white', position: 'absolute', top: '$space2', right: '$space2' }}
        size="extraSmall"
      />
      <Stack css={{ alignItems: 'center' }}>
        <Image src={SuccessImg} />
        <Heading as="h3" variant="md">
          Thanks for your feedback
        </Heading>
        <Text>We rely on your input to improve our product.</Text>
        <Button size="small" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Card>
  );
};
