import React, { FC } from 'react';

import { Select } from '@attentive/picnic';

enum DROPDOWN_VALUES {
  ANY_VALUE = 'ANY_VALUE',
  SPECIFIC_VALUE = 'SPECIFIC_VALUE',
}

interface OwnProps {
  currentValue?: string | null;
  onChange: (newValue: string | null) => void;
}

const CustomEventValueSelector: FC<OwnProps> = ({ currentValue, onChange }) => {
  const selectedDropdownValue =
    typeof currentValue === 'string' ? DROPDOWN_VALUES.SPECIFIC_VALUE : DROPDOWN_VALUES.ANY_VALUE;

  return (
    <Select
      value={selectedDropdownValue}
      onChange={(selectedValue: string) => {
        onChange(selectedValue === DROPDOWN_VALUES.ANY_VALUE ? null : '');
      }}
      size="small"
    >
      <Select.Item value={DROPDOWN_VALUES.ANY_VALUE}>Any value</Select.Item>
      <Select.Item value={DROPDOWN_VALUES.SPECIFIC_VALUE}>A specific value</Select.Item>
    </Select>
  );
};

export default CustomEventValueSelector;
