import _getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature from "./__generated__/getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature.graphql";
import { graphql, readInlineData } from '@attentive/data';
import CompanyVendorIntegrationFeatureFrag from './__generated__/getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature.graphql';
_getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature;
export const getIntegrationFeature = (companyVendorIntegrationFeatures, type) => {
  return companyVendorIntegrationFeatures === null || companyVendorIntegrationFeatures === void 0 ? void 0 : companyVendorIntegrationFeatures.find(feature => {
    const {
      externalVendorFeature,
      enabled
    } = readInlineData(CompanyVendorIntegrationFeatureFrag, feature);
    return (externalVendorFeature === null || externalVendorFeature === void 0 ? void 0 : externalVendorFeature.type) === type && enabled;
  });
};