import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { PicnicCss } from '@attentive/picnic';

import {
  CustomAttributeValueGroup,
  SegmentParameters,
  SelectableCampaignType,
} from '../../../../constants';

import { LegacyMessageSegmentCondition } from './components/LegacyMessageSegmentCondition';
import { NewMessageSegmentCondition } from './components/NewMessageSegmentCondition';
import { getCampaignPropertyName } from './utils';

type MessageSegmentConditionProps = {
  parameters: SegmentParameters;
  setDialogIsOpen: (isOpen: boolean) => void;
  onChange: (parameters: SegmentParameters) => void;
  selectableCampaignType: SelectableCampaignType;
  css?: PicnicCss;
};

export const MessageSegmentCondition = (props: MessageSegmentConditionProps) => {
  const { onChange, parameters, selectableCampaignType } = props;
  const enableJourneyMessage = useCompanyFeatureFlag('ENABLE_SEGMENT_BY_JOURNEY_MESSAGE');
  const { customAttributeValueGroup, subscriberAttributeValueGroup } = parameters;
  const msgCondition =
    selectableCampaignType === 'KLAVIYO_EMAIL'
      ? subscriberAttributeValueGroup
      : customAttributeValueGroup;

  const onUpdateLegacyMessageSegmentEngagementRow = (newValues: CustomAttributeValueGroup) => {
    const propertyName = getCampaignPropertyName(selectableCampaignType);
    if (!parameters[propertyName]) return;

    onChange({
      ...parameters,
      [propertyName]: newValues,
    });
  };

  const onDeleteLegacyMessageEngagementRow = () => {
    const propertyName = getCampaignPropertyName(selectableCampaignType);
    if (!parameters[propertyName]) return;

    const updatedParams = { ...parameters };
    delete updatedParams[propertyName];

    onChange(updatedParams);
  };

  if (!enableJourneyMessage) {
    return msgCondition ? (
      <LegacyMessageSegmentCondition
        {...props}
        onChange={onUpdateLegacyMessageSegmentEngagementRow}
        onRemove={onDeleteLegacyMessageEngagementRow}
        msgCondition={msgCondition}
      />
    ) : null;
  }

  return <NewMessageSegmentCondition {...props} />;
};
