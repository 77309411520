import React, { FC } from 'react';

import { useIsOperatorUIEnabled } from '../../../OperatorUIToggle';

import {
  LegacySegmentCondition,
  SegmentConditionProps,
  NewSegmentCondition,
  NewSegmentConditionProps,
} from './components';

export const SegmentCondition: FC<SegmentConditionProps & NewSegmentConditionProps> = (props) => {
  const enableOperatorUI = useIsOperatorUIEnabled();

  if (enableOperatorUI) {
    return <NewSegmentCondition {...props} />;
  }

  return <LegacySegmentCondition {...props} />;
};
