import React from 'react';

import { Box, Button, StandardDialog, PicnicCss } from '@attentive/picnic';

import { DialogLoadingIndicator } from './DialogLoadingIndicator';
import { ItemSelectDialogButton } from './ItemSelectDialogButton';
import { SearchField } from './SearchField';
import { SelectedItemsWrapper } from './SelectedItemsWrapper';

const PU_DIALOG_WIDTH = 544;

type ItemSelectDialogComponentProps = {
  heading: string;
  children: React.ReactNode;
  onClose: () => void;
  onSubmit: () => void;
  open?: boolean;
  submitDisabled?: boolean;
  rowHeight?: number;
  dialogBodyCss?: PicnicCss;
};

const ItemSelectDialogComponent = ({
  heading,
  children,
  onClose,
  onSubmit,
  open,
  dialogBodyCss = {},
  submitDisabled,
  rowHeight = 66,
}: ItemSelectDialogComponentProps) => {
  return (
    <StandardDialog open={open} onOpenChange={onClose}>
      <StandardDialog.Content css={{ width: PU_DIALOG_WIDTH }}>
        <StandardDialog.Header>
          <StandardDialog.Heading>{heading}</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body css={{ pb: '$space0' }}>
          <Box
            css={{
              display: 'grid',
              height: '50vh',
              gridTemplateRows: `auto minmax(${rowHeight * 1.25}px,1fr) auto`,
              ...dialogBodyCss,
            }}
          >
            {children}
          </Box>
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={submitDisabled}>
            Apply Selection
          </Button>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

type ComponentType = typeof ItemSelectDialogComponent;
interface CompositeComponent extends ComponentType {
  SelectedItems: typeof SelectedItemsWrapper;
  SearchField: typeof SearchField;
  LoadingIndicator: typeof DialogLoadingIndicator;
  Button: typeof ItemSelectDialogButton;
}

const ItemSelectDialog = ItemSelectDialogComponent as CompositeComponent;
ItemSelectDialog.SelectedItems = SelectedItemsWrapper;
ItemSelectDialog.SearchField = SearchField;
ItemSelectDialog.LoadingIndicator = DialogLoadingIndicator;
ItemSelectDialog.Button = ItemSelectDialogButton;

export { ItemSelectDialog };
