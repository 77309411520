export enum HasVerbType {
  HAS = 'HAS',
  HAS_NOT = 'HAS_NOT',
}

export enum IsVerbType {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
}

export const IsVerbLabel = {
  [IsVerbType.IS]: 'is',
  [IsVerbType.IS_NOT]: 'is not',
};

export enum OperatorComparator {
  EQUAL_TO = 'EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  MORE_THAN = 'MORE_THAN',
  AT_LEAST_ONCE = 'AT_LEAST_ONCE',
}

export enum OperatorType {
  AND = 'AND',
  OR = 'OR',
}

export enum BooleanValue {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum QuantityComparator {
  EQUAL_TO = 'EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  MORE_THAN = 'MORE_THAN',
  BETWEEN = 'BETWEEN',
}

export enum StringComparator {
  EQUALS = 'STRING_COMPARATOR_EQUALS',
  LIKE = 'STRING_COMPARATOR_LIKE',
}

export enum TimeComparatorType {
  OVER_ALL_TIME = 'OVER_ALL_TIME',
  BETWEEN = 'BETWEEN',
  BETWEEN_THE_LAST = 'BETWEEN_THE_LAST',
  ON = 'ON',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  IN_THE_LAST = 'IN_THE_LAST',
  IN_THE_NEXT = 'IN_THE_NEXT',
  IS_TODAY = 'IS_TODAY',
  DAYS_UNTIL = 'DAYS_UNTIL',
  DAYS_AGO = 'DAYS_AGO',
  IN_THE_MONTH = 'IN_THE_MONTH',
  BETWEEN_THE_NEXT = 'BETWEEN_THE_NEXT',
}

export enum ValueComparator {
  EQUAL_TO = 'VALUE_COMPARATOR_EQUAL',
  LESS_THAN = 'VALUE_COMPARATOR_LESS_THAN',
  MORE_THAN = 'VALUE_COMPARATOR_GREATER_THAN',
}

export const DEFAULT_VALUE_COMPARATOR = ValueComparator.LESS_THAN;

/**
 * Start of default time comparators.
 * If adding a new time comparator to all date fields, you'll need to update these arrays
 */
export const defaultTimeComparatorOptions = [
  TimeComparatorType.BEFORE,
  TimeComparatorType.AFTER,
  TimeComparatorType.ON,
  TimeComparatorType.BETWEEN,
  TimeComparatorType.BETWEEN_THE_LAST,
  TimeComparatorType.IN_THE_LAST,
  TimeComparatorType.OVER_ALL_TIME,
];

export const rechargeTimeComparators = [
  TimeComparatorType.BEFORE,
  TimeComparatorType.AFTER,
  TimeComparatorType.ON,
  TimeComparatorType.BETWEEN,
  TimeComparatorType.BETWEEN_THE_LAST,
  TimeComparatorType.IN_THE_LAST,
];

// Vendor attributes support "IN_THE_NEXT" and don't support "OVER_ALL_TIME"
export const vendorAttributeTimeComparatorOptions = [
  TimeComparatorType.BEFORE,
  TimeComparatorType.AFTER,
  TimeComparatorType.ON,
  TimeComparatorType.BETWEEN,
  TimeComparatorType.BETWEEN_THE_LAST,
  TimeComparatorType.IN_THE_LAST,
  TimeComparatorType.IN_THE_NEXT,
];

export const customAttributeTimeComparatorOptions = [
  TimeComparatorType.BEFORE,
  TimeComparatorType.AFTER,
  TimeComparatorType.ON,
  TimeComparatorType.BETWEEN,
  TimeComparatorType.BETWEEN_THE_LAST,
  TimeComparatorType.IN_THE_LAST,
];
/**
 * end of default time comparators
 */

export const rechargeFutureDateTimeComparators = [
  TimeComparatorType.BEFORE,
  TimeComparatorType.AFTER,
  TimeComparatorType.ON,
  TimeComparatorType.BETWEEN,
  TimeComparatorType.IN_THE_NEXT,
];

// These time comparators are only supported by custom attributes and only appear when ignoreYear is true
export const customAttributePartialDateTimeComparators = [TimeComparatorType.IN_THE_MONTH];

// Enabled by ENABLE_SEGMENTATION_NEW_TIME_COMPARATORS
export const newDateTimeComparators = [
  TimeComparatorType.BETWEEN_THE_NEXT,
  TimeComparatorType.DAYS_AGO,
  TimeComparatorType.DAYS_UNTIL,
  TimeComparatorType.IS_TODAY,
];

// These are comparators that do not support the ignore year flag
export const partialDateUnsupportedTimeComparators = [
  TimeComparatorType.BEFORE,
  TimeComparatorType.AFTER,
];

// Events cannot support future or today time comparators
export const eventUnsupporedTimeComparators = [
  TimeComparatorType.BETWEEN_THE_NEXT,
  TimeComparatorType.DAYS_UNTIL,
  TimeComparatorType.IS_TODAY,
  TimeComparatorType.IN_THE_NEXT,
];

export const durationTimeComparators = [
  TimeComparatorType.IN_THE_LAST,
  TimeComparatorType.IN_THE_NEXT,
  TimeComparatorType.DAYS_AGO,
  TimeComparatorType.DAYS_UNTIL,
];

export const relativeDateRangeTimeComparators = [
  TimeComparatorType.BETWEEN_THE_LAST,
  TimeComparatorType.BETWEEN_THE_NEXT,
];

export const singleDateTimeComparators = [
  TimeComparatorType.ON,
  TimeComparatorType.AFTER,
  TimeComparatorType.BEFORE,
];

type ValueDisplayMappingsType = {
  [key in TimeComparatorType]: string;
};

export const timeComparatorLabels: ValueDisplayMappingsType = {
  [TimeComparatorType.AFTER]: 'After',
  [TimeComparatorType.ON]: 'On',
  [TimeComparatorType.BEFORE]: 'Before',
  [TimeComparatorType.BETWEEN]: 'Between',
  [TimeComparatorType.BETWEEN_THE_LAST]: 'Between the last',
  [TimeComparatorType.IN_THE_LAST]: 'In the last',
  [TimeComparatorType.IN_THE_NEXT]: 'In the next',
  [TimeComparatorType.OVER_ALL_TIME]: 'Over all time',
  [TimeComparatorType.DAYS_AGO]: 'Days ago',
  [TimeComparatorType.DAYS_UNTIL]: 'Days until',
  [TimeComparatorType.IS_TODAY]: 'Today',
  [TimeComparatorType.IN_THE_MONTH]: 'In the month of',
  [TimeComparatorType.BETWEEN_THE_NEXT]: 'Between the next',
};
