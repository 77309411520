import { NestedPropertyFilter, SegmentParameters } from '../../../../constants';

function getNestedFilterIndex(
  childFieldName: string,
  { nestedPropertyFilters = [] }: SegmentParameters
) {
  return nestedPropertyFilters.findIndex(({ fieldName }) => fieldName === childFieldName);
}

/**
 *
 * @param childFieldName The field to retreived from the nested attributes
 * @param parameters Segment parameters
 * @returns returns a tuple containing the target filter and the nested property filters without the requested filter
 */
export function getNestedFilter<T>(
  childFieldName: string,
  parameters: SegmentParameters
): [filter: T, nestedFilters: NestedPropertyFilter[]] {
  const { nestedPropertyFilters = [] } = parameters;
  const fieldIndex = getNestedFilterIndex(childFieldName, parameters);
  const newNestedPropertyFilters = [...nestedPropertyFilters];

  const filter = nestedPropertyFilters[fieldIndex];
  newNestedPropertyFilters.splice(fieldIndex, 1);

  // TODO: sc - see if we can refactor to remove this cast to unknown
  return [filter as unknown as T, newNestedPropertyFilters];
}
