import React, { ReactNode, useEffect, useState } from 'react';

import {
  Box,
  Heading,
  IconCircle,
  IconProps,
  LoadingIndicator,
  PicnicCss,
  styled,
  Text,
} from '@attentive/picnic';

import desertImg from '../../../../assets/desert.png';
import { BlankSegment, Segment } from '../../../../constants';
import { useWorkingSegmentCountV2 } from '../../../../hooks';
import { getSegmentCountDisplay, getSegmentComponentCount } from '../../../../utils';
import { SmartSendingTooltip } from '../../../SmartSendingTooltip';

import { MANUAL_RECALCULATE_CONDITION_THRESHOLD } from './SegmentCountSidePane';
import { ImgContainer, SidePaneImg } from './SegmentCountStyledImgComponents';
import { UpdateSubscriberCount } from './UpdateSubscriberCount';

const LIVE_COUNT_STALE_TIME = 8000;

export const IconCircleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mr: '$space4',
});

interface OwnProps {
  segment: Segment | BlankSegment;
  isWorking?: boolean;
  isSegmentInvalid?: boolean;
  isStale?: boolean;
  isAutoUpdateEnabled: boolean;
  onUpdateCountClick?: () => void;
  onCountUpdate?: () => void;
}

const SegmentCountContentNew = ({
  segment,
  isWorking,
  isSegmentInvalid,
  isStale,
  isAutoUpdateEnabled,
  onCountUpdate,
  onUpdateCountClick,
}: OwnProps) => {
  const [isSegmentCountEnabled, setIsSegmentCountEnabled] = useState(isAutoUpdateEnabled);
  const {
    data: segmentCount,
    isFetching,
    error,
  } = useWorkingSegmentCountV2(segment, {
    enabled: isSegmentCountEnabled,
    onSuccess: onCountUpdate,
    staleTime: LIVE_COUNT_STALE_TIME,
  });

  const [segmentCountDisplay, setSegmentCountDisplay] = useState(
    getSegmentCountDisplay(segmentCount)
  );

  useEffect(() => {
    if (segmentCount) {
      setSegmentCountDisplay(getSegmentCountDisplay(segmentCount));
    }
  }, [segmentCount]);

  useEffect(() => {
    // This handles the case where a user loads an existing segment that has 5+ conditions and then deletes
    // a condition without requesting a segment count. It ensures that we only call the segment count endpoint once
    // with the newest segment. Without this check, we would call the segment count endpoint twice, once with a stale segment
    if (
      isAutoUpdateEnabled &&
      !isSegmentCountEnabled &&
      getSegmentComponentCount(segment) < MANUAL_RECALCULATE_CONDITION_THRESHOLD
    ) {
      setIsSegmentCountEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoUpdateEnabled, segment]);

  const isLoading = isWorking || isFetching;
  const displayLoadingIndicators = isAutoUpdateEnabled && isLoading;
  const isDataStale = !isAutoUpdateEnabled && isStale;
  const isUpdateButtonDisabled = !isStale || isSegmentInvalid || isLoading;

  if (error) {
    return (
      <>
        <ImgContainer>
          <SidePaneImg src={desertImg} />
        </ImgContainer>
        <Text css={{ textAlign: 'center' }} color="subdued">
          We had some trouble loading your results
        </Text>
      </>
    );
  }

  return (
    <>
      <SegmentCountItem
        activeTestId="sms-active-subs"
        eligibleTestId="sms-eligible-subs"
        icon="MessageBubbleDots"
        isLoading={displayLoadingIndicators}
        isStale={isDataStale}
        activeSubscribers={segmentCountDisplay.sms.activeSubscribers}
        eligibleSubscribers={segmentCountDisplay.sms.activeSubscribersNotFatigued}
        label="SMS subscribers"
        tooltip={<SmartSendingTooltip visuallyDisabled={isDataStale} />}
      />
      <SegmentCountItem
        activeTestId="email-active-subs"
        css={{ mt: '$space8' }}
        eligibleTestId="email-eligible-subs"
        icon="EnvelopeFilled"
        label="Email subscribers"
        isLoading={displayLoadingIndicators}
        isStale={isDataStale}
        activeSubscribers={segmentCountDisplay.email.activeSubscribers}
        eligibleSubscribers={segmentCountDisplay.email.activeSubscribersNotFatigued}
      />
      <SegmentCountItem
        activeTestId="sms-and-email-active-subs"
        css={{ m: '$space8 $space0' }}
        eligibleTestId="sms-and-email-eligible-subs"
        icon="MessageBubbleDotsStacked"
        isLoading={displayLoadingIndicators}
        isStale={isDataStale}
        label="Multi-channel subscribers"
        activeSubscribers={segmentCountDisplay.smsAndEmail.activeSubscribers}
        eligibleSubscribers={segmentCountDisplay.smsAndEmail.activeSubscribersNotFatigued}
      />
      {!isAutoUpdateEnabled && (
        <UpdateSubscriberCount
          isLoading={isLoading}
          onUpdateCountClick={() => {
            setIsSegmentCountEnabled(true);
            onUpdateCountClick && onUpdateCountClick();
          }}
          disabled={isUpdateButtonDisabled}
        />
      )}
    </>
  );
};

interface SegmentCountItemProps {
  activeTestId: string;
  css?: PicnicCss;
  eligibleTestId: string;
  label: string;
  icon: IconProps['name'];
  isLoading: boolean;
  isStale?: boolean;
  activeSubscribers: string;
  eligibleSubscribers: string;
  tooltip?: ReactNode;
}

export const segmentCountTextTransition = 'ease-in-out color 200ms';

function SegmentCountItem({
  activeSubscribers,
  activeTestId,
  css,
  eligibleSubscribers,
  eligibleTestId,
  icon,
  isLoading,
  isStale,
  label,
  tooltip,
}: SegmentCountItemProps) {
  const headlineCss: PicnicCss = {
    color: isStale ? '$textDisabled' : '$textDefault',
    transition: segmentCountTextTransition,
  };
  const textCss: PicnicCss = {
    color: isStale ? '$textDisabled' : '$textSubdued',
    transition: segmentCountTextTransition,
  };

  return (
    <Box css={{ display: 'flex', ...css }}>
      <IconCircleContainer>
        <IconCircle iconName={icon} size="small" color={isStale ? 'disabled' : 'default'} />
      </IconCircleContainer>
      <Box>
        <Heading variant="lg" data-testid={activeTestId} css={headlineCss}>
          {isLoading ? <LoadingIndicator css={{ height: 'initial' }} /> : activeSubscribers}
        </Heading>
        <Text css={textCss}>{label}</Text>
        <Text css={textCss} data-testid={eligibleTestId}>
          {`${eligibleSubscribers} eligible`}
          {tooltip}
        </Text>
      </Box>
    </Box>
  );
}

export default SegmentCountContentNew;
