import sortBy from 'lodash/sortBy';

import { CountryCode, countryNamesMap, Locale } from '@attentive/locale-utils';

import {
  DisplayableOption,
  StringValueDisplayableOption,
  UNITED_STATES,
  UNSPECIFIED,
  UNSPECIFIED_VALUE,
} from '../../../constants';

const countryNames = countryNamesMap(Locale.enUS);
// TODO: sc - the backend returns 'United States' and locale utils returns 'United States of America'. We should unify these two
countryNames[CountryCode.US] = UNITED_STATES;

type SortedCountries = {
  all: StringValueDisplayableOption[];
  myRegions: StringValueDisplayableOption[];
};

type SortedCountryList = {
  allCountries: StringValueDisplayableOption[];
  supportedCountries: StringValueDisplayableOption[];
};

export function separateAndSortCountries(
  countries: DisplayableOption[],
  currentCountry: CountryCode,
  includeUnspecified?: boolean
): SortedCountryList {
  const companyCountryName = countryNames[currentCountry];

  const separatedCountries = countries.reduce(
    (acc, country) => {
      const countryOption = {
        optionValue: `${country.optionValue}`,
        displayName: country.displayName,
      };

      if (country.displayName === companyCountryName) {
        acc.myRegions.push(countryOption);
      } else {
        acc.all.push(countryOption);
      }
      return acc;
    },
    { all: [], myRegions: [] } as SortedCountries
  );

  if (includeUnspecified) {
    separatedCountries.myRegions.push({ optionValue: UNSPECIFIED_VALUE, displayName: UNSPECIFIED });
  }

  return {
    allCountries: sortBy(separatedCountries.all, 'displayName'),
    supportedCountries: separatedCountries.myRegions,
  };
}
