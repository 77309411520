import React from 'react';

import { Text } from '@attentive/picnic';

import {
  NestedPropertyStringCondition,
  RechargeFilterAttrs,
  SegmentParameters,
} from '../../../../constants';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import OptionSelector from '../OptionSelector';

import { getNestedFilter } from './utils';

interface OwnProps {
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
}

export enum RECHARGE_SUBSCRIPTION_STATUS_OPTIONS {
  ANY = 'ANY',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}

const STATUS_LABELS = {
  [RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ANY]: 'Any',
  [RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ACTIVE]: 'Active',
  [RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.CANCELLED]: 'Cancelled',
  [RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.EXPIRED]: 'Expired',
};

export function RechargeSubscriptionStatusSelector({ onChange, parameters }: OwnProps) {
  if (!parameters.nestedPropertyFilters) {
    throw new Error('Nested properties are missing');
  }

  const [{ stringCondition }] = getNestedFilter<NestedPropertyStringCondition>(
    RechargeFilterAttrs.STATUS,
    parameters
  );

  const statusValue = getStatusValue(stringCondition.values);

  return (
    <>
      <OptionSelector
        value={statusValue}
        onChange={(value) => {
          const [filter, nestedPropertertyFilters] = getNestedFilter<NestedPropertyStringCondition>(
            RechargeFilterAttrs.STATUS,
            parameters
          );

          const selectedValues =
            value === RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ANY
              ? [
                  RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ACTIVE,
                  RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.CANCELLED,
                  RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.EXPIRED,
                ]
              : [value];

          onChange({
            ...parameters,
            nestedPropertyFilters: [
              ...nestedPropertertyFilters,
              {
                ...filter,
                stringCondition: { ...filter.stringCondition, values: selectedValues },
              },
            ],
          });
        }}
        conditionName="subscription status"
        options={Object.entries(STATUS_LABELS).map(([optionValue, displayName]) => ({
          optionValue,
          displayName,
        }))}
      />
      <SegmentConditionItemContainer>
        <Text>for</Text>
      </SegmentConditionItemContainer>
    </>
  );
}

function getStatusValue(values: string[]) {
  if (values.length === 0) {
    return RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ACTIVE;
  } else if (values.length === 1) {
    return values[0];
  }

  return RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ANY;
}
