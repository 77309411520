import React from 'react';
import { useFragment, usePreloadedQuery } from 'react-relay';

import { DisplayableOption } from '../../../../../../../../../constants';
import { ItemSelectDialog } from '../../../../../../../../ItemSelectDialog';
import {
  JourneyConditionPreloadedQuery,
  JourneyConditionQuery,
  JourneyConditionQueryType,
  ComposeMessageChannel,
} from '../../../JourneyCondition';
import { useFilteredJourneys } from '../useJourneys';

import CompanyFrag, {
  JourneyDialog_TargetingCommon_Company$key as CompanyFragType,
} from '../__generated__/JourneyDialog_TargetingCommon_Company.graphql';

type SelectedItemsProps = {
  queryRef: JourneyConditionPreloadedQuery;
  channel: ComposeMessageChannel;
  selectedJourneys: string[];
  onRemoveSelection: (selection: DisplayableOption) => void;
  onClearAll: () => void;
};

export const SelectedItems = ({
  channel,
  selectedJourneys,
  onRemoveSelection,
  onClearAll,
  queryRef,
}: SelectedItemsProps) => {
  const queryData = usePreloadedQuery<JourneyConditionQueryType>(JourneyConditionQuery, queryRef);
  const companyFrag = useFragment<CompanyFragType>(CompanyFrag, queryData.node);
  const { data: journeys } = useFilteredJourneys(companyFrag, channel);

  const messageTags = selectedJourneys.map((journeyId) => {
    const journey = journeys.find(({ node }) => node.internalId === journeyId);

    if (!journey) {
      return { optionValue: journeyId, displayName: `Journey ID: ${journeyId}`, disabled: true };
    }

    return { optionValue: journeyId, displayName: journey.node.name, disabled: false };
  });

  return (
    <ItemSelectDialog.SelectedItems
      selectedOptions={messageTags}
      onRemoveSelection={onRemoveSelection}
      itemTypeLabel="Journeys"
      onClearAll={onClearAll}
      tagCss={{
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '410px',
      }}
    />
  );
};
