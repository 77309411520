import React, { useState } from 'react';

import {
  Box,
  Button,
  Heading,
  Icon,
  Link,
  PicnicCss,
  StandardDialog,
  Text,
} from '@attentive/picnic';

const PU_PROMPT_HEIGHT = 146;

const promptCss: PicnicCss = {
  display: 'flex',
  border: '$borderWidths$borderWidth1 solid $borderDefault',
  borderRadius: '$radius1',
  height: PU_PROMPT_HEIGHT,
  padding: '$space6',
};

interface CampaignDialogEmptyPromptProps {
  css: PicnicCss;
}

const CampaignDialogEmptyPrompt: React.FC<CampaignDialogEmptyPromptProps> = ({ css = {} }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <Box css={{ ...promptCss, ...css }}>
        <Icon
          name="MessageBubbleDots"
          size="large"
          css={{ marginRight: '$space9' }}
          color="subdued"
        />
        <div>
          <Heading variant="sm" css={{ fontWeight: 'normal', marginBottom: '$space1' }}>
            You haven't sent any campaigns yet
          </Heading>
          <Text color="subdued" variant="caption" css={{ marginRight: '$space4' }}>
            You'll have to send a campaign before you can segment by link clicks.
          </Text>
          <Box css={{ marginTop: '$space4', display: 'flex' }}>
            <Link
              css={{ fontSize: '$size3', cursor: 'pointer', fontWeight: '$bold' }}
              onClick={() => setShowConfirm(true)}
            >
              Create a campaign
              <Icon name="ArrowRight" css={{ marginLeft: '$space2' }} size="small" />
            </Link>
          </Box>
        </div>
      </Box>
      <StandardDialog open={showConfirm} onOpenChange={() => setShowConfirm(false)}>
        <StandardDialog.Content>
          <StandardDialog.Header>
            <StandardDialog.Heading>Lose unsaved changes?</StandardDialog.Heading>
          </StandardDialog.Header>
          <StandardDialog.Body>
            <Text>Go back to save your changes or continue on and lose unsaved changes.</Text>
          </StandardDialog.Body>
          <StandardDialog.Footer>
            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
              Cancel
            </Button>
            <Button as="a" variant="primary" href="/campaigns/compose">
              Continue
            </Button>
          </StandardDialog.Footer>
        </StandardDialog.Content>
      </StandardDialog>
    </>
  );
};

export default CampaignDialogEmptyPrompt;
