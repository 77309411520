import React, { FC, CSSProperties } from 'react';

import { Box, Checkbox, ContainedLabel, Heading, PicnicCss, Text } from '@attentive/picnic';

import { StringValueDisplayableOption } from '../../constants';
import SearchQueryHighlightedTextComponent from '../SearchQueryHighlightedText/SearchQueryHighlightedText';

import { CompleteProductDataOption } from './interfaces';
const rowCss: PicnicCss = {
  display: 'flex',
  borderBottom: '1px solid $borderDefault',
  padding: '$space0 $space4',
  height: 50,
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '$bgRowHover',
  },
};

interface RowProps {
  style: CSSProperties;
  option: CompleteProductDataOption;
  onRemoveSelection: (option: CompleteProductDataOption) => void;
  onAddSelection: (option: CompleteProductDataOption) => void;
  selectedOptions: CompleteProductDataOption[];
  outsideSelections?: Map<string, StringValueDisplayableOption[]>;
  searchQuery: string;
  showOptionIdSubtext?: boolean;
}

const isOptionSelected = (
  outsideSelections: StringValueDisplayableOption[] | undefined,
  optionValue: string
) => {
  if (!outsideSelections) {
    return false;
  }
  return outsideSelections.some((selection) => selection.optionValue === optionValue);
};

const getOutsideSelectionName = (
  outsideSelections: Map<string, StringValueDisplayableOption[]> | undefined,
  optionValue: string
) => {
  if (!outsideSelections) {
    return false;
  }
  return Array.from(outsideSelections).find(([_, branchOptions]) =>
    branchOptions.some((selection) => selection.optionValue === optionValue)
  )?.[0];
};

export const ProductDataDialogRow: FC<RowProps> = ({
  style,
  option,
  selectedOptions,
  onRemoveSelection,
  onAddSelection,
  outsideSelections,
  searchQuery,
  showOptionIdSubtext,
}) => {
  const isSelected = isOptionSelected(selectedOptions, option.optionValue);

  const nameOfOutsideSelection = getOutsideSelectionName(outsideSelections, option.optionValue);

  const isSelectedOutside = Boolean(nameOfOutsideSelection);

  const handleClick = () => {
    if (isSelectedOutside) return;

    if (isSelected) {
      onRemoveSelection(option);
    } else {
      onAddSelection(option);
    }
  };

  return (
    <Box css={rowCss} onClick={handleClick} style={style} role="button">
      <SearchQueryHighlightedTextComponent
        itemName={option.displayName}
        searchQuery={searchQuery}
        isSelected={isSelectedOutside}
        subtext={showOptionIdSubtext ? `ID ${option.optionValue}` : undefined}
      />
      {isSelectedOutside && nameOfOutsideSelection ? (
        <ContainedLabel variant="neutral">{nameOfOutsideSelection}</ContainedLabel>
      ) : (
        <Checkbox.CheckboxItem aria-label={`Selected-${option.optionValue}`} checked={isSelected} />
      )}
    </Box>
  );
};

export const ProductDataDialogSearchHeader: React.VFC<{
  disabled?: boolean;
  onClick: () => void;
  isChecked: boolean;
}> = ({ disabled, isChecked, onClick }) => {
  return (
    <Box css={{ ...rowCss, '&:hover': {} }}>
      <Heading variant="subheading" as="h2">
        Search Results
      </Heading>
      <Checkbox
        aria-label={isChecked ? 'Unselect all results' : 'Select all results'}
        checked={isChecked}
        disabled={disabled}
        onChange={onClick}
      >
        <Text variant="caption">Select all / Unselect all</Text>
      </Checkbox>
    </Box>
  );
};
