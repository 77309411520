import React, { FC } from 'react';

import { IconButton, PicnicCss, Text } from '@attentive/picnic';

import {
  DEFAULT_VALUE_COMPARATOR,
  IsVerbType,
  ProductDataAttributeValue,
  StringValueDisplayableOption,
} from '../../../../constants';
import { SegmentConditionContainer } from '../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import { isPriceAttribute } from '../../utils/filterType';
import ProductDataAttributeSelector from '../ProductData/ProductDataAttributeSelector';
import ProductDataValueSelector from '../ProductData/ProductDataValueSelector';
import VerbSelector, { VerbSelectorType } from '../VerbSelector';

import PriceSelector from './PriceSelector';

interface ProductDataSegmentConditionProps {
  isFirst: boolean;
  productDataAttributeValue: ProductDataAttributeValue;
  attributeOptions: StringValueDisplayableOption[];
  onDelete: () => void;
  onChange: (productDataAttributeValue: ProductDataAttributeValue) => void;
  setDialogIsOpen: (isOpen: boolean) => void;
  css?: PicnicCss;
}

const ProductDataSegmentCondition: FC<ProductDataSegmentConditionProps> = ({
  isFirst,
  productDataAttributeValue,
  attributeOptions,
  onDelete,
  onChange,
  setDialogIsOpen,
  css,
}) => {
  const { attribute, isVerb, name, valueComparator, values } = productDataAttributeValue;
  const currency = attributeOptions.find((o) => isPriceAttribute(o.optionValue))?.displayName;

  return (
    <SegmentConditionContainer css={css}>
      <SegmentConditionItemContainer css={{ width: '$size12' }}>
        <Text>{isFirst ? 'where' : 'and'}</Text>
      </SegmentConditionItemContainer>
      <ProductDataAttributeSelector
        attribute={attribute}
        name={name}
        options={attributeOptions}
        onChange={(attr) =>
          onChange({
            attribute: attr,
            values: [],
            isVerb: productDataAttributeValue.isVerb || IsVerbType.IS,
            valueComparator: isPriceAttribute(attr) ? DEFAULT_VALUE_COMPARATOR : undefined,
          })
        }
      />
      {attribute &&
        (isPriceAttribute(attribute) ? (
          <PriceSelector
            comparator={valueComparator || DEFAULT_VALUE_COMPARATOR}
            currency={currency}
            value={values[0]?.optionValue as number}
            onComparatorChange={(comparator) =>
              onChange({ ...productDataAttributeValue, valueComparator: comparator })
            }
            onValueChange={(value) =>
              onChange({
                ...productDataAttributeValue,
                values: value ? [{ displayName: String(value), optionValue: value }] : [],
              })
            }
          />
        ) : (
          <>
            <VerbSelector
              verbType={VerbSelectorType.IS}
              value={isVerb || IsVerbType.IS}
              overrideNot={true}
              onChange={(verb) =>
                onChange({ ...productDataAttributeValue, isVerb: verb as IsVerbType })
              }
            />
            <ProductDataValueSelector
              attribute={attribute}
              name={name}
              values={values as StringValueDisplayableOption[]}
              onChange={(newValues) =>
                onChange({ ...productDataAttributeValue, values: newValues })
              }
              setDialogIsOpen={setDialogIsOpen}
            />
          </>
        ))}
      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description="Remove product data condition"
          onClick={onDelete}
        />
      </SegmentConditionItemContainer>
    </SegmentConditionContainer>
  );
};

export default ProductDataSegmentCondition;
