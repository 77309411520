import { differenceInCalendarDays } from 'date-fns';
import React, { useState } from 'react';

import {
  Box,
  DateRangePicker,
  convertMomentToString,
  PicnicCss,
  FormField,
} from '@attentive/picnic';

import { partialDateRangeIsInvalid } from '../../utils';
import { TimeValue } from '../SegmentCreateEditModal/components/TimeSelector/constants';

import {
  MonthClickHandler,
  PARTIAL_DATE_ARIA_LABEL_FORMAT,
  PARTIAL_DATE_MONTH_FORMAT,
} from './constants';
import {
  getPartialDate,
  formatDateString,
  isStartOfDateRangeYear,
  isEndOfDateRangeYear,
} from './utils';

type DateRangeObject = {
  startDate: string | null;
  endDate: string | null;
};

type PartialDateRangePickerProps = {
  css?: PicnicCss;
  startDate: string | null;
  endDate: string | null;
  onChange: (dates: DateRangeObject) => void;
  values: TimeValue;
};

const MAX_DAYS = 365;
const MAX_DAYS_LEAP_YEAR = 366;

export const PartialDateRangePicker = ({
  css,
  onChange,
  values,
  ...props
}: PartialDateRangePickerProps) => {
  const startDateProp = props.startDate || getPartialDate();
  const endDateProp = props.endDate || getPartialDate(7);
  const [isOpen, setIsOpen] = useState(false);
  // startDate and endDate is the selected date range. A user can navigated between months without changing these values
  const [{ startDate, endDate }, setDates] = useState<DateRangeObject>({
    startDate: startDateProp,
    endDate: endDateProp,
  });
  const [currentStartDate, setCurrentStartDate] = useState<string | null>(null);
  const [allowedNumberOfDays, setAllowedNumberOfDays] = useState(MAX_DAYS);
  // displayedMonth updates as a user navigates between months. It represents the currently visible month
  const [displayedMonth, setDisplayedMonth] = useState<string | null>(startDateProp);
  const errorMessage = getErrorMessage(values, allowedNumberOfDays);
  const handleMonthClick: MonthClickHandler = (newCurrentMonth) => {
    const newMonth = convertMomentToString(newCurrentMonth);
    setDisplayedMonth(newMonth);
  };

  return (
    <FormField>
      <DateRangePicker.Root open={isOpen} onOpenChange={setIsOpen}>
        <DateRangePicker.Trigger>
          <DateRangePicker.Input
            value={formatDateRangeString(startDate, endDate)}
            size="small"
            state="normal"
            css={css}
          />
        </DateRangePicker.Trigger>
        <DateRangePicker.Popover>
          <DateRangePicker.Control
            startDate={startDate}
            endDate={endDate}
            onChange={(newDate) => {
              setIsOpen(false);
              setDates(newDate);
              onChange(newDate);
              setDisplayedMonth(newDate.startDate);
            }}
            onDateSelect={({ startDate: newStartDate }) => {
              setCurrentStartDate(newStartDate);

              if (newStartDate) {
                const leapYearDay = new Date('1972-02-29');
                const selectedStartDay = new Date(newStartDate);
                const difference = differenceInCalendarDays(selectedStartDay, leapYearDay);
                // difference <= 0 = date before 1972-02-29.
                // If the difference is less than 366, then they've picked a date that contains a leap day within its valid range
                const maxDays =
                  difference <= 0 && Math.abs(difference) < MAX_DAYS_LEAP_YEAR
                    ? MAX_DAYS_LEAP_YEAR
                    : MAX_DAYS;
                setAllowedNumberOfDays(maxDays);
              }
            }}
            isDayBlocked={(day) => {
              if (!currentStartDate) return false;

              const currentDay = new Date(day);
              const selectedStartDay = new Date(currentStartDate);

              const difference = Math.abs(differenceInCalendarDays(currentDay, selectedStartDay));

              return difference >= allowedNumberOfDays;
            }}
            noNavNextButton={isEndOfDateRangeYear(
              displayedMonth,
              currentStartDate,
              allowedNumberOfDays
            )}
            noNavPrevButton={isStartOfDateRangeYear(displayedMonth)}
            onPrevMonthClick={handleMonthClick}
            onNextMonthClick={handleMonthClick}
            monthFormat={PARTIAL_DATE_MONTH_FORMAT}
            dayAriaLabelFormat={PARTIAL_DATE_ARIA_LABEL_FORMAT}
            renderMonthElement={({ month }) => {
              return (
                <Box>
                  <strong>{month.format(PARTIAL_DATE_MONTH_FORMAT)}</strong>
                </Box>
              );
            }}
          />
        </DateRangePicker.Popover>
      </DateRangePicker.Root>
      {!isOpen && errorMessage && <FormField.ErrorText>{errorMessage}</FormField.ErrorText>}
    </FormField>
  );
};

function formatDateRangeString(startDateString: string | null, endDateString: string | null) {
  const momentStartDate = formatDateString(startDateString);
  const momentEndDate = formatDateString(endDateString);

  if (!momentStartDate) return '';

  return `${momentStartDate} ${momentEndDate ? `- ${momentEndDate}` : ''}`;
}

function getErrorMessage({ startTime, endTime }: TimeValue, maxDays: number) {
  if (!startTime || !endTime) {
    return false;
  }

  if (partialDateRangeIsInvalid(startTime, endTime, maxDays)) {
    return `You may only select up to ${maxDays} days for a partial date range`;
  }

  return false;
}
