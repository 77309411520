import { add, addDays, differenceInCalendarDays, setYear, startOfMonth } from 'date-fns';

import { convertDateToString, convertStringToMoment } from '@attentive/picnic';

import {
  PARTIAL_DATE_DEFAULT_VALUE,
  PARTIAL_DATE_FORMAT,
  PARTIAL_DATE_RANGE_DEFAULT_END_VALUE,
  PARTIAL_DATE_YEAR,
} from './constants';

function getPartialDateToday() {
  return setYear(new Date(), PARTIAL_DATE_YEAR);
}

export function getPartialDate(daysToAdd?: number) {
  let partialNow = getPartialDateToday();
  let defaultDateString = PARTIAL_DATE_DEFAULT_VALUE;

  if (daysToAdd) {
    partialNow = addDays(partialNow, daysToAdd);
    // If days are being added, this is an end date for a date range and should use a different default value
    defaultDateString = PARTIAL_DATE_RANGE_DEFAULT_END_VALUE;
  }

  const dateString = convertDateToString(partialNow);

  return dateString || defaultDateString;
}

export function formatDateString(dateString: string | null) {
  const momentDate = convertStringToMoment(dateString);
  return momentDate ? momentDate.format(PARTIAL_DATE_FORMAT) : '';
}

function isPartialMonth(date: string | null, month: string, year = PARTIAL_DATE_YEAR) {
  return Boolean(date?.includes(`${year}-${month}`));
}

export function isStartOfYear(date: string | null) {
  return isPartialMonth(date, '01');
}

export function isEndOfYear(date: string | null) {
  return isPartialMonth(date, '12');
}

export function isStartOfDateRangeYear(date: string | null) {
  const startYear = PARTIAL_DATE_YEAR - 1;
  return isPartialMonth(date, '01', startYear);
}

// The date range picker displays 2 months at a time. The value passed to this function is for the month on the left
// If we used the standard end of year function, the date range picker would possibly display December and January from the next year
export function isEndOfDateRangeYear(
  displayedDate: string | null,
  selectedStartDate: string | null,
  maxDays: number
) {
  if (selectedStartDate && displayedDate) {
    const selectedDate = new Date(selectedStartDate);

    // nextDisplayedMonth date represents the next month that will be displayed when clicking the next month navigation.
    const nextDisplayedMonth = add(startOfMonth(new Date(displayedDate)), { months: 2 });

    const difference = Math.abs(differenceInCalendarDays(selectedDate, nextDisplayedMonth));

    if (difference >= maxDays) {
      return true;
    }
  }

  const endYear = PARTIAL_DATE_YEAR + 1;
  return isPartialMonth(displayedDate, '11', endYear);
}
