import React from 'react';

import { IconButton, PicnicCss, Text } from '@attentive/picnic';

import {
  CustomAttributeValueGroup,
  IsVerbType,
  SelectableCampaignType,
} from '../../../../../../constants';
import { SegmentConditionContainer } from '../../../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../../../SegmentConditionItemContainer';
import VerbSelector, { VerbSelectorType } from '../../../VerbSelector';
import { CampaignMessageSelector } from '../CampaignMessageSelector';

interface OwnProps {
  msgCondition: CustomAttributeValueGroup;
  setDialogIsOpen: (isOpen: boolean) => void;
  onChange: (customAttributeValueGroup: CustomAttributeValueGroup) => void;
  onRemove: () => void;
  selectableCampaignType?: SelectableCampaignType;
  css?: PicnicCss;
}

export const LegacyMessageSegmentCondition: React.FC<OwnProps> = ({
  msgCondition,
  setDialogIsOpen,
  onChange,
  onRemove,
  selectableCampaignType,
  css,
}) => {
  const { isVerb, values } = msgCondition;

  return (
    <SegmentConditionContainer css={css}>
      <SegmentConditionItemContainer>
        <Text>where campaign</Text>
      </SegmentConditionItemContainer>
      <VerbSelector
        verbType={VerbSelectorType.IS}
        value={isVerb || IsVerbType.IS}
        onChange={(verb) => onChange({ ...msgCondition, isVerb: verb as IsVerbType })}
      />
      <CampaignMessageSelector
        values={values}
        setDialogIsOpen={setDialogIsOpen}
        onSubmit={(newValues) => onChange({ ...msgCondition, values: newValues })}
        selectableCampaignType={selectableCampaignType}
      />
      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description="Remove message condition"
          onClick={onRemove}
        />
      </SegmentConditionItemContainer>
    </SegmentConditionContainer>
  );
};
