import React, { useState } from 'react';

import { FormField, MultiSelect, TextInput } from '@attentive/picnic';

import { CustomAttributeOptionValue, SubscriberAttributeDataType } from '../../../../constants';
import { useCompany } from '../../../../hooks';

import { isCommaSeparatedField } from './commaSeparatedFreeformProperties';

const PU_DELETED_OPTION_CSS = { '& > span': { color: '$textCritical' } };

interface OwnProps {
  type: SubscriberAttributeDataType;
  selectedValues: CustomAttributeOptionValue[];
  optionValues: CustomAttributeOptionValue[];
  onChange: (newValues: CustomAttributeOptionValue[]) => void;
  showValidation: boolean;
  disabled?: boolean;
  attribute: string;
}

const CustomPropertySelector: React.FC<OwnProps> = ({
  type,
  selectedValues,
  optionValues,
  onChange,
  showValidation,
  disabled,
  attribute,
}) => {
  const { internalId } = useCompany();
  const isFreeFormCommaSeparatedField = isCommaSeparatedField(internalId, attribute);
  const freeForm = type === SubscriberAttributeDataType.TEXT;
  const showEmptyError = showValidation && selectedValues.length < 1;
  const hasDeletedValue = selectedValues.some((val) => val.deleted);
  const selectedOptionValues = selectedValues.map((val) => val.optionValue);
  const [localCsvValues, setLocalCsvValues] = useState(formatCommaSeparatedValues());

  const options = optionValues.filter(({ deleted, optionValue }) => {
    return !deleted || selectedOptionValues.includes(optionValue);
  });

  const handleChange = (selectedOptions: string[]) => {
    let newValues = [];
    if (isFreeFormCommaSeparatedField) {
      const splitValues = selectedOptions[0].split(',');
      const dedupedValues = [...new Set(splitValues)];
      newValues = dedupedValues.reduce<CustomAttributeOptionValue[]>((acc, value) => {
        const trimmedValue = value.trim();
        if (trimmedValue) {
          acc.push({ displayName: trimmedValue, optionValue: trimmedValue });
        }
        return acc;
      }, []);
    } else if (freeForm) {
      newValues = [{ displayName: selectedOptions[0], optionValue: selectedOptions[0] }];
    } else {
      newValues = selectedOptions.reduce<CustomAttributeOptionValue[]>((acc, optionValue) => {
        const foundOption = options.find((option) => option.optionValue === optionValue);
        if (foundOption) acc.push(foundOption);
        return acc;
      }, []);
    }
    onChange(newValues);
  };

  function formatCommaSeparatedValues() {
    if (!selectedOptionValues || !selectedOptionValues.length) return '';

    return selectedOptionValues.join(',');
  }

  return (
    <FormField>
      {isFreeFormCommaSeparatedField && (
        <TextInput
          value={localCsvValues}
          placeholder="Enter a comma separated list of values"
          onChange={(e) => {
            setLocalCsvValues(e.target.value);
            handleChange([e.target.value]);
          }}
          state={showEmptyError ? 'error' : 'normal'}
          disabled={disabled}
          size="small"
          css={{ minWidth: '300px' }}
        />
      )}
      {freeForm && !isFreeFormCommaSeparatedField && (
        <TextInput
          value={selectedOptionValues[0] || ''}
          placeholder="Enter a value"
          onChange={(e) => handleChange([e.target.value])}
          state={showEmptyError ? 'error' : 'normal'}
          disabled={disabled}
          size="small"
        />
      )}
      {!freeForm && (
        <MultiSelect
          value={selectedOptionValues}
          placeholder="Choose values"
          onChange={(newValues: string[]) => handleChange(newValues)}
          state={showEmptyError || hasDeletedValue ? 'error' : 'normal'}
          maxTokens={1}
          disabled={disabled}
          css={{ backgroundColor: '$bgDefault' }}
          size="small"
        >
          {options.map(({ displayName, optionValue, deleted }) => (
            <MultiSelect.Item
              key={optionValue}
              value={optionValue}
              css={deleted ? PU_DELETED_OPTION_CSS : {}}
            >
              {displayName}
            </MultiSelect.Item>
          ))}
        </MultiSelect>
      )}
      {(showEmptyError || hasDeletedValue) && (
        <FormField.ErrorText>
          {showEmptyError ? 'Please choose a value.' : 'Remove or replace this value.'}
        </FormField.ErrorText>
      )}
    </FormField>
  );
};

export default CustomPropertySelector;
