/**
 * @generated SignedSource<<19c0f75c35b9190e35069295f9ad1342>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComposeMessageChannel = "COMPOSE_MESSAGE_CHANNEL_EMAIL" | "COMPOSE_MESSAGE_CHANNEL_TEXT" | "COMPOSE_MESSAGE_CHANNEL_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useJourneys_TargetingCommon_JourneyConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly internalId: string;
      readonly journeyRevisions: ReadonlyArray<{
        readonly messages: ReadonlyArray<{
          readonly composeMessage: {
            readonly channel: ComposeMessageChannel;
            readonly id: string;
          } | null;
        }>;
      }> | null;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"JourneyRow_TargetingCommon_Journey">;
    };
  }>;
  readonly " $fragmentType": "useJourneys_TargetingCommon_JourneyConnection";
};
export type useJourneys_TargetingCommon_JourneyConnection$key = {
  readonly " $data"?: useJourneys_TargetingCommon_JourneyConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"useJourneys_TargetingCommon_JourneyConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useJourneys_TargetingCommon_JourneyConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JourneyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Journey",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JourneyRow_TargetingCommon_Journey"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "JourneyRevision",
              "kind": "LinkedField",
              "name": "journeyRevisions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JourneyMessage",
                  "kind": "LinkedField",
                  "name": "messages",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ComposeMessage",
                      "kind": "LinkedField",
                      "name": "composeMessage",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "channel",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JourneyConnection",
  "abstractKey": null
};
})();

(node as any).hash = "21394d43e63b491508152ff09400d01d";

export default node;
