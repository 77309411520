import { SegmentParameters, TimeComparatorType } from '../../../../constants';

// This is the year we use for partial dates. The BE will ignore these but the date picker is built using the year
// 1972 so we use this date for consistency. 1972 is the first leap year after the unix epoch
export const PARTIAL_DATE_YEAR = 1972;

export type TimeValue = Pick<
  SegmentParameters,
  | 'startTime'
  | 'endTime'
  | 'time'
  | 'durationTime'
  | 'durationUnit'
  | 'durationStart'
  | 'durationEnd'
  | 'months'
  | 'ignoreYear'
> & {
  timeComparator: TimeComparatorType;
};

export const TIME_PARAMETERS: Array<keyof TimeValue> = [
  'timeComparator',
  'startTime',
  'endTime',
  'time',
  'durationUnit',
  'durationEnd',
  'durationStart',
  'durationTime',
  'months',
  'ignoreYear',
];
