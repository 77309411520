import { useCompanyFeatureFlag, useCurrentUser } from '@attentive/acore-utils';

const SEGMENT_ASSISTANT_ALLOWED_USERS = [
  'scorrales@attentivemobile.com',
  'tmolinero@attentivemobile.com',
  'pkannan@attentivemobile.com',
  'gskrapits@attentivemobile.com',
  'dlewandowski@attentivemobile.com',
  'denak@attentivemobile.com',
  // Targeting team
  'acordova@attentivemobile.com',
  'ccarter@attentivemobile.com',
  'dguddemi@attentivemobile.com',
  'dadolphson@attentivemobile.com',
  'iromero@attentivemobile.com',
  'mhamannwright@attentivemobile.com',
  'mndubuisi@attentivemobile.com',
  'tchichirico@attentivemobile.com',
  'ychettiar@attentivemobile.com',
  // Design
  'almitra@attentivemobile.com',
  'rbrinkerhoff@attentivemobile.com',
  // Testers
  'kzheng@attentivemobile.com',
  'jpattni@attentivemobile.com',
  'davidc@attentivemobile.com',
  'ngoldweitz@attentivemobile.com',
  'jwallner@attentivemobile.com',
  'knajera@attentivemobile.com',
];

export const useIsSegmentAssistantEnabled = () => {
  const { email } = useCurrentUser();
  const featureFlagEnabled = useCompanyFeatureFlag('ENABLE_SEGMENT_ASSISTANT_EXPERIMENT');

  return featureFlagEnabled && SEGMENT_ASSISTANT_ALLOWED_USERS.includes(email);
};
