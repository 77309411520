import _useCopyAssistantTemplate_TargetingCommon_Query from "./__generated__/useCopyAssistantTemplate_TargetingCommon_Query.graphql";
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from '@attentive/data';
import Query from './__generated__/useCopyAssistantTemplate_TargetingCommon_Query.graphql';
_useCopyAssistantTemplate_TargetingCommon_Query;
export const useCopyAssistantTemplate = templateName => {
  const {
    copyAssistantTemplate
  } = useLazyLoadQuery(Query, {
    templateName
  });

  if (!(copyAssistantTemplate !== null && copyAssistantTemplate !== void 0 && copyAssistantTemplate.template)) {
    throw new Error("Cannot load ".concat(templateName, " template"));
  }

  return copyAssistantTemplate.template.liveVersion || '1';
};