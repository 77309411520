import { useMutation } from 'react-relay';

import { useCurrentCompanyId } from '@attentive/acore-utils';

import { CustomAttributeErrors } from '../../constants';
import { CustomAttributeFormState } from '../../form';
import { SaveFormResponse } from '../FormDialog';
import { buildNewFieldsVariable } from '../utils';

import UpdateMutation, {
  UpdateCustomAttributeTargetingCommonMutation as UpdateMutationType,
} from './__generated__/UpdateCustomAttributeTargetingCommonMutation.graphql';

export function useUpdateCustomAttributeMutation(customPropertyId: string) {
  const companyId = useCurrentCompanyId();
  const [commit, commitInFlight] = useMutation<UpdateMutationType>(UpdateMutation);

  const updateCustomAttribute = (values: CustomAttributeFormState) => {
    return new Promise<SaveFormResponse>((resolve, reject) => {
      if (!customPropertyId) {
        reject(new Error('Custom attribute ID not set'));
        return;
      }

      const newFields = buildNewFieldsVariable(values);

      commit({
        variables: {
          companyId,
          customPropertyId,
          newFields,
        },
        onCompleted: (response, errors) => {
          if (errors?.some((err) => err.message.includes('ALREADY_EXISTS'))) {
            reject(new Error(CustomAttributeErrors.ALREADY_EXISTS));
            return;
          }

          const propertyDefintion =
            response.updateCustomPropertyDefinition?.customPropertyDefinition;

          if (!propertyDefintion) {
            reject(new Error(CustomAttributeErrors.UNKNOWN_UPDATE_ERROR));
            return;
          }

          const {
            id,
            name,
            customPropertyId: { internalId },
          } = propertyDefintion;
          resolve({ id, name, internalId });
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  return [updateCustomAttribute, commitInFlight] as const;
}
