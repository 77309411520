import React, { FC } from 'react';

import { useIsOperatorUIEnabled } from '../../../OperatorUIToggle';

import LegacySegmentExpressionBuilder, {
  SegmentExpressionBuilderProps,
} from './LegacySegmentExpressionBuilder';
import { NewSegmentExpressionBuilder } from './NewSegmentExpressionBuilder';

export const SegmentExpressionBuilder: FC<SegmentExpressionBuilderProps> = (props) => {
  const enableOperatorUI = useIsOperatorUIEnabled();
  if (enableOperatorUI) {
    return (
      <NewSegmentExpressionBuilder {...props} availableSegmentData={props.availablSegmentData} />
    );
  }

  return <LegacySegmentExpressionBuilder {...props} />;
};
