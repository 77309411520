import React from 'react';

import { Box, Checkbox, FormField, Heading, styled } from '@attentive/picnic';

import SearchQueryHighlightedText from '../../../../../../../../SearchQueryHighlightedText/SearchQueryHighlightedText';
import { OnMessageClickFn, SelectedMessages } from '../types';

type JourneyMessagesProps = {
  journeyId: string;
  name: string;
  messages: string[];
  onMessageChange: OnMessageClickFn;
  selectedMessages: SelectedMessages;
  searchText: string;
};

const rowStyling = {
  display: 'flex',
  borderBottom: '1px solid $borderDefault',
  padding: '$space0 $space4',
  height: '52px',
  justifyContent: 'space-between',
};

const DialogHeading = styled(Box, {
  ...rowStyling,
  pb: '$space2',
  alignItems: 'end',
});

const DialogRow = styled(Box, {
  ...rowStyling,
  alignItems: 'center',
  fontWeight: 'normal',
  pb: '$space0',
  '&:hover': {
    backgroundColor: '$bgRowHover',
  },
});

export const JourneyMessages = ({
  journeyId,
  name,
  messages,
  onMessageChange,
  selectedMessages,
  searchText,
}: JourneyMessagesProps) => {
  const currentJourney = selectedMessages[journeyId] || [];

  return (
    <>
      <DialogHeading>
        <Heading variant="subheading">{name}</Heading>
      </DialogHeading>
      {messages.map((message) => (
        <FormField.Label key={`${journeyId}:${message}`}>
          <DialogRow>
            <SearchQueryHighlightedText itemName={message} searchQuery={searchText} />
            <Box css={{ flexShrink: 0 }}>
              <Checkbox.CheckboxItem
                checked={currentJourney.includes(message)}
                onChange={() => onMessageChange(journeyId, message)}
              />
            </Box>
          </DialogRow>
        </FormField.Label>
      ))}
    </>
  );
};
