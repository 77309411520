import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import {
  customAttributeTimeComparatorOptions,
  defaultTimeComparatorOptions,
  eventUnsupporedTimeComparators,
  newDateTimeComparators,
  customAttributePartialDateTimeComparators,
  partialDateUnsupportedTimeComparators,
  RechargeFilterAttrs,
  rechargeFutureDateTimeComparators,
  rechargeTimeComparators,
  SegmentParameters,
  TimeComparatorType,
  vendorAttributeTimeComparatorOptions,
} from '../../../../constants';
import {
  isCustomAttributeType,
  isCustomEventType,
  isEventType,
  isRechargeAttributeType,
  isSpecificRechargeAttribute,
  isVendorAttributeType,
} from '../../../../utils/typeAssertions';

export function useTimeComparators(parameters: SegmentParameters, isPartialDate: boolean) {
  const enableCustomAttributeInTheNextComparator = useCompanyFeatureFlag(
    'ENABLE_CUSTOM_ATTRIBUTE_IN_THE_NEXT_COMPARATOR'
  );
  const enableNewTimeComparators = useCompanyFeatureFlag(
    'ENABLE_SEGMENTATION_NEW_TIME_COMPARATORS'
  );
  const isEvent = isEventType(parameters) || isCustomEventType(parameters);

  const isRechargeAttribute = isRechargeAttributeType(parameters);
  const isCustomAttribute = isCustomAttributeType(parameters);

  let options = defaultTimeComparatorOptions;

  if (isRechargeAttribute) {
    const isFutureDate = isSpecificRechargeAttribute(
      RechargeFilterAttrs.NEXT_CHARGE_SCHEDULED_AT,
      parameters
    );
    options = isFutureDate ? rechargeFutureDateTimeComparators : rechargeTimeComparators;
  }

  if (isVendorAttributeType(parameters)) {
    options = vendorAttributeTimeComparatorOptions;
  }

  if (isCustomAttribute) {
    options = enableCustomAttributeInTheNextComparator
      ? [...customAttributeTimeComparatorOptions, TimeComparatorType.IN_THE_NEXT]
      : customAttributeTimeComparatorOptions;
  }

  if (enableNewTimeComparators) {
    options = [...options, ...newDateTimeComparators];
  }

  if (isPartialDate) {
    options = [
      ...options.filter((opt) => !partialDateUnsupportedTimeComparators.includes(opt)),
      // Add partial date comparators that are only supported for custom attributes
      ...(isCustomAttribute ? customAttributePartialDateTimeComparators : []),
    ];
  }

  if (isEvent) {
    options = options.filter((opt) => !eventUnsupporedTimeComparators.includes(opt));
  }

  return options;
}
