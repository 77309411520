// constants.ts is a catchall for items that don't fit in the other files
export const SHOPIFY_VENDOR_NAME = 'SHOPIFY';
export const SHOPIFY_DISPLAY_NAME = 'Display';
export const KLAVIYO_VENDOR_NAME = 'KLAVIYO';
export const KLAVIYO_DISPLAY_NAME = 'Klaviyo';
export const KLAVIYO_EMAIL_FEATURE = 'FEATURE_SYNC_EMAIL_EVENTS';
export const KLAVIYO_SEGMENTS_FEATURE = 'SYNC_THIRD_PARTY_LISTS';
export const CAMPAIGN_ATTR = 'CAMPAIGN';
export const PRODUCT_PRICE_ATTR = 'PRODUCT_PRICE';
export const PRODUCT_PRICE_DISPLAY = 'Price';
export const PRODUCT_PRICE_USD = 'USD';
export const DEBOUNCED_FIELD_UPDATE_INTERVAL = 750;
export const DEBOUNCED_SITE_FIELD_UPDATE_INTERVAL = 2250;
export const SENT_KEYWORD_PROPERTY_NAME = 'MESSAGE_BODY';

export enum AvailableDataType {
  PURCHASE = 'PURCHASE',
  PRODUCT_VIEW = 'PRODUCT_VIEW',
  ADD_TO_CART = 'ADD_TO_CART',
}

export enum UserPropertyType {
  CUSTOM_EVENT_TYPE = 'CUSTOM_EVENT_TYPE',
  CUSTOM_PROPERTY_ID = 'CUSTOM_PROPERTY_ID',
  THIRD_PARTY_PROPERTY = 'THIRD_PARTY_PROPERTY',
  WELL_KNOWN_PROPERTY = 'WELL_KNOWN_PROPERTY',
}

export enum FilterType {
  ACTION = 'subscriberActivity',
  ATTRIBUTE = 'subscriberAttributeOptions',
  MEMBERSHIP = 'subscriberMembership',
}

export enum LocationType {
  STATE = 'STATE',
  CITY = 'CITY',
  POSTAL_CODE = 'POSTAL_CODE',
  COUNTRY = 'COUNTRY',
}

export enum ORDER {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export type QuantityTypes = 'number' | 'amount' | 'year';

export enum SegmentComponentType {
  CUSTOM = 'CUSTOM',
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  SUBSCRIBER_TAG = 'SUBSCRIBER_TAG',
  URL_VIEWED = 'URL_VIEWED',
  LOCATION = 'LOCATION',
  BULK_LOCATION = 'BULK_LOCATION',
  PRODUCT_DATA = 'PRODUCT_DATA',
  PROFILE = 'PROFILE',
  SUBSCRIBER_PREFERENCES = 'SUBSCRIBER_PREFERENCES',
  SUBSCRIBER_LIST = 'SUBSCRIBER_LIST',
  DEVICE = 'DEVICE',
  CARRIER = 'CARRIER',
  USER_EVENT = 'USER_EVENT',
  USER_PROPERTY = 'USER_PROPERTY',
}

export enum SegmentTemplateId {
  NEW_SUBSCRIBERS = 1,
  ENGAGED_SUBSCRIBERS = 2,
  PROSPECTS = 3,
  REPEAT_BUYERS = 4,
  UNENGAGED_SUBSCRIBERS = 5,
  HOLIDAY_TARGET_SUBSCRIBERS = 6,
}

export type SelectableCampaignType = 'ATTENTIVE_TEXT' | 'ATTENTIVE_EMAIL' | 'KLAVIYO_EMAIL';

export enum SORT_BY {
  ALPHABETICAL = 'NAME',
  LAST_EDITED = 'UPDATED',
  DATE_ADDED = 'CREATED',
}

export enum Source {
  DYNAMIC = 'DYNAMIC',
  INTERNAL_API = 'INTERNAL_API',
  MANUAL_UPLOAD = 'MANUAL_UPLOAD',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum SourceType {
  KEYWORD = 'KEYWORD',
  CREATIVE = 'CREATIVE',
  CHECKOUT = 'CHECKOUT',
  MANUAL = 'MANUAL',
  API = 'API',
}

export enum SubscriberPreference {
  SUBSCRIBER_PREFERENCES = 'SUBSCRIBER_PREFERENCES',
}

export type SupportedCountryValues = 'US' | 'CA';

export enum SyncStatus {
  NEEDS_SYNC = 'NEEDS_SYNC',
  PREPARING_FOR_SYNC = 'PREPARING_FOR_SYNC',
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
  INVALID = 'INVALID',
}

export enum Visibility {
  ALL = 'ALL',
  SUPERUSER = 'SUPERUSER',
  NONE = 'NONE',
}

export enum CampaignStatus {
  ATTENTIVE_REVIEW = 'ATTENTIVE_REVIEW',
  SCHEDULED = 'SCHEDULED',
  SEND = 'SEND',
  PAUSED = 'PAUSED',
  SCHEDULED_DRAFT = 'SCHEDULED_DRAFT',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  NEEDS_COUPONS = 'NEEDS_COUPONS',
}

// Currently segment-options and segmentable-attributes use different data type values. We should look to unify when moving to GQL
export enum SubscriberAttributeDataType {
  BOOLEAN = 'TYPE_BOOL',
  ENUM = 'TYPE_ENUM',
  INTEGER = 'TYPE_NUMBER',
  TEXT = 'TYPE_STRING',
  TIMESTAMP = 'TYPE_DATE',
  OBJECT = 'TYPE_OBJECT',
  // UNKNOWN does not exist on the BE, only on FE to handle a case where we don't find a match
  UNKNOWN = 'TYPE_UNKNOWN',
}

export enum VendorAttributeDataType {
  BOOLEAN = 'SEGMENT_DATA_TYPE_BOOLEAN',
  FLOAT = 'SEGMENT_DATA_TYPE_FLOAT',
  INTEGER = 'SEGMENT_DATA_TYPE_INTEGER',
  LIST = 'SEGMENT_DATA_TYPE_LIST',
  OBJECT = 'SEGMENT_DATA_TYPE_OBJECT',
  TEXT = 'SEGMENT_DATA_TYPE_TEXT',
  TIMESTAMP = 'SEGMENT_DATA_TYPE_TIMESTAMP',
  // UNKNOWN does not exist on the BE, only on FE to handle a case where we don't find a match
  UNKNOWN = 'SEGMENT_DATA_TYPE_UNKNOWN',
}

export const SupportedCustomAttributeDataType = [
  SubscriberAttributeDataType.BOOLEAN,
  SubscriberAttributeDataType.INTEGER,
  SubscriberAttributeDataType.ENUM,
  SubscriberAttributeDataType.TEXT,
  SubscriberAttributeDataType.TIMESTAMP,
];

export const SupportedVendorAttributeDataType = [
  VendorAttributeDataType.BOOLEAN,
  VendorAttributeDataType.FLOAT,
  VendorAttributeDataType.INTEGER,
  VendorAttributeDataType.TEXT,
  VendorAttributeDataType.TIMESTAMP,
];

export const supportedKlaviyoAttributeDataTypes = [
  VendorAttributeDataType.TEXT,
  VendorAttributeDataType.BOOLEAN,
  VendorAttributeDataType.INTEGER,
  VendorAttributeDataType.TIMESTAMP,
];

export const UNITED_STATES = 'United States';
export const UNSPECIFIED = 'Unspecified';
export const UNSPECIFIED_VALUE = 'UNSPECIFIED';
