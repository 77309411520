import React, { useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { Box, PicnicCss, Text } from '@attentive/picnic';

import { DisplayableOption } from '../../../constants';
import { campaignRowHeight, KlaviyoCampaign } from '../constants';
import { useKlaviyoCampaigns } from '../hooks/useKlaviyoCampaigns';

import CampaignDialogEmptyPrompt from './CampaignDialogEmptyPrompt';
import { MessageRowComponent } from './MessageRowComponent';

const PU_CAMPAIGN_LIST_WIDTH = 494;

const emptyStateCss: PicnicCss = {
  marginTop: '$space4',
};

interface KlaviyoCampaignDialogBodyProps {
  searchString: string;
  selections: DisplayableOption[];
  handleAddMessages: (optionValues: DisplayableOption[]) => void;
  handleRemoveMessages: (optionValues: DisplayableOption[]) => void;
  setHasCampaigns: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const KlaviyoCampaignDialogBody = ({
  searchString,
  selections,
  handleAddMessages,
  handleRemoveMessages,
  setHasCampaigns,
}: KlaviyoCampaignDialogBodyProps) => {
  const { filteredCampaigns, totalCount } = useKlaviyoCampaigns(searchString);

  useEffect(() => {
    setHasCampaigns(!!totalCount);
  }, [totalCount, setHasCampaigns]);

  const selectedMessageValues = selections.map((o) => o.optionValue);
  const handleCampaignRowClick = (campaign: KlaviyoCampaign) => {
    const { externalId, name } = campaign;
    const messageIsSelected = selectedMessageValues.includes(externalId);
    const option = { optionValue: externalId, displayName: name };

    if (messageIsSelected) {
      handleRemoveMessages([option]);
    } else {
      handleAddMessages([option]);
    }
  };

  return (
    <Box>
      {totalCount === 0 && <CampaignDialogEmptyPrompt css={emptyStateCss} />}
      {filteredCampaigns.length === 0 && searchString && (
        <Text css={emptyStateCss}>No results</Text>
      )}
      {filteredCampaigns.length > 0 && (
        <AutoSizer>
          {({ height }) => (
            <FixedSizeList
              height={height}
              width={PU_CAMPAIGN_LIST_WIDTH}
              itemSize={campaignRowHeight}
              itemCount={filteredCampaigns.length}
            >
              {(listProps) => {
                const { index, style } = listProps;
                const campaign = filteredCampaigns[index];
                return (
                  <Box style={style}>
                    <MessageRowComponent
                      key={index}
                      isChecked={selectedMessageValues.includes(campaign.externalId)}
                      onClickHandler={() => handleCampaignRowClick(campaign)}
                      primaryText={campaign.name}
                      queryString={searchString}
                    />
                  </Box>
                );
              }}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </Box>
  );
};
