import { camelCase, kebabCase } from '@attentive/nodash';

import { segmentTemplates } from '../segmentTemplates';

import { useAvailableSegmentData } from './availableSegmentData';

export type SegmentTemplateType = keyof typeof segmentTemplates;

/**
 * If the returned value is `undefined`, the template does not exist
 * If the returned value is `null`, the template exists but the company does not meet the template's data requirements
 */
export const useSegmentTemplate = (templateId?: string) => {
  const availableData = useAvailableSegmentData();

  const formattedKey = templateId ? (camelCase(templateId) as SegmentTemplateType) : undefined;
  const segmentTemplate = getSegmentTemplate(formattedKey);

  if (!segmentTemplate) {
    return undefined;
  }

  const canUseTemplate =
    !segmentTemplate.neededDataType ||
    (availableData &&
      segmentTemplate.neededDataType.every((dataType) => availableData.get(dataType)));

  return canUseTemplate ? segmentTemplate : null;
};

/**
 * Returns a URL for accessing a segment template
 *
 * If the return is `null`, the segment template either does not exist or the current company does not
 * meet the template's data requirements
 *
 * @param {string} templateId - the template to fetch a URL for
 * @returns {(string|null)} returns a string if the template exists and is accessible to the current user
 */
export const useSegmentTemplateURL = (templateId: SegmentTemplateType) => {
  const segmentTemplate = useSegmentTemplate(templateId);

  return segmentTemplate ? `/segments/create/${kebabCase(templateId)}` : null;
};

function getSegmentTemplate(templateId?: SegmentTemplateType) {
  if (!templateId) return null;

  return segmentTemplates[templateId] || null;
}
