import React from 'react';

import { FormField, Select, Text } from '@attentive/picnic';

import { BooleanValue } from '../../../constants';
import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

type OwnProps = {
  textValue?: string;
  onChange: (value: string) => void;
};

export const BooleanSelector: React.VFC<OwnProps> = ({ textValue, onChange }) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const showError = showValidation && !textValue;

  return (
    <>
      <SegmentConditionItemContainer>
        <Text>is</Text>
      </SegmentConditionItemContainer>
      <SegmentConditionItemContainer>
        <Select
          onChange={onChange}
          value={textValue}
          size="small"
          css={{ minWidth: '120px' }}
          placeholder="Select a value"
        >
          <Select.Item value={BooleanValue.TRUE}>true</Select.Item>
          <Select.Item value={BooleanValue.FALSE}>false</Select.Item>
        </Select>
        {showError && <FormField.ErrorText>Please choose a value.</FormField.ErrorText>}
      </SegmentConditionItemContainer>
    </>
  );
};
