import { getNestedFilter } from '../../components/SegmentCreateEditModal/components/Recharge/utils';
import {
  SubscriberAttributeDataTypeMap,
  VendorAttributeDataTypeMap,
} from '../../components/SegmentCreateEditModal/utils/attributeDataTypeMap';
import {
  UserPropertyType,
  DistanceUnit,
  EmailActionFilter,
  JoinedActionFilter,
  MAX_ZIP_CODES_PER_FIELD,
  PropertiesFilter,
  SegmentParameters,
  ShopifyActionFilter,
  SignUpMethodFilter,
  TextActionFilter,
  BooleanValue,
  ActionFilter,
  WellKnownPropertyLocaleTypes,
  NestedPropertyStringCondition,
  IsVerbType,
} from '../../constants';
import { getLocationValues } from '../location';
import {
  getFilter,
  isBazaarvoiceEventType,
  isBooleanFilterType,
  isIntegerFilterType,
  isLanguageType,
  isMailExchangeType,
  isRechargeAttributeType,
  isSupportedVendor,
  isTimestampFilterType,
  isWellKnownCountryType,
} from '../typeAssertions';

import { bazaarvoiceEventIsInvalid } from './bazaarvoice';
import { customEventIsInvalid } from './customEvent';
import { isWithinValidRadius } from './distance';
import { frequencyComparatorIsInvalid } from './frequency';
import { journeyMessageDataIsInvalid, messageDataIsInvalid } from './message';
import { patternIsInvalid } from './pattern';
import { productDataIsInvalid } from './productData';
import { quantityIsInvalid } from './quantity';
import { rechargeAttributeIsInvalid } from './recharge';
import { sentKeywordIsInvalid } from './string';
import { timeComparatorIsInvalid, MAX_DAYS } from './time';
import { userPropertyValuesAreInvalid } from './userPropertyValue';

export const isValidSegmentCondition = (
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) => {
  const {
    profileAttribute,
    isAttributeSet,
    frequency,
    frequencyComparator,
    propertyAttribute,
    propertyAttributeCondition,
    fieldValues,
    stateId,
    tagKey,
    tagValue,
    quantity,
    quantityEnd,
    quantityComparator,
    textValue,
    textValues,
    subscriberPreferenceKey,
    subscriberPreferenceValue,
    subscriberListMetadataId,
    productDataAttributeValues,
    customAttributeValueGroup,
    customEventOption,
    deviceTypes,
    deviceOSList,
    carrier,
    userPropertyValues,
    deleted,
    subscriberAttributeValueGroup,
    locations,
    radius,
    origin,
    distanceUnit,
    wellKnownUserPropertyValues,
  } = parameters;
  const locationValues = getLocationValues(parameters);

  if (isRechargeAttributeType(parameters)) {
    return !rechargeAttributeIsInvalid(parameters, vendorAttributeDataTypeMap);
  }

  if (isBazaarvoiceEventType(parameters)) {
    return !bazaarvoiceEventIsInvalid(parameters);
  }

  if (isBooleanFilterType(parameters, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap)) {
    return [BooleanValue.TRUE, BooleanValue.FALSE].includes(textValue as BooleanValue);
  }

  if (isIntegerFilterType(parameters, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap)) {
    return !quantityIsInvalid(quantity, quantityComparator, quantityEnd);
  }

  if (
    isTimestampFilterType(parameters, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap)
  ) {
    return !timeComparatorIsInvalid(parameters, MAX_DAYS);
  }

  // TODO: sc - this will be revisited after we do a final pass on text handling and remove KlaviyoProfileAttrSelector
  if (isSupportedVendor(parameters)) {
    return (
      Boolean(profileAttribute) &&
      (isAttributeSet !== undefined || (textValues && textValues.length > 0))
    );
  }

  if (isMailExchangeType(parameters) || isWellKnownCountryType(parameters)) {
    return wellKnownUserPropertyValues && wellKnownUserPropertyValues.length > 0;
  }

  if (isLanguageType(parameters)) {
    const [{ stringCondition }] = getNestedFilter<NestedPropertyStringCondition>(
      WellKnownPropertyLocaleTypes.LANGUAGE,
      parameters
    );
    return (
      // the stringCondition verb is always 'IS'
      // the main isVerb on segment parameters is 'IS' / 'IS_NOT'
      stringCondition.verb === IsVerbType.IS && stringCondition.values?.length > 0
    );
  }

  if (customEventOption) {
    return !(
      frequencyComparatorIsInvalid(frequencyComparator, frequency) ||
      timeComparatorIsInvalid(parameters, MAX_DAYS) ||
      customEventIsInvalid(customEventOption)
    );
  }

  const filterType = getFilter(parameters);

  switch (filterType) {
    case ActionFilter.JOINED:
    case ActionFilter.PURCHASED:
    case ActionFilter.VIEWED:
    case ActionFilter.ADDED_TO_CART:
    case ActionFilter.ORDER_SUBSCRIPTION_CHARGE_PAID:
    case ActionFilter.ORDER_SUBSCRIPTION_PROCESSED:
    case ShopifyActionFilter.PURCHASED:
    case ShopifyActionFilter.ORDER_CONFIRMED:
    case ShopifyActionFilter.REFUND_SUCCEEDED:
    case ShopifyActionFilter.CHECKOUT_ABANDONED:
    case TextActionFilter.CLICKED:
    case TextActionFilter.SENT:
    case EmailActionFilter.CLICKED:
    case EmailActionFilter.OPENED:
    case EmailActionFilter.SENT:
    case JoinedActionFilter.TEXT:
    case JoinedActionFilter.EMAIL:
    case TextActionFilter.SENT_MESSAGE:
      if (frequencyComparatorIsInvalid(frequencyComparator, frequency)) {
        return false;
      } else if (timeComparatorIsInvalid(parameters, MAX_DAYS)) {
        return false;
      } else if (productDataIsInvalid(productDataAttributeValues)) {
        return false;
      } else if (messageDataIsInvalid(customAttributeValueGroup)) {
        return false;
      } else if (messageDataIsInvalid(subscriberAttributeValueGroup)) {
        return false;
      } else if (journeyMessageDataIsInvalid(parameters)) {
        return false;
      } else if (sentKeywordIsInvalid(parameters)) {
        return false;
      }
      return true;
    case ActionFilter.VISITED_SITE:
      if (timeComparatorIsInvalid(parameters, MAX_DAYS)) {
        return false;
      } else if (patternIsInvalid(parameters)) {
        return false;
      }
      return true;
    case SignUpMethodFilter.EMAIL:
    case SignUpMethodFilter.TEXT:
      if (propertyAttribute === 'CREATIVE') {
        return propertyAttributeCondition !== 'SPECIFIC_CREATIVE' || Boolean(fieldValues?.length);
      } else if (propertyAttribute === 'KEYWORD') {
        return propertyAttributeCondition !== 'SPECIFIC_KEYWORD' || Boolean(fieldValues?.length);
      }
      return true;
    case 'SUBSCRIBER_TAG':
      return Boolean(tagKey && tagValue);
    case 'STATE':
    case PropertiesFilter.COUNTRY:
      return Boolean(locationValues?.length);
    case 'CITY':
    case 'POSTAL_CODE':
      return Boolean(stateId && locationValues?.length);
    case PropertiesFilter.BULK_POSTAL_CODES: {
      if (!locations || !locations.length) {
        return false;
      }
      return locations.length <= MAX_ZIP_CODES_PER_FIELD;
    }
    case PropertiesFilter.DISTANCE: {
      if (!radius || !origin || !distanceUnit) {
        return false;
      }

      if (!Object.values(DistanceUnit).includes(distanceUnit)) {
        return false;
      }

      if (!Number.isInteger(radius) || !isWithinValidRadius(radius, distanceUnit)) {
        return false;
      }

      if (origin.value.length < 3 || origin.value.length > 5) {
        return false;
      }
      return true;
    }
    case 'SUBSCRIBER_PREFERENCES':
      return Boolean(subscriberPreferenceKey && subscriberPreferenceValue);
    case PropertiesFilter.THIRD_PARTY_LIST:
    case PropertiesFilter.MANUAL_UPLOAD_LIST:
      return Boolean(subscriberListMetadataId);
    case PropertiesFilter.DEVICE_TYPE:
      return Boolean(deviceTypes?.length);
    case PropertiesFilter.OPERATING_SYSTEM:
      return Boolean(deviceOSList?.length);
    case PropertiesFilter.CARRIER:
      return Boolean(carrier);
    case UserPropertyType.CUSTOM_PROPERTY_ID:
      return !deleted && !userPropertyValuesAreInvalid(userPropertyValues);
    default:
      return false;
  }
};
