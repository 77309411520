export interface CountDisplay {
  activeSubscribers: string;
  activeSubscribersNotFatigued: string;
}

export interface SegmentCountDisplay {
  sms: CountDisplay;
  email: CountDisplay;
  smsAndEmail: CountDisplay;
}
