import React, { useState } from 'react';

import {
  Box,
  Button,
  ButtonBar,
  Card,
  Heading,
  IconButton,
  Stack,
  TextArea,
  Text,
} from '@attentive/picnic';

import SuccessImg from '../../../../assets/feedbackSuccess.png';

type FeedbackFormProps = {
  onClose: () => void;
  onSubmit: (feedback: string) => void;
};

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState('');

  React.useEffect(() => {
    // Preload the success image
    const img = new Image();
    img.src = SuccessImg;
  }, []);

  return (
    <Card
      css={{
        padding: '$space2 $space0',
      }}
    >
      <IconButton
        iconName="X"
        description="Close segment builder feedback form"
        onClick={onClose}
        css={{ position: 'absolute', top: '$space2', right: '$space2' }}
        size="small"
      />
      <Stack css={{ padding: '$space0 $space6' }} spacing="$space3">
        <Heading variant="md" as="h3" css={{ pt: '$space3' }}>
          How can we improve?
        </Heading>
        <Text>
          Attentive is committed to product improvement. Please let us know what you think of the
          new UI.
        </Text>
        <TextArea
          placeholder="Share your thoughts here..."
          css={{ height: '150px', mt: '$space4' }}
          value={feedback}
          onChange={(e) => {
            setFeedback(e.currentTarget.value);
          }}
        ></TextArea>
      </Stack>
      <Box css={{ padding: '$space5 $space6 $space4' }}>
        <ButtonBar layout="stretch">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onSubmit(feedback)} disabled={!feedback}>
            Submit
          </Button>
        </ButtonBar>
      </Box>
    </Card>
  );
};
