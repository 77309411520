export enum CreativeCondition {
  ANY_MOBILE_CREATIVE = 'ANY_MOBILE_CREATIVE',
  ANY_DESKTOP_CREATIVE = 'ANY_DESKTOP_CREATIVE',
  ANY_LANDING_PAGE = 'ANY_LANDING_PAGE',
  SPECIFIC_CREATIVE = 'SPECIFIC_CREATIVE',
}

export enum KeywordCondition {
  SPECIFIC_KEYWORD = 'SPECIFIC_KEYWORD',
  ANY_KEYWORD = 'ANY_KEYWORD',
}

export enum SiteCondition {
  SPECIFIC_PAGE = 'SPECIFIC_PAGE',
  ANY_PAGE = 'ANY_PAGE',
}
