import React from 'react';

import { Box, Checkbox, ContainedLabel, Text } from '@attentive/picnic';

import SearchQueryHighlightedTextComponent from '../..//SearchQueryHighlightedText/SearchQueryHighlightedText';
import { StatusToVariantMapping, MessageRowStatus } from '../utils';

interface RowProps {
  isChecked: boolean | 'indeterminate';
  primaryText: string;
  queryString: string;
  secondaryText?: string | null;
  status?: MessageRowStatus;
  isCollapsible?: boolean;
}

export const RowDeatilsComponent: React.FC<RowProps> = ({
  isChecked,
  primaryText,
  queryString,
  secondaryText,
  status,
}) => (
  <Box
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Box
      css={{
        paddingRight: '$space4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 0,
      }}
    >
      <SearchQueryHighlightedTextComponent itemName={primaryText} searchQuery={queryString} />
      {secondaryText && (
        <Text css={{ paddingTop: '$space1', maxLines: 1 }} variant="caption" color="subdued">
          {secondaryText}
        </Text>
      )}
    </Box>
    <Box css={{ flexShrink: 0 }}>
      {status && (
        <ContainedLabel variant={StatusToVariantMapping[status]} css={{ mr: '$space4' }}>
          {status}
        </ContainedLabel>
      )}
      <Checkbox.CheckboxItem aria-label={`Selected-${primaryText}`} checked={isChecked} />
    </Box>
  </Box>
);
