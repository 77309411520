import React, { useState } from 'react';

import { useDebouncedValue } from '@attentive/acore-utils';
import {
  Box,
  Button,
  ButtonBar,
  Card,
  Heading,
  Icon,
  IconButton,
  PicnicCss,
  Separator,
  Stack,
  Text,
  TextArea,
} from '@attentive/picnic';

import { useTargetingCommonLogger } from '../../logger/useTargetingCommonLogger';
import { useAnimatedEntry } from '../SegmentCreateEditModal/useAnimatedEntry';

import { useSegmentAsssitantResponse } from './useSegmentAssistantResponse';

const DISPLAY_DELAY = 5000;

type SegmentAssistantFeedbackProps = {
  css?: PicnicCss;
  isOpen: boolean;
  onClose: () => void;
};

export const SegmentAssistantFeedback: React.FC<SegmentAssistantFeedbackProps> = ({
  css,
  isOpen,
  onClose,
}) => {
  const { userPrompt, systemResponse } = useSegmentAsssitantResponse();
  const [selectedOption, setSelectedOption] = useState<'good' | 'issues' | undefined>();
  const [userFeedback, setUserFeedback] = useState('');
  const [open, setOpen] = useState(false);
  const debouncedOpen = useDebouncedValue(open, isOpen ? DISPLAY_DELAY : 0);
  const { logAction } = useTargetingCommonLogger();

  const { elementCss, isMounted } = useAnimatedEntry({
    animationDuration: 200,
    isVisible: debouncedOpen,
    hiddenCss: { opacity: 0 },
    visibleCss: { opacity: 1 },
  });

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!isMounted) return null;

  return (
    <Card
      css={{
        ...css,
        padding: '$space2 $space0',
        minWidth: '700px',
        transition: 'all 0.2s ease-in',
        ...elementCss,
      }}
    >
      <Box
        css={{
          padding: '$space0 $space4 $space2',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Heading variant="md" as="h3">
          Rate this segment
        </Heading>
        <IconButton iconName="X" description="Close rate this segment dialog" onClick={onClose} />
      </Box>
      <Separator />
      <Stack css={{ padding: '$space4' }}>
        <Heading variant="sm" as="h4">
          Choose an option
        </Heading>
        <Stack direction="horizontal" css={{ width: '100%', justifyContent: 'space-around' }}>
          <Card
            interactive
            active={selectedOption === 'good'}
            css={{ width: '50%' }}
            onClick={() => {
              setSelectedOption('good');
            }}
          >
            <Icon name="Checkmark" size="large" />
            <Text>This segment is good</Text>
          </Card>
          <Card
            interactive
            active={selectedOption === 'issues'}
            css={{ width: '50%' }}
            onClick={() => {
              setSelectedOption('issues');
            }}
          >
            <Icon name="CircleError" size="large" />
            <Text>This segment has issues</Text>
          </Card>
        </Stack>
        <Heading variant="sm" as="h4">
          Additional notes (optional)
        </Heading>
        <TextArea
          placeholder="Type to add more details"
          onChange={({ currentTarget }) => {
            setUserFeedback(currentTarget.value);
          }}
          value={userFeedback}
        />
      </Stack>
      <Separator />
      <Box css={{ padding: '$space4' }}>
        <ButtonBar layout="stretch">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!selectedOption}
            onClick={() => {
              logAction('Segment Assistant Feedback', {
                userPrompt,
                userFeedback,
                segmentIsGood: selectedOption === 'good',
                selectedSystemResponse: systemResponse,
              });
              onClose();
            }}
          >
            Submit
          </Button>
        </ButtonBar>
      </Box>
    </Card>
  );
};
