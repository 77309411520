import React, { FC } from 'react';

import { Box, LoadingIndicator } from '@attentive/picnic';

import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';

const PUFieldLoader: FC = () => (
  <Box
    data-testid="loading-dots"
    css={{
      position: 'absolute',
      top: 0,
      right: '$space10',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <SegmentConditionItemContainer>
      <LoadingIndicator />
    </SegmentConditionItemContainer>
  </Box>
);

export default PUFieldLoader;
