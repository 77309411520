import React, { FC, useState } from 'react';

import { Box, Button, Icon, IconButton, styled, Text } from '@attentive/picnic';

import { FilterType, SegmentComponent } from '../../../../../../constants';
import { getFilter } from '../../../../../../utils/typeAssertions';
import { useAnimatedEntry } from '../../../../useAnimatedEntry';
import { getInitialFilterType } from '../../../../utils/filterType';
import FilterTypeSelector from '../../../FilterTypeSelector';
import {
  SegmentConditionSelectors,
  SegmentConditionSelectorsProps,
} from '../SegmentConditionSelectors';

const ConditionBox = styled('div', {
  display: 'flex',
  flex: 1,
  background: '$bgAccent',
  borderRadius: '$radius2',
  p: '$space4 $space4 $space0 $space4',
  variants: {
    hasError: {
      true: {
        border: '1px solid $borderInputError',
      },
      false: {
        border: '1px solid $bgAccent',
      },
    },
  },
});

export interface NewSegmentConditionProps
  extends Omit<SegmentConditionSelectorsProps, 'segmentFilterType' | 'children'> {
  showDupeText: boolean;
  higlightCondition?: boolean;
  component: SegmentComponent;
  onClickDeleteButton: () => void;
  onClickOrButton: () => void;
  resetComponent: () => void;
  showAddButton?: boolean;
}

export const NewSegmentCondition: FC<NewSegmentConditionProps> = ({
  showDupeText,
  higlightCondition,
  onClickOrButton,
  onClickDeleteButton,
  resetComponent,
  showAddButton,
  ...props
}) => {
  const { parameters } = props.component;
  const { deleted: customPropertyDeleted } = parameters;

  const [segmentFilterType, setSegmentFilterType] = useState<FilterType | undefined>(
    getInitialFilterType(parameters)
  );

  const { elementCss: addButtonCss, isMounted: isOperatorButtonDisplayed } = useAnimatedEntry({
    animationDuration: 200,
    isVisible: Boolean(showAddButton && segmentFilterType),
    visibleCss: { opacity: 1 },
    hiddenCss: { opacity: 0 },
  });
  const { elementCss: conditionCss } = useAnimatedEntry({
    animationDuration: 200,
    isVisible: true,
    visibleCss: { opacity: 1, height: '48px' },
    hiddenCss: { opacity: 0, height: 0 },
  });

  const filter = getFilter(parameters);

  const handleFilterTypeChange = (newFilterType: FilterType) => {
    if (segmentFilterType) {
      resetComponent();
    }
    setSegmentFilterType(newFilterType);
  };

  const displayFilterTypeSelector = !segmentFilterType;
  const hasErrors = showDupeText || higlightCondition;

  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: displayFilterTypeSelector ? 'center' : 'flex-start',
        transition: 'height 0.2s ease-out, opacity 0.3s ease-out',
        ...(displayFilterTypeSelector ? conditionCss : {}),
      }}
    >
      {displayFilterTypeSelector && (
        <FilterTypeSelector value={segmentFilterType} onChange={handleFilterTypeChange} />
      )}
      {!displayFilterTypeSelector && (
        <Box css={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <Box css={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            {hasErrors && (
              <Box
                css={{
                  height: '$size9',
                  display: 'flex',
                  alignItems: 'center',
                  mr: '$space2',
                }}
              >
                <Icon
                  name="CircleExclamation"
                  color="error"
                  data-testid="segment-condition-error-icon"
                />
              </Box>
            )}
            <ConditionBox hasError={hasErrors}>
              <SegmentConditionSelectors {...props} segmentFilterType={segmentFilterType} />
            </ConditionBox>
          </Box>
          {showDupeText && (
            <Text variant="caption" color="critical" css={{ mt: '$space2', ml: '$space9' }}>
              This condition is a duplicate.
            </Text>
          )}
          {customPropertyDeleted && (
            <Text variant="caption" color="critical" css={{ ml: '$space4' }}>
              Remove or replace this category.
            </Text>
          )}
        </Box>
      )}
      <Box
        css={{
          display: 'flex',
          alignItems: 'flex-start',
          ml: '$space4',
          mt: displayFilterTypeSelector ? '$space0' : '$space4',
          minWidth: '105px',
        }}
      >
        <IconButton
          iconName="TrashCan"
          description="Delete segment condition"
          onClick={() => {
            onClickDeleteButton();
            setSegmentFilterType(undefined);
          }}
          size="small"
          data-segmentation-id="segment-builder-delete-button"
          css={{ mr: '$space4' }}
        />
        {isOperatorButtonDisplayed && (
          <Box css={{ transition: 'all 0.2s ease-in-out', ...addButtonCss }}>
            <Button
              onClick={onClickOrButton}
              size="small"
              data-segmentation-id="segment-builder-operator-button"
              disabled={!filter}
              css={{ transition: 'all 0.2s ease-in-out' }}
            >
              <Icon name="PlusSign" size="extraSmall" />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
