import { StandardDialog, styled } from '@attentive/picnic';

export const StandardDialogFullscreenContent = styled(StandardDialog.Content, {
  width: '100%',
  height: '100%',
  maxHeight: 'none',
  maxWidth: 'none',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 0,
});
