import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Box, Button, DropdownMenu, Icon } from '@attentive/picnic';

import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  CAMPAIGN_ATTR,
  EmailActionFilter,
  HasVerbType,
  IsVerbType,
  RechargeFilterAttrs,
  SegmentParameters,
  ShopifyAction,
  TextActionFilter,
} from '../../../../constants';
import {
  hasNestedAttribute,
  isRechargeAttributeType,
  isRechargeStatusAttribute,
} from '../../../../utils/typeAssertions';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import { churnFilterParameter } from '../Recharge';

const subscriberActionsWithProductData = [
  ActionFilter.COMPLETED_REVIEW,
  ActionFilter.PURCHASED,
  ActionFilter.VIEWED,
  ActionFilter.ADDED_TO_CART,
  ActionFilter.ORDER_SUBSCRIPTION_CHARGE_PAID,
  ActionFilter.ORDER_SUBSCRIPTION_PROCESSED,
  ShopifyAction.CHECKOUT_ABANDONED,
];

type FilterButtonOption = {
  name: string;
  onSelect: () => void;
  showOption: boolean;
  showSuperUserIcon?: boolean;
};

export enum SegmentFilterTypes {
  CAMPAIGN = 'Campaign',
  PRODUCT = 'Product',
  MPP = 'MPP',
}

interface OwnProps {
  parameters: SegmentParameters;
  onChange: (parameters: SegmentParameters, filterType?: string) => void;
  disableProductFilters: boolean;
  disableCustomEventFilters: boolean;
}

const SegmentFilterButton: FC<OwnProps> = ({
  parameters,
  onChange,
  disableProductFilters,
  disableCustomEventFilters,
}) => {
  const {
    hasVerb,
    subscriberAction,
    subscriberActionChannel,
    subscriberActionSource,
    customEventOption,
    isGroupedByOrderId,
    productDataAttributeValues = [],
  } = parameters;

  const enableProductData = useCompanyFeatureFlag('ENABLE_PRODUCT_DATA');
  const isRechargeSubscriptionStatus = isRechargeStatusAttribute(parameters);

  const addMessageEngagementRow = () => {
    const params = {
      ...parameters,
      customAttributeValueGroup: {
        attribute: CAMPAIGN_ATTR,
        values: [],
        isVerb: IsVerbType.IS,
      },
    };

    onChange(params, SegmentFilterTypes.CAMPAIGN);
  };

  const addThirdPartyCampaignRow = () => {
    const params = {
      ...parameters,
      subscriberAttributeValueGroup: {
        attribute: CAMPAIGN_ATTR,
        values: [],
        isVerb: IsVerbType.IS,
      },
    };

    onChange(params, SegmentFilterTypes.CAMPAIGN);
  };

  const addMppRow = () => {
    const params = {
      ...parameters,
      excludePrivacyOpens: true,
    };

    onChange(params, SegmentFilterTypes.MPP);
  };

  const addProductDataRow = () => {
    const params = {
      ...parameters,
      productDataAttributeValues: [
        ...productDataAttributeValues,
        {
          attribute: '',
          values: [],
          isVerb: IsVerbType.IS,
        },
      ],
    };

    onChange(params, SegmentFilterTypes.PRODUCT);
  };

  const addCustomEventValueRow = () => {
    if (!customEventOption) return;
    const { customEventValues } = customEventOption;

    const params = {
      ...parameters,
      customEventOption: {
        ...customEventOption,
        customEventValues: [
          ...customEventValues,
          {
            isVerb: IsVerbType.IS,
            customEventKey: '',
            value: null,
          },
        ],
      },
    };
    onChange(params);
  };

  const addRechargeChurnReasonRow = () => {
    if (!parameters.nestedPropertyFilters) {
      throw new Error('Missing nestedPRopertyFilters');
    }

    const params: SegmentParameters = {
      ...parameters,
      nestedPropertyFilters: [...parameters.nestedPropertyFilters, churnFilterParameter],
    };
    onChange(params);
  };

  const showTextCampaignOption = Boolean(
    subscriberAction &&
      subscriberAction in TextActionFilter &&
      subscriberAction !== ActionFilter.SENT_MESSAGE &&
      subscriberActionChannel === ActionChannel.TEXT &&
      subscriberActionSource === ActionSource.ATTENTIVE &&
      parameters.customAttributeValueGroup?.attribute !== CAMPAIGN_ATTR &&
      !parameters.journeyAttributeValues
  );

  const showEmailCampaignOption = Boolean(
    subscriberAction &&
      subscriberAction in EmailActionFilter &&
      subscriberActionChannel === ActionChannel.EMAIL &&
      subscriberActionSource === ActionSource.ATTENTIVE &&
      parameters.customAttributeValueGroup?.attribute !== CAMPAIGN_ATTR &&
      !parameters.journeyAttributeValues
  );

  const showMppOption = Boolean(
    hasVerb === HasVerbType.HAS &&
      subscriberAction === ActionFilter.OPENED &&
      subscriberActionChannel === ActionChannel.EMAIL &&
      subscriberActionSource === ActionSource.ATTENTIVE &&
      parameters.excludePrivacyOpens === undefined
  );

  const showThirdPartyCampaignOption = Boolean(
    [ActionFilter.CLICKED, ActionFilter.OPENED, ActionFilter.SENT].includes(
      subscriberAction as ActionFilter
    ) &&
      subscriberActionSource === ActionSource.KLAVIYO &&
      parameters.subscriberAttributeValueGroup?.attribute !== CAMPAIGN_ATTR
  );

  const fieldSupportsProductData =
    isRechargeSubscriptionStatus ||
    (subscriberAction && subscriberActionsWithProductData.includes(subscriberAction));

  const showProductDataOption = Boolean(
    enableProductData && fieldSupportsProductData && !isGroupedByOrderId && !disableProductFilters
  );

  const showCustomEventFilterOption = Boolean(customEventOption && !disableCustomEventFilters);

  const showRechargeChurnReason =
    isRechargeAttributeType(parameters) &&
    hasNestedAttribute(parameters, RechargeFilterAttrs.CANCELLED_AT) &&
    !hasNestedAttribute(parameters, RechargeFilterAttrs.CANCELLATION_REASON);

  const options: FilterButtonOption[] = [
    {
      name: 'Message filter',
      onSelect: addMessageEngagementRow,
      showOption: showTextCampaignOption || showEmailCampaignOption,
    },
    {
      name: 'Third Party Campaign filter',
      onSelect: addThirdPartyCampaignRow,
      showOption: showThirdPartyCampaignOption,
    },
    {
      name: 'MPP filter',
      onSelect: addMppRow,
      showOption: showMppOption,
    },
    {
      name: 'Product filter',
      onSelect: addProductDataRow,
      showOption: showProductDataOption,
    },
    {
      name: 'Event value filter',
      onSelect: addCustomEventValueRow,
      showOption: showCustomEventFilterOption,
    },
    {
      name: 'Recharge churn reason filter',
      onSelect: addRechargeChurnReasonRow,
      showOption: showRechargeChurnReason,
    },
  ];

  const availableOptions = options.filter(({ showOption }) => showOption);

  if (availableOptions.length < 1) return null;

  const handleClick = () => {
    if (availableOptions.length === 1) {
      availableOptions[0].onSelect();
    }
  };

  return (
    <SegmentConditionItemContainer>
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <Button
            variant="basic"
            size="small" // XXX: this was "extraSmall" before and visually it was smaller then "small"
            onClick={handleClick}
            data-testid="seg-filter-button"
          >
            <Icon name="Filter" css={{ mr: '$space2' }} size="extraSmall" />
            Filter
          </Button>
        </DropdownMenu.Trigger>
        {availableOptions.length > 1 && (
          <DropdownMenu.Content align="end">
            {availableOptions.map(({ name, onSelect, showSuperUserIcon }) => (
              <DropdownMenu.TextItem key={name} onSelect={onSelect}>
                <Box css={{ display: 'flex', alignItems: 'center' }}>
                  {name}
                  {showSuperUserIcon && (
                    <Icon css={{ ml: '$space2' }} name="ShieldPerson" size="small" />
                  )}
                </Box>
              </DropdownMenu.TextItem>
            ))}
          </DropdownMenu.Content>
        )}
      </DropdownMenu>
    </SegmentConditionItemContainer>
  );
};

export default SegmentFilterButton;
