import { useMemo } from 'react';

import { API, useToast, ToastType, useCurrentCompanyId } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';

import { AvailableDataType, AvailableSegmentData } from '../constants';

export const fetchSegmentDataAvailability = async () => {
  // GMRU: GET /user-profile/v1/segment-options/segment-option-availabilities
  const response = await API.get<Array<{ type: AvailableDataType; active: boolean }>>(
    '/user-profile/v1/segment-options/segment-option-availabilities'
  );
  return response;
};

export const useAvailableSegmentData = (useQueryOptions = {}) => {
  const [createToast] = useToast();
  const companyId = useCurrentCompanyId();
  const { data } = useQuery<Array<{ type: AvailableDataType; active: boolean }> | null, Error>(
    ['segmentDataAvailability', companyId],
    () => {
      return fetchSegmentDataAvailability().then((response) => {
        const { body, status } = response;
        if (status !== 200) {
          throw new Error('Segment data availability failed to load');
        }
        if (!body) {
          throw new Error('Unexpected empty response');
        }
        return body;
      });
    },
    {
      ...useQueryOptions,
      onError: (error) => {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch segment data availability',
          text: error.message,
        });
      },
    }
  );

  return useMemo(() => {
    const emptyMap: AvailableSegmentData = new Map<AvailableDataType, boolean>();

    if (data) {
      return data.reduce((map, item) => {
        map.set(item.type, item.active);
        return map;
      }, emptyMap);
    }
    return emptyMap;
  }, [data]);
};
