import React from 'react';

import { FormField, MultiSelect } from '@attentive/picnic';

import {
  NestedPropertyStringCondition,
  SegmentParameters,
  StringValueDisplayableOption,
} from '../../../../constants';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface OwnProps {
  languageFilter: NestedPropertyStringCondition;
  languageOptions: StringValueDisplayableOption[];
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
}

export const LanguageSelector = ({
  languageFilter,
  languageOptions,
  onChange,
  parameters,
}: OwnProps) => {
  const values = languageFilter.stringCondition.values;
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !values.length;

  return (
    <FormField>
      <MultiSelect
        value={values}
        onChange={(newValues: string[]) => {
          const newLanguageFilter = {
            ...languageFilter,
            stringCondition: {
              ...languageFilter.stringCondition,
              values: newValues,
            },
          };
          onChange({
            ...parameters,
            nestedPropertyFilters: [newLanguageFilter],
          });
        }}
        size="small"
        maxTokens={10}
        placeholder="Choose languages"
        state={error ? 'error' : 'normal'}
      >
        {languageOptions.map(({ displayName, optionValue }) => {
          return (
            <MultiSelect.Item key={optionValue} value={optionValue}>
              {displayName}
            </MultiSelect.Item>
          );
        })}
      </MultiSelect>
      {error && <FormField.ErrorText>Please choose a language</FormField.ErrorText>}
    </FormField>
  );
};
