import React from 'react';

import { Box, FormField, PicnicCss, TextInput } from '@attentive/picnic';

import { CustomAttributeFormErrors } from '../form';

import { StyledLabel, StyledTooltip } from './styledElements';

type AttributeNameInputProps = {
  css?: PicnicCss;
  errors: CustomAttributeFormErrors;
  onChange: (value: string) => void;
  size?: 'small' | 'normal';
  showTooltip?: boolean;
  value: string | undefined;
};

export const AttributeNameInput = ({
  css,
  errors,
  onChange,
  showTooltip,
  size = 'small',
  value,
}: AttributeNameInputProps) => {
  return (
    <FormField css={css}>
      <Box css={{ display: 'flex', marginBottom: '$space2' }}>
        <StyledLabel>Attribute name</StyledLabel>
        {showTooltip && (
          <StyledTooltip>
            The name you give to your attribute will be sent to the User Profile API. This is
            required in order to use this input’s data in segmentation.
          </StyledTooltip>
        )}
      </Box>
      <TextInput
        name="attributeName"
        value={value}
        placeholder="Name your attribute"
        size={size}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {errors.attributeName && (
        // wrap FormField.ErrorText to prevent FormField from adding a marginTop value
        <Box>
          <FormField.ErrorText>{errors.attributeName}</FormField.ErrorText>
        </Box>
      )}
    </FormField>
  );
};
