import React from 'react';

import { Box, FormField, Select, Text, TextInput } from '@attentive/picnic';

import {
  SegmentParameters,
  StringComparator,
  StringComparatorCondition,
  SENT_KEYWORD_PROPERTY_NAME,
} from '../../../../constants';
import { validateSentKeywordParameters } from '../../../../utils';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

const COMPARATOR_MIN_WIDTH = '170px';
const KEYWORD_TYPE_MIN_WIDTH = '180px';
const KEYWORD_VALUE_MIN_WIDTH = '250px';

enum ComparatorOptions {
  DOES_CONTAIN = 'DOES_CONTAIN',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  IS_EXACTLY = 'IS_EXACTLY',
}

enum KeywordFilter {
  ANY = 'ANY',
  KEYWORDS = 'KEYWORDS',
}

interface KeywordSelectorProps {
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
}

export const KeywordSelector = ({ onChange, parameters }: KeywordSelectorProps) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const { stringComparator } = parameters;
  const { comparator, inverse, value } = stringComparator || {};
  const errors = validateSentKeywordParameters(parameters, showValidation);

  const filterType = stringComparator ? KeywordFilter.KEYWORDS : KeywordFilter.ANY;
  const selectedComparator = getComparatorSelectorValue(comparator, inverse);

  return (
    <>
      <SegmentConditionItemContainer>
        <Text>which</Text>
      </SegmentConditionItemContainer>
      <FormField>
        <Select
          size="small"
          onChange={(val: string) => {
            // If stringComparator is undefined, this is a any message filter and doesn't require string comparator
            if (!stringComparator) return;

            const values = getComparatorAndInverseValues(val as ComparatorOptions);

            onChange({
              ...parameters,
              stringComparator: {
                ...stringComparator,
                ...values,
              },
            });
          }}
          value={
            filterType === KeywordFilter.ANY ? ComparatorOptions.DOES_CONTAIN : selectedComparator
          }
          css={{ minWidth: COMPARATOR_MIN_WIDTH }}
          disabled={filterType === KeywordFilter.ANY}
        >
          <Select.Item value={ComparatorOptions.DOES_CONTAIN}>does contain</Select.Item>
          <Select.Item value={ComparatorOptions.DOES_NOT_CONTAIN}>does not contain</Select.Item>
          <Select.Item value={ComparatorOptions.IS_EXACTLY}>is exactly</Select.Item>
        </Select>
        {errors.comparator && <FormField.ErrorText>{errors.comparator}</FormField.ErrorText>}
      </FormField>
      <Box>
        <Select
          size="small"
          onChange={(val: string) => {
            if (val === KeywordFilter.ANY) {
              onChange({
                ...parameters,
                stringComparator: undefined,
              });
              return;
            }

            onChange({
              ...parameters,
              stringComparator: getDefaultStringComparator(),
            });
          }}
          value={filterType}
          css={{ minWidth: KEYWORD_TYPE_MIN_WIDTH }}
        >
          <Select.Item value={KeywordFilter.KEYWORDS}>keyword or phrase</Select.Item>
          <Select.Item value={KeywordFilter.ANY}>any message</Select.Item>
        </Select>
      </Box>
      {filterType === KeywordFilter.KEYWORDS && stringComparator && (
        <FormField>
          <TextInput
            size="small"
            value={value}
            onChange={(e) =>
              onChange({
                ...parameters,
                stringComparator: {
                  ...stringComparator,
                  value: e.target.value,
                },
              })
            }
            placeholder="Enter keyword or phrase"
            css={{ minWidth: KEYWORD_VALUE_MIN_WIDTH }}
          />
          {errors.value && <FormField.ErrorText>{errors.value}</FormField.ErrorText>}
        </FormField>
      )}
    </>
  );
};

export function getDefaultStringComparator() {
  return {
    comparator: StringComparator.LIKE,
    inverse: false,
    propertyName: SENT_KEYWORD_PROPERTY_NAME,
    value: '',
  };
}

function getComparatorSelectorValue(
  comparator: StringComparator | undefined,
  inverse: boolean | undefined
) {
  if (!comparator) return undefined;

  if (comparator === StringComparator.LIKE) {
    // inverse true: Does contain
    // inverse false: Does not contain
    return inverse ? ComparatorOptions.DOES_NOT_CONTAIN : ComparatorOptions.DOES_CONTAIN;
  }

  if (comparator === StringComparator.EQUALS) {
    return ComparatorOptions.IS_EXACTLY;
  }

  throw new Error(`Unknown string comparator type: ${comparator}`);
}

function getComparatorAndInverseValues(
  comparator: ComparatorOptions
): Pick<StringComparatorCondition, 'inverse' | 'comparator'> {
  switch (comparator) {
    case ComparatorOptions.DOES_CONTAIN:
      return {
        comparator: StringComparator.LIKE,
        inverse: false,
      };
    case ComparatorOptions.DOES_NOT_CONTAIN:
      return {
        comparator: StringComparator.LIKE,
        inverse: true,
      };
    case ComparatorOptions.IS_EXACTLY:
      return {
        comparator: StringComparator.EQUALS,
        inverse: false,
      };
    default:
      throw new Error(`Unmatched comparator: ${comparator}`);
  }
}
