import { SegmentParameters, TimeComparatorType } from '../../../../../constants';
import { TimeValue } from '../constants';

export function defaultGetDateValues(parameters: SegmentParameters): TimeValue {
  const {
    timeComparator = TimeComparatorType.OVER_ALL_TIME,
    startTime,
    endTime,
    time,
    durationUnit,
    durationEnd,
    durationStart,
    durationTime,
    months,
    ignoreYear,
  } = parameters;

  return {
    timeComparator,
    startTime,
    endTime,
    time,
    durationUnit,
    durationEnd,
    durationStart,
    durationTime,
    months,
    ignoreYear,
  };
}
