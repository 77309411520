import { VendorAttributeDataType } from '../constants';
import {
  OperatorType,
  Source,
  SyncStatus,
  SegmentComponentType,
  ActionFilter,
  ActionChannel,
  ActionSource,
  ShopifyFilter,
  PropertiesFilter,
  SubscriberPreference,
  OperatorComparator,
  HasVerbType,
  IsVerbType,
  TimeComparatorType,
  DurationUnit,
  SourceType,
  KeywordCondition,
  CreativeCondition,
  SiteCondition,
  QuantityComparator,
  UserPropertyType,
  Vendor,
  ShopifyAction,
  StringComparator,
} from '../index';
import { WellKnownPropertyTypes } from '../parameters';

import {
  CampaignUsingSegment,
  CustomAttributeValueGroup,
  CustomEventOption,
  CustomAttributeOptionValue,
  JourneyUsingSegment,
  ManualUploadList,
  ThirdPartyList,
  SalesChannelValue,
  ProductDataAttributeValue,
  Optional,
  WellKnownPropertyOptionValue,
} from './index';

export type BlankSegment = Optional<Segment, 'id' | 'created' | 'updated' | 'readOnly' | 'source'>;

export type IndexPageSegment = Pick<
  Segment,
  | 'id'
  | 'name'
  | 'description'
  | 'source'
  | 'syncStatus'
  | 'anyComponentSyncing'
  | 'operator'
  | 'readOnly'
  | 'created'
  | 'updated'
  | 'segmentErrors'
>;

export interface ManualSegment extends SegmentBase {
  source: Source.MANUAL_UPLOAD;
  manualUploadList: ManualUploadList;
}

export interface Segment extends SegmentBase {
  manualUploadList?: ManualUploadList;
  thirdPartyList?: ThirdPartyList;
  isRetargeting?: boolean;
}

export interface SegmentBase {
  id: number;
  name: string;
  description: string;
  anyComponentSyncing?: boolean;
  readOnly: boolean;
  operator: OperatorType;
  expressions: SegmentExpression[];
  source: Source;
  created: number;
  updated: number;
  templateId?: number | null;
  segmentErrors?: SegmentError[];
  syncStatus?: SyncStatus;
  campaigns?: CampaignUsingSegment[];
  journeys?: JourneyUsingSegment[];
  tag?: string;
}

export interface NestedPropertyFilterBase {
  fieldName: string;
  fieldDataType: VendorAttributeDataType;
  displayName: string;
}

interface NestedPropertyBooleanCondition extends NestedPropertyFilterBase {
  booleanCondition: {
    value: boolean;
  };
}

export interface NestedPropertyStringCondition extends NestedPropertyFilterBase {
  stringCondition: {
    verb: IsVerbType;
    values: string[];
  };
}

export interface NestedPropertyTimeCondition extends NestedPropertyFilterBase {
  timeCondition: {
    comparator: TimeComparatorType;
    epochTime?: number;
    epochTimeEnd?: number;
    units?: DurationUnit;
    duration?: number;
    durationEnd?: number;
  };
}

interface NestedPropertyNumericCondition extends NestedPropertyFilterBase {
  numericCondition: {
    comparator: QuantityComparator;
    quantity: number;
  };
}

export type NestedPropertyFilter =
  | NestedPropertyBooleanCondition
  | NestedPropertyNumericCondition
  | NestedPropertyStringCondition
  | NestedPropertyTimeCondition;

export interface StringComparatorCondition {
  comparator: StringComparator;
  inverse: boolean;
  propertyName: string;
  value: string;
}

// This should be used for storing transient data that is only important for use on the client inside the segment builder
// It will *not* be sent to or persisted on the BE and will be created anew each time the segment builder is opened
export type SegmentComponentMetaData = {
  // This value is used for generating the key prop for segment components.
  // The value comes either from the server provided component id or a UNIX timestamp from when the component was created
  // The server sent component IDs are *not* unique across a segment but they should be unique within a segment expression
  id: number;
};

export interface SegmentComponent {
  id?: number;
  description?: string;
  syncing?: boolean;
  type: SegmentComponentType;
  parameters: SegmentParameters;
  metadata?: SegmentComponentMetaData;
}

export interface SegmentError {
  errorReason: string;
  errorType: string;
}

export interface SegmentExpression {
  id?: number;
  operator: OperatorType;
  segmentComponents: SegmentComponent[];
}

export type BulkLocationValues = Array<{
  countryIso?: string;
  countryIsoCode?: string;
  subdivisionIso?: string;
  countryId?: string;
  value: string;
}>;

export type OriginLocationValue = {
  value: string;
  countryIsoCode: string;
};

export enum DistanceUnit {
  MILES = 'DISTANCE_UNIT_MILE',
  KILOMETERS = 'DISTANCE_UNIT_KILOMETER',
}

type JourneyAttributeMessage = {
  name: string;
};

export type JourneyAttributeValue = {
  journeyId: number;
  messages: JourneyAttributeMessage[];
};

type ReviewFilterVendor = 'VENDOR_BAZAARVOICE';

type ReviewFilterValue = {
  vendor: ReviewFilterVendor;
  onlyIncludeReviewsWithMedia: boolean;
  ratingComparator?: ReviewFilterRatingComparatorValue;
};

type ReviewFilterRatingComparatorValue = {
  comparator?: QuantityComparator;
  quantity?: number;
  quantityEnd?: number;
};

export type SegmentParameters = {
  subscriberAction?: ActionFilter | ShopifyAction;
  subscriberActionSource?: ActionSource;
  subscriberActionChannel?: ActionChannel;
  profileAttribute?: ShopifyFilter | string;
  isAttributeSet?: boolean;
  vendor?: Vendor;
  subscriberProperty?: PropertiesFilter | SubscriberPreference | WellKnownPropertyTypes;
  frequency?: number;
  frequencyComparator?: OperatorComparator;
  hasVerb?: HasVerbType;
  isVerb?: IsVerbType;
  timeComparator?: TimeComparatorType;
  durationUnit?: DurationUnit;
  durationTime?: number;
  durationStart?: number;
  durationEnd?: number;
  startTime?: number;
  endTime?: number;
  time?: number;
  propertyAttribute?: SourceType;
  propertyAttributeCondition?: KeywordCondition | CreativeCondition | SiteCondition;
  pattern?: string;
  fieldValues?: string[] | number[] | null;
  quantity?: number;
  quantityEnd?: number;
  quantityComparator?: QuantityComparator;
  countryId?: string;
  stateId?: string;
  locations?: BulkLocationValues;
  locationValue?: string | null;
  locationValues?: string[];
  tagKey?: string;
  tagValue?: string;
  textValue?: string;
  textValues?: string[];
  subscriberPreferenceKey?: string;
  subscriberPreferenceValue?: string;
  subscriberListMetadataId?: number | null;
  salesChannelValue?: SalesChannelValue;
  productDataAttributeValues?: ProductDataAttributeValue[];
  customAttributeValueGroup?: CustomAttributeValueGroup;
  subscriberAttributeValueGroup?: CustomAttributeValueGroup;
  journeyAttributeValues?: JourneyAttributeValue[];
  customEventOption?: CustomEventOption;
  deviceTypes?: string[];
  deviceOSList?: string[];
  carrier?: string;
  userPropertyType?: UserPropertyType;
  displayName?: string;
  attribute?: string;
  deleted?: boolean;
  userPropertyValues?: CustomAttributeOptionValue[];
  radius?: number;
  origin?: OriginLocationValue;
  distanceUnit?: DistanceUnit;
  subdivisionCount?: number;
  locationCount?: number;
  isGroupedByOrderId?: boolean;
  excludePrivacyOpens?: boolean;
  wellKnownUserPropertyValues?: WellKnownPropertyOptionValue[];
  propertyDataType?: VendorAttributeDataType;
  nestedPropertyFilters?: NestedPropertyFilter[];
  stringComparator?: StringComparatorCondition;
  months?: number[];
  ignoreYear?: boolean;
  reviewFilter?: ReviewFilterValue;
};

export type ThinSegment = Pick<
  Segment,
  | 'id'
  | 'name'
  | 'description'
  | 'anyComponentSyncing'
  | 'operator'
  | 'readOnly'
  | 'source'
  | 'syncStatus'
  | 'created'
  | 'updated'
  | 'segmentErrors'
>;

export interface ThirdPartySegment extends SegmentBase {
  source: Source.THIRD_PARTY;
  thirdPartyList: ThirdPartyList;
}
