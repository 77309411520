import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  DurationUnit,
  HasVerbType,
  OperatorComparator,
  OperatorType,
  SegmentComponentType,
  SegmentTemplateId,
  TimeComparatorType,
} from '../constants';
import { getNewBlankSegment } from '../utils';

import { SegmentTemplateEntry } from './types';

export const unengagedSubscribers: SegmentTemplateEntry = {
  segment: () => ({
    ...getNewBlankSegment(),
    templateId: SegmentTemplateId.UNENGAGED_SUBSCRIBERS,
    expressions: [
      {
        operator: OperatorType.OR,
        segmentComponents: [
          {
            type: SegmentComponentType.CUSTOM,
            parameters: {
              durationTime: 30,
              durationUnit: DurationUnit.DAYS,
              frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
              hasVerb: HasVerbType.HAS_NOT,
              subscriberAction: ActionFilter.CLICKED,
              subscriberActionChannel: ActionChannel.TEXT,
              subscriberActionSource: ActionSource.ATTENTIVE,
              timeComparator: TimeComparatorType.IN_THE_LAST,
            },
          },
        ],
      },
    ],
  }),
};
