import {
  getVendorAttributeDataType,
  VendorAttributeDataTypeMap,
} from '../../components/SegmentCreateEditModal/utils/attributeDataTypeMap';
import {
  IsVerbType,
  NestedPropertyStringCondition,
  NestedPropertyTimeCondition,
  RechargeFilterAttrs,
  SegmentParameters,
  TimeComparatorType,
  Vendor,
} from '../../constants';

import { productDataIsInvalid } from './productData';
import { quantityIsInvalid } from './quantity';

export function rechargeAttributeIsInvalid(
  parameters: SegmentParameters,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const {
    nestedPropertyFilters,
    attribute,
    quantity,
    quantityComparator,
    quantityEnd,
    productDataAttributeValues,
  } = parameters;

  if (!nestedPropertyFilters || !nestedPropertyFilters.length) {
    return true;
  }

  return nestedPropertyFilters.some((filter) => {
    const key = `${attribute}:${filter.fieldName}`;
    const { isText, isTimestamp } = getVendorAttributeDataType(
      Vendor.RECHARGE,
      key,
      vendorAttributeDataTypeMap
    );

    if (filter.fieldName === RechargeFilterAttrs.STATUS) {
      if (quantityIsInvalid(quantity, quantityComparator, quantityEnd)) {
        return true;
      }

      if (productDataAttributeValues && productDataIsInvalid(productDataAttributeValues)) {
        return true;
      }
    }

    if (isText) {
      return stringConditionIsInvalid(filter as NestedPropertyStringCondition);
    }

    if (isTimestamp) {
      return timeConditionIsInvalid(filter as NestedPropertyTimeCondition);
    }

    return true;
  });
}

const verbValues = [IsVerbType.IS, IsVerbType.IS_NOT];

function stringConditionIsInvalid({ stringCondition }: NestedPropertyStringCondition) {
  if (!stringCondition) {
    return true;
  }

  const { verb, values } = stringCondition;

  if (!verb || !verbValues.includes(verb)) {
    return true;
  }

  if (!values || !values.length) {
    return true;
  }

  return false;
}

function timeConditionIsInvalid({ timeCondition }: NestedPropertyTimeCondition) {
  if (!timeCondition) {
    return true;
  }

  const { comparator, units, duration, durationEnd, epochTime, epochTimeEnd } = timeCondition;

  if (!comparator) {
    return true;
  }

  switch (comparator) {
    case TimeComparatorType.AFTER:
    case TimeComparatorType.BEFORE:
    case TimeComparatorType.ON:
      return !epochTime;
    case TimeComparatorType.BETWEEN:
      return !epochTime || !epochTimeEnd;
    case TimeComparatorType.IN_THE_LAST:
    case TimeComparatorType.IN_THE_NEXT:
    case TimeComparatorType.DAYS_AGO:
    case TimeComparatorType.DAYS_UNTIL:
      return !units || !duration;
    case TimeComparatorType.BETWEEN_THE_LAST:
    case TimeComparatorType.BETWEEN_THE_NEXT:
      return !units || !duration || !durationEnd;
    case TimeComparatorType.IS_TODAY:
      // IS_TODAY does not require additional arguments
      return false;
    default:
      return true;
  }
}
