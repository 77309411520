/**
 * @generated SignedSource<<973a191524df13235677e902ed0b9aa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePaginatedJourneys_TargetingCommon_Company$data = {
  readonly id: string;
  readonly journeys: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    }>;
    readonly pageInfo: {
      readonly endCursor: string;
      readonly hasNextPage: boolean;
    };
    readonly " $fragmentSpreads": FragmentRefs<"useJourneyMessages_TargetingCommon_JourneyConnection" | "useJourneys_TargetingCommon_JourneyConnection">;
  } | null;
  readonly " $fragmentType": "usePaginatedJourneys_TargetingCommon_Company";
};
export type usePaginatedJourneys_TargetingCommon_Company$key = {
  readonly " $data"?: usePaginatedJourneys_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedJourneys_TargetingCommon_Company">;
};

import usePaginatedJourneys_TargetingCommon_RefetchQuery_graphql from './usePaginatedJourneys_TargetingCommon_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "journeys"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": usePaginatedJourneys_TargetingCommon_RefetchQuery_graphql,
      "identifierField": "id"
    }
  },
  "name": "usePaginatedJourneys_TargetingCommon_Company",
  "selections": [
    {
      "alias": "journeys",
      "args": null,
      "concreteType": "JourneyConnection",
      "kind": "LinkedField",
      "name": "__usePaginatedJourneys_TargetingCommon_connection_journeys_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JourneyEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Journey",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useJourneyMessages_TargetingCommon_JourneyConnection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useJourneys_TargetingCommon_JourneyConnection"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "dc19c6ae21086c2423464ab1649901a7";

export default node;
