import React, { ReactNode } from 'react';

import { Box, Heading, PicnicCss, styled } from '@attentive/picnic';

import desertImg from '../../assets/desert.png';

const Image = styled('img', { width: '440px' });
const defaultText = 'Oops, something went wrong';

interface OwnProps {
  primaryText?: string;
  children?: ReactNode;
  css?: PicnicCss;
}

export function ErrorComponent({ primaryText = defaultText, children, css }: OwnProps) {
  return (
    <Box
      css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ...css }}
      data-testid="error-large"
    >
      <Box css={{ display: 'flex', justifyContent: 'center', mt: '$space12' }}>
        <Image src={desertImg} />
      </Box>
      <Heading variant="lg" css={{ textAlign: 'center', mb: '$space2' }}>
        {primaryText}
      </Heading>
      <Box css={{ textAlign: 'center' }}>{children}</Box>
    </Box>
  );
}
