import React from 'react';
import { useFragment, usePreloadedQuery } from 'react-relay';

import { DisplayableOption } from '../../../../../../../../../constants';
import { ItemSelectDialog } from '../../../../../../../../ItemSelectDialog';
import { SelectedOption } from '../../../../../../../../ItemSelectDialog/SelectedItemsWrapper';
import {
  JourneyConditionPreloadedQuery,
  JourneyConditionQuery,
  JourneyConditionQueryType,
  ComposeMessageChannel,
} from '../../../JourneyCondition';
import { SelectedMessages } from '../types';
import { useJourneyMessages } from '../useJourneyMessages';

import CompanyFrag, {
  JourneyMessageDialog_TargetingCommon_Company$key as CompanyFragType,
} from '../__generated__/JourneyMessageDialog_TargetingCommon_Company.graphql';

type SelectedItemsProps = {
  queryRef: JourneyConditionPreloadedQuery;
  channel: ComposeMessageChannel;
  selectedMessages: SelectedMessages;
  selectedJourneys: string[];
  setSelectedMessages: (selectedMessages: SelectedMessages) => void;
};

export const SelectedItems = ({
  channel,
  selectedMessages,
  selectedJourneys,
  setSelectedMessages,
  queryRef,
}: SelectedItemsProps) => {
  const queryData = usePreloadedQuery<JourneyConditionQueryType>(JourneyConditionQuery, queryRef);
  const companyFrag = useFragment<CompanyFragType>(CompanyFrag, queryData.node);
  const { data: journeys } = useJourneyMessages(companyFrag, selectedJourneys, channel);

  const handleTagClick = ({ optionValue }: DisplayableOption) => {
    if (typeof optionValue === 'number') {
      throw new Error('Invalid option value');
    }

    const [journeyId, messageName] = optionValue.split('::');

    if (messageName) {
      setSelectedMessages({
        ...selectedMessages,
        [journeyId]: selectedMessages[journeyId].filter((msgName) => msgName !== messageName),
      });
      return;
    }

    const matchingJourney = journeys.find((journey) => journey.journeyId === journeyId);

    if (!matchingJourney) {
      throw new Error('Cannot find matching journey');
    }

    setSelectedMessages({
      ...selectedMessages,
      [journeyId]: [matchingJourney.messages[0]],
    });
  };

  const messageTags = Object.entries(selectedMessages).reduce(
    (acc, [journeyId, journeyMessages]) => {
      if (!journeyMessages.length) {
        const newAcc = [...acc];

        const journey = journeys.find((_journey) => _journey.journeyId === journeyId);

        if (!journey) {
          newAcc.push({
            optionValue: journeyId,
            displayName: `Any message in journey ID: ${journeyId}`,
            icon: 'Workflow',
            disabled: true,
          });
          return newAcc;
        }

        newAcc.push({
          optionValue: journeyId,
          displayName: `Any message in ${journey.name}`,
          icon: 'Workflow',
        });

        return newAcc;
      }

      return [
        ...acc,
        ...journeyMessages.map((messageName) => ({
          optionValue: `${journeyId}::${messageName}`,
          displayName: messageName,
        })),
      ];
    },
    [] as SelectedOption[]
  );

  return (
    <ItemSelectDialog.SelectedItems
      selectedOptions={messageTags}
      onRemoveSelection={handleTagClick}
      itemTypeLabel="Messages"
      tagCss={{
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '410px',
      }}
    />
  );
};
