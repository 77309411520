import { SegmentParameters, SENT_KEYWORD_PROPERTY_NAME } from '../../constants';

type SentKeywordErrors = {
  comparator?: string;
  inverse?: string;
  propertyName?: string;
  value?: string;
};

export function sentKeywordIsInvalid(parameters: SegmentParameters) {
  const errors = validateSentKeywordParameters(parameters, true);

  return Object.keys(errors).length > 0;
}

// Currently this is very specific to the TextActionFilter.SENT_MESSAGE filter
export function validateSentKeywordParameters(
  { stringComparator }: SegmentParameters,
  validateParameters: boolean
) {
  const errors: SentKeywordErrors = {};

  // if there's no stringComparator, the user has selected "any"
  if (!stringComparator || !validateParameters) {
    return errors;
  }
  const { comparator, inverse, propertyName, value } = stringComparator;

  // Make sure we don't accept empty values
  if (!value.trim()) {
    errors.value = 'You must enter a keyword or phrase';
  }

  if (!comparator) {
    errors.comparator = 'You must select an option';
  }

  // inverse should always be defined regardless of comparator
  if (inverse === undefined) {
    errors.inverse = 'Inverse value is not set';
  }

  // Property name is hardcoded and should match
  if (propertyName !== SENT_KEYWORD_PROPERTY_NAME) {
    errors.propertyName = `Property name is not correctly set. Received ${propertyName} but expected ${SENT_KEYWORD_PROPERTY_NAME}`;
  }

  return errors;
}
