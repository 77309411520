import { useAtomValue } from 'jotai';
// eslint-disable-next-line lodash/import-scope
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import React, { useState, useRef, useMemo, useEffect } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { isEqual } from '@attentive/nodash';
import { Box, LoadingIndicator, PicnicCss, Text } from '@attentive/picnic';

import planeImg from '../../../../assets/airplane.png';
import { BlankSegment, DEBOUNCED_FIELD_UPDATE_INTERVAL, Segment } from '../../../../constants';
import { useSegmentIsInvalid } from '../../../../hooks';
import {
  getSegmentComponentCount,
  getValidSegmentComponents,
  usePrevious,
} from '../../../../utils';
import { OperatorUIToggle } from '../../../OperatorUIToggle';
import { PU_MODAL_BODY_HEIGHT } from '../../SegmentCreateEditModal';
import {
  subscriberAttributeDataTypeMapAtom,
  vendorAttributeDataTypeMapAtom,
} from '../../utils/attributeDataTypeMap';

import SegmentComponentCount from './SegmentComponentCount';
import SegmentCountContent from './SegmentCountContent';
import SegmentCountContentNew from './SegmentCountContentNew';
import { ImgContainer, SidePaneImg } from './SegmentCountStyledImgComponents';

interface OwnProps {
  segment: Segment | BlankSegment;
  isFetchingSegment: boolean;
}

export const MANUAL_RECALCULATE_CONDITION_THRESHOLD = 5;

const segmentMetaFields = ['name', 'description'];

const sidePaneStyling: PicnicCss = {
  width: '268px',
  borderLeft: '1px solid $borderDefault',
  pt: '$space6',
  pb: '$space6',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
};

const SegmentCountSidePaneComponent = ({ segment }: OwnProps) => {
  const debouncedUpdateRef = useRef<DebouncedFunc<(value: Segment | BlankSegment) => void>>();
  const enableEmailSubCounts = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const subscriberAttributeDataTypeMap = useAtomValue(subscriberAttributeDataTypeMapAtom);
  const vendorAttributeDataTypeMap = useAtomValue(vendorAttributeDataTypeMapAtom);
  const isSegmentInvalid = useSegmentIsInvalid(segment);
  const segmentComponentCount = getSegmentComponentCount(segment);
  const shouldAutoUpdateCounts = segmentComponentCount < MANUAL_RECALCULATE_CONDITION_THRESHOLD;
  const initialCountableSegment = getValidSegmentComponents(
    segment,
    subscriberAttributeDataTypeMap,
    vendorAttributeDataTypeMap
  );
  const [countableSegment, setCountableSegment] = useState(initialCountableSegment);
  const [workingSegment, setWorkingSegment] = useState(initialCountableSegment);
  const [isWorking, setIsWorking] = useState(false);
  const [isStale, setIsStale] = useState(false);
  const previousWorkingSegment = usePrevious(workingSegment);

  const hasParams = countableSegment.expressions.some(
    ({ segmentComponents }) => segmentComponents.length > 0
  );

  const updateCountableSegment = (newCountableSegment: Segment | BlankSegment) => {
    setCountableSegment(newCountableSegment);
    setIsWorking(false);
  };

  const debouncedUpdateCountableSegment = useMemo(() => {
    if (debouncedUpdateRef.current) {
      debouncedUpdateRef.current.cancel();
    }

    debouncedUpdateRef.current = debounce(updateCountableSegment, DEBOUNCED_FIELD_UPDATE_INTERVAL);
    return debouncedUpdateRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when the segment prop is updated, we immediately set the working segment.
  useEffect(() => {
    setWorkingSegment(
      getValidSegmentComponents(segment, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);

  // when the working segment is updated, we check to see if any new valid
  // conditions have been added. if they have, we show the loading state immediately,
  // while setting the actual counted segment (sent to the backend) is debounced.
  useEffect(() => {
    if (
      isEqual(
        omit(previousWorkingSegment, segmentMetaFields),
        omit(workingSegment, segmentMetaFields)
      )
    ) {
      return;
    }

    if (shouldAutoUpdateCounts) {
      setIsWorking(true);
      debouncedUpdateCountableSegment(workingSegment);
    } else {
      setIsStale(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingSegment]);

  const updateSegmentCounts = () => {
    setIsWorking(true);
    debouncedUpdateCountableSegment(workingSegment);
  };

  const renderContent = () => {
    if (enableEmailSubCounts) {
      return (
        <SegmentCountContentNew
          segment={countableSegment}
          isWorking={isWorking}
          isStale={isStale}
          isSegmentInvalid={isSegmentInvalid}
          isAutoUpdateEnabled={shouldAutoUpdateCounts}
          onUpdateCountClick={updateSegmentCounts}
          onCountUpdate={() => setIsStale(false)}
        />
      );
    }

    return (
      <SegmentCountContent
        segment={countableSegment}
        isWorking={isWorking}
        isStale={isStale}
        isSegmentInvalid={isSegmentInvalid}
        isAutoUpdateEnabled={shouldAutoUpdateCounts}
        onUpdateCountClick={updateSegmentCounts}
        onCountUpdate={() => setIsStale(false)}
      />
    );
  };

  return (
    <Box css={{ ...sidePaneStyling, maxHeight: PU_MODAL_BODY_HEIGHT }}>
      <SegmentComponentCount segment={segment} />
      <Box
        css={{
          p: '$space6',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: '2',
        }}
      >
        <Box>
          {hasParams ? (
            renderContent()
          ) : (
            <>
              <ImgContainer>
                <SidePaneImg src={planeImg} data-testid="segment-count-side-pane-empty-img" />
              </ImgContainer>
              <Text css={{ textAlign: 'center' }} color="subdued">
                Add conditions to view results
              </Text>
            </>
          )}
        </Box>
        <OperatorUIToggle />
      </Box>
    </Box>
  );
};

const SegmentCountSidePane = (props: OwnProps) => {
  const { isFetchingSegment } = props;

  if (isFetchingSegment) {
    return (
      <Box css={sidePaneStyling}>
        <SegmentComponentCount />
        <Box
          css={{
            mt: '140px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            color: '$textDisabled',
          }}
        >
          <Text css={{ mb: '$space4' }}>Loading segment</Text>
          <LoadingIndicator css={{ color: 'inherit' }} />
        </Box>
      </Box>
    );
  }

  return <SegmentCountSidePaneComponent {...props} />;
};

export default SegmentCountSidePane;
