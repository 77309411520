import React from 'react';

import { Box, Button, Heading, StandardDialog } from '@attentive/picnic';

import { CampaignUsingSegment, IntegrationUsingSegment, JourneyUsingSegment } from '../constants';

import { SegmentInUse } from './SegmentInUse';

const SEGMENT_IN_USE_DIALOG_WIDTH = '600px';

interface OwnProps {
  open: boolean;
  onOpenChange: (val: boolean) => void;
  campaigns?: CampaignUsingSegment[] | null;
  journeys?: JourneyUsingSegment[] | null;
  integrations?: IntegrationUsingSegment[] | null;
}

const dialogTitle = {
  inUse: 'This segment is in use!',
  servicesUnavailable: "We're sorry!",
};

export const SegmentInUseErrorDialog = ({
  open,
  onOpenChange,
  campaigns,
  journeys,
  integrations,
}: OwnProps) => {
  const inUse = Boolean(campaigns || journeys || integrations);

  return (
    <StandardDialog open={open} onOpenChange={onOpenChange}>
      <StandardDialog.Content css={{ maxWidth: SEGMENT_IN_USE_DIALOG_WIDTH }}>
        <StandardDialog.Header>
          <Heading variant="md">
            {inUse ? dialogTitle.inUse : dialogTitle.servicesUnavailable}
          </Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <SegmentInUse campaigns={campaigns} journeys={journeys} integrations={integrations} />
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <Box css={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="primary" onClick={() => onOpenChange(false)}>
              Got it
            </Button>
          </Box>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
