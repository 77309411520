// interfaces.ts is a catchall for items that don't fit in the other files
import {
  IsVerbType,
  ShopifyOptions,
  FilterType,
  ValueComparator,
  Visibility,
  AvailableDataType,
  CampaignStatus,
  VendorAPIName,
} from '../index';

import {
  CustomAttributeOption,
  DisplayableOption,
  NumberValueDisplayableOption,
  StringValueDisplayableOption,
  SubscriberPreferenceGroup,
  WellKnownPropertyOption,
} from './options';

export interface CustomAttributeValueGroup {
  attribute: string;
  values: DisplayableOption[];
  isVerb: IsVerbType;
}

export interface CustomEventOption {
  customEventType: string;
  customEventValues: CustomEventValue[];
  customEventVendor?: string;
  customEventSource?: string;
  customEventSourceId?: string;
}

export interface CustomEventValue {
  isVerb: IsVerbType;
  customEventKey: string;
  value?: string | null;
  id?: number;
  dataType?: string | null;
}

export interface CampaignUsingSegment {
  campaignId: number;
  campaignName: string;
  campaignStatus: CampaignStatus;
}

export interface JourneyUsingSegment {
  journeyActive: boolean;
  journeyId: string;
  revisionId: string;
  name: string;
  url: string;
}

export interface IntegrationUsingSegment {
  id: number;
  name: string;
  url: string;
}

export interface SegmentUsage {
  campaigns?: CampaignUsingSegment[];
  journeys?: JourneyUsingSegment[];
  integrations?: IntegrationUsingSegment[];
}

export interface ManualUploadHistoricRecord {
  fileName: string;
  matchRate: number;
  matchedRecordCount: number;
  totalRecordCount: number;
  uploadedAt: number;
}

export interface ManualUploadList extends SubscriberList {
  matchRate: number;
  uploadedAt: number;
  originalFileNames: string[];
  manualUploadHistoricalRecords?: ManualUploadHistoricRecord[];
}

export interface ProductDataAttributeValue {
  attribute: string;
  values: StringValueDisplayableOption[] | NumberValueDisplayableOption[];
  isVerb: IsVerbType;
  valueComparator?: ValueComparator;
  name?: string;
}

export interface SalesChannelValue {
  isVerb: IsVerbType;
  values: StringValueDisplayableOption[];
}

export interface SegmentOptions {
  [FilterType.ACTION]: SubscriberActionOptions;
  [FilterType.ATTRIBUTE]: SubscriberAttributeOptions;
  [FilterType.MEMBERSHIP]: SubscriberMembershipOptions;
}

export interface SubscriberActionOptions {
  ecommerceOptions: {
    productDataOptions: {
      attributeOptions: StringValueDisplayableOption[];
    };
    bazaarVoiceEventOptions?: StringValueDisplayableOption[];
    rechargeEventOptions?: StringValueDisplayableOption[];
    salesChannels: StringValueDisplayableOption[];
  };
  customEventOptions: CustomEventOption[];
}

export interface SubscriberAttributeOptions {
  customAttributeOptions: CustomAttributeOption[];
  deviceOptions: {
    carriers: StringValueDisplayableOption[];
    deviceTypes: StringValueDisplayableOption[];
    operatingSystems: StringValueDisplayableOption[];
  };
  demographicOptions: {
    countries: NumberValueDisplayableOption[];
    states: NumberValueDisplayableOption[];
  };
  emailOptions: {
    creativeNameToIds: Record<string, number>;
  };
  smsOptions: {
    companyCodes: string[];
    creativeNameToIds: Record<string, number>;
    subscriberPreferenceGroups: SubscriberPreferenceGroup[];
  };
  shopifyOptions: {
    [ShopifyOptions.ACCEPTS_MARKETING_OPTIONS]: StringValueDisplayableOption[];
    [ShopifyOptions.CURRENCY_OPTIONS]: StringValueDisplayableOption[];
    [ShopifyOptions.CUSTOMER_STATE_OPTIONS]: StringValueDisplayableOption[];
    [ShopifyOptions.CUSTOMER_TAG_OPTIONS]: StringValueDisplayableOption[];
    [ShopifyOptions.MARKETING_OPT_IN_LEVEL_OPTIONS]: StringValueDisplayableOption[];
  };
  wellKnownAttributeOptions: WellKnownPropertyOption[];
}

export interface SubscriberMembershipOptions {
  subscriberTags: string[];
  manualUploadLists: SubscriberList[];
  thirdPartyLists: ThirdPartyList[];
}

export interface SubscriberList {
  subscriberListMetadataId: number;
  name: string;
}

export interface IntegrationFeature {
  created?: string;
  enabled?: boolean;
  externalVendorFeatureId?: number;
  externalVendorFeatureType?: string;
}

export interface ThirdPartyIntegration {
  externalVendorApiId: number;
  companyVendorIntegrationId: number;
  features: IntegrationFeature[];
  vendor: string;
  displayName: string;
  visibility?: Visibility;
}

export interface ThirdPartyList extends SubscriberList {
  externalId: string;
  externalSource: string;
  externalUrl: string;
  matchRate: number;
  syncedAt: number;
}

interface BaseVendorAttribute {
  attributeName: string;
  displayName: string;
  segmentDataType: string;
}

interface NestedVendorAttribute extends BaseVendorAttribute {
  values?: string[];
}

export interface VendorAttribute extends BaseVendorAttribute {
  // Only appears on Recharge attributes
  nestedOptions?: NestedVendorAttribute[];
}

export type SegmentableAttributes = Partial<Record<VendorAPIName, VendorAttribute[]>>;

export interface LocationValue {
  stateId?: string;
  locationValue?: string | null;
  locationValues?: string[];
  countryId?: string;
}

export type AvailableSegmentData = Map<AvailableDataType, boolean>;
