import {
  usePaginatedCampaigns_TargetingCommon_Query$data as PaginatedCampaignsFragmentData,
  CampaignMessageGroupStatus,
  ComposeCampaignStatus,
} from './hooks/__generated__/usePaginatedCampaigns_TargetingCommon_Query.graphql';

export const campaignRowHeight = 66;

export interface KlaviyoCampaign {
  name: string;
  externalId: string;
}

type ComposeCampaigns = NonNullable<PaginatedCampaignsFragmentData['composeCampaigns']>['edges'];
type ComposeCampaign = ComposeCampaigns[number]['node'];
type CampaignMessageGroups = NonNullable<ComposeCampaign['campaignMessageGroups']>['edges'];
type CampaignMessageGroup = CampaignMessageGroups[number]['node'];
type ComposeMessage = CampaignMessageGroup['messageMembers'][number]['composeMessage'];

export type {
  ComposeCampaign,
  CampaignMessageGroups,
  CampaignMessageGroup,
  ComposeMessage,
  CampaignMessageGroupStatus,
  ComposeCampaignStatus,
};
