import React from 'react';

import { IconButton, Switch, Text, Tooltip } from '@attentive/picnic';

import { SegmentConditionContainer } from '../../../../SegmentConditionContainer';

interface PartialDateToggleProps {
  onChange: (newValue: boolean) => void;
  value: boolean;
}

export const PartialDateToggle = ({ onChange, value }: PartialDateToggleProps) => {
  return (
    <SegmentConditionContainer css={{ width: '100%', alignContent: 'top' }}>
      <Switch checked={value} css={{ height: '$size9', mr: '$space0' }} onChange={onChange}>
        this date is recurring
      </Switch>
      <Tooltip>
        <Tooltip.Trigger>
          <IconButton
            iconName="CircleInformation"
            description="Recurring date information"
            size="small"
          />
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="right" variant="normal">
          <Text variant="caption">
            Year information will be ignored for filtering this date. (ex. Birthday)
          </Text>
        </Tooltip.Content>
      </Tooltip>
    </SegmentConditionContainer>
  );
};
