import React, { useEffect, useState } from 'react';

import { isEqual } from '@attentive/nodash';
import { Box, Text, Button, StandardDialog, ContainedLabel, Banner } from '@attentive/picnic';

import { useTargetingCommonLogger } from '../../../logger/useTargetingCommonLogger';
import { CustomAttributeErrors } from '../constants';
import {
  CustomAttributeFormErrors,
  CustomAttributeFormState,
  validateCustomAttributeForm,
} from '../form';

import { AttributeNameInput } from './AttributeNameInput';
import { AttributeTypeFields } from './AttributeTypeFields';

export type SaveFormResponse = { id: string; name: string; internalId: string };

export type FormDialogProps = {
  hasUpdatedAllSegments?: boolean;
  id?: string;
  initialFormValues: CustomAttributeFormState;
  onClose?: (error?: Error) => void;
  onEditExistingAttributeWithUsages?: (values: CustomAttributeFormState) => void;
  onSuccess?: (id: string, name?: string, internalId?: string) => void;
  onError?: (isEditing: boolean) => void;
  open?: boolean;
  saveForm: (values: CustomAttributeFormState) => Promise<SaveFormResponse>;
  segmentUsages?: number;
  // TODO: remove temporary prop once all attribute types are supported by journeys
  supportEnumAttributeOnly?: boolean;
};

const PU_DIALOG_WIDTH = 400;

export const FormDialog: React.FC<React.PropsWithChildren<FormDialogProps>> = ({
  children,
  hasUpdatedAllSegments,
  id,
  initialFormValues,
  onClose,
  onEditExistingAttributeWithUsages,
  onSuccess,
  onError,
  open,
  saveForm,
  segmentUsages,
  supportEnumAttributeOnly,
}) => {
  const { logError } = useTargetingCommonLogger();
  const [formState, setFormState] = useState(initialFormValues);
  const [hasClickedUpdate, setHasClickedUpdate] = useState(false);
  const [formErrors, setFormErrors] = useState<CustomAttributeFormErrors>({});
  const [hasChangedAttrDataType, setHasChangedAttrDataType] = useState(false);
  const [hasChangedForm, setHasChangedForm] = useState(false);

  const dialogTitle = id ? 'Edit custom attribute' : 'Create new custom attribute';

  const isEditing = Boolean(id);
  const hasSegmentUsages = Boolean(segmentUsages);
  const displayConfirmation = hasSegmentUsages && hasClickedUpdate;

  const checkForAttrDataTypeChange = () => {
    // The UI doesn't care if there's a change if this is a new attribute or one with no usages
    if ((!isEditing || !hasSegmentUsages) && !hasChangedAttrDataType) {
      return;
    }

    const hasRemovedExistingOptions = initialFormValues.options.some(
      (oldValue) => !formState.options.includes(oldValue)
    );

    const hasChanges =
      formState.dataType !== initialFormValues.dataType ||
      formState.inputType !== initialFormValues.inputType ||
      hasRemovedExistingOptions;

    setHasChangedAttrDataType(hasChanges);
  };

  useEffect(() => {
    const isFormUpdated = !isEqual(formState, initialFormValues);
    setHasChangedForm(isFormUpdated);
    if (isFormUpdated) checkForAttrDataTypeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const closeAndResetDialog = () => {
    if (onClose) onClose();
    setFormState(initialFormValues);
    setHasClickedUpdate(false);
    setHasChangedForm(false);
    setHasChangedAttrDataType(false);
    setFormErrors({});
  };

  const handleEditingExistingAttribute = () => {
    if (!hasClickedUpdate) {
      setHasClickedUpdate(true);
      return;
    }

    if (onEditExistingAttributeWithUsages) {
      onEditExistingAttributeWithUsages(formState);
    }
  };

  const getSubmitLabel = () => {
    if (!isEditing) return 'Create attribute';

    return displayConfirmation ? 'Proceed' : 'Update';
  };

  const submitForm = async () => {
    try {
      const { id: savedId, name, internalId } = await saveForm(formState);
      closeAndResetDialog();
      onSuccess?.(savedId, name, internalId);
    } catch (err) {
      if (err.message === CustomAttributeErrors.ALREADY_EXISTS) {
        setFormErrors({ attributeName: CustomAttributeErrors.ALREADY_EXISTS });
        return;
      }
      logError(err);
      onError?.(isEditing);
    }
  };

  return (
    <>
      <StandardDialog open={open} onOpenChange={closeAndResetDialog}>
        <StandardDialog.Content css={{ width: PU_DIALOG_WIDTH }}>
          <StandardDialog.Header>
            <StandardDialog.Heading>{dialogTitle}</StandardDialog.Heading>
          </StandardDialog.Header>
          <StandardDialog.Body>
            {hasUpdatedAllSegments && (
              <Banner css={{ marginBottom: '$space4' }} variant="success">
                Segments successfully updated.
              </Banner>
            )}
            {displayConfirmation && (
              <Banner css={{ marginBottom: '$space4' }} variant="warning">
                This attribute is used in one or more active segments. You will be asked to edit the
                instances of this attribute in order to change the data type.
              </Banner>
            )}
            <Box css={{ marginBottom: '$space4' }}>
              <Text variant="caption" color="subdued">
                You’ll be able to use this attribute to save subscriber information in Journeys or
                filter subscribers in a Segment.{' '}
                {isEditing && 'If you need to delete this attribute, contact your CS rep.'}
              </Text>
            </Box>
            {hasSegmentUsages && (
              <Box css={{ marginBottom: '$space4' }}>
                <ContainedLabel>
                  {segmentUsages} {segmentUsages === 1 ? 'instance' : 'instances'}
                </ContainedLabel>
              </Box>
            )}
            <AttributeNameInput
              onChange={(attributeName) => setFormState({ ...formState, attributeName })}
              value={formState.attributeName}
              errors={formErrors}
              showTooltip={!isEditing}
            />
            <AttributeTypeFields
              {...formState}
              errors={formErrors}
              isEditing={isEditing}
              supportEnumAttributeOnly={supportEnumAttributeOnly}
              onInputTypeChange={(newFormState) => {
                setFormState({
                  ...formState,
                  ...newFormState,
                });
              }}
              onDataTypeChange={(dataType) => {
                setFormState({
                  ...formState,
                  dataType,
                });
              }}
              onOptionsChange={(options) => {
                setFormState({
                  ...formState,
                  options,
                });
              }}
            />
          </StandardDialog.Body>
          <StandardDialog.Footer>
            <Button variant="secondary" onClick={closeAndResetDialog} size="small">
              Cancel
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                const [errors, hasErrors] = validateCustomAttributeForm(formState);
                setFormErrors(errors);

                if (hasErrors) return;

                // Only take users through usages flow if they've changed the data type
                if (hasSegmentUsages && hasChangedAttrDataType) {
                  return handleEditingExistingAttribute();
                }
                return submitForm();
              }}
              disabled={!hasChangedForm}
            >
              {getSubmitLabel()}
            </Button>
          </StandardDialog.Footer>
        </StandardDialog.Content>
      </StandardDialog>
      {children}
    </>
  );
};
