import React from 'react';

import { FormField } from '@attentive/picnic';

import { DisplayableOption, SelectableCampaignType } from '../../../../../../constants';
import { useShowExpressionValidationAtomValue } from '../../../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

import { MessageDialogButton } from './MessageDialogButton';

interface OwnProps {
  values: DisplayableOption[];
  setDialogIsOpen: (isOpen: boolean) => void;
  onSubmit: (values: DisplayableOption[]) => void;
  selectableCampaignType?: SelectableCampaignType;
}

export const CampaignMessageSelector: React.FC<OwnProps> = ({
  values,
  setDialogIsOpen,
  onSubmit,
  selectableCampaignType,
}) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && values.length < 1;

  return (
    <FormField>
      <MessageDialogButton
        values={values}
        setDialogIsOpen={setDialogIsOpen}
        onSubmit={onSubmit}
        selectableCampaignType={selectableCampaignType}
      />
      {error && <FormField.ErrorText>Please choose values.</FormField.ErrorText>}
    </FormField>
  );
};
