import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@attentive/picnic';

type LiveRegionContext =
  | {
      liveRegionText: string;
      setLiveRegionText: (newText: string) => void;
    }
  | undefined;

type LiveRegionProviderProps = {
  children: React.ReactNode;
  context: React.Context<LiveRegionContext>;
};

// Provides the context for powering LiveRegionArea and the live region updaters
const LiveRegionProvider = ({ children, context }: LiveRegionProviderProps) => {
  const [liveRegionText, setLiveRegionText] = useState('');
  const { Provider } = context;
  return <Provider value={{ liveRegionText, setLiveRegionText }}>{children}</Provider>;
};

type LiveRegionAreaProps = {
  context: React.Context<LiveRegionContext>;
  'data-testid'?: string;
};

// Displays the current text in an aria live region
const LiveRegionArea = ({ context, 'data-testid': dataTestid }: LiveRegionAreaProps) => {
  const liveRegionContext = useContext(context);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    if (liveRegionContext && currentText !== liveRegionContext.liveRegionText) {
      setCurrentText(liveRegionContext.liveRegionText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveRegionContext]);

  return (
    <Box
      aria-live="polite"
      aria-atomic="true"
      css={{
        height: '1px',
        width: '1px',
        overflow: 'hidden',
        padding: '$space0',
        position: 'absolute',
        margin: '-1px',
        border: '0px',
      }}
      data-testid={dataTestid}
    >
      {currentText}
    </Box>
  );
};

// Creates a new context and returns components and a hook for working with this specific live region
export const createLiveRegion = () => {
  const context = React.createContext<LiveRegionContext>(undefined);

  const LiveRegionProviderWithContext = ({ children }: { children: React.ReactNode }) => {
    return <LiveRegionProvider context={context}>{children}</LiveRegionProvider>;
  };

  const LiveRegionAreaWithContext = (props: Omit<LiveRegionAreaProps, 'context'>) => {
    return <LiveRegionArea context={context} {...props} />;
  };

  const useLiveRegionUpdaters = () => {
    const liveRegionContext = useContext(context);
    if (!liveRegionContext) {
      throw new Error('This hook must be used in the provider');
    }
    const { setLiveRegionText } = liveRegionContext;

    return { setLiveRegionText, clearLiveRegionText: () => setLiveRegionText('') };
  };

  return {
    LiveRegionProvider: LiveRegionProviderWithContext,
    LiveRegionArea: LiveRegionAreaWithContext,
    useLiveRegionUpdaters,
  };
};
