// This function removes the extraneous metadata attributes that are only used on the client

import { cloneDeep, omit } from '@attentive/nodash';

import { BlankSegment, Segment } from '../../../constants';

// The BE doesn't complain if we include them in the payload but we shouldn't send data that isn't needed
export function stripSegmentMetadata(originalSegment: Segment | BlankSegment) {
  const segment = cloneDeep(originalSegment);

  segment.expressions = segment.expressions.map((expression) => {
    return {
      ...expression,
      segmentComponents: expression.segmentComponents.map((component) =>
        omit(component, ['metadata'])
      ),
    };
  });

  return segment;
}
