import { useMemo } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import {
  SegmentIoSupportedProfileAttrs,
  SegmentableAttributes,
  Vendor,
  VendorAttribute,
  knownMParticleFilterAttrs,
  knownPunchFilterAttrs,
  knownRechargeFilterAttrs,
  knownSmileFilterAttrs,
  mParticleUnsupportedAttributes,
  rechargeUnsupportedAttributes,
  segmentIoUnsupportedAttributes,
  smileUnsupportedAttributes,
  yotpoSupportedAttributes,
} from '../../../../constants';

import {
  separateKlaviyoOptions,
  separateShopifyOptions,
  useMemoizedSeparateOptions,
} from './utils';

type AttributeFlags =
  | 'ENABLE_MPARTICLE_ATTRIBUTES'
  | 'ENABLE_SMILE_ATTRIBUTES'
  | 'ENABLE_PUNCHH_ATTRIBUTES'
  | 'ENABLE_RECHARGE_ATTRIBUTES'
  | 'ENABLE_SEGMENT_BY_KLAVIYO_PROFILE_ATTRIBUTES'
  | 'ENABLE_YOTPO_ATTRIBUTES';

function useEnabledVendorAttributes(
  attributes: VendorAttribute[] | undefined,
  flagName: AttributeFlags,
  allowedAttributes?: string[]
) {
  const isEnabled = useCompanyFeatureFlag(flagName);

  return useMemo(() => {
    if (!isEnabled || !attributes) return [];
    if (allowedAttributes?.length) {
      return attributes.filter(({ attributeName }) => allowedAttributes.includes(attributeName));
    }
    return attributes;
  }, [allowedAttributes, attributes, isEnabled]);
}

export const useVendorAttributes = (vendorAttributes: SegmentableAttributes) => {
  const klaviyoOptions = useEnabledVendorAttributes(
    vendorAttributes.KLAVIYO,
    'ENABLE_SEGMENT_BY_KLAVIYO_PROFILE_ATTRIBUTES'
  );
  const punchhOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_PUNCHH,
    'ENABLE_PUNCHH_ATTRIBUTES'
  );
  const mParticleOptions = useEnabledVendorAttributes(
    vendorAttributes.MPARTICLE,
    'ENABLE_MPARTICLE_ATTRIBUTES'
  );
  const rechargeOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_RECHARGE,
    'ENABLE_RECHARGE_ATTRIBUTES'
  );
  const smileOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_SMILE_IO,
    'ENABLE_SMILE_ATTRIBUTES'
  );
  const yotpoOptions = useEnabledVendorAttributes(
    vendorAttributes.VENDOR_YOTPO,
    'ENABLE_YOTPO_ATTRIBUTES'
  );

  const {
    klaviyoCustomOptions,
    klaviyoEmailOptions,
    klaviyoLocationOptions,
    klaviyoProfileOptions,
  } = useMemo(() => separateKlaviyoOptions(klaviyoOptions), [klaviyoOptions]);

  const { profileOptions: punchhProfileOptions, customOptions: punchhCustomOptions } =
    useMemoizedSeparateOptions({
      options: punchhOptions,
      knownOptions: knownPunchFilterAttrs,
      vendor: Vendor.PUNCHH,
    });

  const { profileOptions: mParticleProfileOptions, customOptions: mParticleCustomOptions } =
    useMemoizedSeparateOptions({
      options: mParticleOptions,
      knownOptions: knownMParticleFilterAttrs,
      vendor: Vendor.MPARTICLE,
      unsupportedOptions: mParticleUnsupportedAttributes,
    });

  const { profileOptions: rechargeProfileOptions } = useMemoizedSeparateOptions({
    options: rechargeOptions,
    knownOptions: knownRechargeFilterAttrs,
    vendor: Vendor.RECHARGE,
    unsupportedOptions: rechargeUnsupportedAttributes,
    enableNestedListTypes: true,
  });

  const { profileOptions: smileProfileOptions, customOptions: smileCustomOptions } =
    useMemoizedSeparateOptions({
      options: smileOptions,
      knownOptions: knownSmileFilterAttrs,
      vendor: Vendor.SMILE,
      unsupportedOptions: smileUnsupportedAttributes,
    });

  const { profileOptions: yotpoProfileOptions, customOptions: yotpoCustomOptions } =
    useMemoizedSeparateOptions({
      options: yotpoOptions,
      knownOptions: yotpoSupportedAttributes,
      vendor: Vendor.YOTPO,
    });

  const { profileOptions: segmentIoProfileOptions, customOptions: segmentIoCustomOptions } =
    useMemoizedSeparateOptions({
      options: vendorAttributes.VENDOR_SEGMENT || [],
      knownOptions: SegmentIoSupportedProfileAttrs,
      vendor: Vendor.SEGMENT_IO,
      unsupportedOptions: segmentIoUnsupportedAttributes,
    });

  const { shopifyEmailOptions, shopifyShoppingOptions } = useMemo(
    () => separateShopifyOptions(vendorAttributes.SHOPIFY),
    [vendorAttributes.SHOPIFY]
  );

  return {
    klaviyoCustomOptions,
    klaviyoEmailOptions,
    klaviyoLocationOptions,
    klaviyoProfileOptions,
    mParticleCustomOptions,
    mParticleProfileOptions,
    punchhCustomOptions,
    punchhProfileOptions,
    rechargeProfileOptions,
    segmentIoCustomOptions,
    segmentIoProfileOptions,
    shopifyEmailOptions,
    shopifyShoppingOptions,
    smileCustomOptions,
    smileProfileOptions,
    yotpoCustomOptions,
    yotpoProfileOptions,
  };
};
