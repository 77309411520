import React from 'react';

import { Box, IconButton } from '@attentive/picnic';

import { campaignRowHeight, ComposeCampaign } from '../constants';
import { getCampaignUpdatedTimeString, getMessageRowStatus, MessageRowStatus } from '../utils';

import { RowDeatilsComponent } from './RowDeatilsComponent';

interface MessageRowProps {
  isChecked: boolean | 'indeterminate';
  onClickHandler: () => void;
  campaign: ComposeCampaign;
  queryString: string;
  secondaryText?: string | null;
  status?: MessageRowStatus;
  isExpanded: boolean;
  onCampaignExpandedChange: () => void;
}

export const CampaignRowComponent: React.FC<MessageRowProps> = ({
  isChecked,
  onClickHandler,
  campaign,
  queryString,
  isExpanded,
  onCampaignExpandedChange,
}) => {
  const timezone = campaign.company?.timezone;
  const campaignPillStatus = getMessageRowStatus(campaign.campaignStatus);

  return (
    <Box
      css={{
        height: `${campaignRowHeight}px`,
        borderBottom: '1px solid $borderDefault',
        '&:hover': {
          backgroundColor: '$bgRowHover',
        },
        display: 'flex',
        alignItems: 'center',
        paddingRight: '$space4',
        minWidth: 0,
      }}
    >
      <IconButton
        iconName="ChevronDown"
        size="small"
        description="Expand campaign sequence"
        aria-expanded={isExpanded}
        css={{
          padding: '$space2',
          pointerEvents: 'auto',
          flexShrink: 0,
          transition: 'transform 0.3s ease-in-out 0s',
          ...(!isExpanded && { transform: 'rotate(-90deg)' }),
        }}
        onClick={onCampaignExpandedChange}
      />
      <Box onClick={onClickHandler} css={{ width: '100%', minWidth: 0 }} role="button">
        <RowDeatilsComponent
          isChecked={isChecked}
          primaryText={campaign.name}
          secondaryText={getCampaignUpdatedTimeString(campaign, timezone)}
          status={campaignPillStatus}
          queryString={queryString}
        />
      </Box>
    </Box>
  );
};
