import React, { useCallback, useEffect } from 'react';

import { useSetShowExpressionValidationAtom } from './useShowExpressionValidationAtom';

export const useShowExpressionValidationHandlers = () => {
  const timerRef = React.useRef(0);
  const setShowValidation = useSetShowExpressionValidationAtom();
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

  const handleFocus = useCallback(() => {
    window.clearTimeout(timerRef.current);
    setShowValidation(false);
  }, [setShowValidation]);

  const handleBlur = useCallback(() => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => setShowValidation(!dialogIsOpen), 100);
  }, [setShowValidation, dialogIsOpen]);

  useEffect(() => {
    // reset atom when component unmounts
    return () => {
      setShowValidation(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleFocus, handleBlur, setDialogIsOpen };
};
