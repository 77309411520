import React from 'react';

import { Box, Select, Text } from '@attentive/picnic';

import { ActionSource } from '../../../constants';
import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';
import { getSentenceCase } from '../utils/stringUtils';

interface EmailSourceSelectorProps {
  value: ActionSource;
  onChange: (newVal: ActionSource) => void;
  options: ActionSource[];
}

const EmailSourceSelector = ({ value, onChange, options }: EmailSourceSelectorProps) => {
  if (!options.length) {
    return null;
  }

  if (options.length === 1) {
    return (
      <SegmentConditionItemContainer>
        <Text>{`from ${getSentenceCase(value)}`}</Text>
      </SegmentConditionItemContainer>
    );
  }

  return (
    <>
      <SegmentConditionItemContainer>
        <Text>from</Text>
      </SegmentConditionItemContainer>
      <Box>
        <Select
          value={value}
          onChange={(newVal: string) => onChange(newVal as ActionSource)}
          placeholder="Source"
          size="small"
        >
          {options.map((option) => (
            <Select.Item key={option} value={option}>
              {getSentenceCase(option)}
            </Select.Item>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default EmailSourceSelector;
