/**
 * @generated SignedSource<<901f64b056b758be4bd2a66b4c944a05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAttributeUsage_TargetingCommon_Company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AttributeDetails_TargetingCommon_Company">;
  readonly " $fragmentType": "CustomAttributeUsage_TargetingCommon_Company";
};
export type CustomAttributeUsage_TargetingCommon_Company$key = {
  readonly " $data"?: CustomAttributeUsage_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeUsage_TargetingCommon_Company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomAttributeUsage_TargetingCommon_Company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttributeDetails_TargetingCommon_Company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "68891664eb4cd304c9df085d819b7088";

export default node;
