import _useKlaviyoEmailFeatureValidDate_TargetingCommon_Company from "./__generated__/useKlaviyoEmailFeatureValidDate_TargetingCommon_Company.graphql";
import _useKlaviyoEmailFeatureValidDate_TargetingCommon_Query from "./__generated__/useKlaviyoEmailFeatureValidDate_TargetingCommon_Query.graphql";
import { differenceInCalendarDays, subDays } from 'date-fns';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { Severity, ToastType, useCurrentCompanyId, useToast } from '@attentive/acore-utils';
import { deserializeGQLDateTime, graphql } from '@attentive/data';
import { KLAVIYO_EMAIL_FEATURE } from '../constants';
import { useTargetingCommonLogger } from '../logger/useTargetingCommonLogger';
import { getIntegrationFeature } from '../utils/getIntegrationFeature';
import CompanyFragment from './__generated__/useKlaviyoEmailFeatureValidDate_TargetingCommon_Company.graphql';
import Query from './__generated__/useKlaviyoEmailFeatureValidDate_TargetingCommon_Query.graphql';
_useKlaviyoEmailFeatureValidDate_TargetingCommon_Query;
_useKlaviyoEmailFeatureValidDate_TargetingCommon_Company;
export const useKlaviyoEmailFeatureValidDate = isKlaviyo => {
  var _data$companyVendorIn, _data$companyVendorIn2, _klaviyoEmailIntegrat;

  const [createToast] = useToast();
  const {
    logError
  } = useTargetingCommonLogger();
  const companyId = useCurrentCompanyId();
  const {
    company
  } = useLazyLoadQuery(Query, {
    companyId,
    isKlaviyo
  });
  const data = useFragment(CompanyFragment, company || null);

  if (!isKlaviyo) {
    return {
      klaviyoEmailDaysMax: 0,
      klaviyoEmailValidDate: new Date()
    };
  }

  const klaviyoEmailIntegration = data === null || data === void 0 ? void 0 : (_data$companyVendorIn = data.companyVendorIntegrations) === null || _data$companyVendorIn === void 0 ? void 0 : _data$companyVendorIn.find(_ref => {
    let {
      companyVendorIntegrationFeatures
    } = _ref;
    return companyVendorIntegrationFeatures && getIntegrationFeature([...companyVendorIntegrationFeatures], KLAVIYO_EMAIL_FEATURE);
  });

  if (!(data !== null && data !== void 0 && (_data$companyVendorIn2 = data.companyVendorIntegrations) !== null && _data$companyVendorIn2 !== void 0 && _data$companyVendorIn2.length) || !klaviyoEmailIntegration) {
    var _data$companyVendorIn3;

    const errorMsg = data !== null && data !== void 0 && (_data$companyVendorIn3 = data.companyVendorIntegrations) !== null && _data$companyVendorIn3 !== void 0 && _data$companyVendorIn3.length && !klaviyoEmailIntegration ? 'Could not find Klaviyo email feature' : 'Unable to fetch integration';
    createToast({
      type: ToastType.Error,
      title: 'Error',
      text: errorMsg
    });
    logError(new Error(errorMsg), {
      severity: Severity.WARNING
    });
    return {
      klaviyoEmailDaysMax: 0,
      klaviyoEmailValidDate: new Date()
    };
  }

  const klaviyoEmailFeature = klaviyoEmailIntegration === null || klaviyoEmailIntegration === void 0 ? void 0 : (_klaviyoEmailIntegrat = klaviyoEmailIntegration.companyVendorIntegrationFeatures) === null || _klaviyoEmailIntegrat === void 0 ? void 0 : _klaviyoEmailIntegrat.find(feature => {
    return getIntegrationFeature([feature], KLAVIYO_EMAIL_FEATURE);
  });
  const klaviyoEmailStartDate = klaviyoEmailFeature !== null && klaviyoEmailFeature !== void 0 && klaviyoEmailFeature.created ? new Date(deserializeGQLDateTime(klaviyoEmailFeature.created)) : new Date();
  const klaviyoEmailValidDate = subDays(klaviyoEmailStartDate, 90);
  const klaviyoEmailDaysMax = differenceInCalendarDays(new Date(), klaviyoEmailValidDate);
  return {
    klaviyoEmailValidDate,
    klaviyoEmailDaysMax
  };
};