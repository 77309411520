import { DistanceUnit } from '../../constants';

const MAX_RADIUS_IN_MILES = 500;
const MAX_RADIUS_IN_KILOMETERS = 805;

export function getMaxRadius(unit: DistanceUnit) {
  return unit === DistanceUnit.MILES ? MAX_RADIUS_IN_MILES : MAX_RADIUS_IN_KILOMETERS;
}

export function isWithinValidRadius(distance: number, unit: DistanceUnit) {
  return distance <= getMaxRadius(unit);
}

// TODO - sc consider moving to different location
export function isValidDistance(
  distance?: string | number,
  origin?: string,
  unit?: DistanceUnit,
  country?: string
) {
  if (!distance || !origin || !unit || !country) {
    return false;
  }

  const distanceInt = Number(distance);

  if (!Number.isInteger(distanceInt)) {
    return false;
  }

  // Distance exceeds max value
  if (distanceInt > getMaxRadius(unit)) {
    return false;
  }

  // Zipcode is not valid for the given country
  if (!isValidZipcode(origin, country)) {
    return false;
  }

  // We don't need to explicitly check unit's value because it's set via a select
  return true;
}

// TODO - sc consider hoisting to locale utils
export function isValidZipcode(input: string, country: string) {
  const canadianZipcodeRegex = /^[0-9A-Z]{3}$/;
  const usZipcodeRegex = /^[0-9]{5}$/;
  const validationRegex = country === 'CA' ? canadianZipcodeRegex : usZipcodeRegex;
  return Boolean(input.match(validationRegex));
}
