import {
  ActionFilter,
  AvailableDataType,
  HasVerbType,
  OperatorComparator,
  OperatorType,
  SegmentComponentType,
  SegmentTemplateId,
  TimeComparatorType,
} from '../constants';
import { getNewBlankSegment } from '../utils';

import { SegmentTemplateEntry } from './types';

export const prospects: SegmentTemplateEntry = {
  neededDataType: [AvailableDataType.PURCHASE],
  segment: () => ({
    ...getNewBlankSegment(),
    templateId: SegmentTemplateId.PROSPECTS,
    expressions: [
      {
        operator: OperatorType.OR,
        segmentComponents: [
          {
            type: SegmentComponentType.CUSTOM,
            parameters: {
              frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
              hasVerb: HasVerbType.HAS_NOT,
              subscriberAction: ActionFilter.PURCHASED,
              timeComparator: TimeComparatorType.OVER_ALL_TIME,
            },
          },
        ],
      },
    ],
  }),
};
