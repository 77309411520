import { atom } from 'jotai';

import {
  VendorAttributeDataType,
  VendorAttribute,
  Vendor,
  SegmentableAttributes,
  VendorAPIMap,
  VendorAPIName,
} from '../../../../constants';

type AttributeDataTypeMap = Record<string, string>;

export type VendorAttributeDataTypeMap = Partial<Record<Vendor, AttributeDataTypeMap>>;

export const vendorAttributeDataTypeMapAtom = atom<VendorAttributeDataTypeMap>({});

function buildVendorAttributeDataTypeMap(attributes: VendorAttribute[]): AttributeDataTypeMap {
  if (!attributes.length) return {};

  return attributes.reduce((acc, { attributeName, segmentDataType, nestedOptions }) => {
    const newAcc = { ...acc, [attributeName]: segmentDataType };
    if (segmentDataType !== VendorAttributeDataType.LIST || !nestedOptions) {
      return newAcc;
    }

    return nestedOptions.reduce(
      (nestedAcc, nestedOption) => ({
        ...nestedAcc,
        [`${attributeName}:${nestedOption.attributeName}`]: nestedOption.segmentDataType,
      }),
      newAcc
    );
  }, {});
}

// This is for usage where we can't access the hook so we have to pass down the data type map
export function getVendorAttributeDataType(
  vendor: Vendor,
  attribute: string,
  dataTypeMap: VendorAttributeDataTypeMap
) {
  const attributeDataType = dataTypeMap?.[vendor]?.[attribute] || VendorAttributeDataType.UNKNOWN;

  return {
    attributeDataType,
    isBoolean: attributeDataType === VendorAttributeDataType.BOOLEAN,
    isFloat: attributeDataType === VendorAttributeDataType.FLOAT,
    isInteger: attributeDataType === VendorAttributeDataType.INTEGER,
    isList: attributeDataType === VendorAttributeDataType.LIST,
    isTimestamp: attributeDataType === VendorAttributeDataType.TIMESTAMP,
    isText: attributeDataType === VendorAttributeDataType.TEXT,
  };
}

// This function takes the response from the segmentableAttributesByVendor endpoint and assembles data type maps for all the vendors
export function assembleVendorAttributeDataTypeMap(
  segmentableAttributes: SegmentableAttributes,
  setDataTypeMap: (map: VendorAttributeDataTypeMap) => void
) {
  const vendors = Object.keys(segmentableAttributes) as VendorAPIName[];
  if (vendors.length === 0) return;

  const dataTypeMap = vendors.reduce((all, vendor) => {
    const attributes = segmentableAttributes[vendor] || [];
    const vendorSegmentationName = VendorAPIMap[vendor];

    return {
      ...all,
      [vendorSegmentationName]: buildVendorAttributeDataTypeMap(attributes),
    };
  }, {} as VendorAttributeDataTypeMap);

  setDataTypeMap(dataTypeMap);
}
