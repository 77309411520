import _downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload from "./__generated__/downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload.graphql";
import _downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment from "./__generated__/downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment.graphql";
import _downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition from "./__generated__/downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition.graphql";
import { readInlineData, graphql } from '@attentive/data';
import { downloadDataAsCsv } from '../../../../utils';
import UsagesFragment from './__generated__/downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition.graphql';
import SegmentFragment from './__generated__/downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment.graphql';
import SegmentCountFragment from './__generated__/downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload.graphql';
_downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition;
export function downloadSegmentUsages(queryRef) {
  const {
    usages
  } = readInlineData(UsagesFragment, queryRef);
  const rows = usages.map(usage => formatCSVRow(usage));
  downloadDataAsCsv(rows);
}
_downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment;

function formatCSVRow(queryRef) {
  const {
    internalId,
    name
  } = readInlineData(SegmentFragment, queryRef);
  const url = "".concat(location.origin, "/segments/").concat(internalId);
  return {
    'Segment name': name,
    'Segment URL': url
  };
}

_downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload;
export function getSubscriberCount(queryRef, emailEnabled) {
  if (!queryRef) return 0;
  const {
    email,
    sms,
    smsAndEmail
  } = readInlineData(SegmentCountFragment, queryRef);
  const smsCount = (sms === null || sms === void 0 ? void 0 : sms.activeSubscribers) || 0;
  const emailCount = (email === null || email === void 0 ? void 0 : email.activeSubscribers) || 0;
  const smsAndEmailCount = (smsAndEmail === null || smsAndEmail === void 0 ? void 0 : smsAndEmail.activeSubscribers) || 0;
  return emailEnabled ? smsCount + emailCount - smsAndEmailCount : smsCount;
}