import { useEffect, useRef, useState } from 'react';

import { PicnicCss } from '@attentive/picnic';

const STATE_CHANGE_DELAY = 5;

type useAnimatedEntryConfig = {
  isVisible: boolean;
  visibleCss: PicnicCss;
  hiddenCss: PicnicCss;
  animationDuration: number;
};

type useAnimatedEntryValues = {
  isMounted: boolean;
  elementCss: PicnicCss;
};

export const useAnimatedEntry = ({
  animationDuration,
  isVisible,
  hiddenCss,
  visibleCss,
}: useAnimatedEntryConfig): useAnimatedEntryValues => {
  const [currentCss, setCurrentCss] = useState(hiddenCss);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef(0);

  useEffect(() => {
    window.clearTimeout(timerRef.current);

    if (isVisible) {
      setIsMounted(true);
      timerRef.current = window.setTimeout(() => {
        setCurrentCss(visibleCss);
      }, STATE_CHANGE_DELAY);
      return;
    }

    setCurrentCss(hiddenCss);
    timerRef.current = window.setTimeout(() => {
      setIsMounted(false);
    }, animationDuration + STATE_CHANGE_DELAY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return {
    elementCss: currentCss,
    isMounted,
  };
};
