import { CAMPAIGN_ATTR, CustomAttributeValueGroup, SegmentParameters } from '../../constants';
import { isKlaviyoEventType } from '../typeAssertions';

export const messageDataIsInvalid = (attr?: CustomAttributeValueGroup) =>
  attr && attr.attribute === CAMPAIGN_ATTR && attr.values.length < 1;

export const journeyMessageDataIsInvalid = (parameters: SegmentParameters) => {
  const isKlaviyoEvent = isKlaviyoEventType(parameters);
  return (
    parameters.journeyAttributeValues &&
    (parameters.journeyAttributeValues.length < 1 || isKlaviyoEvent)
  );
};
