import { addDays } from 'date-fns';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

export const EXPIRATION_DATE_LOCAL_STORAGE_KEY = 'segmentBuilderUIToggleExpirationDate';
export const UI_STATUS_STORAGE_KEY = 'segmentBuilderUIToggleOperatorUIEnabled';
const operatorUIEnabled = atom(window.localStorage.getItem(UI_STATUS_STORAGE_KEY) === 'true');

const durationInDays = 14;

export function useIsOperatorUIToggleDisplayed() {
  const isEnabled = useCompanyFeatureFlag('ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE');
  const featureFlagStatus = useCompanyFeatureFlag('ENABLE_SEGMENT_BUILDER_OPERATOR_UI');
  const setOperatorUIEnabled = useSetAtom(operatorUIEnabled);

  const isExpired = React.useMemo(() => {
    if (!isEnabled) return false;
    const expirationDate = window.localStorage.getItem(EXPIRATION_DATE_LOCAL_STORAGE_KEY);

    if (!expirationDate) return false;

    const now = new Date();
    const parsedExpirationDate = Number(expirationDate);

    return !isNaN(parsedExpirationDate) && now.getTime() >= parsedExpirationDate;
  }, [isEnabled]);

  React.useEffect(() => {
    const hasLocalData = window.localStorage.getItem(EXPIRATION_DATE_LOCAL_STORAGE_KEY);

    if (!hasLocalData && isEnabled) {
      const expirationDate = addDays(new Date(), durationInDays);
      window.localStorage.setItem(EXPIRATION_DATE_LOCAL_STORAGE_KEY, `${expirationDate.getTime()}`);
      window.localStorage.setItem(UI_STATUS_STORAGE_KEY, featureFlagStatus ? 'true' : 'false');
      setOperatorUIEnabled(featureFlagStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  return isEnabled && !isExpired;
}

/**
 * Checks if the new segment operator UI is enabled. The UI should be enabled if one of the following is true
 * - ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE is enabled and the local storage value is set to 'true'
 * - ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE is disabled and ENABLE_SEGMENT_BUILDER_OPERATOR_UI is enabled
 *
 * @returns boolean
 */
export function useIsOperatorUIEnabled() {
  const featureFlagStatus = useCompanyFeatureFlag('ENABLE_SEGMENT_BUILDER_OPERATOR_UI');
  const isToggleEnabled = useCompanyFeatureFlag('ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE');
  const localStorageStatus = useAtomValue(operatorUIEnabled);

  return isToggleEnabled ? localStorageStatus : featureFlagStatus;
}

export function useOperatorUIToggle() {
  const isOperatorUIEnabled = useIsOperatorUIEnabled();
  const setOperatorUIEnabled = useSetAtom(operatorUIEnabled);

  const toggleOperatorUI = () => {
    const isEnabled = !isOperatorUIEnabled;
    setOperatorUIEnabled(isEnabled);
    window.localStorage.setItem(UI_STATUS_STORAGE_KEY, isEnabled ? 'true' : 'false');
  };

  return { isOperatorUIEnabled, toggleOperatorUI };
}
