// Currently the backend only supports strings and enums for custom properties. As a stopgap for lack of integer support,
// we are providing a way to support comma separated strings in a freeform text field. This is only supported for specific
// customers and properties.

type OverrideObject = Record<number, Record<string, string>>;

// the name of the field is included to make it easier to find the correct id; it isn't used in the code
const FREEFORM_MULTIVALUE_OVERRIDES: OverrideObject = {
  // Dev: Siren Denim 2
  5: { '216': 'favorite nba player' },
  // Production: Jockey
  1651: { '6731': 'pointsAwarded', '7409': 'availablePoints' },
  // Production: Knock! Knock!
  10507: { '506': 'zip' },
  // Prodution: Scotts
  10429: { '7399': 'Zip' },
  // Production: MiracleGro
  10438: { '4721': 'Zip Code' },
};

export function isCommaSeparatedField(companyInternalId: number | undefined, propertyId: string) {
  return Boolean(
    companyInternalId && FREEFORM_MULTIVALUE_OVERRIDES?.[companyInternalId]?.[propertyId]
  );
}
