import React, { useState } from 'react';

import { omit } from '@attentive/nodash';
import { IconButton, PicnicCss, Select, Text } from '@attentive/picnic';

import { IsVerbType, SegmentParameters, SelectableCampaignType } from '../../../../../../constants';
import { isKlaviyoEventType } from '../../../../../../utils/typeAssertions';
import { SegmentConditionContainer } from '../../../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../../../SegmentConditionItemContainer';
import { getCampaignPropertyName } from '../../utils';
import { JourneyCondition } from '../JourneyCondition';

import { CampaignCondition } from './CampaignCondition';

const MESSAGE_TYPE = {
  CAMPAIGN: 'CAMPAIGN',
  JOURNEY: 'JOURNEY',
} as const;

type MessageType = (typeof MESSAGE_TYPE)[keyof typeof MESSAGE_TYPE];

export const MESSAGE_FILTER_PARAMETERS: Array<keyof SegmentParameters> = [
  'journeyAttributeValues',
  'subscriberAttributeValueGroup',
  'customAttributeValueGroup',
];

interface OwnProps {
  parameters: SegmentParameters;
  setDialogIsOpen: (isOpen: boolean) => void;
  onChange: (parameters: SegmentParameters) => void;
  selectableCampaignType: SelectableCampaignType;
  css?: PicnicCss;
}

export const NewMessageSegmentCondition: React.FC<OwnProps> = ({ css, ...props }: OwnProps) => {
  const { parameters, onChange, selectableCampaignType } = props;
  const isKlaviyoEvent = isKlaviyoEventType(parameters);
  const [selectedType, setSelectedType] = useState<MessageType | undefined>(
    getDefaultSelectedType(parameters)
  );

  const onSelectedTypeChange = (newType: MessageType) => {
    const propertyName = getCampaignPropertyName(selectableCampaignType);
    const updatedParams = omit(parameters, MESSAGE_FILTER_PARAMETERS);

    if (newType === 'CAMPAIGN') {
      onChange({
        ...updatedParams,
        [propertyName]: {
          attribute: 'CAMPAIGN',
          values: [],
          isVerb: IsVerbType.IS,
        },
      });
    } else {
      onChange({
        ...updatedParams,
        journeyAttributeValues: [],
      });
    }

    setSelectedType(newType);
  };

  const onRemoveCondition = () => {
    const updatedParameters = omit(parameters, MESSAGE_FILTER_PARAMETERS);
    onChange(updatedParameters);
  };

  return (
    <SegmentConditionContainer css={{ ...css }}>
      <SegmentConditionItemContainer>
        <Text>where type is</Text>
      </SegmentConditionItemContainer>
      <SegmentConditionItemContainer>
        <Select
          placeholder="Select a message type"
          size="small"
          value={selectedType}
          onChange={(newType: string) => onSelectedTypeChange(newType as MessageType)}
        >
          {!isKlaviyoEvent && <Select.Item value={MESSAGE_TYPE.JOURNEY}>Journey</Select.Item>}
          <Select.Item value={MESSAGE_TYPE.CAMPAIGN}>Campaign</Select.Item>
        </Select>
      </SegmentConditionItemContainer>
      {selectedType === 'CAMPAIGN' && <CampaignCondition {...props} css={{ mr: '$space0' }} />}
      {selectedType === 'JOURNEY' && <JourneyCondition {...props} css={{ mr: '$space0' }} />}

      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description={`Remove ${selectedType?.toLocaleLowerCase() || ''} message condition`}
          onClick={onRemoveCondition}
        />
      </SegmentConditionItemContainer>
    </SegmentConditionContainer>
  );
};

function getDefaultSelectedType(parameters: SegmentParameters): MessageType {
  return parameters.journeyAttributeValues ? 'JOURNEY' : 'CAMPAIGN';
}
