import React from 'react';

import { FormField, Stack, TextInput } from '@attentive/picnic';

import { TimeComparatorType } from '../../../../../constants';
import SegmentConditionItemContainer from '../../../../SegmentConditionItemContainer';
import { useShowExpressionValidationAtomValue } from '../../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';
import { TimeValue } from '../constants';
import { getBetweenDaysErrorMessages } from '../utils';

const PU_NUMBER_INPUT_MAX_WIDTH = '100px';
const PU_NUMBER_INPUT_MIN_WIDTH = '60px';

interface TimeSelectorProps {
  maxDays: number;
  onChange: (value: TimeValue) => void;
  values: TimeValue;
}

export const RelativeDateRangeFields = ({ maxDays, onChange, values }: TimeSelectorProps) => {
  const betweenDaysErrorMessage = getBetweenDaysErrorMessages(values, maxDays);
  const showValidation = useShowExpressionValidationAtomValue();

  const firstValue =
    values.timeComparator === TimeComparatorType.BETWEEN_THE_LAST ? 'durationEnd' : 'durationStart';
  const secondValue =
    values.timeComparator === TimeComparatorType.BETWEEN_THE_LAST ? 'durationStart' : 'durationEnd';

  return (
    <Stack direction="horizontal">
      <FormField css={{ maxWidth: PU_NUMBER_INPUT_MAX_WIDTH }}>
        <TextInput
          type="number"
          placeholder="Number"
          value={values[firstValue] !== 0 ? values[firstValue] : ''}
          state={showValidation && !values[firstValue] ? 'error' : 'normal'}
          onChange={(event) => {
            const time = Number(event.target.value) ?? values[firstValue] ?? 0;
            onChange({ ...values, [firstValue]: Math.max(0, time) });
          }}
          size="small"
          css={{ minWidth: PU_NUMBER_INPUT_MIN_WIDTH }}
        />
        {showValidation && betweenDaysErrorMessage[firstValue] && (
          <FormField.ErrorText>{betweenDaysErrorMessage[firstValue]}</FormField.ErrorText>
        )}
      </FormField>
      <SegmentConditionItemContainer>and</SegmentConditionItemContainer>
      <FormField css={{ maxWidth: PU_NUMBER_INPUT_MAX_WIDTH }}>
        <TextInput
          type="number"
          placeholder="Number"
          value={values[secondValue] !== 0 ? values[secondValue] : ''}
          state={showValidation && !values[secondValue] ? 'error' : 'normal'}
          onChange={(event) => {
            const time = Number(event.target.value) ?? values[secondValue] ?? 0;
            onChange({ ...values, [secondValue]: Math.max(0, time) });
          }}
          size="small"
          css={{ minWidth: PU_NUMBER_INPUT_MIN_WIDTH }}
        />
        {showValidation && betweenDaysErrorMessage[secondValue] && (
          <FormField.ErrorText>{betweenDaysErrorMessage[secondValue]}</FormField.ErrorText>
        )}
      </FormField>
      <SegmentConditionItemContainer>days</SegmentConditionItemContainer>
    </Stack>
  );
};
