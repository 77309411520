import { useAtomValue } from 'jotai';

import { OperatingModes, operatingModeAtom } from '@attentive/acore-utils';

export const useCurrentOperatingMode = () => {
  const operatingMode = useAtomValue(operatingModeAtom);
  const isEmergencyMode = operatingMode === OperatingModes.Emergency;
  const isNominal = operatingMode === OperatingModes.Nominal;

  return {
    isEmergencyMode,
    isNominal,
  };
};
