import cloneDeep from 'lodash/cloneDeep';
import React, { FC } from 'react';

import { Box, Button, Icon } from '@attentive/picnic';

import {
  BlankSegment,
  Segment,
  SegmentOptions,
  SegmentParameters,
  SegmentComponentType,
  OperatorType,
  AvailableSegmentData,
  SegmentDuplicates,
  SegmentableAttributes,
  SegmentComponent,
} from '../../../../constants';
import { createSegmentComponentMetadata, getSegmentType } from '../../../../utils';
import { ConnectorPill } from '../../../ConnectorPill';
import { HighlightConditionConfigs } from '../../utils/highlightConditions';

import { SegmentExpression } from './components/SegmentExpression';

interface SegmentExpressionBuilderProps {
  segment: Segment | BlankSegment;
  updateCurrentSegment: (values: Partial<Segment>) => void;
  duplicates: SegmentDuplicates;
  validateDuplicates: boolean;
  segmentOptions: SegmentOptions;
  segmentableAttributesByVendor: SegmentableAttributes;
  availableSegmentData: AvailableSegmentData;
  highlightConditions?: HighlightConditionConfigs;
}

export const NewSegmentExpressionBuilder: FC<SegmentExpressionBuilderProps> = ({
  segment,
  updateCurrentSegment,
  ...props
}) => {
  const { expressions } = segment;

  const getBlankComponent = (): SegmentComponent => ({
    parameters: {},
    type: SegmentComponentType.CUSTOM,
    description: '',
    metadata: createSegmentComponentMetadata(),
  });

  const getBlankExpression = () => ({
    operator: segment.operator === OperatorType.OR ? OperatorType.AND : OperatorType.OR,
    segmentComponents: [getBlankComponent()],
  });

  const addExpression = () => {
    updateCurrentSegment({
      expressions: [...expressions, getBlankExpression()],
    });
  };

  const updateExpressionComparator = (expressionIdx: number, operator: OperatorType) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx] = { ...newSegment.expressions[expressionIdx], operator };
    updateCurrentSegment(newSegment);
  };

  const addComponent = (expressionIdx: number) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx].segmentComponents.push(getBlankComponent());
    updateCurrentSegment(newSegment);
  };

  const updateComponent = (
    expressionIdx: number,
    componentIdx: number,
    parameters: SegmentParameters
  ) => {
    const newSegment = cloneDeep(segment);
    const type = getSegmentType(parameters);
    const currentComponent = newSegment.expressions[expressionIdx].segmentComponents[componentIdx];
    newSegment.expressions[expressionIdx].segmentComponents.splice(componentIdx, 1, {
      parameters,
      type,
      metadata: createSegmentComponentMetadata(currentComponent),
    });
    updateCurrentSegment(newSegment);
  };

  const resetComponent = (expressionIdx: number, componentIdx: number) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx].segmentComponents.splice(
      componentIdx,
      1,
      getBlankComponent()
    );
    updateCurrentSegment(newSegment);
  };

  const deleteComponent = (expressionIdx: number, componentIdx: number) => {
    const newSegment = cloneDeep(segment);
    newSegment.expressions[expressionIdx].segmentComponents.splice(componentIdx, 1);

    if (newSegment.expressions[expressionIdx].segmentComponents.length === 0) {
      // If all the expression conditions were deleted, delete the empty expression
      newSegment.expressions.splice(expressionIdx, 1);
    }

    if (newSegment.expressions.length === 0) {
      // If all expressions have been deleted, add a blank one
      newSegment.expressions.push(getBlankExpression());
    }

    updateCurrentSegment(newSegment);
  };

  const enableAddButton = expressions
    .at(-1)
    ?.segmentComponents.every(({ parameters }) => Object.keys(parameters).length > 0);

  return (
    <Box>
      {expressions.map((expression, expressionIdx) => {
        const expressionKey = `exp-${expression.id}-${expressionIdx}`;

        return (
          <React.Fragment key={expressionKey}>
            {expressionIdx > 0 && <ConnectorPill operator={segment.operator} />}
            <SegmentExpression
              {...props}
              addComponent={addComponent}
              deleteComponent={deleteComponent}
              expression={expression}
              expressionIndex={expressionIdx}
              resetComponent={resetComponent}
              segmentId={segment.id}
              updateComponent={updateComponent}
              onExpressionOperatorChange={updateExpressionComparator}
            />
          </React.Fragment>
        );
      })}
      <Button
        onClick={() => addExpression()}
        size="small"
        css={{
          mt: '$space4',
          transition: 'all 0.2s ease-in-out',
        }}
        data-segmentation-id="segment-builder-add-expression"
        disabled={!enableAddButton}
      >
        <Icon name="PlusSign" size="extraSmall" css={{ mr: '$space2' }} /> Condition group
      </Button>
    </Box>
  );
};
