import { useQuery } from '@attentive/data/react-query';

import { Vendor } from '../../constants';

import { fetchVendorAttrValues } from './api';
import { AttrValueResponse } from './constants';

export const useVendorAttrValues = (vendor: Vendor, attr: string, queryOptions = {}) => {
  return useQuery<AttrValueResponse | null>(
    [`${vendor}Attr`, attr],
    () => {
      return fetchVendorAttrValues(vendor, attr).then((response) => {
        const { body, status } = response;
        if (!body) throw new Error('Unexpected empty response');
        if (status !== 200) {
          const errorMsg = body?.errors
            ? body.errors[0].message
            : 'Attribute options failed to load';

          // some of these attributes are 'restricted fields'. the endpoint responds 403, but the UI
          // should not treat this as an error, and should treat it like an attr with no options.
          if (status === 403 && errorMsg === `${attr} is a restricted field`) {
            return { keys: [] };
          }

          throw new Error(errorMsg);
        }

        if (!body.keys) {
          return body;
        }

        // Filter out null and falsy values for the UI
        return { keys: body.keys.filter((key) => Boolean(key)) };
      });
    },
    { ...queryOptions }
  );
};
