import React from 'react';

import { FormField, IconButton, MultiSelect, PicnicCss, Text } from '@attentive/picnic';

import {
  IsVerbType,
  NestedPropertyStringCondition,
  RechargeFilterAttrs,
  SegmentParameters,
  VendorAttribute,
  VendorAttributeDataType,
} from '../../../../constants';
import { SegmentConditionContainer } from '../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';
import VerbSelector, { VerbSelectorType } from '../VerbSelector';

import { getNestedFilter } from './utils';

interface OwnProps {
  css?: PicnicCss;
  rechargeAttributes: VendorAttribute[];
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
}

export const churnFilterParameter = {
  fieldName: RechargeFilterAttrs.CANCELLATION_REASON,
  fieldDataType: VendorAttributeDataType.TEXT,
  displayName: 'Churn reason',
  stringCondition: {
    verb: IsVerbType.IS,
    values: [],
  },
};

export function RechargeChurnReasonSelector({
  css,
  onChange,
  parameters,
  rechargeAttributes,
}: OwnProps) {
  if (!parameters.nestedPropertyFilters) {
    throw new Error('Nested properties are missing');
  }

  const churnReasonOptions = getChurnReasonOptions(rechargeAttributes);
  const [{ stringCondition }] = getNestedFilter<NestedPropertyStringCondition>(
    RechargeFilterAttrs.CANCELLATION_REASON,
    parameters
  );
  const showValidation = useShowExpressionValidationAtomValue();
  const showError = showValidation && !stringCondition.values.length;

  return (
    <SegmentConditionContainer css={css}>
      <SegmentConditionItemContainer>
        <Text>where churn reason</Text>
      </SegmentConditionItemContainer>
      <VerbSelector
        verbType={VerbSelectorType.IS}
        value={stringCondition.verb}
        onChange={(newVerb) => {
          const [filter, nestedPropertertyFilters] = getNestedFilter(
            RechargeFilterAttrs.CANCELLATION_REASON,
            parameters
          );

          const typedFilter = filter as NestedPropertyStringCondition;

          onChange({
            ...parameters,
            nestedPropertyFilters: [
              ...nestedPropertertyFilters,
              {
                ...typedFilter,
                stringCondition: {
                  ...typedFilter.stringCondition,
                  verb: newVerb as IsVerbType,
                },
              },
            ],
          });
        }}
      />
      <FormField>
        <MultiSelect
          value={stringCondition.values}
          onChange={(values: string[]) => {
            const [filter, nestedPropertertyFilters] = getNestedFilter(
              RechargeFilterAttrs.CANCELLATION_REASON,
              parameters
            );

            const typedFilter = filter as NestedPropertyStringCondition;

            onChange({
              ...parameters,
              nestedPropertyFilters: [
                ...nestedPropertertyFilters,
                {
                  ...typedFilter,
                  stringCondition: {
                    ...typedFilter.stringCondition,
                    values,
                  },
                },
              ],
            });
          }}
          placeholder="Choose values"
          state={showError ? 'error' : 'normal'}
          size="small"
        >
          {churnReasonOptions.map((key) => (
            <MultiSelect.Item key={key} value={key}>
              {key}
            </MultiSelect.Item>
          ))}
        </MultiSelect>
        {showError && <FormField.ErrorText>Please choose values.</FormField.ErrorText>}
      </FormField>
      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description="Remove Recharge churn reason condition"
          onClick={() => {
            const nestedPropertyFilters = parameters.nestedPropertyFilters?.filter(
              ({ fieldName }) => fieldName !== RechargeFilterAttrs.CANCELLATION_REASON
            );
            onChange({
              ...parameters,
              nestedPropertyFilters,
            });
          }}
        />
      </SegmentConditionItemContainer>
    </SegmentConditionContainer>
  );
}

function getChurnReasonOptions(rechargeAttributes: VendorAttribute[]) {
  const subscriptionAttribute = rechargeAttributes.find(
    ({ attributeName }) => attributeName === RechargeFilterAttrs.SUBSCRIPTIONS
  );

  if (!subscriptionAttribute || !subscriptionAttribute.nestedOptions) {
    throw new Error('Cannot find nested subscription attribute');
  }

  const churnReasonAttribute = subscriptionAttribute.nestedOptions.find(
    ({ attributeName }) => attributeName === RechargeFilterAttrs.CANCELLATION_REASON
  );

  if (!churnReasonAttribute || !churnReasonAttribute.values) {
    throw new Error('Cannot find churn reason attribute');
  }

  return churnReasonAttribute.values;
}
