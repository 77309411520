import { addWeeks, set as setDate } from 'date-fns';

import { DurationUnit, TimeComparatorType } from '../../../../../constants';
import {
  dateToSeconds,
  endOfDayInTimezone,
  secondsToDate,
  startOfDayInTimezone,
} from '../../../utils/dateTime';
import { PARTIAL_DATE_YEAR, TimeValue } from '../constants';

type getTimeComparatorParametersOptions = {
  useUtc?: boolean;
  timezone: string;
  values: TimeValue;
};

export const getTimeComparatorParameters = (
  newTimeComparator: string,
  { useUtc, timezone, values }: getTimeComparatorParametersOptions
) => {
  const isPartialDate = Boolean(values.ignoreYear);
  const now = isPartialDate ? setDate(new Date(), { year: PARTIAL_DATE_YEAR }) : new Date();
  const weekFromNow = addWeeks(now, 1);

  switch (newTimeComparator) {
    case TimeComparatorType.AFTER: {
      const { time, startTime } = values;
      const selectedSeconds = time || startTime || dateToSeconds(now);
      const selectedDate = secondsToDate(selectedSeconds);
      let adjustedDate;

      if (useUtc) {
        adjustedDate = new Date(selectedDate);
        adjustedDate.setUTCHours(0, 0, 0, 0);
      } else {
        adjustedDate = endOfDayInTimezone(selectedDate, timezone);
      }

      return {
        timeComparator: newTimeComparator,
        time: dateToSeconds(adjustedDate),
      };
    }
    case TimeComparatorType.BEFORE:
    case TimeComparatorType.ON: {
      const { time, startTime } = values;

      const selectedSeconds = time || startTime || dateToSeconds(now);
      const selectedDate = secondsToDate(selectedSeconds);
      let adjustedDate;

      if (useUtc) {
        adjustedDate = new Date(selectedDate);
        adjustedDate.setUTCHours(0, 0, 0, 0);
      } else {
        adjustedDate = startOfDayInTimezone(selectedDate, timezone);
      }

      return {
        timeComparator: newTimeComparator,
        time: dateToSeconds(adjustedDate),
      };
    }
    case TimeComparatorType.BETWEEN: {
      const { time, startTime, endTime } = values;

      const startSeconds = time || startTime || dateToSeconds(now);
      const startDate = secondsToDate(startSeconds);
      let adjustedStartDate;

      const endSeconds = endTime || dateToSeconds(weekFromNow);
      const endDate = secondsToDate(endSeconds);
      let adjustedEndDate;

      if (useUtc) {
        adjustedStartDate = new Date(startDate);
        adjustedStartDate.setUTCHours(0, 0, 0, 0);

        adjustedEndDate = new Date(endDate);
        adjustedEndDate.setUTCHours(0, 0, 0, 0);
      } else {
        adjustedStartDate = startOfDayInTimezone(startDate, timezone);
        adjustedEndDate = endOfDayInTimezone(endDate, timezone);
      }

      return {
        timeComparator: newTimeComparator,
        startTime: dateToSeconds(adjustedStartDate),
        endTime: dateToSeconds(adjustedEndDate),
      };
    }
    case TimeComparatorType.BETWEEN_THE_LAST:
    case TimeComparatorType.BETWEEN_THE_NEXT: {
      const { durationUnit } = values;

      return {
        timeComparator: newTimeComparator,
        durationUnit: durationUnit || DurationUnit.DAYS,
        // reset durationStart and durationEnd to avoid weirdness when changing between "between the last" to "between the next"
        durationStart: undefined,
        durationEnd: undefined,
      };
    }
    case TimeComparatorType.IN_THE_NEXT:
    case TimeComparatorType.IN_THE_LAST:
    case TimeComparatorType.DAYS_AGO:
    case TimeComparatorType.DAYS_UNTIL: {
      const { durationUnit, durationTime } = values;

      return {
        timeComparator: newTimeComparator,
        durationUnit: durationUnit || DurationUnit.DAYS,
        durationTime: durationTime || 0,
      };
    }
    case TimeComparatorType.IN_THE_MONTH: {
      const { months = [] } = values;

      return {
        timeComparator: newTimeComparator,
        months,
      };
    }
    case TimeComparatorType.IS_TODAY:
    case TimeComparatorType.OVER_ALL_TIME: {
      return {
        timeComparator: newTimeComparator,
      };
    }
    default:
      throw new Error('Unsupported time comparator');
  }
};
