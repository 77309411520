import { SegmentParameters } from '../constants';

export const getStateLabel = (country: string | null) => (country === 'CA' ? 'province' : 'state');
export const getPostalCodeLabel = (country: string | null) =>
  country === 'CA' ? 'postal code' : 'zip code';

export const getLocationValues = (params: SegmentParameters) => {
  if (params.locationValues) {
    return params.locationValues;
  }

  return params.locationValue ? [params.locationValue] : undefined;
};
