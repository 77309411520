import React, { FC } from 'react';

import { FormField, SearchableSelect } from '@attentive/picnic';

import { StringValueDisplayableOption } from '../../../constants';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface OwnProps {
  value: string;
  options: StringValueDisplayableOption[];
  conditionName: string;
  onChange: (value: string) => void;
}

const OptionSelector: FC<OwnProps> = ({ value, conditionName, options, onChange }) => {
  const showValidation = useShowExpressionValidationAtomValue();

  const error = showValidation && !value;

  return (
    <FormField>
      <SearchableSelect
        value={value}
        placeholder={`Choose ${conditionName}`}
        state={error ? 'error' : 'normal'}
        onChange={(newValue: string) => onChange(newValue)}
        size="small"
      >
        {options.map((option) => {
          const { optionValue, displayName } = option;
          return (
            <SearchableSelect.Item key={optionValue} value={optionValue}>
              {displayName}
            </SearchableSelect.Item>
          );
        })}
      </SearchableSelect>
      {error && <FormField.ErrorText>{`Please choose ${conditionName}.`}</FormField.ErrorText>}
    </FormField>
  );
};

export default OptionSelector;
