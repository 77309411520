/**
 * @generated SignedSource<<8e4ffb001d9d837031a9e0aefb026f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition$data = {
  readonly __typename: "CustomPropertyDefinition";
  readonly created: SerializedDateTime;
  readonly customPropertyId: {
    readonly id: string;
  };
  readonly id: string;
  readonly name: string;
  readonly propertyType: CustomPropertyType;
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeMenu_TargetingCommon_CustomPropertyDefinition">;
  readonly " $fragmentType": "useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition";
};
export type useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition$key = {
  readonly " $data"?: useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition"
};

(node as any).hash = "fabce25ec8194616dddb1db963593d91";

export default node;
