import { toDate } from 'date-fns-tz';

import { dateToSeconds } from '../components/SegmentCreateEditModal/utils/dateTime';
import {
  ActionFilter,
  AvailableDataType,
  HasVerbType,
  OperatorComparator,
  OperatorType,
  SegmentComponentType,
  SegmentTemplateId,
  TimeComparatorType,
} from '../constants';
import { getNewBlankSegment } from '../utils';

import { SegmentTemplateEntry } from './types';

// This segment is for targeting subscribers who have not purchased after 11/01/2022
export const holidayTargetSubscribers: SegmentTemplateEntry = {
  neededDataType: [AvailableDataType.PURCHASE],
  segment: ({ timezone }) => {
    // Midnight in provided timezone
    const date = toDate('2022-11-01T00:00:00', { timeZone: timezone });

    return {
      ...getNewBlankSegment(),
      templateId: SegmentTemplateId.HOLIDAY_TARGET_SUBSCRIBERS,
      expressions: [
        {
          operator: OperatorType.OR,
          segmentComponents: [
            {
              parameters: {
                subscriberAction: ActionFilter.PURCHASED,
                hasVerb: HasVerbType.HAS_NOT,
                frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
                timeComparator: TimeComparatorType.AFTER,
                time: dateToSeconds(date),
              },
              type: SegmentComponentType.CUSTOM,
            },
          ],
        },
      ],
    };
  },
};
