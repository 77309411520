/**
 * @generated SignedSource<<09b712c48e28f4119ca10f089b50e932>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneyRow_getJourneySecondaryText_TargetingCommon_Company$data = {
  readonly timezone: string;
  readonly " $fragmentType": "JourneyRow_getJourneySecondaryText_TargetingCommon_Company";
};
export type JourneyRow_getJourneySecondaryText_TargetingCommon_Company$key = {
  readonly " $data"?: JourneyRow_getJourneySecondaryText_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"JourneyRow_getJourneySecondaryText_TargetingCommon_Company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "JourneyRow_getJourneySecondaryText_TargetingCommon_Company"
};

(node as any).hash = "93f12f9750a6429e1a23bfc8e7e477e1";

export default node;
