import React from 'react';

import { Box, FormField, Icon, styled, Tooltip } from '@attentive/picnic';

export const StyledFormField = styled(FormField, { marginTop: '$space4' });
export const StyledLabel = styled(FormField.Label, { fontSize: '$fontSize2' });

export const StyledTooltip: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Box css={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="CircleQuestion" size="small" css={{ marginLeft: '$space1' }} />
        </Box>
      </Tooltip.Trigger>
      <Tooltip.Content>{children}</Tooltip.Content>
    </Tooltip>
  );
};
