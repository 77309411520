/**
 * @generated SignedSource<<9b5c3fd26e90eee482acbfd18dbcd0f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttributeDetails_formatDate_TargetingCommon_Company$data = {
  readonly timezone: string;
  readonly " $fragmentType": "AttributeDetails_formatDate_TargetingCommon_Company";
};
export type AttributeDetails_formatDate_TargetingCommon_Company$key = {
  readonly " $data"?: AttributeDetails_formatDate_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttributeDetails_formatDate_TargetingCommon_Company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AttributeDetails_formatDate_TargetingCommon_Company"
};

(node as any).hash = "d94858dcb6b1edb6c5747018d600b912";

export default node;
