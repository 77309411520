import React, { useState } from 'react';

import { useCompanyFeatureFlag, usePermission } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';
import { Box, FormField, IconButton, PicnicCss } from '@attentive/picnic';

import {
  ATTRIBUTE_INPUT_TYPES,
  enumInputTypes,
  SupportedPropertyTypeWithEmpty,
} from '../constants';
import { CustomAttributeFormErrors, CustomAttributeFormState } from '../form';

import { AttributeOptions } from './AttributeOptions';
import { DataTypeSelect } from './DataTypeSelect';
import { InputTypeSelect } from './InputTypeSelect';
import { StyledFormField, StyledLabel, StyledTooltip } from './styledElements';

type AttributeTypeFieldsProps = {
  css?: PicnicCss;
  errors: CustomAttributeFormErrors;
  isEditing: boolean;
  inputType: ATTRIBUTE_INPUT_TYPES;
  dataType: SupportedPropertyTypeWithEmpty;
  options: string[];
  onDataTypeChange: (value: SupportedPropertyTypeWithEmpty) => void;
  onOptionsChange: (value: string[]) => void;
  onInputTypeChange: (
    value: Pick<CustomAttributeFormState, 'inputType' | 'dataType' | 'options'>
  ) => void;
  displayMultiSelect?: boolean;
  displayCheckbox?: boolean;
  disableTypeChanges?: boolean;
  allowSoftDeleteOptions?: boolean;
  size?: 'small' | 'normal';
  // TODO: remove temporary prop once all attribute types are supported by journeys
  supportEnumAttributeOnly?: boolean;
};

export const AttributeTypeFields = ({
  css,
  errors,
  isEditing,
  inputType,
  dataType,
  options,
  onInputTypeChange,
  onDataTypeChange,
  onOptionsChange,
  displayMultiSelect,
  displayCheckbox,
  size = 'small',
  disableTypeChanges,
  allowSoftDeleteOptions,
  supportEnumAttributeOnly,
}: AttributeTypeFieldsProps) => {
  const [isDataTypeFieldsVisible, setIsDataTypeFieldsVisible] = useState(false);
  const isSuperUser = usePermission(Permission.SuperUserAccess);
  const enableNumber = useCompanyFeatureFlag(
    'ENABLE_CUSTOM_ATTRIBUTE_DATE_AND_NUMBER_TYPE_CREATION'
  );
  const isInputTypeDisabled = isEditing && (!isSuperUser || disableTypeChanges);

  const isDataTypeFieldDisabled = shouldDataTypeFieldBeDisabled({
    inputType,
    isEditing,
    enableNumber,
    disableTypeChanges: Boolean(disableTypeChanges),
  });
  const displayDataTypeFields = isDataTypeFieldsVisible && !isDataTypeFieldDisabled;

  return (
    <Box css={css}>
      {!supportEnumAttributeOnly && (
        <StyledFormField>
          <Box css={{ display: 'flex', marginBottom: '$space2' }}>
            <StyledLabel>Input type</StyledLabel>
            <StyledTooltip>Type of input field that will appear to your subscribers</StyledTooltip>
          </Box>
          <Box css={{ display: 'flex', alignItems: 'center' }}>
            <InputTypeSelect
              displayMultiSelect={displayMultiSelect}
              displayCheckbox={displayCheckbox}
              disabled={isInputTypeDisabled}
              isEditing={isEditing}
              value={inputType}
              onChange={(_inputType, _dataType, _options) => {
                onInputTypeChange({
                  inputType: _inputType,
                  dataType: _dataType,
                  options: _options || options,
                });
              }}
              size={size}
            />
            <IconButton
              iconName="Filter"
              size="small"
              description="Change attribute data type"
              variant="subdued"
              onClick={() => setIsDataTypeFieldsVisible(!isDataTypeFieldsVisible)}
              disabled={isDataTypeFieldDisabled}
              disabledVisually={isDataTypeFieldDisabled || isDataTypeFieldsVisible}
            />
          </Box>
          {errors.inputType && (
            <Box>
              <FormField.ErrorText>{errors.inputType}</FormField.ErrorText>
            </Box>
          )}
        </StyledFormField>
      )}
      {displayDataTypeFields && (
        <Box
          css={{
            backgroundColor: '$bgAccent',
            borderRadius: '$radius2',
            padding: '$space2 $space3',
            marginRight: '$space9',
            marginTop: '$space4',
          }}
        >
          <FormField>
            <StyledLabel css={{ marginBottom: '$space2' }}>Attribute data type</StyledLabel>
            <DataTypeSelect
              isEditing={isEditing}
              inputTypeValue={inputType}
              onChange={onDataTypeChange}
              dataTypeValue={dataType}
              size={size}
            />
            {errors.dataType && (
              <Box>
                <FormField.ErrorText>{errors.dataType}</FormField.ErrorText>
              </Box>
            )}
          </FormField>
        </Box>
      )}
      {enumInputTypes.includes(inputType) && (
        <StyledFormField>
          <Box css={{ display: 'flex' }}>
            <StyledLabel>Options</StyledLabel>
            <StyledTooltip>
              These are the options your subscribers will have to select from
            </StyledTooltip>
          </Box>
          <AttributeOptions
            isEditing={isEditing}
            options={options}
            onChange={onOptionsChange}
            errors={errors}
            size={size}
            allowSoftDeleteOptions={allowSoftDeleteOptions}
          />
        </StyledFormField>
      )}
    </Box>
  );
};

function shouldDataTypeFieldBeDisabled({
  inputType,
  isEditing,
  enableNumber,
  disableTypeChanges,
}: {
  inputType: ATTRIBUTE_INPUT_TYPES;
  isEditing: boolean;
  enableNumber: boolean;
  disableTypeChanges: boolean;
}) {
  // Only short answer suppoprts multiple data types (string and number)
  if (inputType !== ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER) {
    return true;
  }

  // If we disable type changes, always disable during editing
  if (isEditing && disableTypeChanges) {
    return true;
  }

  // Currently, we only allow users to create number attributes when editing or if the FF is turned on
  return !enableNumber && !isEditing;
}
