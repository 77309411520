import React, { FC } from 'react';

import { FormField, Select } from '@attentive/picnic';

import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface OwnProps {
  customEventKey?: string;
  options: string[];
  onChange: (newValue: string) => void;
}

const CustomEventKeySelector: FC<OwnProps> = ({ customEventKey, options, onChange }) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !customEventKey;

  return (
    <FormField>
      <Select
        value={customEventKey}
        placeholder="Choose a property"
        onChange={(newValue: string) => onChange(newValue)}
        state={error ? 'error' : 'normal'}
        size="small"
      >
        {options.map((option) => (
          <Select.Item value={option} key={option}>
            {option}
          </Select.Item>
        ))}
      </Select>
      {error && <FormField.ErrorText>Please select a property.</FormField.ErrorText>}
    </FormField>
  );
};

export default CustomEventKeySelector;
