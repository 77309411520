import React, { useState } from 'react';

import { Box, DatePicker, convertMomentToString, PicnicCss } from '@attentive/picnic';

import {
  MonthClickHandler,
  PARTIAL_DATE_ARIA_LABEL_FORMAT,
  PARTIAL_DATE_MONTH_FORMAT,
} from './constants';
import { getPartialDate, formatDateString, isStartOfYear, isEndOfYear } from './utils';

type PartialDatePickerProps = {
  css?: PicnicCss;
  onChange: (newDate: string) => void;
  value: string | null;
};

export const PartialDatePicker = ({ css, onChange, ...props }: PartialDatePickerProps) => {
  const value = props.value || getPartialDate();
  const [isOpen, setIsOpen] = useState(false);
  // date is the selected date. A user can navigated between months without changing this value.
  const [date, setDate] = useState(value);
  // displayedMonth updates as a user navigates between months. It represents the currently visible month
  const [displayedMonth, setDisplayedMonth] = useState<string | null>(value);

  const handleMonthClick: MonthClickHandler = (newCurrentMonth) => {
    const newMonth = convertMomentToString(newCurrentMonth);
    setDisplayedMonth(newMonth);
  };

  return (
    <Box>
      <DatePicker.Root open={isOpen} onOpenChange={setIsOpen}>
        <DatePicker.Trigger>
          <DatePicker.Input value={formatDateString(date)} size="small" state="normal" css={css} />
        </DatePicker.Trigger>
        <DatePicker.Popover>
          <DatePicker.Control
            value={date}
            onChange={(newDate) => {
              setIsOpen(false);
              setDate(newDate);
              onChange(newDate);
            }}
            noNavNextButton={isEndOfYear(displayedMonth)}
            noNavPrevButton={isStartOfYear(displayedMonth)}
            onPrevMonthClick={handleMonthClick}
            onNextMonthClick={handleMonthClick}
            monthFormat={PARTIAL_DATE_MONTH_FORMAT}
            dayAriaLabelFormat={PARTIAL_DATE_ARIA_LABEL_FORMAT}
          />
        </DatePicker.Popover>
      </DatePicker.Root>
    </Box>
  );
};
