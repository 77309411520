import { CountryCode } from '@attentive/locale-utils';

import { TagObject } from './components/TagSelector';

import { ZipcodeTargetingCommonQuery$data as QueryData } from './__generated__/ZipcodeTargetingCommonQuery.graphql';

const addCommaRegex = /([^,\s])\s+/gm;

// This list is not comprehensive; this is the countries where we know that spaces are not expected
const countriesWithoutSpaces = [CountryCode.US, CountryCode.CA, CountryCode.AU];

export function parseTagInput(input: string, existingValues: string[], countryCode: string) {
  const duplicateValues: string[] = [];

  // If there is a space not preceded by a comma or space, add a comma if the country does not require spaces
  const formattedValue = countriesWithoutSpaces.includes(countryCode as CountryCode)
    ? input.replace(addCommaRegex, '$1, ')
    : input;

  const values = formattedValue
    .replace(/ /g, '')
    .toUpperCase()
    .split(',')
    .filter((val) => {
      if (val === '') return false;
      const exisitingIndex = existingValues.indexOf(val);

      if (exisitingIndex >= 0) {
        duplicateValues.push(val);
      }

      return exisitingIndex < 0;
    });

  return { values, duplicateValues };
}

export function isValidTag(input: string, country: string) {
  const tagLength = getTagLength(country);
  return input.length >= tagLength || input[input.length - 1] === ',';
}

function getTagLength(country: string) {
  switch (country) {
    case CountryCode.CA:
      return 3;
    case CountryCode.US:
      return 5;
    case CountryCode.GB:
      return 8;
    case CountryCode.AU:
      return 4;
    default:
      return 15;
  }
}

export function convertToTagObject(values: string[], state: 'VALID' | 'INVALID' | 'INDETERMINATE') {
  return values.map((value) => ({ value, state })) as TagObject[];
}

export function buildValidationMap(validationResult: QueryData['validationResult']) {
  let validationStatus: Record<string, 'VALID' | 'INVALID'> = {};

  if (!validationResult) return validationStatus;

  if (validationResult.valid) {
    validationStatus = validationResult.valid.reduce(
      (acc, item) => ({
        ...acc,
        [item.postalCode]: 'VALID',
      }),
      validationStatus
    );
  }

  if (validationResult.invalid) {
    validationStatus = validationResult.invalid.reduce(
      (acc, item) => ({
        ...acc,
        [item.postalCode]: 'INVALID',
      }),
      validationStatus
    );
  }

  return validationStatus;
}
