import _usePaginatedCampaigns_TargetingCommon_Query from "./__generated__/usePaginatedCampaigns_TargetingCommon_Query.graphql";
import { useEffect } from 'react';
import { usePaginationFragment } from 'react-relay';
import { graphql } from '@attentive/data';
export const CAMPAIGNS_PER_PAGE = 20;
import { getComposeCampaginsFilter } from '../../SegmentCreateEditModal/components/MessageEngagement/components/CampaignMessageSelector/MessageDialogButton';
import Fragment from './__generated__/usePaginatedCampaigns_TargetingCommon_Query.graphql';
/**
 *
 * This hook is used in conjunction with useFilteredCampaignMessageGroups hook.
 * It allows fetching X campaigns per request. It will continue to fetch until hasNext is false
 *
 * @param queryRef
 * @returns
 */

export function usePaginatedCampaigns(queryRef, searchString, selectableCampaignType) {
  var _data$composeCampaign;

  const {
    data,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment(Fragment, queryRef);
  useEffect(() => {
    if (hasNext && !isLoadingNext) {
      loadNext(CAMPAIGNS_PER_PAGE);
    }
  }, [hasNext, loadNext, isLoadingNext]);
  useEffect(() => {
    const defaultFilter = getComposeCampaginsFilter(selectableCampaignType);

    if (queryRef) {
      refetch({
        filter: { ...defaultFilter,
          name: searchString
        }
      });
    }
  }, [queryRef, searchString, refetch, selectableCampaignType]);
  return (_data$composeCampaign = data.composeCampaigns) === null || _data$composeCampaign === void 0 ? void 0 : _data$composeCampaign.edges;
}
_usePaginatedCampaigns_TargetingCommon_Query;