import _useCompanyApps_TargetingCommon_Company from "./__generated__/useCompanyApps_TargetingCommon_Company.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import CompanyFragment from './__generated__/useCompanyApps_TargetingCommon_Company.graphql';
_useCompanyApps_TargetingCommon_Company;
export const useCompanyApps = companyRef => {
  var _company$builtApplica, _company$installedApp;

  const company = useFragment(CompanyFragment, companyRef);
  const builtApps = ((_company$builtApplica = company.builtApplications) === null || _company$builtApplica === void 0 ? void 0 : _company$builtApplica.edges.map(_ref => {
    let {
      node
    } = _ref;
    return node;
  })) || [];
  const installedApps = ((_company$installedApp = company.installedApplications) === null || _company$installedApp === void 0 ? void 0 : _company$installedApp.edges.map(_ref2 => {
    let {
      node
    } = _ref2;
    return node.application;
  })) || [];
  return [...builtApps, ...installedApps];
};