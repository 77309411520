import React from 'react';

import { Banner, Box, Icon, Link, List, Stack, Text } from '@attentive/picnic';

type WarningDialogListItemsProps = {
  type: string;
  items: Array<{ href: string; name: string }>;
};

// TODO: SC - this is duplicated from messaging-core because we can't import it due to circular dependencies.
// We should look to find a better pattern for sharing code like this, probably a separate library just for UI patterns
export const WarningDialogListItems: React.FC<
  React.PropsWithChildren<WarningDialogListItemsProps>
> = ({ type, items, children }) => {
  const plural = items.length > 1 && 's';

  return (
    <Stack>
      <Box>{children}</Box>
      <Banner css={{ width: '100%' }}>
        <Banner.Heading>
          Please review the following {type}
          {plural}:
        </Banner.Heading>
        <List variant="unstyled">
          {items.map(({ href, name }) => (
            <List.Item key={name}>
              <Link
                onClick={() => window.open(href, '_blank', 'noopener,noreferrer')}
                role="link"
                css={{ '&:hover': { cursor: 'pointer' }, '&:active': { cursor: 'pointer' } }}
              >
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  <Text>{name}</Text>
                  <Icon name="NewWindow" size="small" />
                </Stack>
              </Link>
            </List.Item>
          ))}
        </List>
      </Banner>
    </Stack>
  );
};
