import React, { useState } from 'react';

import { useCompanyFeatureFlag, useRoles } from '@attentive/acore-utils';
import { Role } from '@attentive/data';
import { Box, Button, Icon } from '@attentive/picnic';

import { SegmentComponent } from '../../../../../constants';

interface SegmentConditionDebugProps {
  component: SegmentComponent;
}

export const SegmentConditionDebug = ({ component }: SegmentConditionDebugProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const enableDebug = useCompanyFeatureFlag('ENABLE_SEGMENT_BUILDER_DEBUG');
  const userRoles = useRoles();

  // Only TAM and ENG users can access debug mode
  const hasRequiredRole = userRoles.has(Role.RoleEngineering) || userRoles.has(Role.RoleCsTam);

  if (!hasRequiredRole || !enableDebug) return null;

  return (
    <Box>
      <Button variant="subdued" size="small" onClick={() => setIsOpen(!isOpen)}>
        <Icon name="ShieldPerson" size="extraSmall" css={{ ml: '$space2' }} /> Show segment
        component
      </Button>
      {isOpen && (
        <Box>
          <Box
            css={{
              whiteSpace: 'pre-wrap',
              mb: '$space4',
              fontFamily: 'monospace',
              border: '1px solid $borderDefault',
              padding: '$space4',
              pr: '$space6',
              width: 'auto',
              backgroundColor: '$bgAccentSubtle',
              display: 'inline-block',
            }}
          >
            {JSON.stringify(component, null, 2)}
          </Box>
        </Box>
      )}
    </Box>
  );
};
