import React, { FC } from 'react';

import { FormField } from '@attentive/picnic';

import { StringValueDisplayableOption } from '../../../../constants';
import ProductDataDialogButton from '../../components/ProductData/ProductDataDialogButton';
import { DialogAttributesMap } from '../../constants';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface OwnProps {
  attribute: string;
  name: string | undefined;
  values: StringValueDisplayableOption[];
  onChange: (values: StringValueDisplayableOption[]) => void;
  setDialogIsOpen: (isOpen: boolean) => void;
}

const ProductDataValueSelector: FC<OwnProps> = ({
  attribute,
  name,
  values,
  onChange,
  setDialogIsOpen,
}) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && values.length < 1;
  return (
    <FormField>
      <ProductDataDialogButton
        attribute={DialogAttributesMap[attribute]}
        attributeName={name}
        selectedOptions={values}
        setDialogIsOpen={setDialogIsOpen}
        onSubmit={onChange}
      />
      {error && <FormField.ErrorText>Please choose values.</FormField.ErrorText>}
    </FormField>
  );
};

export default ProductDataValueSelector;
