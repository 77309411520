import React, { useState } from 'react';

import { SegmentParameters, JourneyAttributeValue } from '../../../../../../../constants';
import { ItemSelectDialog } from '../../../../../../ItemSelectDialog';
import { JourneyConditionPreloadedQuery, ComposeMessageChannel } from '../JourneyCondition';

import { JourneyMessageDialog } from './JourneyMessageDialog';
import { SelectedMessages } from './JourneyMessageDialog/types';

interface JourneyMessageDialogButtonProps {
  setDialogIsOpen: (isOpen: boolean) => void;
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
  channel: ComposeMessageChannel;
  queryRef: JourneyConditionPreloadedQuery;
}

export const JourneyMessageDialogButton = ({
  onChange,
  parameters,
  channel,
  setDialogIsOpen,
  queryRef,
}: JourneyMessageDialogButtonProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const { journeyAttributeValues = [] } = parameters;
  const selectedJourneys = journeyAttributeValues.map(({ journeyId }) => `${journeyId}`);

  const selectedMessages: Record<string, string[]> = journeyAttributeValues.reduce(
    (acc, journey) => ({
      ...acc,
      [journey.journeyId]: journey.messages.map(({ name }) => name),
    }),
    {}
  );

  const onCloseHandler = () => {
    setShowDialog(false);
    setDialogIsOpen(false);
  };

  const onOpenHandler = () => {
    setShowDialog(true);
    setDialogIsOpen(true);
  };

  const onSubmitHandler = (newSelectedMessages: SelectedMessages) => {
    const newParams = {
      ...parameters,
      journeyAttributeValues: Object.entries(newSelectedMessages).reduce(
        (acc, [journeyIdString, messages]) => {
          const journeyId = Number(journeyIdString);
          return [...acc, { journeyId, messages: messages.map((name) => ({ name })) }];
        },
        [] as JourneyAttributeValue[]
      ),
    };

    onChange(newParams);
    onCloseHandler();
  };

  return (
    <>
      {showDialog && (
        <JourneyMessageDialog
          channel={channel}
          onClose={onCloseHandler}
          onSubmit={onSubmitHandler}
          selectedJourneys={selectedJourneys}
          selectedMessages={selectedMessages}
          queryRef={queryRef}
        />
      )}
      <ItemSelectDialog.Button
        content={getButtonText(journeyAttributeValues)}
        onClick={onOpenHandler}
        disabled={!selectedJourneys.length}
      />
    </>
  );
};

function getButtonText(journeyAttributeValues: JourneyAttributeValue[]) {
  const totalMessages = journeyAttributeValues.reduce(
    (total, { messages }) => total + messages.length,
    0
  );

  // If no messages are selected, the user is implicitly selecting any message from the selected journeys
  if (totalMessages === 0) {
    return 'Any message';
  }

  const totalJourneysWithoutMessages = journeyAttributeValues.filter(
    ({ messages }) => !messages.length
  ).length;

  // if there is a mixture of journeys with messages selected and journeys without messages selected, each journey without a message selected counts as one message
  const totalItems = totalMessages + totalJourneysWithoutMessages;

  // This case only be true if the user has selected one journey and one message from that journey
  if (totalItems === 1) {
    const journeyWithMessage = journeyAttributeValues.find(({ messages }) => messages.length);
    return journeyWithMessage ? journeyWithMessage.messages[0].name : '1 message';
  }

  return `${totalItems} messages`;
}
