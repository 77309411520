import { Box, styled } from '@attentive/picnic';

const SegmentConditionItemContainer = styled(Box, {
  minHeight: '$size9',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
});

export default SegmentConditionItemContainer;
