import React from 'react';

import { Box, RadioGroup, Text } from '@attentive/picnic';

import { SELECTION_OPTIONS, SelectionMode } from '../types';

type SelectionToggleProps = {
  onChange: (newMode: SelectionMode) => void;
  selectionMode: SelectionMode;
};

export const SelectionToggle = ({ onChange, selectionMode }: SelectionToggleProps) => {
  return (
    <Box css={{ mb: '$space4' }}>
      <RadioGroup
        value={selectionMode}
        layout="vertical"
        onChange={(newVal) => onChange(newVal as SelectionMode)}
      >
        <RadioGroup.Radio value={SELECTION_OPTIONS.ANY}>
          Include any message in the selected journeys
        </RadioGroup.Radio>
        <RadioGroup.Radio value={SELECTION_OPTIONS.SPECIFIC}>
          Select specific messages from journeys
        </RadioGroup.Radio>
      </RadioGroup>
      <Text css={{ mt: '$space6' }}>
        Messages added to a journey in the future will be included when Any message is selected.
        {selectionMode === 'SPECIFIC'
          ? ' Journeys without a selected message will include any messages available for that journey.'
          : ''}
      </Text>
    </Box>
  );
};
