import { useEffect, useRef } from 'react';

// this is how we can reference previous state, from the react docs:
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
