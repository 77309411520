import { isBefore, set as setDate } from 'date-fns';
import { toDate } from 'date-fns-tz';

import { convertDateToString, convertStringToDate } from '@attentive/picnic';

import { SegmentParameters } from '../../../../../constants';
import { isCustomAttributeType, isWellKnownProperty } from '../../../../../utils/typeAssertions';
import { dateToSeconds, secondsToDate } from '../../../utils/dateTime';
import { PARTIAL_DATE_YEAR, TimeValue } from '../constants';

export function usesUtcDate(parameters: SegmentParameters) {
  return isCustomAttributeType(parameters) || isWellKnownProperty(parameters);
}

type SecondsToDateOptions = {
  timezone: string;
  useUtc?: boolean;
};

export const convertSecondsToDateString = (
  seconds: number,
  { timezone, useUtc }: SecondsToDateOptions
) => {
  const date = secondsToDate(seconds);
  if (date === null) throw new Error('Tried to convert invalid date to seconds');
  return convertDateToString(date, useUtc ? 'UTC' : timezone);
};

export const getIsDayBlockedFn = (startDate?: Date) => {
  return (day: string) => {
    if (startDate) return isBefore(new Date(day), startDate);
    return false;
  };
};

export function updateTimeValueTimestamps(values: TimeValue, isPartialDate: boolean) {
  if (isPartialDate) {
    // Convert timestamps to same month and day with year set to PARTIAL_DATE_YEAR
    return {
      time: convertDateToPartialDate(values.time),
      startTime: convertDateToPartialDate(values.startTime),
      endTime: convertDateToPartialDate(values.endTime),
    };
  }

  // Convert timestamps to the same month and day with year set to the current year
  return {
    time: convertPartialDateToCurrentDate(values.time),
    startTime: convertPartialDateToCurrentDate(values.startTime),
    endTime: convertPartialDateToCurrentDate(values.endTime),
  };
}

function convertDateToPartialDate(
  date: number | undefined,
  partialDateYear: number = PARTIAL_DATE_YEAR
) {
  if (!date) return undefined;

  const partialDate = setDate(new Date(date * 1000), { year: partialDateYear });
  return dateToSeconds(partialDate);
}

function convertPartialDateToCurrentDate(date: number | undefined) {
  if (!date) return undefined;

  const currentYear = new Date().getFullYear();
  const currentDate = setDate(new Date(date * 1000), { year: currentYear });
  return dateToSeconds(currentDate);
}

export type DateStringToSecondsConfig = {
  isEndOfDay?: boolean;
  timeZone: string;
  useUtc?: boolean;
};

export const convertSelectedDateStringToSeconds = (
  dateString: string | null,
  { isEndOfDay, timeZone, useUtc }: DateStringToSecondsConfig
) => {
  const date = convertStringToDate(dateString);
  if (date === null) throw new Error('Received invalid date string from event');
  let adjustedDate;

  if (useUtc) {
    adjustedDate = new Date(date);
    adjustedDate.setUTCHours(0, 0, 0, 0);
  } else {
    const dateTimeString = `${dateString} ${isEndOfDay ? '23:59:59' : '00:00:00'}`;
    adjustedDate = toDate(dateTimeString, { timeZone });
  }

  return dateToSeconds(adjustedDate);
};
