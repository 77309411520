import React, { Suspense, useState } from 'react';
import { useFragment, usePreloadedQuery } from 'react-relay';

import { SegmentParameters } from '../../../../../../../constants';
import { ItemSelectDialog } from '../../../../../../ItemSelectDialog';
import {
  JourneyConditionPreloadedQuery,
  ComposeMessageChannel,
  JourneyConditionQueryType,
  JourneyConditionQuery,
} from '../JourneyCondition';

import {
  JourneyDialog,
  JourneyDialogCompanyFrag,
  JourneyDialogCompanyFragType,
} from './JourneyDialog';
import { useFilteredJourneys } from './JourneyDialog/useJourneys';

type DialogButtonProps = {
  channel: ComposeMessageChannel;
  onClick: () => void;
  selectedJourneys: string[];
  queryRef: JourneyConditionPreloadedQuery;
};

const DialogButton = ({ channel, onClick, selectedJourneys, queryRef }: DialogButtonProps) => {
  const queryData = usePreloadedQuery<JourneyConditionQueryType>(JourneyConditionQuery, queryRef);
  const companyRef = useFragment<JourneyDialogCompanyFragType>(
    JourneyDialogCompanyFrag,
    queryData.node
  );
  const { data: journeys } = useFilteredJourneys(companyRef, channel);

  let journeyName = '';
  if (selectedJourneys.length === 1) {
    const journey = journeys.find(({ node }) => node.internalId === selectedJourneys[0]);
    if (journey) {
      journeyName = journey.node.name;
    }
  }

  return (
    <ItemSelectDialog.Button
      onClick={onClick}
      content={getButtonText(selectedJourneys, journeyName)}
    />
  );
};

interface JourneyDialogButtonProps {
  parameters: SegmentParameters;
  setDialogIsOpen: (isOpen: boolean) => void;
  onChange: (parameters: SegmentParameters) => void;
  channel: ComposeMessageChannel;
  queryRef: JourneyConditionPreloadedQuery;
}

export const JourneyDialogButton = ({
  parameters,
  channel,
  setDialogIsOpen,
  onChange,
  queryRef,
}: JourneyDialogButtonProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const { journeyAttributeValues = [] } = parameters;
  const selectedJourneys = journeyAttributeValues.map(({ journeyId }) => `${journeyId}`);

  const onCloseHandler = () => {
    setShowDialog(false);
    setDialogIsOpen(false);
  };

  const onOpenHandler = () => {
    setShowDialog(true);
    setDialogIsOpen(true);
  };

  const onSubmitHandler = (newValues: string[]) => {
    const newJourneyAttributeValues = newValues.map((optionValue) => {
      // GQL returns internalId as a string but the targeting BE expects journeyAttributeValues[].journeyId as a number
      const matchingItem = journeyAttributeValues.find(
        ({ journeyId }) => `${journeyId}` === optionValue
      );

      if (matchingItem) {
        return matchingItem;
      }

      return { journeyId: Number(optionValue), messages: [] };
    });

    onChange({
      ...parameters,
      journeyAttributeValues: newJourneyAttributeValues,
    });
    onCloseHandler();
  };

  return (
    <>
      {showDialog && (
        <JourneyDialog
          channel={channel}
          onClose={onCloseHandler}
          onSubmit={onSubmitHandler}
          selectedOptions={selectedJourneys}
          queryRef={queryRef}
        />
      )}
      <Suspense
        fallback={
          <ItemSelectDialog.Button
            content={getButtonText(selectedJourneys)}
            onClick={onOpenHandler}
          />
        }
      >
        <DialogButton
          channel={channel}
          onClick={onOpenHandler}
          selectedJourneys={selectedJourneys}
          queryRef={queryRef}
        />
      </Suspense>
    </>
  );
};

function getButtonText(selectedJourneys: string[], singleItemLabel?: string) {
  if (selectedJourneys.length === 0) {
    return 'Choose journey(s)';
  }

  if (selectedJourneys.length > 1) {
    return `${selectedJourneys.length} journeys`;
  }

  return singleItemLabel || `1 journey`;
}
