import React, { useEffect } from 'react';

import { Box, Select } from '@attentive/picnic';

import {
  SegmentParameters,
  TimeComparatorType,
  timeComparatorLabels,
} from '../../../../../constants';
import { useCompany } from '../../../../../hooks';
import { TimeValue } from '../constants';
import { useTimeComparators } from '../hooks';
import { getTimeComparatorParameters, usesUtcDate } from '../utils';

interface TimeComparatorSelectProps {
  parameters: SegmentParameters;
  onChange: (value: TimeValue) => void;
  values: TimeValue;
}

export const TimeComparatorSelect = ({
  parameters,
  onChange,
  values,
}: TimeComparatorSelectProps) => {
  const { timeComparator, ignoreYear } = values;
  const isPartialDate = Boolean(ignoreYear);

  const { timezone } = useCompany();
  const options = useTimeComparators(parameters, isPartialDate);
  const useUtc = usesUtcDate(parameters);

  function updateTimeComparator(newComparator: string) {
    const newParameters = getTimeComparatorParameters(newComparator, {
      useUtc,
      timezone,
      values,
    });
    onChange({ ...newParameters, ignoreYear: values.ignoreYear });
  }

  useEffect(() => {
    // Check to make sure that the currently selected comparator is supported when ignoreYear changes
    if (!options.includes(timeComparator)) {
      updateTimeComparator(TimeComparatorType.ON);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPartialDate]);

  return (
    <Box>
      <Select value={timeComparator} onChange={updateTimeComparator} size="small">
        {options.map((optionValue) => (
          <Select.Item key={optionValue} value={optionValue}>
            {timeComparatorLabels[optionValue]}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
};
