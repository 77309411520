import { DurationUnit, TimeComparatorType } from '../../../../../constants';
import { formatNumberWithCommas } from '../../../../../utils';
import { TimeValue } from '../constants';

export const getErrorMessage = (values: TimeValue, daysMax?: number) => {
  const { durationTime, durationUnit } = values;
  if (!durationTime) {
    return 'Please enter a number';
  } else if (daysMax && durationUnit === DurationUnit.DAYS && durationTime > daysMax) {
    return `The max number of days is ${formatNumberWithCommas(daysMax)}`;
  } else if (daysMax && durationUnit === DurationUnit.HOURS && durationTime > daysMax * 24) {
    return `The max number of hours is ${formatNumberWithCommas(daysMax * 24)}`;
  }
};

export const getBetweenDaysErrorMessages = (values: TimeValue, daysMax?: number) => {
  const emptyFirstNumberErrorMsg = 'Please enter a first number';
  const emptySecondNumberErrorMsg = 'Please enter a second number';
  const numberTooSmallErrorMsg = 'The second number must be greater than the first number';

  const { durationEnd, durationStart, timeComparator } = values;

  const errors: { durationEnd: string | null; durationStart: string | null } = {
    durationEnd: null,
    durationStart: null,
  };

  const isReversed = timeComparator === TimeComparatorType.BETWEEN_THE_LAST;

  if (isReversed) {
    // Note: durationEnd is the smaller number in the range, e.g. between the last 1-10 days
    // is a range from durationStart 10 days ago to durationEnd 1 day ago
    if (!durationEnd) {
      errors.durationEnd = emptyFirstNumberErrorMsg;
      return errors;
    }

    if (!durationStart) {
      errors.durationStart = emptySecondNumberErrorMsg;
      return errors;
    }

    if (Number(durationStart) <= Number(durationEnd)) {
      errors.durationEnd = numberTooSmallErrorMsg;
      return errors;
    }
  } else {
    if (!durationStart) {
      errors.durationStart = emptyFirstNumberErrorMsg;
      return errors;
    }

    if (!durationEnd) {
      errors.durationEnd = emptySecondNumberErrorMsg;
      return errors;
    }

    if (Number(durationEnd) <= Number(durationStart)) {
      errors.durationStart = numberTooSmallErrorMsg;
      return errors;
    }
  }

  if (daysMax && Number(durationStart) > daysMax) {
    errors.durationStart = `The max number of days is ${formatNumberWithCommas(daysMax)}`;
    return errors;
  }

  if (daysMax && Number(durationEnd) > daysMax) {
    errors.durationEnd = `The max number of days is ${formatNumberWithCommas(daysMax)}`;
    return errors;
  }

  return errors;
};
