/**
 * @generated SignedSource<<8d9c9c5f076145a86c2a671ecc3dd53e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterByActivitySelector_TargetingCommon_company$data = {
  readonly companyVendorIntegrations: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"integrationUtils_getIntegrationId_TargetingCommon_CompanyVendorIntegration" | "vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration">;
  }> | null;
  readonly internalId: number;
  readonly " $fragmentSpreads": FragmentRefs<"useCompanyApps_TargetingCommon_Company">;
  readonly " $fragmentType": "FilterByActivitySelector_TargetingCommon_company";
};
export type FilterByActivitySelector_TargetingCommon_company$key = {
  readonly " $data"?: FilterByActivitySelector_TargetingCommon_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"FilterByActivitySelector_TargetingCommon_company">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilterByActivitySelector_TargetingCommon_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "status",
          "value": "ENABLED"
        }
      ],
      "concreteType": "CompanyVendorIntegration",
      "kind": "LinkedField",
      "name": "companyVendorIntegrations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "integrationUtils_getIntegrationId_TargetingCommon_CompanyVendorIntegration",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ExternalVendorApi",
              "kind": "LinkedField",
              "name": "externalVendorApi",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "vendor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExternalVendorFeature",
                  "kind": "LinkedField",
                  "name": "externalVendorFeatures",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ExternalVendorApi",
              "kind": "LinkedField",
              "name": "externalVendorApi",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": "companyVendorIntegrations(status:\"ENABLED\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCompanyApps_TargetingCommon_Company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "3173ddf0656553a302e988ee466cc4b8";

export default node;
