import { useCurrentUser } from '@attentive/acore-utils';

export const useCompany = () => {
  const { company } = useCurrentUser();
  const { country, id, internalId, timezone } = company;

  return {
    country,
    timezone,
    internalId,
    id,
  };
};
