import {
  ActionFilter,
  DurationUnit,
  HasVerbType,
  IsVerbType,
  OperatorComparator,
  OperatorType,
  PropertiesFilter,
  SegmentComponent,
  SegmentComponentType,
  SegmentSubscriberCount,
  Segment,
  Source,
  SyncStatus,
  TimeComparatorType,
  SegmentSubscriberCountV2,
} from '../constants';

export const demoSegments: Segment[] = [
  {
    id: 9,
    syncStatus: SyncStatus.SYNCED,
    name: 'Mobile Subscribers',
    description: 'Subscribers using mobile devices',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 10,
    syncStatus: SyncStatus.SYNCED,
    name: 'iOS Subscribers',
    description: 'Subscribers on iOS phones.',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 1,
    syncStatus: SyncStatus.SYNCED,
    name: 'New Subscribers',
    description: 'Subscribers who have signed up in the past 7 days.',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 2,
    syncStatus: SyncStatus.SYNCED,
    name: 'Active Subscribers',
    description: 'Subscribers who have clicked on at least one of your last 5 messages.',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 3,
    syncStatus: SyncStatus.SYNCED,
    name: 'Inactive Subscribers',
    description: "Subscribers who haven't clicked on any of your last 5 messages.",
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 4,
    syncStatus: SyncStatus.SYNCED,
    name: 'Potential Customers',
    description: "Subscribers who haven't made a purchase from your site.",
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 5,
    syncStatus: SyncStatus.SYNCED,
    name: 'Recent Customers',
    description: 'Customers who have purchased in the past 30 days.',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 6,
    syncStatus: SyncStatus.SYNCED,
    name: 'Top Customers',
    description: 'Customers who have placed at least 4 orders from your site.',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 7,
    syncStatus: SyncStatus.SYNCED,
    name: 'Repeat Customers',
    description: 'Customers who have placed at least 2 orders from your site.',
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  {
    id: 8,
    syncStatus: SyncStatus.SYNCED,
    name: 'Lapsed Customers',
    description: "Customers who haven't purchased in the past 8 months.",
    operator: OperatorType.AND,
    expressions: [],
    readOnly: true,
    source: Source.DYNAMIC,
    created: 1605207323,
    updated: 1605207323,
  },
  /*
   * UNCOMMENT BELOW WHEN IT'S DECIDED TO DEMO THIRD PARTY AND UPLOAD SEGMENTS
   */
  // {
  //   id: 11,
  //   syncStatus: SyncStatus.SYNCED,
  //   name: 'Giveaway Contest Winners',
  //   description: 'Customers who won a prize in your most recent giveaway contest',
  //   operator: OperatorType.AND,
  //   expressions: [],
  //   readOnly: true,
  //   source: 'MANUAL_UPLOAD',
  //   manualUploadList: {
  //     subscriberListMetadataId: 247,
  //     name: 'Givewaway Contest Winners',
  //     matchRate: 100,
  //     uploadedAt: 1600187208,
  //     originalFileNames: ['giveaway contest winners.csv'],
  //   },
  //   updated: 1600187208,
  // },
  // {
  //   id: 12,
  //   syncStatus: SyncStatus.SYNCED,
  //   name: 'Engaged Email Subscribers',
  //   description: 'Subscribers who opened an email in the last 30 days',
  //   operator: OperatorType.AND,
  //   expressions: [],
  //   anyComponentSyncing: false,
  //   readOnly: true,
  //   source: 'THIRD_PARTY',
  //   thirdPartyList: {
  //     subscriberListMetadataId: 238,
  //     name: 'Engaged Email Subscribers',
  //     externalId: 'EngagedEmailSubs',
  //     externalSource: 'Klaviyo',
  //     matchRate: 100,
  //     syncedAt: 1600241236,
  //   },
  //   updated: 1598383432,
  // },
];

const segmentCounts: { [id: number]: SegmentSubscriberCount } = {
  1: {
    activeSubscribers: 18936,
    activeSubscribersNotFatigued: 18934,
  },
  2: {
    activeSubscribers: 1061630,
    activeSubscribersNotFatigued: 1061500,
  },
  3: {
    activeSubscribers: 187347,
    activeSubscribersNotFatigued: 186523,
  },
  4: {
    activeSubscribers: 599508,
    activeSubscribersNotFatigued: 599402,
  },
  5: {
    activeSubscribers: 274774,
    activeSubscribersNotFatigued: 274522,
  },
  6: {
    activeSubscribers: 136862,
    activeSubscribersNotFatigued: 134292,
  },
  7: {
    activeSubscribers: 427141,
    activeSubscribersNotFatigued: 427000,
  },
  8: {
    activeSubscribers: 359012,
    activeSubscribersNotFatigued: 357243,
  },
  9: {
    activeSubscribers: 1019762,
    activeSubscribersNotFatigued: 1008675,
  },
  10: {
    activeSubscribers: 865482,
    activeSubscribersNotFatigued: 865333,
  },
  11: {
    activeSubscribers: 10,
    activeSubscribersNotFatigued: 10,
  },
  12: {
    activeSubscribers: 1023,
    activeSubscribersNotFatigued: 1023,
  },
};

const segmentCountsV2: { [id: number]: SegmentSubscriberCountV2 } = {
  1: {
    email: {
      activeSubscribers: 18936,
      activeSubscribersNotFatigued: 18934,
    },
    sms: {
      activeSubscribers: 18242,
      activeSubscribersNotFatigued: 18240,
    },
    smsAndEmail: {
      activeSubscribers: 18166,
      activeSubscribersNotFatigued: 18122,
    },
  },
  2: {
    email: {
      activeSubscribers: 1042630,
      activeSubscribersNotFatigued: 1042500,
    },
    sms: {
      activeSubscribers: 1061630,
      activeSubscribersNotFatigued: 1061500,
    },
    smsAndEmail: {
      activeSubscribers: 1022630,
      activeSubscribersNotFatigued: 1021500,
    },
  },
  3: {
    email: {
      activeSubscribers: 182327,
      activeSubscribersNotFatigued: 180001,
    },
    sms: {
      activeSubscribers: 187347,
      activeSubscribersNotFatigued: 186523,
    },
    smsAndEmail: {
      activeSubscribers: 179282,
      activeSubscribersNotFatigued: 177782,
    },
  },
  4: {
    email: {
      activeSubscribers: 566502,
      activeSubscribersNotFatigued: 564402,
    },
    sms: {
      activeSubscribers: 599508,
      activeSubscribersNotFatigued: 599402,
    },
    smsAndEmail: {
      activeSubscribers: 511502,
      activeSubscribersNotFatigued: 510402,
    },
  },
  5: {
    email: {
      activeSubscribers: 235774,
      activeSubscribersNotFatigued: 222774,
    },
    sms: {
      activeSubscribers: 274774,
      activeSubscribersNotFatigued: 274522,
    },
    smsAndEmail: {
      activeSubscribers: 231174,
      activeSubscribersNotFatigued: 230074,
    },
  },
  6: {
    email: {
      activeSubscribers: 122862,
      activeSubscribersNotFatigued: 121844,
    },
    sms: {
      activeSubscribers: 136862,
      activeSubscribersNotFatigued: 134292,
    },
    smsAndEmail: {
      activeSubscribers: 100862,
      activeSubscribersNotFatigued: 100802,
    },
  },
  7: {
    email: {
      activeSubscribers: 403141,
      activeSubscribersNotFatigued: 400141,
    },
    sms: {
      activeSubscribers: 427141,
      activeSubscribersNotFatigued: 427000,
    },
    smsAndEmail: {
      activeSubscribers: 400141,
      activeSubscribersNotFatigued: 399141,
    },
  },
  8: {
    email: {
      activeSubscribers: 359012,
      activeSubscribersNotFatigued: 357243,
    },
    sms: {
      activeSubscribers: 359012,
      activeSubscribersNotFatigued: 357243,
    },
    smsAndEmail: {
      activeSubscribers: 359012,
      activeSubscribersNotFatigued: 357243,
    },
  },
  9: {
    email: {
      activeSubscribers: 1019762,
      activeSubscribersNotFatigued: 1008675,
    },
    sms: {
      activeSubscribers: 1019762,
      activeSubscribersNotFatigued: 1008675,
    },
    smsAndEmail: {
      activeSubscribers: 1019762,
      activeSubscribersNotFatigued: 1008675,
    },
  },
  10: {
    email: {
      activeSubscribers: 865482,
      activeSubscribersNotFatigued: 865333,
    },
    sms: {
      activeSubscribers: 865482,
      activeSubscribersNotFatigued: 865333,
    },
    smsAndEmail: {
      activeSubscribers: 865482,
      activeSubscribersNotFatigued: 865333,
    },
  },
  11: {
    email: {
      activeSubscribers: 10,
      activeSubscribersNotFatigued: 10,
    },
    sms: {
      activeSubscribers: 10,
      activeSubscribersNotFatigued: 10,
    },
    smsAndEmail: {
      activeSubscribers: 10,
      activeSubscribersNotFatigued: 10,
    },
  },
  12: {
    email: {
      activeSubscribers: 1023,
      activeSubscribersNotFatigued: 1023,
    },
    sms: {
      activeSubscribers: 1023,
      activeSubscribersNotFatigued: 1023,
    },
    smsAndEmail: {
      activeSubscribers: 1023,
      activeSubscribersNotFatigued: 1023,
    },
  },
};

const segmentComponents: SegmentComponent[] = [
  {
    id: 1,
    type: SegmentComponentType.CUSTOM,
    syncing: false,
    description: 'has joined in the last 7 days',
    parameters: {
      durationTime: 7,
      durationUnit: DurationUnit.DAYS,
      hasVerb: HasVerbType.HAS,
      subscriberAction: ActionFilter.JOINED,
      timeComparator: TimeComparatorType.IN_THE_LAST,
    },
  },
  {
    id: 2,
    type: SegmentComponentType.CUSTOM,
    syncing: false,
    description: 'has clicked at least once in the last 5 messages',
    parameters: {},
  },
  {
    id: 3,
    type: SegmentComponentType.CUSTOM,
    syncing: false,
    description: "hasn't clicked at least once in the last 5 messages",
    parameters: {},
  },
  {
    id: 4,
    type: SegmentComponentType.CUSTOM,
    syncing: false,
    description: "haven't purchased at least once over all time",
    parameters: {
      frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
      hasVerb: HasVerbType.HAS_NOT,
      subscriberAction: ActionFilter.PURCHASED,
      timeComparator: TimeComparatorType.OVER_ALL_TIME,
    },
  },
  {
    id: 5,
    type: SegmentComponentType.CUSTOM,
    syncing: false,
    description: 'have purchased at least once in the last 30 days',
    parameters: {
      frequencyComparator: OperatorComparator.AT_LEAST_ONCE,
      hasVerb: HasVerbType.HAS,
      subscriberAction: ActionFilter.PURCHASED,
      timeComparator: TimeComparatorType.IN_THE_LAST,
      durationTime: 30,
      durationUnit: DurationUnit.DAYS,
    },
  },
  {
    id: 6,
    type: SegmentComponentType.CUSTOM,
    description: 'have placed at least 4 orders from your site',
    syncing: false,
    parameters: {},
  },
  {
    id: 7,
    type: SegmentComponentType.CUSTOM,
    description: 'have purchased at least twice',
    syncing: false,
    parameters: {},
  },
  {
    id: 8,
    type: SegmentComponentType.CUSTOM,
    description: `haven't purchased in the last 8 months`,
    syncing: false,
    parameters: {},
  },
  {
    id: 9,
    type: SegmentComponentType.DEVICE,
    description: 'device type is mobile',
    syncing: false,
    parameters: {
      isVerb: IsVerbType.IS,
      subscriberProperty: PropertiesFilter.DEVICE_TYPE,
      deviceTypes: ['MOBILE'],
    },
  },
  {
    id: 10,
    type: SegmentComponentType.DEVICE,
    description: 'operating system is iOS',
    syncing: false,
    parameters: {
      isVerb: IsVerbType.IS,
      subscriberProperty: PropertiesFilter.OPERATING_SYSTEM,
      deviceTypes: ['IOS'],
    },
  },
  {
    id: 11,
    type: SegmentComponentType.SUBSCRIBER_LIST,
    description: 'Giveaway contest winners',
    syncing: false,
    parameters: {
      isVerb: IsVerbType.IS,
      subscriberProperty: PropertiesFilter.MANUAL_UPLOAD_LIST,
      subscriberListMetadataId: 247,
    },
  },
  {
    id: 12,
    type: SegmentComponentType.SUBSCRIBER_LIST,
    description: 'Subscribers who opened an email in the last 30 days',
    syncing: false,
    parameters: {
      isVerb: IsVerbType.IS,
      subscriberProperty: PropertiesFilter.THIRD_PARTY_LIST,
      subscriberListMetadataId: 238,
    },
  },
];

export const demoDataProvider = {
  getSegments({ offset = 0, limit = 8 }) {
    const start = offset;
    const end = offset > 0 ? offset * limit : limit;
    const segments = demoSegments.slice(start, end);
    return { segments, totalSegmentsCount: demoSegments.length };
  },

  getSegmentById(segmentId: number) {
    const segment = demoSegments.find(({ id }) => id === segmentId) || null;
    const components = segmentComponents.filter(({ id }) => id === segmentId) || null;
    if (segment && components) {
      segment.expressions = [
        {
          operator: OperatorType.OR,
          segmentComponents: components,
        },
      ];
    }
    return segment;
  },

  getSegmentCounts() {
    return Object.keys(segmentCounts).reduce((response, _id) => {
      const id = Number(_id);
      return {
        ...response,
        [id]: segmentCounts[id].activeSubscribers,
      };
    }, {});
  },

  getSegmentCountsV2() {
    return Object.keys(segmentCountsV2).reduce((response, _id) => {
      const id = Number(_id);
      return {
        ...response,
        [id]: {
          sms: segmentCountsV2[id].sms.activeSubscribers,
          email: segmentCountsV2[id].email.activeSubscribers,
          smsAndEmail: segmentCountsV2[id].smsAndEmail.activeSubscribers,
        },
      };
    }, {});
  },

  getSegmentCountById(segmentId: number) {
    return segmentCounts[segmentId] || null;
  },

  getSegmentCountByIdV2(segmentId: number) {
    return segmentCountsV2[segmentId] || null;
  },
};
