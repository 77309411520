import React from 'react';

import { Box, IconButton, IconPopover, PicnicCss, Text } from '@attentive/picnic';

import { HasVerbType } from '../../../../constants';
import { SegmentConditionContainer } from '../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import VerbSelector, { VerbSelectorType } from '../../components/VerbSelector';

interface OwnProps {
  excludePrivacyOpens: boolean;
  onChangeExcludePrivacyOpens: (newValue: boolean) => void;
  onRemove: () => void;
  css?: PicnicCss;
}

export const MppSegmentCondition: React.FC<OwnProps> = ({
  excludePrivacyOpens,
  onChangeExcludePrivacyOpens,
  onRemove,
  css,
}) => {
  return (
    <SegmentConditionContainer css={css}>
      <SegmentConditionItemContainer>
        <Text>where subscriber</Text>
      </SegmentConditionItemContainer>
      <VerbSelector
        verbType={VerbSelectorType.HAS}
        // "where subscriber HAS NOT enabled MPP" == excludePrivacyOpens: true
        value={excludePrivacyOpens ? HasVerbType.HAS_NOT : HasVerbType.HAS}
        onChange={(verb) => onChangeExcludePrivacyOpens(verb === HasVerbType.HAS_NOT)}
      />
      <SegmentConditionItemContainer>
        <Box css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text>enabled MPP</Text>
          <IconPopover iconName="CircleInformation" size="small">
            <Text>
              Open behavior identified as Apple iOS MPP, which doesn't necessarily represent a true
              user interaction. Apple iOS MPP data is available starting from October 3, 2022.
            </Text>
          </IconPopover>
        </Box>
      </SegmentConditionItemContainer>
      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description="Remove MPP condition"
          onClick={onRemove}
        />
      </SegmentConditionItemContainer>
    </SegmentConditionContainer>
  );
};
