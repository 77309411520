import React, { FC, useState } from 'react';

import { Box, Button, Icon, IconButton, styled, Text } from '@attentive/picnic';

import { FilterType, OperatorType, SegmentComponent } from '../../../../../../constants';
import SegmentConditionItemContainer from '../../../../../SegmentConditionItemContainer';
import { getInitialFilterType } from '../../../../utils/filterType';
import FilterTypeSelector from '../../../FilterTypeSelector';
import {
  SegmentConditionSelectors,
  SegmentConditionSelectorsProps,
} from '../SegmentConditionSelectors';

const ConditionBox = styled('div', {
  display: 'flex',
  flex: 1,
  background: '$bgAccent',
  borderRadius: '$radius2',
  p: '$space4 $space4 $space0 $space4',
});

export interface SegmentConditionProps
  extends Omit<SegmentConditionSelectorsProps, 'segmentFilterType' | 'children'> {
  showDupeText: boolean;
  higlightCondition?: boolean;
  component: SegmentComponent;
  showDeleteButton: boolean;
  onClickDeleteButton: () => void;
  onClickOrButton: () => void;
  operator: OperatorType;
  resetComponent: () => void;
}

export const LegacySegmentCondition: FC<SegmentConditionProps> = ({
  showDupeText,
  higlightCondition,
  showDeleteButton,
  onClickOrButton,
  onClickDeleteButton,
  operator,
  resetComponent,
  ...props
}) => {
  const { parameters } = props.component;
  const { deleted: customPropertyDeleted } = parameters;

  const [segmentFilterType, setSegmentFilterType] = useState<FilterType | undefined>(
    getInitialFilterType(parameters)
  );

  const handleFilterTypeChange = (newFilterType: FilterType) => {
    if (segmentFilterType) {
      resetComponent();
    }
    setSegmentFilterType(newFilterType);
  };

  return (
    <Box>
      <Box css={{ display: 'flex', justifyContent: 'space-between' }}>
        <FilterTypeSelector value={segmentFilterType} onChange={handleFilterTypeChange} />
        <SegmentConditionItemContainer>
          <IconButton
            iconName="Delete"
            size="extraSmall"
            description="Remove segment expression"
            onClick={onClickDeleteButton}
            css={{ m: '$space2', visibility: showDeleteButton ? 'visible' : 'hidden' }}
          />
        </SegmentConditionItemContainer>
      </Box>
      {segmentFilterType && (
        <>
          <Box css={{ display: 'flex', mt: '$space4' }}>
            <ConditionBox>
              <SegmentConditionSelectors {...props} segmentFilterType={segmentFilterType}>
                {higlightCondition && (
                  <Box
                    css={{
                      height: '$size9',
                      display: 'flex',
                      alignItems: 'center',
                      mr: '$space2',
                    }}
                  >
                    <Icon
                      name="CircleExclamation"
                      color="error"
                      data-testid="segment-condition-error-icon"
                    />
                  </Box>
                )}
              </SegmentConditionSelectors>
            </ConditionBox>
            <Box css={{ marginLeft: '$space8', display: 'flex' }}>
              <SegmentConditionItemContainer>
                <Button
                  onClick={onClickOrButton}
                  size="small"
                  data-segmentation-id="segment-builder-or-button"
                >
                  {operator === OperatorType.AND ? 'And' : 'Or'}
                </Button>
              </SegmentConditionItemContainer>
            </Box>
          </Box>
          {showDupeText && (
            <Text variant="caption" color="critical" css={{ mt: '$space2' }}>
              This condition is a duplicate.
            </Text>
          )}
          {customPropertyDeleted && (
            <Text variant="caption" color="critical" css={{ ml: '$space4' }}>
              Remove or replace this category.
            </Text>
          )}
        </>
      )}
    </Box>
  );
};
