import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Select } from '@attentive/picnic';

import { ATTRIBUTE_INPUT_TYPES, SupportedPropertyTypeWithEmpty, typeDefaults } from '../constants';
import { getInitialCustomAttributeFormValues } from '../form';

interface InputTypeSelectProps {
  disabled?: boolean;
  isEditing?: boolean;
  value: ATTRIBUTE_INPUT_TYPES;
  onChange: (
    val: ATTRIBUTE_INPUT_TYPES,
    type: SupportedPropertyTypeWithEmpty,
    options: string[] | undefined
  ) => void;
  displayMultiSelect?: boolean;
  displayCheckbox?: boolean;
  size: 'small' | 'normal';
}

export function InputTypeSelect({
  disabled,
  displayMultiSelect,
  displayCheckbox,
  isEditing,
  value,
  onChange,
  size,
}: InputTypeSelectProps) {
  const enableDateAndNumber = useCompanyFeatureFlag(
    'ENABLE_CUSTOM_ATTRIBUTE_DATE_AND_NUMBER_TYPE_CREATION'
  );
  const hasExistingEnum = isEditing && value === ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT;

  // If the existing input type is single select, we do not allow editing because enums cannot be transformed into other types
  const disableField = disabled || hasExistingEnum;

  // We only allow creation of enums.
  // While editing an enum attribute, we include the single select option to populate the select field
  const displaySingleSelectOption = !isEditing || hasExistingEnum;

  // Dates can be created if the flag is turned on or if an existing attribute is being edited.
  // We show super users the date option when editing to allow fixing incorrectly inferred types
  const displayDateOption = enableDateAndNumber || isEditing;

  // Users cannot create boolean attributes but super users can edit the attribute to change to those types.
  // This is to help CS fix incorrectly inferred attributes
  const displayBooleanOption = isEditing;

  return (
    <Select
      name="inputType"
      value={value}
      size={size === 'normal' ? 'medium' : size}
      onChange={(val: string) => {
        const typedVal = val as ATTRIBUTE_INPUT_TYPES;
        const options = getOptions(typedVal);
        onChange(typedVal, typeDefaults[typedVal], options);
      }}
      placeholder="Select an input type"
      disabled={disableField}
    >
      <Select.IconItem name="TextFileOutline" value={ATTRIBUTE_INPUT_TYPES.SHORT_ANSWER}>
        Short answer
      </Select.IconItem>
      {displayDateOption && (
        <Select.IconItem name="Calendar" value={ATTRIBUTE_INPUT_TYPES.DATE}>
          Date
        </Select.IconItem>
      )}
      {displaySingleSelectOption && (
        <Select.IconItem name="RadioButton" value={ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT}>
          Single select list
        </Select.IconItem>
      )}
      {displayMultiSelect && (
        <Select.IconItem name="Checkbox" value={ATTRIBUTE_INPUT_TYPES.MUTLI_SELECT}>
          Multi select list
        </Select.IconItem>
      )}
      {displayCheckbox && (
        <Select.IconItem name="Checkbox" value={ATTRIBUTE_INPUT_TYPES.CHECKBOX}>
          Checkbox
        </Select.IconItem>
      )}
      {displayBooleanOption && (
        <Select.IconItem name="Sync" value={ATTRIBUTE_INPUT_TYPES.BOOLEAN}>
          Boolean
        </Select.IconItem>
      )}
    </Select>
  );
}

function getOptions(inputType: ATTRIBUTE_INPUT_TYPES) {
  if (inputType === ATTRIBUTE_INPUT_TYPES.CHECKBOX) {
    return [''];
  }

  if (inputType === ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT) {
    const { options } = getInitialCustomAttributeFormValues();
    return options;
  }
}
