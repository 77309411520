import { API } from '@attentive/acore-utils';

import { Vendor } from '../../constants';

import { KlaviyoAttrValueResponse } from './constants';

/**
 * Fetches values for a Klaviyo profile attribute
 * @param attr is the attribute
 */
export const fetchKlaviyoProfileAttrValues = (attr: string) => {
  const controller = new AbortController();
  const queryString = `?vendor=${
    Vendor.PROFILE_VENDOR_KLAVIYO
  }&profileAttribute=${encodeURIComponent(attr)}`;
  // GMRU: GET /user-profile/v1/segment-options/profile
  const promise = API.get<KlaviyoAttrValueResponse>(
    `/user-profile/v1/segment-options/profile${queryString}`,
    null,
    {
      signal: controller.signal,
    }
  );

  // @ts-ignore
  promise.cancel = controller.abort;
  return promise;
};
