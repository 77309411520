import { ATTRIBUTE_INPUT_TYPES, enumInputTypes, SupportedPropertyTypeWithEmpty } from './constants';

export interface CustomAttributeFormState {
  attributeName: string;
  dataType: SupportedPropertyTypeWithEmpty;
  inputType: ATTRIBUTE_INPUT_TYPES;
  options: string[];
}

export const getInitialCustomAttributeFormValues = (
  // TODO: remove temporary arg once all attribute types are supported by journeys
  supportEnumAttributeOnly = false
): CustomAttributeFormState => {
  return {
    attributeName: '',
    inputType: supportEnumAttributeOnly
      ? ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT
      : ATTRIBUTE_INPUT_TYPES.EMPTY,
    dataType: supportEnumAttributeOnly ? 'TYPE_ENUM' : '',
    options: [...Array(3)].map(() => ''),
  };
};

export type CustomAttributeFormErrors = {
  attributeName?: string;
  dataType?: string;
  inputType?: string;
  options?: Record<number, string>;
};

export function validateCustomAttributeForm(formValues: CustomAttributeFormState) {
  const errors: CustomAttributeFormErrors = {};

  if (!formValues.attributeName) {
    errors.attributeName = 'Please enter an attribute name';
  }

  if (customAttributeNameHasInvalidCharacters(formValues.attributeName)) {
    errors.attributeName = `Please remove the invalid characters. ' and " are not allowed`;
  }

  if (!formValues.dataType) {
    errors.dataType = 'Please select a data type';
  }

  if (!formValues.inputType) {
    errors.inputType = 'Please select an input type';
  }

  if (enumInputTypes.includes(formValues.inputType)) {
    if (formValues.options.length < 1) {
      throw new Error('No options provided');
    }

    formValues.options.forEach((value, index) => {
      if (!value) {
        if (!errors.options) {
          errors.options = {};
        }
        errors.options[index] = 'Please enter an option name';
      }
    });
  }

  const hasErrors = Object.keys(errors).length > 0;

  return [errors, hasErrors] as const;
}

export function customAttributeNameHasInvalidCharacters(attributeName: string) {
  return /['"]/.test(attributeName);
}
