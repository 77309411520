/**
 * @generated SignedSource<<865bb0f9f7c9454ce09add7b54d9907f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration$data = {
  readonly externalVendorApi: {
    readonly displayName: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration";
};
export type vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration$key = {
  readonly " $data"?: vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration$data;
  readonly " $fragmentSpreads": FragmentRefs<"vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration"
};

(node as any).hash = "59d9f93a8e973a34a631fe5ba2bf42eb";

export default node;
