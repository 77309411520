import _integrationUtils_getIntegrationId_TargetingCommon_CompanyVendorIntegration from "./__generated__/integrationUtils_getIntegrationId_TargetingCommon_CompanyVendorIntegration.graphql";
import { graphql, readInlineData } from '@attentive/data';
import CompanyVendorIntegrationFrag from './__generated__/integrationUtils_getIntegrationId_TargetingCommon_CompanyVendorIntegration.graphql';
_integrationUtils_getIntegrationId_TargetingCommon_CompanyVendorIntegration;
// returns the integration id if the list of company vendor integrations includes the vendor,
// and the vendor's list of features includes the (optional) passed feature
// it does NOT check if the company has the feature enabled
export const getIntegrationId = (companyVendorIntegrations, vendor, feature) => {
  let matchedIntegrationId = null;
  companyVendorIntegrations === null || companyVendorIntegrations === void 0 ? void 0 : companyVendorIntegrations.find(integration => {
    var _externalVendorApi$ex;

    const {
      externalVendorApi,
      id: integrationId
    } = readInlineData(CompanyVendorIntegrationFrag, integration);
    const hasFeature = Boolean(feature && (externalVendorApi === null || externalVendorApi === void 0 ? void 0 : (_externalVendorApi$ex = externalVendorApi.externalVendorFeatures) === null || _externalVendorApi$ex === void 0 ? void 0 : _externalVendorApi$ex.find(externalVendorFeature => {
      return externalVendorFeature.type === feature;
    })));

    if ((externalVendorApi === null || externalVendorApi === void 0 ? void 0 : externalVendorApi.vendor) === vendor && hasFeature) {
      matchedIntegrationId = integrationId;
      return true;
    }

    return false;
  });
  return matchedIntegrationId;
};
export const DECODED_ID_ERROR_MESSAGE = 'Decoded id is not a company vendor integration';
export const COMPANY_VENDOR_INTEGRATION_PREFIX = '24:CompanyVendorIntegration';
export const convertIntegrationsNodeIdToInternalId = (id, logError) => {
  try {
    const decodedId = atob(id);

    if (!decodedId.includes(COMPANY_VENDOR_INTEGRATION_PREFIX)) {
      throw new Error(DECODED_ID_ERROR_MESSAGE);
    }

    return decodedId.replace(COMPANY_VENDOR_INTEGRATION_PREFIX, '');
  } catch (e) {
    logError === null || logError === void 0 ? void 0 : logError(e);
    return '';
  }
};