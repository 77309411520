import { DatePicker } from '@attentive/picnic';

// 1972 was chosen because it is the first leap year after the unix epoch
export const PARTIAL_DATE_YEAR = 1972;

export const PARTIAL_DATE_FORMAT = 'MMM D';
export const PARTIAL_DATE_MONTH_FORMAT = 'MMMM';
export const PARTIAL_DATE_ARIA_LABEL_FORMAT = 'MMMM D';

export const PARTIAL_DATE_DEFAULT_VALUE = `${PARTIAL_DATE_YEAR}-01-01`;
export const PARTIAL_DATE_RANGE_DEFAULT_END_VALUE = `${PARTIAL_DATE_YEAR}-01-07`;

// We extract the typing from the DatePicker component to avoid having to import typing for the Moment argument
export type MonthClickHandler = React.ComponentProps<typeof DatePicker.Control>['onNextMonthClick'];
