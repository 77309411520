import React, { FC } from 'react';

import { Box, FormField, Select, TextInput } from '@attentive/picnic';

import { OperatorComparator } from '../../../constants';
import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

const PU_FREQUENCY_TYPE_MIN_WIDTH = '130px';
const PU_FREQUENCY_MAX_WIDTH = '100px';
const PU_FREQUENCY_MIN_WIDTH = '60px';

type OperatorValue = {
  frequencyComparator?: OperatorComparator;
  frequency?: number;
};

interface FrequencySelectorProps {
  values: OperatorValue;
  onChange: (value: OperatorValue) => void;
}

const FrequencySelector: FC<FrequencySelectorProps> = ({ values, onChange }) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const frequencyError = showValidation && !values.frequency;

  return (
    <>
      <Box css={{ minWidth: PU_FREQUENCY_TYPE_MIN_WIDTH }}>
        <Select
          value={values.frequencyComparator}
          onChange={(newValue: string) =>
            onChange({ frequencyComparator: newValue as OperatorComparator })
          }
          size="small"
        >
          <Select.Item value={OperatorComparator.EQUAL_TO}>Equal to</Select.Item>
          <Select.Item value={OperatorComparator.LESS_THAN}>Less than</Select.Item>
          <Select.Item value={OperatorComparator.MORE_THAN}>More than</Select.Item>
          <Select.Item value={OperatorComparator.AT_LEAST_ONCE}>At least once</Select.Item>
        </Select>
      </Box>
      {values.frequencyComparator !== OperatorComparator.AT_LEAST_ONCE && (
        <>
          <FormField css={{ maxWidth: PU_FREQUENCY_MAX_WIDTH }}>
            <TextInput
              type="number"
              placeholder="Number"
              value={`${values.frequency || ''}`}
              state={frequencyError ? 'error' : 'normal'}
              onChange={(event) => {
                const frequency = !Number.isNaN(Number(event.target.value))
                  ? Number(event.target.value)
                  : values.frequency;
                onChange({ ...values, frequency: frequency ? Math.abs(frequency) : undefined });
              }}
              size="small"
              css={{ minWidth: PU_FREQUENCY_MIN_WIDTH }}
            />
            {frequencyError && <FormField.ErrorText>Please enter a number.</FormField.ErrorText>}
          </FormField>
          <SegmentConditionItemContainer>times</SegmentConditionItemContainer>
        </>
      )}
    </>
  );
};

export default FrequencySelector;
