import React, { FC } from 'react';

import { Box, Select } from '@attentive/picnic';

import { FilterType } from '../../../constants';

interface OwnProps {
  value: FilterType | undefined;
  onChange: (newVal: FilterType) => void;
}

const FilterTypeSelector: FC<OwnProps> = ({ value, onChange }) => {
  return (
    <Box css={{ width: '300px' }}>
      <Select
        value={value}
        onChange={(newVal: string) => onChange(newVal as FilterType)}
        placeholder="Choose condition"
        data-testid="filter-type-selector"
      >
        <Select.Item value={FilterType.ACTION}>A subscriber's activity</Select.Item>
        <Select.Item value={FilterType.ATTRIBUTE}>A subscriber's characteristics</Select.Item>
        <Select.Item value={FilterType.MEMBERSHIP}>Members of non-dynamic segment</Select.Item>
      </Select>
    </Box>
  );
};

export default FilterTypeSelector;
