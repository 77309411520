import { atom } from 'jotai';

import { SegmentOptions, SubscriberAttributeDataType } from '../../../../constants';

export type SubscriberAttributeDataTypeMap = Record<string, string>;

export const subscriberAttributeDataTypeMapAtom = atom<SubscriberAttributeDataTypeMap>({});

export function getSubscriberAttributeDataType(
  attribute: string,
  dataTypeMap: SubscriberAttributeDataTypeMap
) {
  const attributeDataType = dataTypeMap[attribute] || SubscriberAttributeDataType.UNKNOWN;

  return {
    attributeDataType,
    isBoolean: attributeDataType === SubscriberAttributeDataType.BOOLEAN,
    isEnum: attributeDataType === SubscriberAttributeDataType.ENUM,
    isInteger: attributeDataType === SubscriberAttributeDataType.INTEGER,
    isTimestamp: attributeDataType === SubscriberAttributeDataType.TIMESTAMP,
    isText: attributeDataType === SubscriberAttributeDataType.TEXT,
  };
}

// This function takes the response from the segment-options endpoint and assembles data type maps for custom attributes and well known properties
export function assembleSubscriberAttributeDataTypeMap(
  segmentOptions: SegmentOptions | null | undefined,
  setDataTypeMap: (map: SubscriberAttributeDataTypeMap) => void
) {
  if (!segmentOptions || !segmentOptions.subscriberAttributeOptions) return;
  const { customAttributeOptions = [], wellKnownAttributeOptions = [] } =
    segmentOptions.subscriberAttributeOptions;

  const attributes = [...customAttributeOptions, ...wellKnownAttributeOptions];
  if (attributes.length === 0) return;

  const dataTypeMap = attributes.reduce((all, attr) => {
    return { ...all, [attr.optionValue]: attr.type };
  }, {} as SubscriberAttributeDataTypeMap);

  setDataTypeMap(dataTypeMap);
}
