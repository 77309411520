import React from 'react';

import { ExistingCustomAttributeDialog } from './components/ExistingCustomAttributeDialog';
import { FormDialogProps } from './components/FormDialog';
import { NewCustomAttributeDialog } from './components/NewCustomAttributeDialog';

type CustomAttributeDialogProps = Omit<FormDialogProps, 'initialFormValues' | 'saveForm'>;

export const CustomAttributeDialog: React.VFC<CustomAttributeDialogProps> = (props) => {
  return props.id ? (
    <ExistingCustomAttributeDialog {...props} />
  ) : (
    <NewCustomAttributeDialog {...props} />
  );
};
