import React from 'react';

import { useCurrentUser } from '@attentive/acore-utils';
import { CountryCode } from '@attentive/locale-utils';
import { FormField, Heading, MultiSelect, Separator } from '@attentive/picnic';

import { WellKnownPropertyOptionValue } from '../../../constants';
import { separateAndSortCountries } from '../utils/sortCountries';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

const UNMAPPED_OPTION_VALUE = 'unmapped';
const PU_WELL_KNOWN_SELECTOR_MIN_WIDTH = '250px';

interface WellKnownPropertySelectorProps {
  selectedValues: WellKnownPropertyOptionValue[];
  onChange: (options: WellKnownPropertyOptionValue[]) => void;
  options: WellKnownPropertyOptionValue[];
  propertyDisplayName: string;
  isCountryProperty?: boolean;
}

export const WellKnownPropertySelector = ({
  onChange,
  options,
  selectedValues,
  propertyDisplayName,
  isCountryProperty,
}: WellKnownPropertySelectorProps) => {
  const currentUser = useCurrentUser();
  const showValidation = useShowExpressionValidationAtomValue();
  const displayError = showValidation && !selectedValues.length;
  const notMappedOption = options.find(({ optionValue }) => optionValue === UNMAPPED_OPTION_VALUE);

  let allOptions;
  let highlightedOptions;

  if (isCountryProperty) {
    const { allCountries, supportedCountries } = separateAndSortCountries(
      options,
      currentUser.company.country as CountryCode
    );
    allOptions = allCountries;
    highlightedOptions = [...supportedCountries, notMappedOption];
  } else {
    highlightedOptions = notMappedOption && [notMappedOption];
    allOptions = options;
  }

  return (
    <FormField>
      <MultiSelect
        value={selectedValues.map(({ optionValue }) => optionValue)}
        onChange={(selectedStrings: string[]) => {
          const selectedOptions = options.filter(({ optionValue }) =>
            selectedStrings.includes(optionValue)
          );
          onChange(selectedOptions);
        }}
        size="small"
        maxTokens={10}
        placeholder={`Choose ${propertyDisplayName}`}
        state={displayError ? 'error' : 'normal'}
        css={{ minWidth: PU_WELL_KNOWN_SELECTOR_MIN_WIDTH }}
      >
        {isCountryProperty && highlightedOptions && (
          <Heading
            as="li"
            variant="subheading"
            css={{
              color: '$textSubdued',
              paddingBottom: '$space1',
              padding: '$space3',
              textTransform: 'uppercase',
            }}
          >
            My regions
          </Heading>
        )}
        {highlightedOptions &&
          highlightedOptions.map((option) => {
            if (!option) {
              return null;
            }
            const { optionValue, displayName } = option;
            return (
              <MultiSelect.Item key={optionValue} value={optionValue}>
                {displayName}
              </MultiSelect.Item>
            );
          })}
        {highlightedOptions && <Separator />}
        {allOptions.map(({ displayName, optionValue }) => {
          if (optionValue === UNMAPPED_OPTION_VALUE) {
            return null;
          }

          return (
            <MultiSelect.Item key={optionValue} value={optionValue}>
              {displayName}
            </MultiSelect.Item>
          );
        })}
      </MultiSelect>
      {displayError && (
        <FormField.ErrorText>{`Please choose a ${propertyDisplayName}`}</FormField.ErrorText>
      )}
    </FormField>
  );
};
