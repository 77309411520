import { useMutation } from 'react-relay';

import { useCurrentCompanyId } from '@attentive/acore-utils';

import { CustomAttributeErrors } from '../../constants';
import { CustomAttributeFormState } from '../../form';
import { SaveFormResponse } from '../FormDialog';
import { buildNewFieldsVariable } from '../utils';

import CreateMutation, {
  CreateCustomAttributeTargetingCommonMutation as CreateMutationType,
} from './__generated__/CreateCustomAttributeTargetingCommonMutation.graphql';

/**
 * Creates a function for performing the create custom attribute GQL mutation
 *
 * @returns a tuple containing a function for creating custom attributes and a boolean flag indicating
 * if the commit is in progress. The create function uses async/await instead of callbacks.
 * Upon success, it will return an object containing the newly created custom attribute's id and name
 */
export function useCreateCustomAttributeMutation() {
  const companyId = useCurrentCompanyId();
  const [commit, commitInFlight] = useMutation<CreateMutationType>(CreateMutation);

  const createCustomAttribute = (values: CustomAttributeFormState) => {
    return new Promise<SaveFormResponse>((resolve, reject) => {
      const newFields = buildNewFieldsVariable(values);

      commit({
        variables: {
          companyId,
          newFields,
        },
        onCompleted: (response, errors) => {
          if (errors?.some((err) => err.message.includes('ALREADY_EXISTS'))) {
            reject(new Error(CustomAttributeErrors.ALREADY_EXISTS));
            return;
          }

          const propertyDefintion =
            response.createCustomPropertyDefinition?.customPropertyDefinition;
          if (!propertyDefintion) {
            reject(new Error(CustomAttributeErrors.UNKNOWN_CREATE_ERROR));
            return;
          }

          const {
            id,
            name,
            customPropertyId: { internalId },
          } = propertyDefintion;
          resolve({ id, name, internalId });
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  return [createCustomAttribute, commitInFlight] as const;
}
