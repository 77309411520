import React, { useEffect } from 'react';

import { FormField, LoadingIndicator, MultiSelect, Text } from '@attentive/picnic';

import { IsVerbType, SegmentParameters } from '../../constants';
import SegmentConditionItemContainer from '../SegmentConditionItemContainer';
import { useShowExpressionValidationAtomValue } from '../SegmentCreateEditModal/components/SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';
import VerbSelector, { VerbSelectorType } from '../SegmentCreateEditModal/components/VerbSelector';

import { useKlaviyProfileAttrValues } from './hooks';

interface OwnProps {
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
}

function KlaviyoProfileAttrSelector({ onChange, parameters }: OwnProps) {
  const {
    isAttributeSet = true,
    isVerb = IsVerbType.IS,
    profileAttribute = '',
    textValues = [],
  } = parameters;

  const { data, isLoading, error } = useKlaviyProfileAttrValues(profileAttribute);
  const attrKeys = data?.keys;
  const showValidation = useShowExpressionValidationAtomValue();
  const showError = showValidation && !textValues.length;

  // options for an attribute load asynchronously. when an attribute is found to have no options,
  // it is selectable as set/not set in the segment builder. the necessary fields are set here.
  useEffect(() => {
    if (!attrKeys || attrKeys.length < 1) {
      onChange({ ...parameters, isAttributeSet: true });
    } else {
      onChange({ ...parameters, isVerb: IsVerbType.IS, isAttributeSet: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attrKeys]);

  const isOrIsNotNullOnChange = (verb: IsVerbType) => {
    onChange({ ...parameters, isAttributeSet: verb === IsVerbType.IS, isVerb: undefined });
  };

  const multiSelectOnChange = (newValues: string[]) => {
    onChange({ ...parameters, isAttributeSet: undefined, textValues: newValues });
  };

  if (isLoading) {
    return (
      <SegmentConditionItemContainer>
        <LoadingIndicator />
      </SegmentConditionItemContainer>
    );
  }

  if (error) {
    return (
      <SegmentConditionItemContainer>
        <Text>{error.message}</Text>
      </SegmentConditionItemContainer>
    );
  }

  if (!attrKeys || attrKeys.length === 0) {
    return (
      <>
        <VerbSelector
          verbType={VerbSelectorType.IS}
          value={isAttributeSet ? IsVerbType.IS : IsVerbType.IS_NOT}
          onChange={(verb) => isOrIsNotNullOnChange(verb as IsVerbType)}
        />
        <SegmentConditionItemContainer>
          <Text>set on the subscriber's profile</Text>
        </SegmentConditionItemContainer>
      </>
    );
  }

  return (
    <>
      <VerbSelector
        verbType={VerbSelectorType.IS}
        value={isVerb}
        onChange={(verb) => onChange({ ...parameters, isVerb: verb as IsVerbType })}
      />
      <FormField>
        <MultiSelect
          value={textValues}
          onChange={multiSelectOnChange}
          placeholder="Choose values"
          state={showError ? 'error' : 'normal'}
          size="small"
        >
          {attrKeys.map((key) => (
            <MultiSelect.Item key={key} value={key}>
              {key}
            </MultiSelect.Item>
          ))}
        </MultiSelect>
        {showError && <FormField.ErrorText>Please choose values.</FormField.ErrorText>}
      </FormField>
    </>
  );
}

export default KlaviyoProfileAttrSelector;
