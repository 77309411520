import { engagedSubscribers } from './engagedSubscribers';
import { holidayTargetSubscribers } from './holidayTargetSubscribers';
import { newSubscribers } from './newSubscribers';
import { prospects } from './prospects';
import { repeatBuyers } from './repeatBuyers';
import { unengagedSubscribers } from './unengagedSubscribers';

export const segmentTemplates = {
  engagedSubscribers,
  holidayTargetSubscribers,
  newSubscribers,
  prospects,
  repeatBuyers,
  unengagedSubscribers,
};
