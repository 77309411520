import React from 'react';

import { Box, Heading, IconCircle, PicnicCss, Text } from '@attentive/picnic';

import { BlankSegment, Segment } from '../../../../constants';
import { getSegmentComponentCount } from '../../../../utils';

import { IconCircleContainer, segmentCountTextTransition } from './SegmentCountContentNew';

interface OwnProps {
  segment?: Segment | BlankSegment;
}

const SegmentComponentCount = ({ segment }: OwnProps) => {
  const componentCount = segment ? getSegmentComponentCount(segment) : '--';
  const hasSegment = Boolean(segment);

  const headlineCss: PicnicCss = {
    color: !hasSegment ? '$textDisabled' : '$textDefault',
    transition: segmentCountTextTransition,
  };
  const textCss: PicnicCss = {
    color: !hasSegment ? '$textDisabled' : '$textSubdued',
    transition: segmentCountTextTransition,
  };

  return (
    <Box
      css={{
        display: 'flex',
        borderBottom: '1px solid $borderDefault',
        p: '$space0 $space6 $space6',
      }}
    >
      <IconCircleContainer>
        <IconCircle iconName="Brackets" size="small" color={!hasSegment ? 'disabled' : 'default'} />
      </IconCircleContainer>
      <Box>
        <Heading variant="lg" data-testid="segment-condition-count" css={headlineCss}>
          {componentCount}
        </Heading>
        <Text css={textCss}>Segment condition{componentCount !== 1 && 's'}</Text>
      </Box>
    </Box>
  );
};

export default SegmentComponentCount;
