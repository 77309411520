import React from 'react';

import { Button, LoadingIndicator, Text } from '@attentive/picnic';

interface OwnProps {
  disabled: boolean;
  isLoading: boolean;
  onUpdateCountClick?: () => void;
}

export const UpdateSubscriberCount = ({ disabled, isLoading, onUpdateCountClick }: OwnProps) => {
  return (
    <>
      <Button
        onClick={onUpdateCountClick}
        disabled={disabled}
        size="small"
        css={{ width: '100%' }}
        data-testid="update-subscriber-count-button"
      >
        {isLoading ? <LoadingIndicator /> : 'Update subscriber count'}
      </Button>
      <Text css={{ mt: '$space6', fontSize: '$fontSize1' }} color="subdued">
        To provide you a more responsive experience when building segments with many conditions, we
        will pause automatically updating subscriber counts. You will need to click the 'Update
        subscriber count' button.
      </Text>
    </>
  );
};
