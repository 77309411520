import debounce from 'lodash/debounce';
import { useCallback, useState, useEffect, useRef, useReducer } from 'react';

// acore
import { useToast, API, ToastType } from '@attentive/acore-utils';

// constants
import { CompleteProductDataOption, RESTResponseProductDataOption } from './interfaces';

export const PAGE_SIZE = 20;

enum ActionTypes {
  REQUEST_FIRED = 'REQUEST_FIRED',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  USER_INPUT_CHANGE = 'USER_INPUT_CHANGE',
  INCREMENT_OFFSET = 'INCREMENT_OFFSET',
  REQUEST_FAILURE = 'REQUEST_FAILURE',
}

interface ProductDataFetchResponse {
  values: RESTResponseProductDataOption[];
  totalResults: number;
}

interface State {
  isLoading: boolean;
  options: CompleteProductDataOption[];
  totalResults: number;
  offset: number;
  errorMessage: string;
  userInput: string;
  hasUserInputChanged: boolean;
  hasOffsetChanged: boolean;
}

type Action =
  | {
      type: ActionTypes.REQUEST_FIRED;
    }
  | {
      type: ActionTypes.REQUEST_SUCCESS;
      options: CompleteProductDataOption[];
      totalResults: number;
    }
  | { type: ActionTypes.REQUEST_FAILURE; errorMessage: string }
  | {
      type: ActionTypes.USER_INPUT_CHANGE;
      userInput: string;
    }
  | { type: ActionTypes.INCREMENT_OFFSET; offset: number };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.REQUEST_FIRED:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        options: [...state.options, ...action.options],
        totalResults: action.totalResults,
        errorMessage: '',
        hasUserInputChanged: false,
        hasOffsetChanged: false,
      };
    case ActionTypes.REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
        hasUserInputChanged: false,
        hasOffsetChanged: false,
      };
    case ActionTypes.USER_INPUT_CHANGE:
      return {
        ...state,
        userInput: action.userInput,
        hasUserInputChanged: true,
        offset: 0,
        options: [],
        totalResults: 0,
      };
    case ActionTypes.INCREMENT_OFFSET:
      return { ...state, offset: action.offset, hasOffsetChanged: true };
  }
};

const productDataRequest = (queryString: string, signal: AbortSignal) => {
  // GMRU: GET /products/v1/attributes
  return API.get<ProductDataFetchResponse>(`/products/v1/attributes${queryString}`, null, {
    signal,
  });
};

const initialState: State = {
  isLoading: false,
  options: [],
  totalResults: 0,
  offset: 0,
  userInput: '',
  errorMessage: '',
  hasUserInputChanged: false,
  hasOffsetChanged: false,
};

type generateProductDataQueryStringArgs = {
  queryAttribute: string;
  queryName: string | undefined;
  userInputQueryParams: string;
  offset: number;
};

const generateProductDataQueryString = ({
  queryAttribute,
  queryName,
  offset,
  userInputQueryParams,
}: generateProductDataQueryStringArgs) => {
  const name = queryName ? `&name=${encodeURIComponent(queryName)}` : '';
  const currentOffset = offset * PAGE_SIZE;

  return `?attribute=${queryAttribute}${name}&pageSize=${PAGE_SIZE}&offset=${currentOffset}${userInputQueryParams}`;
};

export const useProductData = (queryAttribute: string, queryName: string | undefined) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { offset, userInput, isLoading, options, hasUserInputChanged, hasOffsetChanged } = state;
  const [createToast] = useToast();

  const userInputQueryParams =
    userInput.trim().length > 0 ? `&query=${encodeURIComponent(userInput)}` : '';

  const [queryString, setQueryString] = useState(
    generateProductDataQueryString({
      queryAttribute,
      queryName,
      offset,
      userInputQueryParams,
    })
  );
  const debouncedSetQueryStringRef = useRef(debounce(setQueryString, 250));

  useEffect(() => {
    const newQueryString = generateProductDataQueryString({
      queryAttribute,
      queryName,
      offset,
      userInputQueryParams,
    });

    // hasOffsetChanged is only set to true when a user triggers the INCREMENT_OFFSET action
    // Since we're not waiting on user input, we can immediately trigger the request
    if (hasOffsetChanged) {
      setQueryString(newQueryString);
      return;
    }

    const debouncedRef = debouncedSetQueryStringRef.current;
    debouncedRef(newQueryString);
    return () => {
      debouncedRef.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, queryAttribute, queryName, userInputQueryParams]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      dispatch({ type: ActionTypes.REQUEST_FIRED });
      try {
        const response = await productDataRequest(queryString, controller.signal);

        if (response.status !== 200 || !response.body) {
          throw new Error('Request for product data failed.');
        }
        dispatch({
          type: ActionTypes.REQUEST_SUCCESS,
          // Remove deleted options
          options: response.body.values.filter(({ deleted }) => !deleted),
          totalResults: response.body.totalResults,
        });
      } catch (e) {
        dispatch({
          type: ActionTypes.REQUEST_FAILURE,
          errorMessage: e.message,
        });
        if (e.name !== 'AbortError') {
          createToast({
            type: ToastType.Error,
            title: 'Request failed',
            text: e.message,
          });
        }
      }
    };
    fetchData();
    return () => {
      controller.abort();
    };
  }, [createToast, queryString]);

  return {
    state,
    isLoading,
    isEmptyLoadingState: isLoading && options.length < 1,
    noResultsReturned: !isLoading && options.length < 1 && !hasUserInputChanged,
    userInput,
    handleChange: useCallback(
      (newUserInput: string) => {
        dispatch({
          type: ActionTypes.USER_INPUT_CHANGE,
          userInput: newUserInput,
        });
      },
      [dispatch]
    ),
    isItemLoaded: useCallback(
      (itemNum: number) => {
        // We add 1 to itemNum because it's zero-indexed. Without this fix, the UI won't fetch pages of results that only contain 1 item.
        return options.length >= itemNum + 1;
      },
      [options.length]
    ),
    loadMoreItems: useCallback(async () => {
      // Check to make sure that the offset is in range. A user can request an out-of-bounds offset if one of their
      // previous requests was skipped or failed. This will cause the fetched options length to never match total results
      if (state.totalResults && (offset + 1) * PAGE_SIZE > state.totalResults) {
        console.warn('Attempted to fetch an out of bounds offset.');
        return;
      }

      // Currently loadMoreItems triggers a debounced function to update the URL and fetch the next page of products. It's possible that a user
      // can trigger multiple loadMoreItems calls in the UI before the debounced function can fire again leading to missing pages of product data
      // This check ensures that the current offset has been fetched before we trigger the next one.
      if (hasOffsetChanged) {
        console.warn('Attempted to fetch an additional offset before the current one is fetched');
        return;
      }

      dispatch({ type: ActionTypes.INCREMENT_OFFSET, offset: offset + 1 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, offset, hasOffsetChanged]),
  };
};
