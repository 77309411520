import React, { FC } from 'react';

import { Button, StandardDialog, Text } from '@attentive/picnic';

import { deleteSegmentById } from '../api';
import { CampaignUsingSegment, IntegrationUsingSegment, JourneyUsingSegment } from '../constants';
import { SegmentInUseError, SegmentUsageServicesUnavailableError } from '../utils';

import { SegmentInUseErrorDialog } from './SegmentInUseErrorDialog';

const PU_DELETE_SEGMENT_DIALOG_WIDTH = '500px';

interface DeleteSegmentDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError?: (err: string) => void;
  isThirdPartySegment?: boolean;
  segmentId?: number;
  useDemoData?: boolean;
}

export const DeleteSegmentDialog: FC<DeleteSegmentDialogProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
  isThirdPartySegment,
  segmentId,
  useDemoData = false,
}) => {
  const [segmentInUseDialogOpen, setSegmentInUseDialogOpen] = React.useState(false);
  const [campaignsUsed, setCampaignsUsed] = React.useState<CampaignUsingSegment[] | null>();
  const [journeysUsed, setJourneysUsed] = React.useState<JourneyUsingSegment[] | null>();
  const [integrationsUsed, setIntegrationsUsed] = React.useState<
    IntegrationUsingSegment[] | null
  >();
  const actionText = `${isThirdPartySegment ? 'Remove' : 'Delete'} segment`;
  const bodyText = isThirdPartySegment
    ? 'Make sure you have already deleted this segment in Marketplace settings or it will continue to populate within the Segments tab.'
    : "Deleting a segment is permanent, but it won't remove any subscribers from your list.";

  const onOpenChange = (openVal: boolean) => {
    if (!openVal) onClose();
  };

  const handleDelete = async () => {
    try {
      await deleteSegmentById(`${segmentId}`, useDemoData);
      onSuccess();
    } catch (err) {
      if (err instanceof SegmentInUseError) {
        setCampaignsUsed(err.campaigns);
        setJourneysUsed(err.journeys);
        setIntegrationsUsed(err.integrations);
        setSegmentInUseDialogOpen(true);
      }
      if (err instanceof SegmentUsageServicesUnavailableError) {
        setCampaignsUsed(null);
        setJourneysUsed(null);
        setIntegrationsUsed(null);
        setSegmentInUseDialogOpen(true);
      }
      if (onError) onError(err.message);
    }
  };

  if (!segmentId) {
    return null;
  }

  return (
    <>
      <StandardDialog open={open} onOpenChange={onOpenChange}>
        <StandardDialog.Content css={{ maxWidth: PU_DELETE_SEGMENT_DIALOG_WIDTH }}>
          <StandardDialog.Header>
            <StandardDialog.Heading>{actionText}</StandardDialog.Heading>
          </StandardDialog.Header>
          <StandardDialog.Body>
            <Text>{bodyText}</Text>
          </StandardDialog.Body>
          <StandardDialog.Footer>
            <Button variant="basic" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDelete}>
              {actionText}
            </Button>
          </StandardDialog.Footer>
        </StandardDialog.Content>
      </StandardDialog>
      <SegmentInUseErrorDialog
        open={segmentInUseDialogOpen}
        onOpenChange={setSegmentInUseDialogOpen}
        campaigns={campaignsUsed}
        journeys={journeysUsed}
        integrations={integrationsUsed}
      />
    </>
  );
};
