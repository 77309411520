import React, { FC } from 'react';

import { Button, FormField, StandardDialog, TextArea, TextInput } from '@attentive/picnic';

const PU_CREATE_SEGMENT_DIALOG_WIDTH = '544px';

interface SegmentEditTitleDialogProps {
  dialogTitle: string;
  onOpenChange: (val: boolean) => void;
  name: string;
  handleNameChange: (val: string) => void;
  description: string;
  handleDescriptionChange: (val: string) => void;
  handleSubmit: () => void;
  isSaving: boolean;
}

const SegmentEditTitleDialog: FC<SegmentEditTitleDialogProps> = ({
  dialogTitle,
  onOpenChange,
  name,
  handleNameChange,
  description,
  handleDescriptionChange,
  handleSubmit,
  isSaving,
}) => (
  <StandardDialog open onOpenChange={onOpenChange}>
    <StandardDialog.Content
      css={{ width: PU_CREATE_SEGMENT_DIALOG_WIDTH }}
      data-testid="segment-update-title-dialog"
    >
      <StandardDialog.Header>
        <StandardDialog.Heading>{dialogTitle}</StandardDialog.Heading>
      </StandardDialog.Header>
      <StandardDialog.Body>
        <FormField css={{ mb: '$space8' }}>
          <FormField.Label>Name</FormField.Label>
          <TextInput
            value={name}
            placeholder="e.g. VIP Customers"
            maxLength={64}
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </FormField>
        <FormField>
          <FormField.Label requirement="optional">Description</FormField.Label>
          <TextArea
            value={description}
            placeholder="e.g. High engagement customers"
            maxLength={1024}
            rows={5}
            onChange={(e) => handleDescriptionChange(e.target.value)}
          />
        </FormField>
      </StandardDialog.Body>
      <StandardDialog.Footer>
        <Button variant="basic" onClick={() => onOpenChange(false)}>
          {/*{text needs to be wrapped in span or will throw an error when using Google Translate https://issues.chromium.org/issues/41407169}*/}
          <span>Cancel</span>
        </Button>
        <Button onClick={handleSubmit} disabled={name === ''} loading={isSaving}>
          {/*{text needs to be wrapped in span or will throw an error when using Google Translate https://issues.chromium.org/issues/41407169}*/}
          <span>Save</span>
        </Button>
      </StandardDialog.Footer>
    </StandardDialog.Content>
  </StandardDialog>
);

export default SegmentEditTitleDialog;
