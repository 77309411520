import React from 'react';

import { DateRangePicker } from '@attentive/picnic';

import { SegmentParameters } from '../../../../../constants';
import { useKlaviyoEmailFeatureValidDate, useCompany } from '../../../../../hooks';
import { isKlaviyoEventType } from '../../../../../utils/typeAssertions';
import { PartialDateRangePicker } from '../../../../PartialDatePicker';
import { TimeValue } from '../constants';
import { convertSecondsToDateString, getIsDayBlockedFn, usesUtcDate } from '../utils';

const PU_DATE_RANGE_PICKER_MIN_WIDTH = '240px';

type DateRangeObject = {
  startDate: string | null;
  endDate: string | null;
};

type TimeSelectorDateRangePickerProps = {
  onChange: (dates: DateRangeObject) => void;
  parameters: SegmentParameters;
  values: TimeValue;
};

export const TimeSelectorDateRangePicker = ({
  onChange,
  parameters,
  values,
}: TimeSelectorDateRangePickerProps) => {
  const isPartialDate = Boolean(values.ignoreYear);
  const isKlaviyoEventFilter = isKlaviyoEventType(parameters);
  const { timezone } = useCompany();
  const useUtc = usesUtcDate(parameters);

  const { klaviyoEmailValidDate } = useKlaviyoEmailFeatureValidDate(isKlaviyoEventFilter);

  const isDayBlocked = getIsDayBlockedFn(isKlaviyoEventFilter ? klaviyoEmailValidDate : undefined);
  const startDateString = values.startTime
    ? convertSecondsToDateString(values.startTime, { useUtc, timezone })
    : null;
  const endDateString = values.endTime
    ? convertSecondsToDateString(values.endTime, { useUtc, timezone })
    : null;

  return isPartialDate ? (
    <PartialDateRangePicker
      startDate={startDateString}
      endDate={endDateString}
      onChange={onChange}
      css={{ minWidth: PU_DATE_RANGE_PICKER_MIN_WIDTH }}
      values={values}
    />
  ) : (
    <DateRangePicker
      startDate={startDateString}
      endDate={endDateString}
      onChange={onChange}
      isDayBlocked={isDayBlocked}
      size="small"
      css={{ minWidth: PU_DATE_RANGE_PICKER_MIN_WIDTH }}
      minimumNights={1}
    />
  );
};
