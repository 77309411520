/**
 * @generated SignedSource<<6dec46b6116953e91d2a897b3b8f4593>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageTable_TargetingCommon_CustomPropertyDefinition$data = {
  readonly id: string;
  readonly usages: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UsageTable_TableRow_TargetingCommon_Segment">;
  }>;
  readonly " $fragmentType": "UsageTable_TargetingCommon_CustomPropertyDefinition";
};
export type UsageTable_TargetingCommon_CustomPropertyDefinition$key = {
  readonly " $data"?: UsageTable_TargetingCommon_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageTable_TargetingCommon_CustomPropertyDefinition">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageTable_TargetingCommon_CustomPropertyDefinition",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Segment",
      "kind": "LinkedField",
      "name": "usages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UsageTable_TableRow_TargetingCommon_Segment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomPropertyDefinition",
  "abstractKey": null
};
})();

(node as any).hash = "ad0c428beb0969f8831c126013fb5b46";

export default node;
