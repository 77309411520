import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

type SegmentAssistantResponse = {
  userPrompt: string;
  systemResponse: string;
};

const segmentAssistantResponseAtom = atomWithReset<SegmentAssistantResponse>({
  userPrompt: '',
  systemResponse: '',
});

export const useIsSegmentCreatedWithAssistant = () => {
  const { userPrompt, systemResponse } = useAtomValue(segmentAssistantResponseAtom);

  return Boolean(userPrompt && systemResponse);
};

export const useSegmentAsssitantResponse = () => useAtomValue(segmentAssistantResponseAtom);

export const useResetSegmentAssistantResponse = () => useResetAtom(segmentAssistantResponseAtom);

export const useSetSegmentAssistantResponse = () => useSetAtom(segmentAssistantResponseAtom);
