import { toDate, format as formatWithTimezone, utcToZonedTime } from 'date-fns-tz';

export const dateToSeconds = (date: Date) => Math.floor(date.getTime() / 1000);
export const secondsToDate = (seconds: number) => new Date(seconds * 1000);

export const startOfDayInTimezone = (date: Date, timezone: string) =>
  getTimezoneDate(date, timezone, false);
export const endOfDayInTimezone = (date: Date, timezone: string) =>
  getTimezoneDate(date, timezone, true);

function getTimezoneDate(date: Date, timeZone: string, isEndOfDay: boolean) {
  const zonedTime = utcToZonedTime(date, timeZone);
  const dateWithoutTime = formatWithTimezone(zonedTime, 'yyyy-MM-dd', { timeZone });
  const startOfDayTime = '00:00:00';
  const endOfDayTime = '23:59:59';

  return toDate(`${dateWithoutTime} ${isEndOfDay ? endOfDayTime : startOfDayTime}`, { timeZone });
}

export function getTimezoneDisplayDate(seconds: number, timeZone: string) {
  const date = secondsToDate(seconds);

  const zonedTime = utcToZonedTime(date, timeZone);
  return formatWithTimezone(zonedTime, 'M/d/yy', { timeZone });
}
