import React, { CSSProperties, useCallback, useMemo } from 'react';

import { Box } from '@attentive/picnic';

import { DisplayableOption } from '../../../constants';
import { ComposeCampaign, CampaignMessageGroup, ComposeMessage } from '../constants';
import {
  getCMGSentTimeString,
  getDisplayableOption,
  getDisplayableOptionsFromMessageGroup,
  getMessageRowStatus,
} from '../utils';

import { CampaignRowComponent } from './CampaignRowComponent';
import { MessageRowComponent } from './MessageRowComponent';

interface CampaignDialogRowProps {
  campaign: ComposeCampaign;
  handleAddMessages: (optionValues: DisplayableOption[]) => void;
  handleRemoveMessages: (optionValues: DisplayableOption[]) => void;
  queryString: string;
  selectedMessageValues: Array<number | string>;
  style: CSSProperties;
  isCampaignExpanded: boolean;
  onCampaignExpandedChange: () => void;
}

const CampaignDialogRow: React.FC<CampaignDialogRowProps> = ({
  style,
  campaign,
  queryString,
  handleAddMessages,
  handleRemoveMessages,
  selectedMessageValues,
  isCampaignExpanded,
  onCampaignExpandedChange,
}) => {
  const companyTimezone = campaign.company?.timezone;
  const campaignMessageGroups = campaign.campaignMessageGroups?.edges;

  const isCampaignMessageGroupSelected = useCallback(
    (cmg: CampaignMessageGroup) => {
      const every = cmg.messageMembers.every(({ composeMessage }) =>
        selectedMessageValues.includes(composeMessage.messageId)
      );
      if (every) return true;

      const some = cmg.messageMembers.some(({ composeMessage }) =>
        selectedMessageValues.includes(composeMessage.messageId)
      );
      return some ? 'indeterminate' : false;
    },
    [selectedMessageValues]
  );

  const isCampaignSelected = useMemo(() => {
    const campaignMessageGroupSelections = campaignMessageGroups?.map(({ node: cmg }) =>
      isCampaignMessageGroupSelected(cmg)
    );
    if (campaignMessageGroupSelections?.every((selected) => selected === true)) {
      return true;
    }
    if (campaignMessageGroupSelections?.some((selected) => !!selected)) {
      return 'indeterminate';
    }
    return false;
  }, [campaignMessageGroups, isCampaignMessageGroupSelected]);

  if (!campaignMessageGroups) {
    return null;
  }

  const handleUpdateSelectedOptions = (
    selected: boolean | 'indeterminate',
    options: DisplayableOption[]
  ) => {
    if (selected) {
      handleRemoveMessages(options);
    } else {
      handleAddMessages(options);
    }
  };

  const handleMessageRowClick = (cmg: CampaignMessageGroup, message: ComposeMessage) => {
    const messageIsSelected = selectedMessageValues.includes(message.messageId);
    const option = getDisplayableOption(cmg, message);
    handleUpdateSelectedOptions(messageIsSelected, [option]);
  };

  const handleCampaignMessageGroupRowClick = (cmg: CampaignMessageGroup) => {
    const options: DisplayableOption[] = getDisplayableOptionsFromMessageGroup(cmg);
    handleUpdateSelectedOptions(isCampaignMessageGroupSelected(cmg), options);
  };

  const handleCampaignRowClick = () => {
    const campaignOptions: DisplayableOption[] = campaignMessageGroups.flatMap(({ node: cmg }) =>
      getDisplayableOptionsFromMessageGroup(cmg)
    );
    handleUpdateSelectedOptions(isCampaignSelected, campaignOptions);
  };

  const displayComposeCampaign = campaignMessageGroups.length > 1;
  const displayMessageGroups = isCampaignExpanded || !displayComposeCampaign;

  return (
    <Box
      style={style}
      key={campaign.id}
      css={{ height: style.height }}
      data-testid="campaign-dialog-row-wrapper"
    >
      {displayComposeCampaign && (
        <CampaignRowComponent
          isChecked={isCampaignSelected}
          onClickHandler={handleCampaignRowClick}
          campaign={campaign}
          queryString={queryString}
          isExpanded={isCampaignExpanded}
          onCampaignExpandedChange={onCampaignExpandedChange}
        />
      )}
      {displayMessageGroups &&
        campaignMessageGroups.map(({ node: cmg }) => {
          const cmgPillStatus = getMessageRowStatus(cmg.status);
          return (
            <Box key={cmg.id}>
              <MessageRowComponent
                containerCss={displayComposeCampaign ? { paddingLeft: '$space6' } : {}}
                isChecked={isCampaignMessageGroupSelected(cmg)}
                onClickHandler={() => handleCampaignMessageGroupRowClick(cmg)}
                primaryText={cmg.name}
                secondaryText={getCMGSentTimeString(cmg, companyTimezone, cmgPillStatus)}
                status={cmgPillStatus}
                queryString={queryString}
              />
              {cmg.messageMembers.length > 1 &&
                cmg.messageMembers.map(({ composeMessage: message }, idx) => (
                  <MessageRowComponent
                    key={message.messageId}
                    containerCss={{ paddingLeft: displayComposeCampaign ? '$space12' : '$space6' }}
                    isChecked={selectedMessageValues.includes(message.messageId)}
                    onClickHandler={() => handleMessageRowClick(cmg, message)}
                    primaryText={message.name}
                    secondaryText={`Variant ${String.fromCharCode(idx + 65)}`}
                    status={cmgPillStatus}
                    queryString={queryString}
                  />
                ))}
            </Box>
          );
        })}
    </Box>
  );
};

CampaignDialogRow.displayName = 'CampaignDialogRow';
export default CampaignDialogRow;
