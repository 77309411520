import { QuantityComparator } from '../../constants';

const quantityValueIsInvalid = (quantity?: number) => !(typeof quantity === 'number');

const quantityRangeIsInvalid = (quantity: number, quantityEnd: number) => quantity >= quantityEnd;

export const quantityIsInvalid = (
  quantity?: number,
  quantityComparator?: QuantityComparator,
  quantityEnd?: number
) => {
  if (!quantityComparator || quantity === undefined || quantityValueIsInvalid(quantity))
    return true;
  if (quantityComparator !== QuantityComparator.BETWEEN) return false;
  if (quantityEnd === undefined || quantityValueIsInvalid(quantityEnd)) return true;

  return quantityRangeIsInvalid(quantity, quantityEnd);
};
