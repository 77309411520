import React from 'react';

import { Box, PicnicCss } from '@attentive/picnic';

import { campaignRowHeight } from '../constants';
import { MessageRowStatus } from '../utils';

import { RowDeatilsComponent } from './RowDeatilsComponent';

interface MessageRowProps {
  containerCss?: PicnicCss;
  isChecked: boolean | 'indeterminate';
  onClickHandler: () => void;
  primaryText: string;
  queryString: string;
  secondaryText?: string | null;
  status?: MessageRowStatus;
}

export const MessageRowComponent: React.FC<MessageRowProps> = ({
  containerCss,
  isChecked,
  onClickHandler,
  primaryText,
  queryString,
  secondaryText,
  status,
}) => (
  <Box
    css={{
      '&:hover': {
        backgroundColor: '$bgRowHover',
      },
      ...containerCss,
    }}
    onClick={onClickHandler}
    role="button"
    data-testid="campaign-dialog-message-row"
  >
    <Box
      css={{
        height: `${campaignRowHeight}px`,
        borderBottom: '1px solid $borderDefault',
        padding: '$space0 $space4 $space0 $space9',
      }}
    >
      <RowDeatilsComponent
        isChecked={isChecked}
        primaryText={primaryText}
        secondaryText={secondaryText}
        status={status}
        queryString={queryString}
      />
    </Box>
  </Box>
);
