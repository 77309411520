import {
  ProductDataAttributeValue,
  StringValueDisplayableOption,
} from '../../../constants/interfaces';

const PRODUCT_OPTION_ATTRIBUTE = 'PRODUCT_OPTION';

export function optionMatchesAttribute(
  option: StringValueDisplayableOption,
  attribute: ProductDataAttributeValue
) {
  // Product options share the same optionValue and have different names
  if (option.optionValue === PRODUCT_OPTION_ATTRIBUTE) {
    return option.optionValue === attribute.attribute && option.displayName === attribute.name;
  }

  return option.optionValue === attribute.attribute;
}
