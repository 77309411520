import { endOfToday } from 'date-fns';

import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  CreativeCondition,
  DurationUnit,
  EmailActionFilter,
  FilterValue,
  HasVerbType,
  IsVerbType,
  JoinedActionFilter,
  KeywordCondition,
  ShopifyAction,
  OperatorComparator,
  PropertiesFilter,
  PunchhFilterAttrs,
  QuantityComparator,
  SegmentParameters,
  ShopifyActionFilter,
  SignUpMethodFilter,
  SiteCondition,
  SourceType,
  SubscriberPreference,
  TextActionFilter,
  TimeComparatorType,
  UserPropertyType,
  Vendor,
  YotpoFilterAttrs,
  VendorAttributeDataType,
  NestedPropertyFilterBase,
  RechargeFilterAttrs,
  WellKnownPropertyTypes,
} from '../../../../constants';
import { isMailExchangeType } from '../../../../utils/typeAssertions';
import {
  getSubscriberAttributeDataType,
  getVendorAttributeDataType,
  SubscriberAttributeDataTypeMap,
  VendorAttributeDataTypeMap,
} from '../../utils/attributeDataTypeMap';
import { dateToSeconds, endOfDayInTimezone } from '../../utils/dateTime';
import { splitNameSpacedAttribute } from '../../utils/stringUtils';
import { getDefaultStringComparator } from '../KeywordSelector/KeywordSelector';
import { RECHARGE_SUBSCRIPTION_STATUS_OPTIONS } from '../Recharge';

type SetFilterParametersArgs = {
  changedFilter: FilterValue;
  companyTimezone?: string;
  defaultEmailActionSource: ActionSource;
  displayName?: string;
  optionValue?: string;
  nestedOptionValue?: string;
  parameters: SegmentParameters;
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap;
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap;
  useExperimentalDefault?: boolean;
};

const withinTheLast365Default = {
  timeComparator: TimeComparatorType.IN_THE_LAST,
  durationUnit: DurationUnit.DAYS,
  durationTime: 365,
};

const overAllTimeDefault = {
  timeComparator: TimeComparatorType.OVER_ALL_TIME,
};

export const setFilterParameters = ({
  changedFilter,
  companyTimezone,
  defaultEmailActionSource,
  displayName,
  optionValue,
  nestedOptionValue,
  parameters,
  subscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap,
  useExperimentalDefault,
}: SetFilterParametersArgs): SegmentParameters => {
  const {
    carrier,
    deviceTypes,
    deviceOSList,
    fieldValues,
    frequencyComparator,
    hasVerb,
    isVerb,
    propertyAttribute,
    propertyAttributeCondition,
    subscriberActionSource,
    stateId,
  } = parameters;
  const defaultTimeDuration = useExperimentalDefault ? withinTheLast365Default : overAllTimeDefault;

  switch (changedFilter) {
    case ActionFilter.VISITED_SITE:
      return {
        subscriberAction: changedFilter,
        hasVerb: hasVerb || HasVerbType.HAS,
        propertyAttributeCondition: propertyAttributeCondition || SiteCondition.SPECIFIC_PAGE,
        ...defaultTimeDuration,
      };
    case JoinedActionFilter.TEXT:
    case JoinedActionFilter.EMAIL: {
      const [channel, actionFilter]: string[] = changedFilter.split(':');
      return {
        subscriberAction: actionFilter as ActionFilter,
        subscriberActionChannel: channel as ActionChannel,
        subscriberActionSource: ActionSource.ATTENTIVE,
        hasVerb: hasVerb || HasVerbType.HAS,
        ...defaultTimeDuration,
      };
    }
    case ActionFilter.PURCHASED:
    case ActionFilter.VIEWED:
    case ActionFilter.ADDED_TO_CART:
    case ActionFilter.ORDER_SUBSCRIPTION_CHARGE_PAID:
    case ActionFilter.ORDER_SUBSCRIPTION_PROCESSED:
      return {
        subscriberAction: changedFilter,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        ...defaultTimeDuration,
      };
    case ActionFilter.COMPLETED_REVIEW:
      return {
        subscriberAction: changedFilter,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        ...defaultTimeDuration,
        reviewFilter: {
          vendor: 'VENDOR_BAZAARVOICE',
          onlyIncludeReviewsWithMedia: false,
        },
      };
    case ActionFilter.LEFT_A_RATING:
      return {
        subscriberAction: changedFilter,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        ...defaultTimeDuration,
        reviewFilter: {
          vendor: 'VENDOR_BAZAARVOICE',
          onlyIncludeReviewsWithMedia: false,
          ratingComparator: {
            comparator: QuantityComparator.EQUAL_TO,
          },
        },
      };
    case ShopifyActionFilter.REFUND_SUCCEEDED:
    case ShopifyActionFilter.CHECKOUT_ABANDONED: {
      const [, attributeValue]: string[] = splitNameSpacedAttribute(changedFilter);
      return {
        subscriberAction: attributeValue as ShopifyAction,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        ...defaultTimeDuration,
      };
    }
    case ShopifyActionFilter.PURCHASED: {
      const [, attributeValue]: string[] = splitNameSpacedAttribute(changedFilter);
      return {
        subscriberAction: attributeValue as ActionFilter,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        isGroupedByOrderId: true,
        ...defaultTimeDuration,
      };
    }
    case SignUpMethodFilter.EMAIL: {
      const [channel, propertyFilter]: string[] = changedFilter.split(':');
      return {
        isVerb: isVerb || IsVerbType.IS,
        propertyAttribute: SourceType.CREATIVE,
        propertyAttributeCondition: CreativeCondition.SPECIFIC_CREATIVE,
        subscriberActionChannel: channel as ActionChannel,
        subscriberActionSource: ActionSource.ATTENTIVE,
        subscriberProperty: propertyFilter as PropertiesFilter,
        fieldValues,
      };
    }
    case SignUpMethodFilter.TEXT: {
      const [channel, propertyFilter]: string[] = changedFilter.split(':');
      return {
        isVerb: isVerb || IsVerbType.IS,
        propertyAttribute: propertyAttribute || SourceType.KEYWORD,
        propertyAttributeCondition: propertyAttributeCondition || KeywordCondition.ANY_KEYWORD,
        subscriberActionChannel: channel as ActionChannel,
        subscriberActionSource: ActionSource.ATTENTIVE,
        subscriberProperty: propertyFilter as PropertiesFilter,
        fieldValues,
      };
    }
    case PropertiesFilter.STATE:
    case PropertiesFilter.CITY:
    case PropertiesFilter.LEGACY_POSTAL_CODE:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
        stateId: stateId || '',
        locationValues: undefined,
      };
    case PropertiesFilter.BULK_POSTAL_CODES:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
        locations: [],
      };
    case PropertiesFilter.DISTANCE:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
      };
    case PropertiesFilter.COUNTRY:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
        locationValues: [],
      };
    case PropertiesFilter.DEVICE_TYPE:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
        deviceTypes: deviceTypes || [],
      };
    case PropertiesFilter.OPERATING_SYSTEM:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
        deviceOSList: deviceOSList || [],
      };
    case PropertiesFilter.CARRIER:
      return {
        subscriberProperty: changedFilter,
        isVerb: isVerb || IsVerbType.IS,
        carrier: carrier || '',
      };
    case SubscriberPreference.SUBSCRIBER_PREFERENCES:
      return {
        isVerb: isVerb || IsVerbType.IS,
        subscriberPreferenceKey: optionValue || '',
        subscriberPreferenceValue: '',
      };
    case UserPropertyType.CUSTOM_PROPERTY_ID:
      return getUserPropertyParameters({
        attribute: optionValue,
        displayName,
        subscriberAttributeDataTypeMap,
        userPropertyType: changedFilter,
      });
    case UserPropertyType.CUSTOM_EVENT_TYPE:
      return {
        isVerb: isVerb || IsVerbType.IS,
        customEventOption: { customEventType: optionValue as string, customEventValues: [] },
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        ...defaultTimeDuration,
      };
    case UserPropertyType.WELL_KNOWN_PROPERTY:
      return getUserPropertyParameters({
        attribute: optionValue,
        nestedAttribute: nestedOptionValue,
        subscriberAttributeDataTypeMap,
        userPropertyType: changedFilter,
      });
    case TextActionFilter.CLICKED:
    case TextActionFilter.SENT: {
      const [channel, actionFilter]: string[] = changedFilter.split(':');
      return {
        subscriberAction: actionFilter as ActionFilter,
        subscriberActionChannel: channel as ActionChannel,
        subscriberActionSource: ActionSource.ATTENTIVE,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        ...defaultTimeDuration,
      };
    }
    case TextActionFilter.SENT_MESSAGE: {
      const [channel, actionFilter]: string[] = changedFilter.split(':');
      return {
        subscriberAction: actionFilter as ActionFilter,
        subscriberActionChannel: channel as ActionChannel,
        subscriberActionSource: ActionSource.ATTENTIVE,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        stringComparator: getDefaultStringComparator(),
        ...defaultTimeDuration,
      };
    }
    case EmailActionFilter.SENT:
    case EmailActionFilter.OPENED:
    case EmailActionFilter.CLICKED: {
      const [channel, actionFilter]: string[] = changedFilter.split(':');
      return {
        subscriberAction: actionFilter as ActionFilter,
        subscriberActionChannel: channel as ActionChannel,
        subscriberActionSource: subscriberActionSource || defaultEmailActionSource,
        hasVerb: hasVerb || HasVerbType.HAS,
        frequencyComparator: frequencyComparator || OperatorComparator.AT_LEAST_ONCE,
        timeComparator: TimeComparatorType.IN_THE_LAST,
        durationTime: 90,
        durationUnit: DurationUnit.DAYS,
      };
    }
    case YotpoFilterAttrs.POINTS_EXPIRE_AT: {
      // POINTS_EXPIRE_AT has a different comparator than other timestamps
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const [, attributeValue]: string[] = splitNameSpacedAttribute(changedFilter!);
      return {
        profileAttribute: attributeValue,
        vendor: Vendor.YOTPO,
        isVerb: isVerb || IsVerbType.IS,
        timeComparator: TimeComparatorType.IN_THE_NEXT,
        durationUnit: DurationUnit.DAYS,
        durationTime: 0,
      };
    }
    case YotpoFilterAttrs.BIRTHDAY_MONTH: {
      // BIRTHDAY_MONTH uses a different comparator than other quantities
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const [, attributeValue]: string[] = splitNameSpacedAttribute(changedFilter!);
      return {
        profileAttribute: attributeValue,
        vendor: Vendor.YOTPO,
        isVerb: isVerb || IsVerbType.IS,
        quantityComparator: QuantityComparator.EQUAL_TO,
      };
    }
    case PunchhFilterAttrs.CHECKINS:
    case PunchhFilterAttrs.FAVORITE_LOCATIONS: {
      // CHECKINS and FAVORITE_LOCATIONS use list but we support them with a plain text field
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const [, attributeValue]: string[] = splitNameSpacedAttribute(changedFilter!);
      return {
        profileAttribute: attributeValue,
        vendor: Vendor.PUNCHH,
        isVerb: isVerb || IsVerbType.IS,
        textValues: [],
      };
    }
    case changedFilter?.match(Vendor.PROFILE_VENDOR_KLAVIYO)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.PROFILE_VENDOR_KLAVIYO,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    case changedFilter?.match(Vendor.SEGMENT_IO)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.SEGMENT_IO,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    case changedFilter?.match(Vendor.PUNCHH)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.PUNCHH,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    case changedFilter?.match(Vendor.MPARTICLE)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.MPARTICLE,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    case changedFilter?.match(Vendor.SMILE)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.SMILE,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    case changedFilter?.match(Vendor.RECHARGE)?.input: {
      return getThirdPartyUserPropertyParameters({
        filter: changedFilter,
        vendor: Vendor.RECHARGE,
        vendorAttributeDataTypeMap,
        displayName,
      });
    }
    case changedFilter?.match(Vendor.YOTPO)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.YOTPO,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    case changedFilter?.match(Vendor.SHOPIFY)?.input: {
      return getVendorCustomAttributesParameters(
        changedFilter,
        Vendor.SHOPIFY,
        vendorAttributeDataTypeMap,
        companyTimezone
      );
    }
    default:
      throw new Error(`Unhandled filter ${changedFilter}`);
  }
};

function getThirdPartyUserPropertyParameters({
  displayName,
  filter,
  vendor,
  vendorAttributeDataTypeMap,
}: {
  displayName?: string;
  filter?: FilterValue;
  vendor: Vendor;
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap;
}): SegmentParameters {
  if (!filter) {
    throw new Error('Filter value is not set');
  }

  const [, parentAttribute, childAttribute]: string[] = filter.split(':');
  const namespacedAttribute = `${parentAttribute}:${childAttribute}`;

  const { attributeDataType: parentAttributeDataType } = getVendorAttributeDataType(
    vendor,
    parentAttribute,
    vendorAttributeDataTypeMap
  );
  const {
    attributeDataType: childAttributeDataType,
    isBoolean,
    isFloat,
    isInteger,
    isText,
    isTimestamp,
  } = getVendorAttributeDataType(vendor, namespacedAttribute, vendorAttributeDataTypeMap);

  const baseParameters: SegmentParameters = {
    userPropertyType: UserPropertyType.THIRD_PARTY_PROPERTY,
    attribute: parentAttribute,
    propertyDataType: parentAttributeDataType as VendorAttributeDataType,
    vendor,
  };

  const baseNestedParameters: NestedPropertyFilterBase = {
    fieldName: childAttribute,
    fieldDataType: childAttributeDataType as VendorAttributeDataType,
    displayName: displayName || '',
  };

  if (isBoolean) {
    return {
      ...baseParameters,
      nestedPropertyFilters: [
        {
          ...baseNestedParameters,
          booleanCondition: {
            value: true,
          },
        },
      ],
    };
  }

  if (isFloat || isInteger) {
    return {
      ...baseParameters,
      nestedPropertyFilters: [
        {
          ...baseNestedParameters,
          numericCondition: {
            comparator: QuantityComparator.MORE_THAN,
            // TODO: sc - make sure this default makes sense. we don't want auto validation
            quantity: 0,
          },
        },
      ],
    };
  }

  if (isText) {
    const isStatusField = childAttribute === RechargeFilterAttrs.STATUS;

    const defaultValues = isStatusField ? [RECHARGE_SUBSCRIPTION_STATUS_OPTIONS.ACTIVE] : [];
    const quantityFields = isStatusField
      ? { quantityComparator: QuantityComparator.EQUAL_TO, quantity: 1 }
      : {};

    return {
      ...baseParameters,
      ...quantityFields,
      nestedPropertyFilters: [
        {
          ...baseNestedParameters,
          stringCondition: {
            verb: IsVerbType.IS,
            values: defaultValues,
          },
        },
      ],
    };
  }

  if (isTimestamp) {
    const today = endOfToday();

    return {
      ...baseParameters,
      nestedPropertyFilters: [
        {
          ...baseNestedParameters,
          timeCondition: {
            comparator: TimeComparatorType.AFTER,
            epochTime: Math.floor(today.getTime() / 1000),
          },
        },
      ],
    };
  }

  return baseParameters;
}

function getUserPropertyParameters({
  attribute,
  nestedAttribute,
  displayName,
  subscriberAttributeDataTypeMap,
  userPropertyType,
}: {
  attribute: string | undefined;
  nestedAttribute?: string;
  displayName?: string;
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap;
  userPropertyType: UserPropertyType;
}) {
  if (!attribute || !userPropertyType) {
    throw new Error('Missing property type');
  }

  const baseParameters: SegmentParameters = {
    attribute,
    userPropertyType,
  };

  if (isMailExchangeType({ attribute })) {
    return {
      ...baseParameters,
      isVerb: IsVerbType.IS,
      wellKnownUserPropertyValues: [],
    };
  }

  const isWellKnownProperty = userPropertyType === UserPropertyType.WELL_KNOWN_PROPERTY;
  const isCustomAttribute = userPropertyType === UserPropertyType.CUSTOM_PROPERTY_ID;
  const isLocale = attribute === WellKnownPropertyTypes.LOCALE;

  const { isBoolean, isEnum, isInteger, isText, isTimestamp } = getSubscriberAttributeDataType(
    attribute,
    subscriberAttributeDataTypeMap
  );

  if (isCustomAttribute) {
    baseParameters.displayName = displayName;
  }

  if (isBoolean) {
    return {
      ...baseParameters,
      isVerb: IsVerbType.IS,
      textValue: '',
    };
  }

  if (isInteger) {
    return {
      ...baseParameters,
      isVerb: IsVerbType.IS,
      quantityComparator: QuantityComparator.MORE_THAN,
    };
  }

  if (isText && isWellKnownProperty) {
    return {
      ...baseParameters,
      textValues: [],
      isVerb: IsVerbType.IS,
    };
  }

  if (isLocale && nestedAttribute) {
    return {
      ...baseParameters,
      propertyDataType: VendorAttributeDataType.OBJECT,
      nestedPropertyFilters: [
        {
          fieldName: nestedAttribute,
          displayName: nestedAttribute,
          fieldDataType: VendorAttributeDataType.TEXT,
          stringCondition: {
            verb: IsVerbType.IS,
            values: [],
          },
        },
      ],
    };
  }

  if ((isText || isEnum) && isCustomAttribute) {
    return {
      ...baseParameters,
      userPropertyValues: [],
    };
  }

  // User properties expect a UTC 00:00:00 timestamp
  if (isTimestamp) {
    const todayUtcMidnight = new Date();
    todayUtcMidnight.setUTCHours(0, 0, 0, 0);

    return {
      ...baseParameters,
      timeComparator: TimeComparatorType.AFTER,
      time: dateToSeconds(todayUtcMidnight),
      isVerb: IsVerbType.IS,
    };
  }

  throw new Error(`Unsupported filter ${attribute}`);
}

function getVendorCustomAttributesParameters(
  filter: FilterValue,
  vendor: Vendor,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap,
  timezone?: string
) {
  if (!filter) {
    throw new Error('Filter value is not set');
  }

  const [, attributeValue]: string[] = splitNameSpacedAttribute(filter);

  const baseParameters = {
    profileAttribute: attributeValue,
    vendor,
  };

  const { isBoolean, isFloat, isInteger, isText, isTimestamp } = getVendorAttributeDataType(
    vendor,
    attributeValue,
    vendorAttributeDataTypeMap
  );

  if (isBoolean) {
    return {
      ...baseParameters,
      isVerb: IsVerbType.IS,
      textValue: '',
    };
  }

  if (isFloat || isInteger) {
    return {
      ...baseParameters,
      isVerb: IsVerbType.IS,
      quantityComparator: QuantityComparator.MORE_THAN,
    };
  }

  if (isText) {
    return {
      ...baseParameters,
      textValues: [],
      isVerb: IsVerbType.IS,
    };
  }

  if (isTimestamp) {
    return {
      ...baseParameters,
      timeComparator: TimeComparatorType.AFTER,
      time: timezone
        ? dateToSeconds(endOfDayInTimezone(new Date(), timezone))
        : dateToSeconds(endOfToday()),
      isVerb: IsVerbType.IS,
    };
  }

  return baseParameters;
}
