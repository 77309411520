import React, { useEffect, useRef, useState } from 'react';

import { PicnicCss, styled } from '@attentive/picnic';

import { Tag } from './Tag';

const PU_TAG_VERTICAL_MARGIN = '3px';

const TagWrapper = styled('div', {
  border: '$borderWidths$borderWidth1 solid $borderInput',
  alignItems: 'center',
  cursor: 'text',
  borderRadius: '$radius1',
  focusVisible: '$focus',
  padding: '$space1 $space3',
  display: 'flex',
  flexWrap: 'wrap',
});

const TagTextInput = styled('input', {
  backgroundColor: '$bgDefault',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flex: 1,
  minWidth: '175px',
  flexDirection: 'column',
  justifyContent: 'stretch',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  variants: {
    size: {
      small: {
        fontSize: '$fontSize2',
        lineHeight: '$lineHeight6',
        fontFeatureSettings: `'ss01' on, 'ss02' on, 'ss05' on`,
        padding: '0',
        // minHeight needs to subtract border that's set on the parent <div> (1px border on both sides)
        minHeight: 'calc($size7 - ($borderWidths$borderWidth1 * 2))',
      },
      normal: {
        fontSize: '$fontSize3',
        lineHeight: '$lineHeight7',
        fontFeatureSettings: `'ss01' on, 'ss02' on, 'ss05' on`,
        padding: '0 $space1',
        minHeight: 'calc($size10 - ($borderWidths$borderWidth1 * 2))',
      },
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});

type TagState = 'VALID' | 'INVALID' | 'INDETERMINATE';

export type TagObject = {
  value: string;
  state: TagState;
};

type TagValue = TagObject | string;

type TagComponentProps = {
  value: TagValue[];
  onChange: (tags: TagValue[]) => void;
  onDelete?: (tag: TagValue) => void;
  id?: string;
  textFormatter?: (tag: string) => string;
  onValidateInput?: (
    value: string,
    setInputValue: (value: string) => void,
    addValueToTags: (value: string) => void,
    submitInput?: boolean
  ) => void;
  disabled?: boolean;
  placeholder?: string;
  css?: PicnicCss;
  size: 'small' | 'normal';
};

export const TagSelector = ({
  disabled,
  onChange,
  onDelete,
  onValidateInput,
  id,
  textFormatter = (str) => str,
  value = [],
  css = {},
  placeholder,
  size,
  ...rest
}: TagComponentProps) => {
  const [inputValue, setInputValue] = useState('');
  const [grabFocus, setGrabFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAdd = React.useCallback(
    (item: string) => {
      setInputValue('');
      // Only add if the input isn't empty and it doesn't already exist
      if (item.trim().length > 0 && !value.includes(item)) {
        onChange([...value, item]);
      }
    },
    [onChange, value]
  );

  const handleUpdateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: eventValue } = e.target;
    if (onValidateInput) {
      onValidateInput(eventValue, setInputValue, handleAdd);
      return;
    }

    setInputValue(eventValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (onValidateInput) {
        onValidateInput(inputValue, setInputValue, handleAdd, true);
      } else {
        handleAdd(inputValue);
      }
      setGrabFocus(true);
      return;
    }
  };

  const handleOnBlur = () => {
    if (inputValue) {
      if (onValidateInput) {
        onValidateInput(inputValue, setInputValue, handleAdd, true);
      } else {
        handleAdd(inputValue);
      }
    }
    setGrabFocus(false);
    return;
  };

  const handleDelete = React.useCallback(
    (index: number) => {
      if (onDelete) {
        onDelete(value[index]);
      }
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    },
    [onChange, onDelete, value]
  );

  useEffect(() => {
    if (value && inputRef.current && grabFocus) {
      inputRef.current.focus();
    }
  }, [grabFocus, value]);

  const focusOnInput = () => {
    inputRef.current?.focus();
  };

  const cssOverride = {
    ...css,
    padding: value.length > 0 && size === 'small' ? '$space0 $space3' : '$space1 $space3',
  };

  return (
    <TagWrapper onClick={focusOnInput} css={cssOverride} {...rest}>
      {value.map((val, index) => {
        let tagValue: string;
        let key: string;
        let state: TagState = 'VALID';

        if (typeof val === 'string') {
          tagValue = val;
          key = val;
        } else {
          tagValue = val.value;
          state = val.state;
          key = `${val.value}-${val.state}`;
        }

        const variant = state === 'INVALID' ? 'error' : 'default';
        const isTagDisabled = disabled || state === 'INDETERMINATE';

        return (
          <Tag
            key={key}
            onDelete={() => handleDelete(index)}
            disabled={isTagDisabled}
            content={textFormatter(tagValue)}
            css={{ margin: `${PU_TAG_VERTICAL_MARGIN} $space1`, flex: 'none' }}
            variant={variant}
            data-state={state}
          />
        );
      })}
      <TagTextInput
        ref={inputRef}
        aria-label="tag selector input"
        id={id}
        onChange={handleUpdateInput}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        value={inputValue}
        disabled={disabled}
        placeholder={value.length ? undefined : placeholder}
        size={size}
      />
    </TagWrapper>
  );
};
