import _usePaginatedJourneys_TargetingCommon_Company from "./__generated__/usePaginatedJourneys_TargetingCommon_Company.graphql";
import { useEffect } from 'react';
import { usePaginationFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { JOURNEYS_PER_PAGE } from '../JourneyCondition';
import CompanyFragment from './__generated__/usePaginatedJourneys_TargetingCommon_Company.graphql';
/**
 *
 * This hook is used in conjunction with useFilteredJourneys and useJourneyMessages hooks.
 * It allows fetching X journeys per request. It will continue to fetch until hasNext is false
 *
 * @param companyRef
 * @returns
 */

export function usePaginatedJourneys(companyRef) {
  const {
    data,
    hasNext,
    loadNext,
    isLoadingNext
  } = usePaginationFragment(CompanyFragment, companyRef);
  useEffect(() => {
    if (hasNext) {
      loadNext(JOURNEYS_PER_PAGE);
    }
  }, [data, hasNext, loadNext]);
  return {
    data: data.journeys,
    isLoading: isLoadingNext || hasNext
  };
}
_usePaginatedJourneys_TargetingCommon_Company;