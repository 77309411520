import React from 'react';

import { usePermission } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';
import { Icon } from '@attentive/picnic';

interface OwnProps {
  hideBadge?: boolean;
}

const SuperUserBadge: React.FC<OwnProps> = ({ hideBadge }) => {
  const superUser = usePermission(Permission.SuperUserAccess);
  const showIcon = superUser && !hideBadge;

  return showIcon ? <Icon name="ShieldPerson" size="extraSmall" css={{ ml: '$space2' }} /> : null;
};

export default SuperUserBadge;
