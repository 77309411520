import React from 'react';

import { getInitialCustomAttributeFormValues } from '../../form';
import { FormDialog, FormDialogProps } from '../FormDialog';

import { useCreateCustomAttributeMutation } from './useCreateCustomAttributeMutation';

type NewCustomAttributeDialogProps = Omit<FormDialogProps, 'initialFormValues' | 'saveForm'>;

export const NewCustomAttributeDialog: React.VFC<NewCustomAttributeDialogProps> = (props) => {
  const [saveForm] = useCreateCustomAttributeMutation();
  const initialValues = getInitialCustomAttributeFormValues(props.supportEnumAttributeOnly);

  return <FormDialog {...props} initialFormValues={initialValues} saveForm={saveForm} />;
};
