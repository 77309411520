import {
  ShopifyFilter,
  QuantityTypes,
  ShopifyOptions,
  YotpoFilterAttrs,
  StringValueDisplayableOption,
  PunchhFilterAttrs,
  NumberValueDisplayableOption,
} from '../../constants';
import { ProductAttribute } from '../ProductDataDialog';

export const PRODUCT_OPTION_ATTR = 'PRODUCT_OPTION';

export enum FilterSelectPlaceholder {
  ACTION = 'Choose activity',
  ATTRIBUTE = 'Choose characteristic',
  MEMBERSHIP = 'Choose segment',
}

export enum ConditionSource {
  API = 'API',
  ATTENTIVE = 'Attentive',
  BAZAARVOICE = 'Bazaarvoice',
  KLAVIYO = 'Klaviyo',
  MAILCHIMP = 'Mailchimp',
  MPARTICLE = 'mParticle',
  PUNCHH = 'Punchh',
  RECHARGE = 'Recharge',
  SEGMENT_IO = 'Segment',
  SHOPIFY = 'Shopify',
  SMILE = 'Smile',
  YOTPO = 'Yotpo',
}

export enum ConditionIcon {
  SEGMENT_IO = 'SegmentIOColor',
}

export const shopifyDynamicSelectors = new Set<ShopifyFilter>([
  ShopifyFilter.CUSTOMER_STATE,
  ShopifyFilter.CURRENCY,
  ShopifyFilter.CUSTOMER_TAG,
  ShopifyFilter.MARKETING_OPT_IN_LEVEL,
]);

export const shopifySelectorsOptionsMap: {
  [key: string]: ShopifyOptions;
} = {
  [ShopifyFilter.ACCEPTS_MARKETING]: ShopifyOptions.ACCEPTS_MARKETING_OPTIONS,
  [ShopifyFilter.CUSTOMER_STATE]: ShopifyOptions.CUSTOMER_STATE_OPTIONS,
  [ShopifyFilter.CURRENCY]: ShopifyOptions.CURRENCY_OPTIONS,
  [ShopifyFilter.CUSTOMER_TAG]: ShopifyOptions.CUSTOMER_TAG_OPTIONS,
  [ShopifyFilter.MARKETING_OPT_IN_LEVEL]: ShopifyOptions.MARKETING_OPT_IN_LEVEL_OPTIONS,
};

interface QuantityType {
  [key: string]: QuantityTypes;
}

export const quantityType: QuantityType = {
  [ShopifyFilter.ORDERS_COUNT]: 'number',
  [ShopifyFilter.LIFETIME_SPEND]: 'amount',
  [YotpoFilterAttrs.TOTAL_SPEND_CENTS]: 'amount',
  [YotpoFilterAttrs.TOTAL_PURCHASES]: 'number',
  [YotpoFilterAttrs.PERKS_REDEEMED]: 'number',
  [YotpoFilterAttrs.POINTS_BALANCE]: 'number',
  [YotpoFilterAttrs.BIRTHDAY_YEAR]: 'year',
};

export const yotpoSingleValueSelectors = new Set<YotpoFilterAttrs>([
  YotpoFilterAttrs.VIP_TIER_NAME,
]);

export const punchhFreeTextInputSelectors = new Set<PunchhFilterAttrs>([
  PunchhFilterAttrs.CHECKINS,
  PunchhFilterAttrs.FAVORITE_LOCATIONS,
]);

export const punchhMultiValueSelectors = new Set<PunchhFilterAttrs>([
  PunchhFilterAttrs.GENDER,
  PunchhFilterAttrs.SIGNUP_CHANNEL,
  PunchhFilterAttrs.PREFERRED_LOCALE,
  PunchhFilterAttrs.CITY,
  PunchhFilterAttrs.STATE,
  PunchhFilterAttrs.ZIP_CODE,
]);

// Because we use the `journeys/product-attributes` in the dialog, we need to translate our attribute names
// to journey's
export const DialogAttributesMap: Record<string, ProductAttribute> = {
  PRODUCT_NAME: ProductAttribute.NAME,
  PRODUCT_TAG: ProductAttribute.TAG,
  PRODUCT_COLOR: ProductAttribute.COLOR,
  PRODUCT_CATEGORY: ProductAttribute.CATEGORY,
  PRODUCT_BRAND: ProductAttribute.BRAND,
  PRODUCT_SIZE: ProductAttribute.SIZE,
  PRODUCT_COLLECTION: ProductAttribute.COLLECTION,
  PRODUCT_OPTION: ProductAttribute.OPTION,
};

export const conditionCategories = {
  BAZAARVOICE: 'Bazaarvoice',
  CUSTOM_ATTRIBUTES: 'Custom attributes',
  CUSTOM_EVENTS: 'Custom Events',
  DEVICE: 'Device',
  EMAIL: 'Email',
  LOCATION: 'Location',
  MPARTICLE: 'mParticle',
  PREFERENCES: 'Preferences',
  PUNCHH: 'Punchh',
  RECHARGE: 'Recharge',
  PROFILE: 'Profile',
  PROPERTIES: 'Properties',
  SEGMENT_IO: 'Segment.io',
  SHOPPING: 'Shopping',
  SMILE: 'Smile',
  TEXT: 'Text messages',
  YOTPO: 'Yotpo',
};

// we remove the product attribute options related to product variants, when the filter is Viewed product
export const productAttrsExcludedFromViewAction = [
  'PRODUCT_COLOR',
  'PRODUCT_OPTION',
  'PRODUCT_PRICE',
  'PRODUCT_SIZE',
];

export const birthdayMonthOptions: StringValueDisplayableOption[] = [
  { optionValue: '1', displayName: 'January' },
  { optionValue: '2', displayName: 'February' },
  { optionValue: '3', displayName: 'March' },
  { optionValue: '4', displayName: 'April' },
  { optionValue: '5', displayName: 'May' },
  { optionValue: '6', displayName: 'June' },
  { optionValue: '7', displayName: 'July' },
  { optionValue: '8', displayName: 'August' },
  { optionValue: '9', displayName: 'September' },
  { optionValue: '10', displayName: 'October' },
  { optionValue: '11', displayName: 'November' },
  { optionValue: '12', displayName: 'December' },
];

// This is a version of birthDayMonthOptions with number values instead of string values for optionValue
export const birthMonthNumberOptions: NumberValueDisplayableOption[] = birthdayMonthOptions.map(
  (option) => ({ ...option, optionValue: Number(option.optionValue) })
);
