import React, { FC } from 'react';

import { Select, SelectProps } from '@attentive/picnic';

interface SelectComponentFallbackProps extends Partial<SelectProps> {
  placeholder: string;
}
export const SelectComponentFallback: FC<SelectComponentFallbackProps> = ({
  placeholder,
  ...args
}) => {
  return (
    <Select
      value={undefined}
      placeholder={placeholder}
      onChange={() => {}}
      disabled={true}
      aria-label={placeholder}
      {...args}
    />
  );
};
