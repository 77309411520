import papaparse from 'papaparse';

type CsvRow = Record<string, string | number | boolean>;
type CsvRows = CsvRow[];

/**
 *
 * @param rows - an array of objects representing the rows in a CSV. The object keys are used
 * as headers and should be consistent across all of the objects in the array.
 */
export function downloadDataAsCsv(rows: CsvRows) {
  const fileUrl = getCsvUrl(rows);
  triggerDownload(fileUrl);
}

export function triggerDownload(url: string) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function getCsvUrl(rows: CsvRows) {
  const csv = papaparse.unparse(rows, { delimiter: ',', header: true });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  return window.URL.createObjectURL(blob);
}
