import React, { useState } from 'react';

import { Box, FormField, Icon, InputGroup, Select, Text, TextInput } from '@attentive/picnic';

import { PRODUCT_PRICE_USD, ValueComparator } from '../../../../constants';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import { trimCurrencyInput } from '../../utils/formUtils';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface OwnProps {
  comparator: ValueComparator;
  onComparatorChange: (value: ValueComparator) => void;
  currency?: string;
  value?: number;
  onValueChange: (value: number | undefined) => void;
}

function PriceSelector({
  comparator,
  onComparatorChange,
  currency,
  value,
  onValueChange,
}: OwnProps) {
  const showValidation = useShowExpressionValidationAtomValue();
  const [currentValue, setCurrentValue] = useState(value?.toFixed(2));
  const error = showValidation && !value;

  const updatePrice = (newValue: string) => {
    if (newValue) {
      onValueChange(Number(newValue));
    } else {
      onValueChange(undefined);
    }
  };

  const handlePriceChange = (newValue: string) => {
    const trimmedInput = trimCurrencyInput(newValue);
    setCurrentValue(trimmedInput);
    updatePrice(trimmedInput);
  };

  return (
    <>
      <Box>
        <Select
          size="small"
          value={comparator}
          onChange={(newComp: string) => onComparatorChange(newComp as ValueComparator)}
        >
          <Select.Item value={ValueComparator.EQUAL_TO}>Equals</Select.Item>
          <Select.Item value={ValueComparator.LESS_THAN}>Is less than</Select.Item>
          <Select.Item value={ValueComparator.MORE_THAN}>Is more than</Select.Item>
        </Select>
      </Box>
      {currency && (
        <SegmentConditionItemContainer>
          <Text>{currency}</Text>
        </SegmentConditionItemContainer>
      )}
      <FormField>
        <InputGroup>
          {currency === PRODUCT_PRICE_USD && (
            <InputGroup.LeftElement>
              <Icon size="small" name="Dollar" />
            </InputGroup.LeftElement>
          )}
          <TextInput
            size="small"
            type="number"
            placeholder="0"
            step="0.01"
            value={currentValue || ''}
            state={error ? 'error' : 'normal'}
            onChange={(e) => handlePriceChange(e.target.value)}
          />
        </InputGroup>
        {error && <FormField.ErrorText>Please enter a price</FormField.ErrorText>}
      </FormField>
    </>
  );
}

export default PriceSelector;
