import React, { FC, useEffect } from 'react';

import { CountryCode } from '@attentive/locale-utils';
import { FormField, MultiSelect, SearchableSelect } from '@attentive/picnic';

import { LocationType, LocationValue, NumberValueDisplayableOption } from '../../../constants';
import { useLocationData } from '../../../hooks';
import { getStateLabel } from '../../../utils';
import { getCountryId } from '../utils/getCountryId';

import { SingleCountrySelector } from './Country';
import LocationSelector from './LocationSelector';
import PUFieldLoader from './PUFieldLoader';
import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

const OPTION_STALE_TIME = 30000;

interface LocationSelectorProps {
  countries: NumberValueDisplayableOption[];
  country: string | null;
  locationType: LocationType;
  onChange: (value: LocationValue) => void;
  states: NumberValueDisplayableOption[];
  values: LocationValue;
}

interface StateDropdownProps
  extends Omit<LocationSelectorProps, 'countries' | 'locationType' | 'values'> {
  multiSelect: boolean;
  stateId: string;
  value: string[];
  countryId?: string;
  isLoading: boolean;
  isCountryWithoutStates?: boolean;
}

type ReactQueryOptions = {
  staleTime: number;
  enabled: boolean;
  initialData?: NumberValueDisplayableOption[];
};

const NO_STATES_AVAILABLE = 'No states available';

const StateDropdown: FC<StateDropdownProps> = ({
  country,
  countryId,
  multiSelect,
  onChange,
  stateId,
  states,
  value,
  isLoading,
  isCountryWithoutStates,
}) => {
  const showValidation = useShowExpressionValidationAtomValue();

  if (multiSelect) {
    // Wait until loading is finished to pass value into MultiSelect
    const currentValue = isLoading || isCountryWithoutStates ? [] : value;
    const disabled = isLoading || isCountryWithoutStates;
    const placeholder = isCountryWithoutStates
      ? NO_STATES_AVAILABLE
      : `Choose ${getStateLabel(country)}s`;

    return (
      <MultiSelect
        value={currentValue}
        onChange={(newValues: string[]) =>
          onChange({
            countryId,
            locationValues: newValues,
            locationValue: undefined,
          })
        }
        placeholder={placeholder}
        state={showValidation && !value.length ? 'error' : 'normal'}
        size="small"
        disabled={disabled}
      >
        {states.map((state) => (
          <MultiSelect.Item key={state.optionValue} value={`${state.optionValue}`}>
            {state.displayName}
          </MultiSelect.Item>
        ))}
      </MultiSelect>
    );
  }

  // Don't pass the current stateId if there are no states. This makes sure the placeholder text is displayed.
  const currentValue = isCountryWithoutStates ? '' : stateId;
  const placeholderText = isCountryWithoutStates
    ? NO_STATES_AVAILABLE
    : `Choose ${getStateLabel(country)}`;

  return (
    <SearchableSelect
      value={currentValue}
      placeholder={placeholderText}
      state={showValidation && !stateId ? 'error' : 'normal'}
      size="small"
      disabled={isCountryWithoutStates}
      onChange={(newValue: string) =>
        onChange({
          countryId,
          stateId: newValue,
          locationValues: [],
          locationValue: undefined,
        })
      }
    >
      {states.map((state) => (
        <SearchableSelect.Item key={state.optionValue} value={`${state.optionValue}`}>
          {state.displayName}
        </SearchableSelect.Item>
      ))}
    </SearchableSelect>
  );
};

const StateSelector: FC<LocationSelectorProps> = ({
  countries,
  country,
  locationType,
  onChange,
  states,
  values,
}) => {
  const isState = locationType === LocationType.STATE;
  const stateId = values.stateId || '';
  const companyCountryId = getCountryId(country as CountryCode, countries);
  const USCountryId = getCountryId(CountryCode.US, countries);
  const selectedCountryId = values.countryId ? `${values.countryId}` : companyCountryId;
  const selectedValues = values.locationValues || [];
  const hasSelectedState = isState ? Boolean(selectedValues.length) : Boolean(stateId);

  const showValidation = useShowExpressionValidationAtomValue();

  const queryOptions: ReactQueryOptions = {
    staleTime: OPTION_STALE_TIME,
    // if the selected country is the US, we can use the states returned by segment-options on load
    enabled: selectedCountryId !== USCountryId,
  };

  // The BE is currently sending US states for all companies regardless of the company's country
  // If the US is the selected country, we can place the states data from segment-options in the cache to prevent re-fetches
  if (selectedCountryId === USCountryId) {
    queryOptions.initialData = states;
  }

  const { data, isLoading } = useLocationData(
    selectedCountryId,
    LocationType.COUNTRY,
    LocationType.STATE,
    queryOptions
  );

  // When a country has no states, the BE returns a single result with an empty display name.
  const isCountryWithoutStates = !isLoading && data?.length === 1 && data[0].displayName === '';

  useEffect(() => {
    if (isCountryWithoutStates) {
      // If the country has no states, we need to set stateId to the returned option value.
      const newStateId = (data && data[0].optionValue) || '';
      onChange({
        countryId: selectedCountryId,
        stateId: `${newStateId}`,
        locationValues: [],
        locationValue: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountryWithoutStates]);

  return (
    <>
      <FormField>
        <SingleCountrySelector
          countries={countries}
          onChange={(countryId) => onChange({ countryId, stateId: '' })}
          selectedCountryId={selectedCountryId}
        />
      </FormField>
      <FormField css={{ position: 'relative' }}>
        <StateDropdown
          country={country}
          multiSelect={isState}
          onChange={onChange}
          stateId={stateId}
          countryId={selectedCountryId}
          states={data || []}
          value={selectedValues}
          isLoading={isLoading}
          isCountryWithoutStates={isCountryWithoutStates}
        />
        {isLoading && <PUFieldLoader />}
        {showValidation && !hasSelectedState && (
          <FormField.ErrorText>{`Please choose a ${getStateLabel(country)}`}</FormField.ErrorText>
        )}
      </FormField>
      {!isState && selectedValues && (
        <LocationSelector
          country={country}
          locationType={locationType}
          onChange={(newValue) =>
            onChange({
              countryId: selectedCountryId,
              stateId,
              locationValues: newValue,
              locationValue: undefined,
            })
          }
          stateId={stateId}
          value={selectedValues}
        />
      )}
    </>
  );
};

export default StateSelector;
