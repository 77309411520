import { OperatorComparator } from '../../constants';

export const frequencyComparatorIsInvalid = (
  freqComparator?: OperatorComparator,
  frequency?: number
) => {
  return (
    freqComparator &&
    [
      OperatorComparator.EQUAL_TO,
      OperatorComparator.LESS_THAN,
      OperatorComparator.MORE_THAN,
    ].includes(freqComparator) &&
    Number(frequency || 0) === 0
  );
};
