import React from 'react';

import { Text } from '@attentive/picnic';

import {
  NestedPropertyTimeCondition,
  SegmentParameters,
  TimeComparatorType,
  relativeDateRangeTimeComparators,
} from '../../../../constants';
import { getRechargeNamespacedFilter } from '../../../../utils/typeAssertions';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import { TimeSelector } from '../TimeSelector';

import { getNestedFilter } from './utils';

interface RechargeTimestampSelectorProps {
  onChange: (parameters: SegmentParameters) => void;
  parameters: SegmentParameters;
}

export function RechargeTimestampSelector({
  onChange,
  parameters,
}: RechargeTimestampSelectorProps) {
  if (!parameters.nestedPropertyFilters) {
    throw new Error('Nested properties are missing');
  }

  const rootAttributeKey = getRechargeNamespacedFilter(parameters);
  const [, childAttributeName] = rootAttributeKey.split(':');

  return (
    <>
      <SegmentConditionItemContainer>
        <Text>is</Text>
      </SegmentConditionItemContainer>
      <TimeSelector
        parameters={parameters}
        getDateValues={getRechargeDateValues}
        onChange={(timeValues) => {
          if (!parameters || !parameters.nestedPropertyFilters) {
            throw new Error('Missing parameters');
          }

          const [currentFilter, currentNestedFilters] =
            getNestedFilter<NestedPropertyTimeCondition>(childAttributeName, parameters);

          const updated = {
            comparator: timeValues.timeComparator,
            epochTime:
              timeValues.timeComparator !== TimeComparatorType.BETWEEN
                ? timeValues.time
                : timeValues.startTime,
            epochTimeEnd: timeValues.endTime,
            units: timeValues.durationUnit,
            duration: !relativeDateRangeTimeComparators.includes(timeValues.timeComparator)
              ? timeValues.durationTime
              : timeValues.durationStart,
            durationEnd: timeValues.durationEnd,
          };

          onChange({
            ...parameters,
            nestedPropertyFilters: [
              ...currentNestedFilters,
              { ...currentFilter, timeCondition: updated },
            ],
          });
        }}
      />
    </>
  );
}

function getRechargeDateValues(parameters: SegmentParameters) {
  const rootAttributeKey = getRechargeNamespacedFilter(parameters);
  const [, childAttributeName] = rootAttributeKey.split(':');

  const [{ timeCondition }] = getNestedFilter<NestedPropertyTimeCondition>(
    childAttributeName,
    parameters
  );

  const { comparator, epochTime, epochTimeEnd, duration, durationEnd, units } = timeCondition;

  return {
    timeComparator: comparator,
    time: epochTime,
    startTime: epochTime,
    endTime: epochTimeEnd,
    durationUnit: units,
    durationTime: duration,
    durationStart: duration,
    durationEnd,
  };
}
