/**
 * @generated SignedSource<<f34b7ec185e4a013e61eed7be6a05f37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition$data = {
  readonly __typename: "CustomPropertyDefinition";
  readonly id: string;
  readonly name: string;
  readonly propertyType: CustomPropertyType;
  readonly typeDefinition: {
    readonly " $fragmentSpreads": FragmentRefs<"formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition">;
  } | null;
  readonly " $fragmentType": "formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition";
};
export type formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition$key = {
  readonly " $data"?: formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition"
};

(node as any).hash = "f59e7c9a6f9f580221c0c77c50a168ec";

export default node;
