import React, { FC } from 'react';

import { Box, PicnicCss, Text } from '@attentive/picnic';

interface TextSectionProps {
  textSection: string;
  isQueryMatch: boolean;
}

const buildRegex = (searchQuery: string) => {
  return new RegExp(`(${searchQuery})`, 'gi');
};

const splitTextBySearchQuery = (text: string, searchQuery: string) => {
  const textSectionArr: TextSectionProps[] = [];
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
  const sanitizedQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const searchRegex = buildRegex(sanitizedQuery);
  const splitTextStringArr = text.split(searchRegex);
  splitTextStringArr.map((section: string) => {
    return textSectionArr.push({ textSection: section, isQueryMatch: searchRegex.test(section) });
  });
  return textSectionArr;
};

const searchQueryContainerBaseCss: PicnicCss = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginRight: '$space2',
};

interface SearchQueryHighlightedTextProps {
  itemName: string;
  searchQuery: string;
  subtext?: string;
  isSelected?: boolean;
}

const SearchQueryHighlightedText: FC<SearchQueryHighlightedTextProps> = ({
  itemName,
  searchQuery,
  isSelected,
  subtext,
}) => {
  const hasSearchQuery = searchQuery.length > 0;

  const containerCss = isSelected
    ? { ...searchQueryContainerBaseCss, color: '$textDisabled' }
    : searchQueryContainerBaseCss;

  return (
    <Box css={containerCss}>
      {hasSearchQuery
        ? splitTextBySearchQuery(itemName, searchQuery).map((section, idx) => {
            const key = `${section.textSection}-${idx}`;
            return (
              <Box
                as="span"
                key={key}
                data-testid={section.isQueryMatch ? 'highlighted-search-query' : ''}
                css={section.isQueryMatch ? { backgroundColor: '$bgHighlighted' } : {}}
              >
                {section.textSection}
              </Box>
            );
          })
        : itemName}
      {subtext && (
        <Text variant="micro" color="subdued">
          {subtext}
        </Text>
      )}
    </Box>
  );
};

SearchQueryHighlightedText.displayName = 'SearchQueryHighlightedText';
export default SearchQueryHighlightedText;
