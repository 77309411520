import React, { useState, Suspense } from 'react';

import { DisplayableOption, SelectableCampaignType } from '../../constants';
import { ItemSelectDialog } from '../ItemSelectDialog';
import { MessageDialogButtonPreloadedQuery } from '../SegmentCreateEditModal/components/MessageEngagement/components/CampaignMessageSelector/MessageDialogButton';

import { CampaignDialogBody } from './components/CampaignDialogBody';
import { KlaviyoCampaignDialogBody } from './components/KlaviyoCampaignDialogBody';
import { campaignRowHeight } from './constants';

interface CampaignDialogProps {
  /**
   * Function to fire on close of the dialog
   */
  onClose: () => void;
  /**
   * Function that is fired on submit. It is passed the message options that were selected
   */
  onSubmit: (selections: DisplayableOption[]) => void;
  /**
   * Options that have already been selected
   */
  selectedOptions: DisplayableOption[];
  /**
   * The type of campaigns to fetch and select from
   * @default is 'ATTENTIVE'
   */
  selectableCampaignType?: SelectableCampaignType;
  queryRef: MessageDialogButtonPreloadedQuery;
}

const CampaignDialog: React.FC<CampaignDialogProps> = ({
  onClose,
  onSubmit,
  selectedOptions,
  selectableCampaignType = 'ATTENTIVE_TEXT',
  queryRef,
}) => {
  const isKlaviyo = selectableCampaignType === 'KLAVIYO_EMAIL';

  const [searchString, setSearchString] = useState('');
  const [selections, setSelections] = useState(selectedOptions);
  const [hasCampaigns, setHasCampaigns] = useState<boolean | undefined>(undefined);
  const initialLoad = hasCampaigns === undefined;

  const handleClose = () => {
    onClose();
  };

  const handleDoneClick = () => {
    onSubmit(selections);
  };

  const handleClearAll = () => {
    setSelections([]);
  };

  const handleAddMessages = (optionsToAdd: DisplayableOption[]) => {
    setSelections([...selections, ...optionsToAdd]);
  };

  const handleRemoveMessages = (optionsToRemove: DisplayableOption[]) => {
    const optionValuesToRemove = optionsToRemove.map((option) => option.optionValue);

    const newSelectedMessageOptions = selections.filter((messageOption) => {
      return !optionValuesToRemove.includes(messageOption.optionValue);
    });
    setSelections(newSelectedMessageOptions);
  };

  return (
    <ItemSelectDialog
      heading="Add campaigns to your segment"
      onClose={handleClose}
      open
      onSubmit={handleDoneClick}
      rowHeight={campaignRowHeight}
    >
      <ItemSelectDialog.SearchField
        searchText={searchString}
        onSearchTextChange={setSearchString}
        disabled={!hasCampaigns}
      />
      <Suspense fallback={<ItemSelectDialog.LoadingIndicator />}>
        {isKlaviyo ? (
          <KlaviyoCampaignDialogBody
            searchString={searchString}
            selections={selections}
            handleAddMessages={handleAddMessages}
            handleRemoveMessages={handleRemoveMessages}
            setHasCampaigns={setHasCampaigns}
          />
        ) : (
          <CampaignDialogBody
            queryRef={queryRef}
            selectableCampaignType={selectableCampaignType}
            searchString={searchString}
            selections={selections}
            handleAddMessages={handleAddMessages}
            handleRemoveMessages={handleRemoveMessages}
            setHasCampaigns={setHasCampaigns}
          />
        )}
      </Suspense>
      {(hasCampaigns || initialLoad) && (
        <ItemSelectDialog.SelectedItems
          selectedOptions={selections}
          onRemoveSelection={(option) => {
            handleRemoveMessages([option]);
          }}
          onClearAll={handleClearAll}
          itemTypeLabel="Campaigns"
        />
      )}
    </ItemSelectDialog>
  );
};

CampaignDialog.displayName = 'CampaignDialog';
export default CampaignDialog;
