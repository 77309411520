import React, { FC } from 'react';

import { Box, IconButton, PicnicCss, Text } from '@attentive/picnic';

import { IsVerbType, SalesChannelValue } from '../../../../constants';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import VerbSelector, { VerbSelectorType } from '../../components/VerbSelector';

interface OwnProps {
  salesChannelValue: SalesChannelValue;
  onRemove: () => void;
  onChange: (newSalesChannel: SalesChannelValue) => void;
  isFirst: boolean;
  css?: PicnicCss;
}

const SalesChannelCondition: FC<OwnProps> = ({
  salesChannelValue,
  onRemove,
  onChange,
  isFirst,
  css,
}) => {
  const { isVerb, values } = salesChannelValue;
  // right now only one sales channel is supported
  const { displayName } = values[0];

  return (
    <Box css={{ display: 'flex', alignItems: 'start', ...css }}>
      <SegmentConditionItemContainer>
        <Text css={{ mr: '$space4' }}>{`${isFirst ? 'where' : 'and'} sales channel`}</Text>
      </SegmentConditionItemContainer>
      <VerbSelector
        verbType={VerbSelectorType.IS}
        value={isVerb}
        onChange={(verb) => onChange({ ...salesChannelValue, isVerb: verb as IsVerbType })}
      />
      <SegmentConditionItemContainer>
        <Text css={{ mr: '$space4', ml: '$space4' }}>{displayName}</Text>
      </SegmentConditionItemContainer>
      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description="Remove sales channel"
          onClick={onRemove}
        />
      </SegmentConditionItemContainer>
    </Box>
  );
};

export default SalesChannelCondition;
