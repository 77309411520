import React from 'react';

import { Box, FormField, MultiSelect, Select } from '@attentive/picnic';

import { ActionChannel, CreativeCondition, StringValueDisplayableOption } from '../../../constants';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface SourceKeywordValue {
  condition: CreativeCondition;
  creatives?: string[];
}

interface SourceCreativeSelectorProps {
  value: SourceKeywordValue;
  creativeOptions: Record<string, number>;
  onChange: (value: SourceKeywordValue) => void;
  subscriberActionChannel?: ActionChannel;
}

const textOptions: StringValueDisplayableOption[] = [
  { optionValue: CreativeCondition.ANY_MOBILE_CREATIVE, displayName: 'Any mobile sign-up unit' },
  { optionValue: CreativeCondition.ANY_DESKTOP_CREATIVE, displayName: 'Any desktop sign-up unit' },
  { optionValue: CreativeCondition.SPECIFIC_CREATIVE, displayName: 'A specific sign-up unit' },
];

const emailOptions: StringValueDisplayableOption[] = [
  { optionValue: CreativeCondition.SPECIFIC_CREATIVE, displayName: 'A specific sign-up unit' },
];

const SourceCreativeSelector = ({
  value,
  creativeOptions,
  onChange,
  subscriberActionChannel,
}: SourceCreativeSelectorProps) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !value?.creatives?.length;
  const options = subscriberActionChannel === ActionChannel.TEXT ? textOptions : emailOptions;

  const onConditionChange = (newValue: string) => {
    const isAnyMobile = newValue === CreativeCondition.ANY_MOBILE_CREATIVE;
    const isAnyDesktop = newValue === CreativeCondition.ANY_DESKTOP_CREATIVE;

    const newSourceKeywordValue: SourceKeywordValue = {
      condition: newValue as CreativeCondition,
      ...(!isAnyMobile && !isAnyDesktop && { creatives: [] }),
    };

    onChange(newSourceKeywordValue);
  };

  return (
    <>
      <Box>
        <Select value={value.condition} onChange={onConditionChange} size="small">
          {options.map((option) => (
            <Select.Item key={option.optionValue} value={option.optionValue}>
              {option.displayName}
            </Select.Item>
          ))}
        </Select>
      </Box>
      {value.condition === CreativeCondition.SPECIFIC_CREATIVE && (
        <FormField>
          <MultiSelect
            value={value.creatives || []}
            onChange={(newValues: string[]) => onChange({ ...value, creatives: newValues })}
            size="small"
            maxTokens={10}
            placeholder="Choose sign-up units"
            state={error ? 'error' : 'normal'}
          >
            {Object.keys(creativeOptions).map((name) => (
              <MultiSelect.Item key={name} value={String(creativeOptions[name])}>
                {name}
              </MultiSelect.Item>
            ))}
          </MultiSelect>
          {error && <FormField.ErrorText>Please choose sign-up units.</FormField.ErrorText>}
        </FormField>
      )}
    </>
  );
};

export default SourceCreativeSelector;
