import React from 'react';

import { Box, Heading, Icon, PicnicCss } from '@attentive/picnic';

import { CampaignUsingSegment, JourneyUsingSegment } from '../../../constants';
import { SegmentInUse } from '../../SegmentInUse';

interface OwnProps {
  campaigns?: CampaignUsingSegment[] | null;
  journeys?: JourneyUsingSegment[] | null;
}

const circleContainerCss: PicnicCss = {
  background: '$bgWarningDefault',
  borderRadius: '$radiusMax',
  width: '$size10',
  height: '$size10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mb: '$space4',
};

const promptTitle = {
  inUse: 'This segment is in use!',
  servicesUnavailable: "We're sorry!",
};

const SegmentInUsePrompt = ({ campaigns, journeys }: OwnProps) => {
  return (
    <Box
      css={{
        border: '$borderWidths$borderWidth1 solid $borderLoud',
        borderRadius: '$radius1',
        p: '$space12',
      }}
    >
      <Box css={circleContainerCss}>
        <Icon name="CircleError" color="critical" />
      </Box>
      <Heading variant="md" css={{ mb: '$space4' }}>
        {campaigns || journeys ? promptTitle.inUse : promptTitle.servicesUnavailable}
      </Heading>
      <SegmentInUse campaigns={campaigns} journeys={journeys} />
    </Box>
  );
};

export default SegmentInUsePrompt;
