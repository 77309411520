import {
  PRODUCT_PRICE_ATTR,
  UserPropertyType,
  FilterType,
  SegmentParameters,
} from '../../../constants';
import { isRechargeAttributeType } from '../../../utils/typeAssertions';

export const getInitialFilterType = (parameters: SegmentParameters) => {
  const {
    customEventOption,
    customAttributeValueGroup,
    profileAttribute,
    subscriberAction,
    subscriberListMetadataId,
    subscriberPreferenceKey,
    subscriberProperty,
    userPropertyType,
    tagKey,
  } = parameters;

  if (subscriberAction || customEventOption) {
    return FilterType.ACTION;
  }

  if (isRechargeAttributeType(parameters)) {
    return FilterType.ATTRIBUTE;
  }

  if (profileAttribute || customAttributeValueGroup || subscriberPreferenceKey) {
    return FilterType.ATTRIBUTE;
  }

  if (userPropertyType && userPropertyType === UserPropertyType.CUSTOM_PROPERTY_ID) {
    return FilterType.ATTRIBUTE;
  }

  if (userPropertyType && userPropertyType === UserPropertyType.WELL_KNOWN_PROPERTY) {
    return FilterType.ATTRIBUTE;
  }

  if (subscriberProperty) {
    if (subscriberListMetadataId || tagKey) {
      return FilterType.MEMBERSHIP;
    }
    return FilterType.ATTRIBUTE;
  }
};

export const isPriceAttribute = (attribute: string) => {
  return attribute === PRODUCT_PRICE_ATTR;
};
