import React from 'react';

import { DatePicker } from '@attentive/picnic';

import { SegmentParameters } from '../../../../../constants';
import { useKlaviyoEmailFeatureValidDate, useCompany } from '../../../../../hooks';
import { isKlaviyoEventType } from '../../../../../utils/typeAssertions';
import { PartialDatePicker } from '../../../../PartialDatePicker';
import { TimeValue } from '../constants';
import { convertSecondsToDateString, getIsDayBlockedFn, usesUtcDate } from '../utils';

const PU_DATE_PICKER_MIN_WIDTH = '140px';

type TimeSelectorDatePickerProps = {
  onChange: (date: string) => void;
  parameters: SegmentParameters;
  values: TimeValue;
};

export const TimeSelectorDatePicker = ({
  onChange,
  parameters,
  values,
}: TimeSelectorDatePickerProps) => {
  const isPartialDate = Boolean(values.ignoreYear);
  const isKlaviyoEventFilter = isKlaviyoEventType(parameters);
  const { timezone } = useCompany();
  const useUtc = usesUtcDate(parameters);

  const { klaviyoEmailValidDate } = useKlaviyoEmailFeatureValidDate(isKlaviyoEventFilter);

  const isDayBlocked = getIsDayBlockedFn(isKlaviyoEventFilter ? klaviyoEmailValidDate : undefined);
  const dateString = values.time
    ? convertSecondsToDateString(values.time, { useUtc, timezone })
    : null;

  return isPartialDate ? (
    <PartialDatePicker
      onChange={onChange}
      value={dateString}
      css={{ minWidth: PU_DATE_PICKER_MIN_WIDTH }}
    />
  ) : (
    <DatePicker
      value={dateString}
      onChange={onChange}
      isDayBlocked={isDayBlocked}
      size="small"
      css={{ minWidth: PU_DATE_PICKER_MIN_WIDTH }}
    />
  );
};
