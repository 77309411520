import { UserPropertyType, SubscriberPreference } from './index';

export enum ActionChannel {
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

export enum ActionFilter {
  JOINED = 'JOINED',
  CLICKED = 'CLICKED',
  PURCHASED = 'PURCHASED',
  VIEWED = 'VIEWED_PRODUCT',
  VISITED_SITE = 'VISITED_SITE',
  ADDED_TO_CART = 'ADDED_TO_CART',
  OPENED = 'OPENED',
  ORDER_SUBSCRIPTION_CHARGE_PAID = 'ORDER_SUBSCRIPTION_CHARGE_PAID',
  ORDER_SUBSCRIPTION_PROCESSED = 'ORDER_SUBSCRIPTION_PROCESSED',
  SENT = 'SENT',
  SENT_MESSAGE = 'SENT_MESSAGE',
  COMPLETED_REVIEW = 'COMPLETED_REVIEW',
  LEFT_A_RATING = 'LEFT_A_RATING',
}

export const actionFiltersWithFrequency = [
  ActionFilter.PURCHASED,
  ActionFilter.VIEWED,
  ActionFilter.ADDED_TO_CART,
  ActionFilter.ORDER_SUBSCRIPTION_CHARGE_PAID,
  ActionFilter.ORDER_SUBSCRIPTION_PROCESSED,
  ActionFilter.COMPLETED_REVIEW,
  ActionFilter.LEFT_A_RATING,
];

export enum ActionSource {
  ATTENTIVE = 'ATTENTIVE',
  KLAVIYO = 'KLAVIYO',
  SHOPIFY = 'SHOPIFY',
}

export enum DurationUnit {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum JoinedActionFilter {
  TEXT = 'TEXT:JOINED',
  EMAIL = 'EMAIL:JOINED',
}

export enum TextActionFilter {
  CLICKED = 'TEXT:CLICKED',
  SENT = 'TEXT:SENT',
  SENT_MESSAGE = 'TEXT:SENT_MESSAGE',
}

export enum EmailActionFilter {
  CLICKED = 'EMAIL:CLICKED',
  OPENED = 'EMAIL:OPENED',
  SENT = 'EMAIL:SENT',
}

export enum KnownKlaviyoProfileAttr {
  CITY = 'PROFILE_VENDOR_KLAVIYO:$city',
  COUNTRY = 'PROFILE_VENDOR_KLAVIYO:$country',
  EMAIL = 'PROFILE_VENDOR_KLAVIYO:$email',
  FIRST_NAME = 'PROFILE_VENDOR_KLAVIYO:$first_name',
  LAST_NAME = 'PROFILE_VENDOR_KLAVIYO:$last_name',
  ORGANIZATION = 'PROFILE_VENDOR_KLAVIYO:$organization',
  PHONE_NUMBER = 'PROFILE_VENDOR_KLAVIYO:$phone_number',
  PHONE_NUMBER_REGION = 'PROFILE_VENDOR_KLAVIYO:$phone_number_region',
  REGION = 'PROFILE_VENDOR_KLAVIYO:$region',
  TIMEZONE = 'PROFILE_VENDOR_KLAVIYO:$timezone',
  ZIP = 'PROFILE_VENDOR_KLAVIYO:$zip',
  // this one has no $, it is known nonetheless
  CREATED = 'PROFILE_VENDOR_KLAVIYO:created',
}

export type KlaviyoProfileFilter = `${Vendor.PROFILE_VENDOR_KLAVIYO}:${string}`;

export enum PropertiesFilter {
  BULK_POSTAL_CODES = 'BULK_POSTAL_CODES',
  CARRIER = 'CARRIER',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  DEVICE_TYPE = 'DEVICE_TYPE',
  DISTANCE = 'BULK_ADJACENT_POSTAL_CODES',
  LEGACY_POSTAL_CODE = 'POSTAL_CODE',
  MANUAL_UPLOAD_LIST = 'MANUAL_UPLOAD_LIST',
  OPERATING_SYSTEM = 'OPERATING_SYSTEM',
  THIRD_PARTY_LIST = 'THIRD_PARTY_LIST',
  SOURCE = 'SOURCE',
  STATE = 'STATE',
  SUBSCRIBER_TAG = 'SUBSCRIBER_TAG',
}

export enum SignUpMethodFilter {
  EMAIL = 'EMAIL:SOURCE',
  TEXT = 'TEXT:SOURCE',
}

export enum ShopifyAction {
  ORDER_CONFIRMED = 'ORDER_CONFIRMED',
  REFUND_SUCCEEDED = 'REFUND_SUCCEEDED',
  CHECKOUT_ABANDONED = 'CHECKOUT_ABANDONED',
}

export enum ShopifyActionFilter {
  PURCHASED = 'SHOPIFY:PURCHASED',
  ORDER_CONFIRMED = 'SHOPIFY:ORDER_CONFIRMED',
  REFUND_SUCCEEDED = 'SHOPIFY:REFUND_SUCCEEDED',
  CHECKOUT_ABANDONED = 'SHOPIFY:CHECKOUT_ABANDONED',
}

export enum ShopifyFilter {
  ACCEPTS_MARKETING = 'SHOPIFY:ACCEPTS_MARKETING',
  ACCEPTS_MARKETING_UPDATED_TIME = 'SHOPIFY:ACCEPTS_MARKETING_UPDATED_TIME',
  CREATED_TIME = 'SHOPIFY:CREATED_TIME',
  CURRENCY = 'SHOPIFY:CURRENCY',
  CUSTOMER_STATE = 'SHOPIFY:CUSTOMER_STATE',
  CUSTOMER_TAG = 'SHOPIFY:CUSTOMER_TAG',
  MARKETING_OPT_IN_LEVEL = 'SHOPIFY:MARKETING_OPT_IN_LEVEL',
  ORDERS_COUNT = 'SHOPIFY:ORDERS_COUNT',
  LIFETIME_SPEND = 'SHOPIFY:LIFETIME_SPEND',
  UPDATED_TIME = 'SHOPIFY:UPDATED_TIME',
}

export enum ShopifyOptions {
  ACCEPTS_MARKETING_OPTIONS = 'acceptsMarketingOptions',
  CURRENCY_OPTIONS = 'currencyOptions',
  CUSTOMER_STATE_OPTIONS = 'customerStateOptions',
  CUSTOMER_TAG_OPTIONS = 'customerTagOptions',
  MARKETING_OPT_IN_LEVEL_OPTIONS = 'marketingOptInLevelOptions',
}

// This is the vendor name used within segmentation
export enum Vendor {
  MPARTICLE = 'PROFILE_VENDOR_MPARTICLE',
  PROFILE_VENDOR_KLAVIYO = 'PROFILE_VENDOR_KLAVIYO',
  PUNCHH = 'PROFILE_VENDOR_PUNCHH',
  RECHARGE = 'VENDOR_RECHARGE',
  SEGMENT_IO = 'PROFILE_VENDOR_SEGMENT',
  SMILE = 'PROFILE_VENDOR_SMILE_IO',
  SHOPIFY = 'SHOPIFY',
  YOTPO = 'PROFILE_VENDOR_YOTPO',
}

// This is the vendor name returned by segmentableAttributesByVendor endpoint
export type VendorAPIName =
  | 'MPARTICLE'
  | 'KLAVIYO'
  | 'VENDOR_PUNCHH'
  | 'VENDOR_RECHARGE'
  | 'VENDOR_SEGMENT'
  | 'SHOPIFY'
  | 'VENDOR_SMILE_IO'
  | 'VENDOR_YOTPO';

// This map is used for converting the api name to the expected segmentation name
export const VendorAPIMap: Record<VendorAPIName, Vendor> = {
  MPARTICLE: Vendor.MPARTICLE,
  KLAVIYO: Vendor.PROFILE_VENDOR_KLAVIYO,
  VENDOR_PUNCHH: Vendor.PUNCHH,
  VENDOR_RECHARGE: Vendor.RECHARGE,
  VENDOR_SEGMENT: Vendor.SEGMENT_IO,
  SHOPIFY: Vendor.SHOPIFY,
  VENDOR_SMILE_IO: Vendor.SMILE,
  VENDOR_YOTPO: Vendor.YOTPO,
};

export const VendorDisplayMap: Record<Vendor, string> = {
  [Vendor.MPARTICLE]: 'mParticle',
  [Vendor.PROFILE_VENDOR_KLAVIYO]: 'Klaviyo',
  [Vendor.PUNCHH]: 'Punchh',
  [Vendor.RECHARGE]: 'Recharge',
  [Vendor.SEGMENT_IO]: 'Segment',
  [Vendor.SHOPIFY]: 'Shopify',
  [Vendor.SMILE]: 'Smile',
  [Vendor.YOTPO]: 'Yotpo',
};

export enum YotpoFilterAttrs {
  BIRTHDAY_MONTH = 'PROFILE_VENDOR_YOTPO:birthday_month',
  BIRTHDAY_YEAR = 'PROFILE_VENDOR_YOTPO:birthday_year',
  LAST_PURCHASED_AT = 'PROFILE_VENDOR_YOTPO:last_purchase_at',
  OPTED_IN_AT = 'PROFILE_VENDOR_YOTPO:opted_in_at',
  PERKS_REDEEMED = 'PROFILE_VENDOR_YOTPO:perks_redeemed',
  POINTS_BALANCE = 'PROFILE_VENDOR_YOTPO:points_balance',
  POINTS_EXPIRE_AT = 'PROFILE_VENDOR_YOTPO:points_expire_at',
  TOTAL_PURCHASES = 'PROFILE_VENDOR_YOTPO:total_purchases',
  TOTAL_SPEND_CENTS = 'PROFILE_VENDOR_YOTPO:total_spend_cents',
  VIP_TIER_NAME = 'PROFILE_VENDOR_YOTPO:vip_tier_name',
}

export const yotpoSupportedAttributes = [
  YotpoFilterAttrs.BIRTHDAY_MONTH,
  YotpoFilterAttrs.BIRTHDAY_YEAR,
  YotpoFilterAttrs.LAST_PURCHASED_AT,
  YotpoFilterAttrs.OPTED_IN_AT,
  YotpoFilterAttrs.PERKS_REDEEMED,
  YotpoFilterAttrs.POINTS_BALANCE,
  YotpoFilterAttrs.POINTS_EXPIRE_AT,
  YotpoFilterAttrs.TOTAL_PURCHASES,
  YotpoFilterAttrs.TOTAL_SPEND_CENTS,
  YotpoFilterAttrs.VIP_TIER_NAME,
];

// The following are either not correctly typed profile attrs or not supported as custom attributes
export const segmentIoUnsupportedAttributes = ['plan', 'logins', 'birthday'];

export enum KnownSegmentIoFilterAttrs {
  AGE = `PROFILE_VENDOR_SEGMENT:age`,
  BIRTHDAY = `PROFILE_VENDOR_SEGMENT:birthday`,
  CREATED_AT = `PROFILE_VENDOR_SEGMENT:created_at`,
  LOGINS = `PROFILE_VENDOR_SEGMENT:logins`,
  PLAN = `PROFILE_VENDOR_SEGMENT:plan`,
  GENDER = `PROFILE_VENDOR_SEGMENT:gender`,
}

export const SegmentIoSupportedProfileAttrs = [
  KnownSegmentIoFilterAttrs.AGE,
  KnownSegmentIoFilterAttrs.CREATED_AT,
  KnownSegmentIoFilterAttrs.GENDER,
];

export enum PunchhFilterAttrs {
  AGE_VERIFIED = 'PROFILE_VENDOR_PUNCHH:age_verified',
  ANNIVERSARY = 'PROFILE_VENDOR_PUNCHH:anniversary',
  BANNED = 'PROFILE_VENDOR_PUNCHH:banned',
  BIRTHDAY = 'PROFILE_VENDOR_PUNCHH:birthday',
  CHECKINS = 'PROFILE_VENDOR_PUNCHH:checkins',
  CITY = 'PROFILE_VENDOR_PUNCHH:city',
  FAVORITE_LOCATIONS = 'PROFILE_VENDOR_PUNCHH:favorite_locations',
  GENDER = 'PROFILE_VENDOR_PUNCHH:gender',
  LAST_ACTIVITY_AT = 'PROFILE_VENDOR_PUNCHH:last_activity_at',
  LAST_SIGN_IN_AT = 'PROFILE_VENDOR_PUNCHH:last_sign_in_at',
  MARKETING_EMAIL_SUBSCRIPTION = 'PROFILE_VENDOR_PUNCHH:marketing_email_subscription',
  MARKETING_PN_SUBSCRIPTION = 'PROFILE_VENDOR_PUNCHH:marketing_pn_subscription',
  PREFERRED_LOCALE = 'PROFILE_VENDOR_PUNCHH:preferred_locale',
  SIGNUP_CHANNEL = 'PROFILE_VENDOR_PUNCHH:signup_channel',
  SMS_SUBSCRIPTION = 'PROFILE_VENDOR_PUNCHH:sms_subscription',
  STATE = 'PROFILE_VENDOR_PUNCHH:state',
  UNSUBSCRIBED = 'PROFILE_VENDOR_PUNCHH:unsubscribed',
  ZIP_CODE = 'PROFILE_VENDOR_PUNCHH:zip_code',
}

export const knownPunchFilterAttrs = [
  PunchhFilterAttrs.AGE_VERIFIED,
  PunchhFilterAttrs.ANNIVERSARY,
  PunchhFilterAttrs.BANNED,
  PunchhFilterAttrs.BIRTHDAY,
  PunchhFilterAttrs.CHECKINS,
  PunchhFilterAttrs.CITY,
  PunchhFilterAttrs.FAVORITE_LOCATIONS,
  PunchhFilterAttrs.GENDER,
  PunchhFilterAttrs.LAST_ACTIVITY_AT,
  PunchhFilterAttrs.LAST_SIGN_IN_AT,
  PunchhFilterAttrs.MARKETING_EMAIL_SUBSCRIPTION,
  PunchhFilterAttrs.MARKETING_PN_SUBSCRIPTION,
  PunchhFilterAttrs.PREFERRED_LOCALE,
  PunchhFilterAttrs.SIGNUP_CHANNEL,
  PunchhFilterAttrs.SMS_SUBSCRIPTION,
  PunchhFilterAttrs.STATE,
  PunchhFilterAttrs.UNSUBSCRIBED,
  PunchhFilterAttrs.ZIP_CODE,
];

export enum MParticleFilterAttrs {
  AGE = 'PROFILE_VENDOR_MPARTICLE:$Age',
  CITY = 'PROFILE_VENDOR_MPARTICLE:$City',
  COUNTRY = 'PROFILE_VENDOR_MPARTICLE:$Country',
  GENDER = 'PROFILE_VENDOR_MPARTICLE:$Gender',
  STATE = 'PROFILE_VENDOR_MPARTICLE:$State',
  ZIP = 'PROFILE_VENDOR_MPARTICLE:$Zip',
}

export const knownMParticleFilterAttrs = [
  MParticleFilterAttrs.AGE,
  MParticleFilterAttrs.CITY,
  MParticleFilterAttrs.COUNTRY,
  MParticleFilterAttrs.GENDER,
  MParticleFilterAttrs.STATE,
  MParticleFilterAttrs.ZIP,
];

export const mParticleUnsupportedAttributes = ['$Address', '$FirstName', '$LastName', '$Mobile'];

export enum SmileFilterAttrs {
  BIRTH_MONTH = 'PROFILE_VENDOR_SMILE_IO:birth_month',
  POINTS_BALANCE = 'PROFILE_VENDOR_SMILE_IO:points_balance',
  REFERRAL_URL = 'PROFILE_VENDOR_SMILE_IO:referral_url',
  STATE = 'PROFILE_VENDOR_SMILE_IO:state',
  VIP_TIER_ID = 'PROFILE_VENDOR_SMILE_IO:vip_tier_id',
  VIP_TIER_NAME = 'PROFILE_VENDOR_SMILE_IO:vip_tier_name',
}

export const knownSmileFilterAttrs = [
  SmileFilterAttrs.BIRTH_MONTH,
  SmileFilterAttrs.POINTS_BALANCE,
  SmileFilterAttrs.REFERRAL_URL,
  SmileFilterAttrs.STATE,
  SmileFilterAttrs.VIP_TIER_ID,
  SmileFilterAttrs.VIP_TIER_NAME,
];

export const smileUnsupportedAttributes = ['first_name', 'last_name', 'date_of_birth'];

export enum RechargeFilterAttrs {
  CANCELLATION_REASON = 'cancellation_reason',
  CANCELLED_AT = 'cancelled_at',
  CREATED_AT = 'created_at',
  NEXT_CHARGE_SCHEDULED_AT = 'next_charge_scheduled_at',
  SUBSCRIPTIONS = 'subscriptions',
  STATUS = 'status',
}

export enum RechargeNamespacedFilterAttrs {
  SUBSCRIPTION_STATUS = 'VENDOR_RECHARGE:subscriptions:status',
  SUBSCRIPTION_ACTIVATION_DATE = 'VENDOR_RECHARGE:subscriptions:created_at',
  SUBSCRIPTION_UPCOMING_CHARGE_DATE = 'VENDOR_RECHARGE:subscriptions:next_charge_scheduled_at',
  SUBSCRIPTION_CANCELLATION_DATE = 'VENDOR_RECHARGE:subscriptions:cancelled_at',
}

export const knownRechargeFilterAttrs = [
  RechargeNamespacedFilterAttrs.SUBSCRIPTION_STATUS,
  RechargeNamespacedFilterAttrs.SUBSCRIPTION_ACTIVATION_DATE,
  RechargeNamespacedFilterAttrs.SUBSCRIPTION_UPCOMING_CHARGE_DATE,
  RechargeNamespacedFilterAttrs.SUBSCRIPTION_CANCELLATION_DATE,
];

export const rechargeUnsupportedAttributes = [];

export enum IntegrationAttributes {
  PROFILE_ATTRIBUTE = 'PROFILE_ATTRIBUTE',
}

export enum WellKnownPropertyTypes {
  COUNTRY = 'WELL_KNOWN_PROPERTY_COUNTRY',
  KLAVIYO_LAST_EMAIL_OPEN = 'WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN',
  MAILCHIMP_MEMBER_RATING = 'WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING',
  LOCALE = 'WELL_KNOWN_PROPERTY_LOCALE',
  MAIL_EXCHANGE = 'WELL_KNOWN_PROPERTY_MAIL_EXCHANGE',
}

export enum WellKnownPropertyLocaleTypes {
  LANGUAGE = 'language',
}

export type FilterValue =
  | ActionFilter
  | UserPropertyType
  | EmailActionFilter
  | TextActionFilter
  | JoinedActionFilter
  | KlaviyoProfileFilter
  | PropertiesFilter
  | SignUpMethodFilter
  | SubscriberPreference
  | ShopifyActionFilter
  | ShopifyFilter
  | string
  | undefined;
