import { styled } from '@attentive/picnic';

const PU_TOP_SPACER = '130px';
export const SidePaneImg = styled('img', { width: '140px' });
export const ImgContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: PU_TOP_SPACER,
  mb: '$space4',
});
