import React, { useEffect, useState } from 'react';

import { usePermission } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';
import {
  Box,
  Button,
  FormField,
  Icon,
  IconButton,
  StandardDialog,
  TextInput,
  Text,
} from '@attentive/picnic';

import { CustomAttributeFormErrors } from '../../form';

interface AttributeOptionsProps {
  allowSoftDeleteOptions?: boolean;
  errors: CustomAttributeFormErrors;
  isEditing?: boolean;
  options: string[];
  onChange: (val: string[]) => void;
  size: 'small' | 'normal';
}

export const AttributeOptions: React.VFC<AttributeOptionsProps> = ({
  allowSoftDeleteOptions,
  errors,
  isEditing = false,
  options,
  onChange,
  size,
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState<number | null>(null);
  const [originalOptions, setOriginalOptions] = useState<string[]>([]);

  // If the user is editing an existing enum attribute, we store a copy of the original options.
  // This is used to make sure we display a confirm delete dialog for existing enum values and to disable editing the existing enum values
  // This updates when isEditing changes which handles use cases within the creative builder
  useEffect(() => {
    if (isEditing) {
      const nonEmptyOptions = options.filter((opt) => opt);
      setOriginalOptions(nonEmptyOptions);
    } else {
      setOriginalOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  // Creatives adds enums with "true" and "false" to support their checkbox workaround.
  const isCheckboxEnum =
    isEditing && options.length === 2 && options.includes('true') && options.includes('false');

  const removeItem = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onChange(newOptions);
  };

  const isOriginalItem = (index: number) => options[index] === originalOptions[index];

  const onDeleteConfirm = (index?: number) => {
    const indexToRemove = index !== undefined ? index : itemIndex;

    if (indexToRemove === null) {
      throw new Error('Item index is not set');
    }

    setOriginalOptions(originalOptions.filter((value) => value !== options[indexToRemove]));

    removeItem(indexToRemove);
    setItemIndex(null);
    setIsConfirmDialogOpen(false);
  };

  const onRemove = (index: number) => {
    if (!isEditing || !isOriginalItem(index)) {
      removeItem(index);
      return;
    }

    // When allowSoftDeleteOptions is enabled, delete without displaying the confirm dialog
    if (allowSoftDeleteOptions) {
      onDeleteConfirm(index);
      return;
    }

    setIsConfirmDialogOpen(true);
    setItemIndex(index);
  };

  const closeDialog = () => {
    setIsConfirmDialogOpen(false);
    setItemIndex(null);
  };

  const onAddOption = () => {
    const newOptions = [...options, ''];
    onChange(newOptions);
  };

  return (
    <>
      {options.map((_, index: number) => (
        <AttributeOption
          index={index}
          remove={onRemove}
          options={options}
          onChange={onChange}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          disableTextField={isOriginalItem(index)}
          errors={errors}
          size={size}
          isCheckboxOption={isCheckboxEnum}
        />
      ))}
      {!isCheckboxEnum && (
        <Button size="small" css={{ mt: '$space3' }} variant="basic" onClick={onAddOption}>
          <Icon name="PlusSign" size="small" css={{ mr: '$space2' }} /> Add
        </Button>
      )}

      <StandardDialog
        onOpenChange={(isOpen) => {
          if (!isOpen) closeDialog();
        }}
        open={isConfirmDialogOpen}
      >
        <StandardDialog.Content>
          <StandardDialog.Header>
            <StandardDialog.Heading>Delete value?</StandardDialog.Heading>
          </StandardDialog.Header>
          <StandardDialog.Body>
            This value will be removed from any subscribers, segments, and journeys that are
            currently using this value.
          </StandardDialog.Body>
          <StandardDialog.Footer>
            <Button variant="secondary" onClick={closeDialog} data-testid="delete-option-cancel">
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onDeleteConfirm()}>
              Delete value
            </Button>
          </StandardDialog.Footer>
        </StandardDialog.Content>
      </StandardDialog>
    </>
  );
};

function AttributeOption({
  index,
  remove,
  disableTextField,
  options,
  onChange,
  errors,
  size,
  isCheckboxOption,
}: {
  index: number;
  remove: (i: number) => void;
  disableTextField: boolean;
  options: string[];
  onChange: (value: string[]) => void;
  errors: CustomAttributeFormErrors;
  size: 'small' | 'normal';
  isCheckboxOption: boolean;
}) {
  const isSuperUser = usePermission(Permission.SuperUserAccess);
  // We only show the delete button if the user has super user perms or if this is not an existing value
  // Deleting an existing enum value that is in use can malform segments
  const showDeleteButton = !isCheckboxOption && (isSuperUser || !disableTextField);
  const errorMsg = errors.options && errors.options[index];

  return (
    <Box css={{ marginTop: '$space2' }}>
      <Box css={{ display: 'flex', alignItems: 'center' }}>
        {/* Existing enum values can't be edited on the BE, only deleted. We disable editing to be more clear about the result of a user's actions */}
        {disableTextField ? (
          <Text
            css={{
              width: '100%',
              fontSize: '$fontSize2',
              ml: '$space3',
            }}
          >
            {options[index]}
          </Text>
        ) : (
          <TextInput
            size={size}
            placeholder={`Option ${index + 1}`}
            value={options[index]}
            onChange={(e) => {
              const newOptions = [...options];
              newOptions[index] = e.target.value;
              onChange(newOptions);
            }}
          />
        )}
        {showDeleteButton && (
          <IconButton
            iconName="Delete"
            size="small"
            description={`Remove option ${index + 1}`}
            variant="subdued"
            onClick={() => remove(index)}
            disabled={options.length <= 1}
          />
        )}
      </Box>
      {errorMsg && (
        <Box>
          <FormField.ErrorText>{errorMsg}</FormField.ErrorText>
        </Box>
      )}
    </Box>
  );
}
