import React from 'react';

import { IconPopover, Text } from '@attentive/picnic';

interface SmartSendingTooltipProps {
  visuallyDisabled?: boolean;
}

export const SmartSendingTooltip = ({ visuallyDisabled }: SmartSendingTooltipProps) => (
  <IconPopover
    iconName="CircleInformation"
    size="extraSmall"
    side="bottom"
    align="center"
    css={{ ml: '$space1' }}
    iconColor={visuallyDisabled ? 'disabled' : undefined}
  >
    <Text variant="caption">
      The number of subscribers that can receive a message right now based on your Smart Sending
      settings
    </Text>
  </IconPopover>
);
