import { useCombobox } from 'downshift';
import React, { useState, useRef, useMemo } from 'react';

import {
  Text,
  List,
  Box,
  TextInput,
  InputGroup,
  Icon,
  Button,
  getItemPropsType,
  MenuItemValueType,
  SelectProps,
  getLabelText,
  SelectItemsList,
  ArrowWrapper,
  PublicSelectGroup,
  PublicSelectItem,
  PublicSelectIconItem,
  PublicSelectThirdPartyIconItem,
  SelectPopout,
  PicnicCss,
} from '@attentive/picnic';

import { SupportedCountryValues } from '../../constants';

import { PinnedMenuItem, PinnedMenuItems, useItems } from './extractItemsHook';
import { useMatch } from './search';

const itemToString = (item: PinnedMenuItem | null) => item?.label ?? '';

export interface DetailPaneComponentProps {
  selectedItem: PinnedMenuItem;
  country: SupportedCountryValues;
}

interface SearchableDetailSelectComponentProps extends SelectProps {
  country?: SupportedCountryValues;
  DetailPane: React.VFC<DetailPaneComponentProps>;
  containerId?: string;
}

const SearchableDetailSelectComponent = ({
  placeholder = 'Search',
  children = [],
  css = {},
  value,
  onChange,
  size = 'medium',
  state = 'normal',
  disabled = false,
  DetailPane,
  country,
  containerId,
  ...rest
}: SearchableDetailSelectComponentProps) => {
  const [term, setTerm] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleChange = onChange as (value: MenuItemValueType) => void;

  const items = useItems(children) as PinnedMenuItems;

  const getItem = (itemValue?: MenuItemValueType) => {
    if (itemValue !== undefined && itemValue !== null) {
      return items.find((item) => item.value === itemValue && !item.disabled) ?? null;
    }

    return null;
  };

  const filteredItems = useMatch(items, term);
  const itemsLookup = filteredItems.reduce(
    (prev: Record<MenuItemValueType, { item: PinnedMenuItem; index: number }>, curr, index) => {
      prev[curr.value] = {
        item: curr,
        index,
      };
      return prev;
    },
    {}
  );

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getToggleButtonProps,
    getItemProps,
    selectedItem,
  } = useCombobox({
    itemToString,
    inputValue: term,
    items: filteredItems,
    selectedItem: getItem(value),

    onSelectedItemChange: ({ selectedItem: changedSelectedItem }) => {
      if (changedSelectedItem) {
        setTerm('');

        if (handleChange) {
          const val = changedSelectedItem.value as string;
          handleChange(val);
        }
      }
    },
  });

  const menuPositionCss = useMemo(() => {
    return isOpen ? getSelectPosition({ element: wrapperRef.current, id: containerId }) : {};
  }, [isOpen, containerId]);

  return (
    <Box ref={wrapperRef} css={{ width: '100%', position: 'relative', ...css }}>
      <Box {...getComboboxProps()}>
        <InputGroup>
          <TextInput
            // this prop disables 1password autocomplete on this field
            data-1p-ignore
            value={term}
            placeholder={selectedItem ? getLabelText(selectedItem.label) : placeholder}
            data-testid="searchable-select-input"
            {...getInputProps()}
            onClick={openMenu}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTerm(e.currentTarget.value);
              openMenu();
            }}
            size={size === 'medium' ? 'normal' : 'small'}
            state={state}
            css={{
              width: '100%',
              textOverflow: 'ellipsis',
              '&::placeholder': { color: selectedItem ? '$textDefault' : '$textSubdued' },
              ...css,
            }}
            disabled={disabled}
            {...rest}
          />
          <InputGroup.RightElement>
            <Button
              variant="subdued"
              disabled={disabled}
              {...getToggleButtonProps()}
              css={{ minHeight: 12, p: '$space2' }}
            >
              <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
                <Icon
                  css={{ display: 'block' }}
                  name="ChevronDown"
                  size="small"
                  description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
                />
              </ArrowWrapper>
            </Button>
          </InputGroup.RightElement>
        </InputGroup>
      </Box>
      <Box {...getMenuProps()} css={{ outline: 'none' }}>
        {isOpen && (
          <SelectPopout
            css={{
              display: 'flex',
              flexDirection: 'row',
              minWidth: '500px',
              overflowY: 'hidden',
              padding: '$space0',
              ...menuPositionCss,
            }}
          >
            {filteredItems.length > 0 && (
              <List variant="unstyled" css={{ overflowY: 'scroll', flexGrow: 1 }}>
                <SelectItemsList
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem as PinnedMenuItem}
                  getItemProps={getItemProps as getItemPropsType}
                  itemsLookup={itemsLookup}
                  size={size}
                >
                  {children}
                </SelectItemsList>
              </List>
            )}
            {filteredItems.length === 0 && (
              <Box css={{ p: '$space2' }}>
                <Text>No results</Text>
              </Box>
            )}
            <DetailPane selectedItem={filteredItems[highlightedIndex]} country={country || 'US'} />
          </SelectPopout>
        )}
      </Box>
    </Box>
  );
};

const downwardOpeningCss: PicnicCss = { top: 'calc(100% + $space2)', bottom: 'auto' };
const upwardOpeningCss: PicnicCss = { bottom: 'calc(100% + $space2)', top: 'auto' };

function getSelectPosition({
  element,
  height = 400,
  selectPadding = 8,
  id = '',
}: {
  element: HTMLDivElement | null;
  height?: number;
  selectPadding?: number;
  id?: string;
}) {
  const container = window.document.getElementById(id);

  if (!element || !container) return downwardOpeningCss;

  const dropdownPosition = element.getBoundingClientRect();

  // This is the predicted top position of the menu when opening upwards
  const upwardMenuStart = dropdownPosition.top - height - selectPadding;
  // This is the predicted bottom position of the menu when opening downwards
  const downwardMenuEnd = dropdownPosition.bottom + height + selectPadding;

  // This is the top of the current viewport based on scroll position
  const viewPortTop = container.scrollTop;
  // This is the bottom of the current viewport based on scroll position
  const viewPortBottom = container.clientHeight + viewPortTop;

  // The offsets tell us if the menu will exceed the viewport. A negative number indicates that the menu has exceeded the bounds of the viewport
  const topOffset = upwardMenuStart - viewPortTop;
  const bottomOffset = viewPortBottom - downwardMenuEnd;

  // We open downwards unless the menu will go off screen but has plenty of room to open upwards
  return bottomOffset < 0 && topOffset >= 0 ? upwardOpeningCss : downwardOpeningCss;
}

type ComponentType = typeof SearchableDetailSelectComponent & { displayName?: string };

interface PinnedPublicSelectItemProps {
  value: MenuItemValueType;
  disabled?: boolean;
  css?: PicnicCss;
  icon?: React.ReactNode;
  pinned?: boolean;
}

const PinnedPublicSelectItem: React.FC<PinnedPublicSelectItemProps> = () => {
  // This component is never rendered.
  // It is used to provide typing information to our Composite Component API
  return null;
};

interface CompositeComponent extends ComponentType {
  Group: typeof PublicSelectGroup;
  Item: typeof PinnedPublicSelectItem;
  IconItem: typeof PublicSelectIconItem;
  ThirdPartyIconItem: typeof PublicSelectThirdPartyIconItem;
}

const SearchableDetailSelect = SearchableDetailSelectComponent as CompositeComponent;
SearchableDetailSelect.Group = PublicSelectGroup;
SearchableDetailSelect.Item = PublicSelectItem;
SearchableDetailSelect.IconItem = PublicSelectIconItem;
SearchableDetailSelect.ThirdPartyIconItem = PublicSelectThirdPartyIconItem;

SearchableDetailSelect.displayName = 'SearchableDetailSelect';
SearchableDetailSelect.Group.displayName = 'SearchableDetailSelect.Group';
SearchableDetailSelect.Item.displayName = 'SearchableDetailSelect.Item';
SearchableDetailSelect.IconItem.displayName = 'SearchableDetailSelect.IconItem';
SearchableDetailSelect.ThirdPartyIconItem.displayName = 'SearchableDetailSelect.ThirdPartyIconItem';

export { SearchableDetailSelect };
