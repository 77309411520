import {
  SegmentOptions,
  WellKnownPropertyOption,
  WellKnownPropertyTypes,
} from '../../../constants';

export function getWellKnownProperty(
  segmentOptions: SegmentOptions,
  optionValue: WellKnownPropertyTypes
) {
  if (!segmentOptions || !segmentOptions.subscriberAttributeOptions.wellKnownAttributeOptions) {
    return undefined;
  }

  const option = segmentOptions.subscriberAttributeOptions.wellKnownAttributeOptions.find(
    (item) => item.optionValue === optionValue
  );

  if (!option) {
    return undefined;
  }

  return {
    ...option,
    values: option.values?.sort((a, b) => a.displayName.localeCompare(b.displayName)),
  };
}

export function getWellKnownNestedProperty(
  wellKnownPropertyOption: WellKnownPropertyOption | undefined,
  nestedOptionName: string
) {
  if (!wellKnownPropertyOption || !nestedOptionName) {
    return undefined;
  }

  const option = wellKnownPropertyOption.nestedOptions?.find(
    (opt) => opt.attributeName === nestedOptionName
  );

  if (!option) {
    return undefined;
  }

  return {
    ...option,
    values: option.values?.sort((a, b) => a.displayName.localeCompare(b.displayName)),
  };
}
