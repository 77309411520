import React from 'react';

import { Box, FormField, Select, MultiSelect } from '@attentive/picnic';

import { KeywordCondition } from '../../../constants';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface SourceKeywordValue {
  condition: KeywordCondition;
  keywords?: string[];
}

interface SourceKeywordSelectorProps {
  value: SourceKeywordValue;
  keywordOptions: string[];
  onChange: (value: SourceKeywordValue) => void;
}

const SourceKeywordSelector = ({ value, keywordOptions, onChange }: SourceKeywordSelectorProps) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !value?.keywords?.length;

  const onConditionChange = (newValue: string) => {
    const isAnyKeyword = newValue === KeywordCondition.ANY_KEYWORD;

    const newSourceKeywordValue: SourceKeywordValue = {
      condition: newValue as KeywordCondition,
      ...(!isAnyKeyword && { keywords: [] }),
    };

    onChange(newSourceKeywordValue);
  };

  return (
    <>
      <Box>
        <Select value={value.condition} onChange={onConditionChange} size="small">
          <Select.Item value={KeywordCondition.SPECIFIC_KEYWORD}>A specific keyword</Select.Item>
          <Select.Item value={KeywordCondition.ANY_KEYWORD}>Any keyword</Select.Item>
        </Select>
      </Box>
      {value.condition === KeywordCondition.SPECIFIC_KEYWORD && (
        <FormField>
          <MultiSelect
            value={value.keywords || []}
            onChange={(newValues: string[]) => onChange({ ...value, keywords: newValues })}
            size="small"
            maxTokens={10}
            placeholder="Choose keywords"
            state={error ? 'error' : 'normal'}
          >
            {keywordOptions.map((keywordOption) => (
              <MultiSelect.Item key={keywordOption} value={keywordOption}>
                {keywordOption}
              </MultiSelect.Item>
            ))}
          </MultiSelect>
          {error && <FormField.ErrorText>Please choose keywords.</FormField.ErrorText>}
        </FormField>
      )}
    </>
  );
};

export default SourceKeywordSelector;
