import { useSetAtom } from 'jotai';
import React, { FC, useState } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import {
  Banner,
  Box,
  Button,
  StandardDialog,
  LoadingIndicator,
  PicnicCss,
  Link,
  Text,
} from '@attentive/picnic';

import { saveOrUpdateSegment } from '../../api';
import { BlankSegment, Segment, ThinSegment } from '../../constants';
import {
  useAvailableSegmentData,
  useSegmentIsInvalid,
  useSegmentableAttributesByVendor,
  useSegmentCreateEdit,
  useSegmentOptions,
  useUserIsDemoUser,
} from '../../hooks';
import { DeleteSegmentDialog } from '../DeleteSegmentDialog';
import { ErrorComponent } from '../Error';
import { OperatorUIFeedbackDialog } from '../OperatorUIFeedbackDialog';
import { OperatorUIToggleError } from '../OperatorUIToggle';
import { SegmentAssistantFeedback } from '../SegmentAssistant';
import { useIsSegmentCreatedWithAssistant } from '../SegmentAssistant/useSegmentAssistantResponse';
import SegmentEditTitleDialog from '../SegmentEditTitleDialog';

import { LiveRegionProvider, LiveRegionArea } from './SegmentBuilderLiveArea';
import { ConfirmSegmentUpdateDialog } from './components/ConfirmSegmentUpdateDialog';
import { SegmentBuilderHeader } from './components/SegmentBuilderHeader';
import SegmentCountSidePane from './components/SegmentCountSidePane/SegmentCountSidePane';
import { SegmentExpressionBuilder } from './components/SegmentExpressionBuilder';
import SegmentInUsePrompt from './components/SegmentInUsePrompt';
import {
  SegmentCreateEditQueryContext,
  useSegmentCreateEditQuery,
} from './useSegmentCreateEditQuery';
import {
  assembleVendorAttributeDataTypeMap,
  vendorAttributeDataTypeMapAtom,
  assembleSubscriberAttributeDataTypeMap,
  subscriberAttributeDataTypeMapAtom,
} from './utils/attributeDataTypeMap';
import { hasHighlightedComponents, HighlightConditionConfigs } from './utils/highlightConditions';
import { stripSegmentMetadata } from './utils/stripSegmentMetadata';

const PU_SEGMENT_MODAL_BUTTON_WIDTH = '196px';

// Explicitly set header and footer heights to ensure that the body height calculation is correct
const PU_MODAL_HEADER_HEIGHT = '76px';
const PU_MODAL_FOOTER_HEIGHT = '88px';
export const PU_MODAL_BODY_HEIGHT = `calc(100vh - ${PU_MODAL_HEADER_HEIGHT} - ${PU_MODAL_FOOTER_HEIGHT})`;
// Subtract an additional 100px to account for headline and element padding
const PU_MODAL_LOADING_HEIGHT = `calc(100vh - ${PU_MODAL_HEADER_HEIGHT} - ${PU_MODAL_FOOTER_HEIGHT} - 100px)`;

export interface SegmentCreateEditModalProps {
  onOpenChange: (isOpen: boolean, isSaving?: boolean) => void;
  id?: number;
  passedSegment?: BlankSegment | Segment | ThinSegment;
  onSave?: (segment: Segment) => void;
  onError?: (error: Error) => void;
  hideSegmentCount?: boolean;
  header?: string;
  subheader?: React.ReactNode;
  contentHeader?: string;
  highlightConditions?: HighlightConditionConfigs;
  displayDeleteButton?: boolean;
}

export const SegmentCreateEditModal: FC<SegmentCreateEditModalProps> = ({
  onOpenChange,
  id,
  hideSegmentCount,
  passedSegment,
  onSave,
  onError,
  header,
  subheader,
  contentHeader,
  highlightConditions,
  displayDeleteButton,
}) => {
  const queryRef = useSegmentCreateEditQuery();

  const isDemoUser = useUserIsDemoUser();
  const setVendorAttributeDataTypeMap = useSetAtom(vendorAttributeDataTypeMapAtom);
  const setSubscriberAttributeDataTypeMap = useSetAtom(subscriberAttributeDataTypeMapAtom);
  const { segment, updateCurrentSegment, duplicates, hasDuplicates, error, isSegmentLoaded } =
    useSegmentCreateEdit(id, passedSegment, isDemoUser);
  const {
    data: segmentOptions,
    error: optsError,
    isLoading: optsLoading,
    refetch: refetchOpts,
  } = useSegmentOptions();
  const segmentableAttributesByVendor = useSegmentableAttributesByVendor();
  const availableSegmentData = useAvailableSegmentData();
  const segmentIsInvalid = useSegmentIsInvalid(segment);
  const segmentHasHighlightedComponents = hasHighlightedComponents(segment, highlightConditions);

  const [validateDuplicates, setValidateDuplicates] = useState(false);
  const [titleDialogOpen, setTitleDialogOpen] = useState(false);
  const [segmentInJourneyUseDialogOpen, setSegmentInJourneyDialogOpen] = useState(false);
  const [deleteSegmentDialogOpen, setDeleteSegmentDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isSegmentAssistantEnabled = useCompanyFeatureFlag('ENABLE_SEGMENT_ASSISTANT_EXPERIMENT');
  const isCreatedWithAssistant = useIsSegmentCreatedWithAssistant();
  const [isSegmentAssistantFeedbackOpen, setIsSegmentAssistantFeedbackOpen] = useState(
    isSegmentAssistantEnabled && isCreatedWithAssistant
  );

  const isEditing = !!id;
  const isLoading =
    !error && (optsLoading || !segment.expressions || segment.expressions.length === 0);
  const activeSegments = (segment.journeys || []).filter(({ journeyActive }) => journeyActive);
  const segmentUsedInJourneys = Boolean(activeSegments.length);
  const segmentUsedInCampaigns = Boolean(segment.campaigns?.length);

  // We can still create segments when services are down but we shouldn't allow edits
  const usageServicesUnavailable =
    (segment.campaigns === null || segment.journeys === null) && isEditing;
  const duplicateError = validateDuplicates && hasDuplicates;
  const saveButtonDisabled =
    isDemoUser ||
    duplicateError ||
    segmentUsedInCampaigns ||
    segmentIsInvalid ||
    usageServicesUnavailable ||
    segmentHasHighlightedComponents;
  const displaySegmentCount = !hideSegmentCount;

  const modalHeader = header || (isEditing ? 'Edit your segment' : 'Create a segment');

  React.useEffect(() => {
    if (!hasDuplicates) {
      setValidateDuplicates(false);
    }
  }, [hasDuplicates]);

  React.useEffect(() => {
    assembleSubscriberAttributeDataTypeMap(segmentOptions, setSubscriberAttributeDataTypeMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentOptions]);

  React.useEffect(() => {
    assembleVendorAttributeDataTypeMap(
      segmentableAttributesByVendor,
      setVendorAttributeDataTypeMap
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentableAttributesByVendor]);

  const submitSegment = async () => {
    setIsSaving(true);
    try {
      const preparedSegment = stripSegmentMetadata(segment);
      const response = await saveOrUpdateSegment(preparedSegment);
      const { body, status } = response;
      if (status !== 200) {
        throw new Error('Failed to save segment');
      }
      if (!body) {
        throw new Error('Unexpected empty response');
      }
      if (onSave) {
        onSave(body);
      }
      onOpenChange(false, true);
      setIsSaving(false);
    } catch (err) {
      if (onError) {
        onError(err);
      }
      setIsSaving(false);
    }
  };

  const handleMainButtonClick = async () => {
    if (hasDuplicates && !validateDuplicates) {
      setValidateDuplicates(true);
    } else if (!isEditing) {
      setTitleDialogOpen(true);
    } else if (segmentUsedInJourneys) {
      setSegmentInJourneyDialogOpen(true);
    } else {
      submitSegment();
    }
  };

  const dialogContentStyle: PicnicCss = {
    width: '100%',
    height: '100%',
    maxHeight: 'none',
    maxWidth: 'none',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    overflow: 'hidden',
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoadingIndicator
          css={{ height: PU_MODAL_LOADING_HEIGHT, width: '100%', justifyContent: 'center' }}
        />
      );
    }

    if (error)
      return (
        <ErrorComponent css={{ mt: '$space16' }}>
          <Text css={{ mb: '$space4' }}>There was an error loading your segment conditions.</Text>
          <Button variant="basic" size="small" onClick={() => onOpenChange(false)}>
            Back to segment overview
          </Button>
        </ErrorComponent>
      );

    if (optsError || !segmentOptions)
      return (
        <ErrorComponent css={{ mt: '$space16' }}>
          There was an error loading segment options.&nbsp;
          <Link css={{ cursor: 'pointer' }} onClick={() => refetchOpts()}>
            Try again
          </Link>
          &nbsp;in a few minutes.
        </ErrorComponent>
      );

    if (segmentUsedInCampaigns || usageServicesUnavailable)
      return (
        <Box css={{ mt: '$space8' }}>
          <SegmentInUsePrompt campaigns={segment.campaigns} />
        </Box>
      );

    return (
      <SegmentExpressionBuilder
        segment={segment}
        updateCurrentSegment={updateCurrentSegment}
        duplicates={duplicates}
        validateDuplicates={validateDuplicates}
        segmentOptions={segmentOptions}
        segmentableAttributesByVendor={segmentableAttributesByVendor}
        availablSegmentData={availableSegmentData}
        highlightConditions={highlightConditions}
      />
    );
  };

  return (
    <LiveRegionProvider>
      <StandardDialog open onOpenChange={onOpenChange}>
        <StandardDialog.Content css={dialogContentStyle} data-testid="segment-builder">
          <StandardDialog.Header css={{ height: PU_MODAL_HEADER_HEIGHT }}>
            <StandardDialog.Heading variant="lg">{modalHeader}</StandardDialog.Heading>
            {subheader}
          </StandardDialog.Header>
          <StandardDialog.Body
            css={{ display: 'flex', padding: '$space0 $space0 $space0 $space6' }}
          >
            <LiveRegionArea />
            <SegmentCreateEditQueryContext.Provider value={{ queryRef }}>
              <Box
                css={{
                  flex: 1,
                  padding: '$space6',
                  paddingLeft: '$space0',
                  maxHeight: PU_MODAL_BODY_HEIGHT,
                  overflowX: 'auto',
                }}
                id="segment-body-container"
              >
                {duplicateError && (
                  <Banner variant="error" css={{ mb: '$space6' }}>
                    <Banner.Text>
                      You have duplicate condtions. Select different conditions or remove one of the
                      duplicates to continue.
                    </Banner.Text>
                  </Banner>
                )}
                {segmentHasHighlightedComponents && (
                  <Banner variant="error" css={{ mb: '$space6' }}>
                    <Banner.Text>
                      Please remove segment condition or delete segment to to change the data type
                      of this custom attribute.
                    </Banner.Text>
                  </Banner>
                )}
                <OperatorUIToggleError />
                <SegmentBuilderHeader
                  contentHeader={contentHeader}
                  showField={segment.expressions.length > 1}
                  onChange={(newOperator) => {
                    updateCurrentSegment({ ...segment, operator: newOperator });
                  }}
                  operator={segment.operator}
                />
                {renderContent()}
                <OperatorUIFeedbackDialog
                  css={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '24px',
                    zIndex: 2,
                  }}
                />

                {isCreatedWithAssistant && (
                  <SegmentAssistantFeedback
                    css={{
                      position: 'absolute',
                      bottom: '20px',
                      right: '24px',
                      zIndex: 2,
                    }}
                    isOpen={isSegmentAssistantFeedbackOpen}
                    onClose={() => setIsSegmentAssistantFeedbackOpen(false)}
                  />
                )}
              </Box>
              {displaySegmentCount && !error && (
                <SegmentCountSidePane
                  segment={segment}
                  isFetchingSegment={isEditing && !isSegmentLoaded}
                />
              )}
              {titleDialogOpen && (
                <SegmentEditTitleDialog
                  dialogTitle="Save your segment"
                  onOpenChange={setTitleDialogOpen}
                  name={segment.name}
                  handleNameChange={(name) => updateCurrentSegment({ name })}
                  description={segment.description}
                  handleDescriptionChange={(description) => updateCurrentSegment({ description })}
                  handleSubmit={submitSegment}
                  isSaving={isSaving}
                />
              )}
              <ConfirmSegmentUpdateDialog
                open={segmentInJourneyUseDialogOpen}
                onClose={() => setSegmentInJourneyDialogOpen(false)}
                onConfirm={() => submitSegment()}
                journeys={activeSegments}
                isSaving={isSaving}
              />
              <DeleteSegmentDialog
                open={deleteSegmentDialogOpen}
                onClose={() => setDeleteSegmentDialogOpen(false)}
                onSuccess={() => {
                  setDeleteSegmentDialogOpen(false);
                  onOpenChange(false);
                }}
                segmentId={segment.id}
              />
            </SegmentCreateEditQueryContext.Provider>
          </StandardDialog.Body>
          <StandardDialog.Footer css={{ height: PU_MODAL_FOOTER_HEIGHT }}>
            <Box
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
              }}
            >
              <Box css={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                  css={{ width: PU_SEGMENT_MODAL_BUTTON_WIDTH, ml: '$space4' }}
                  onClick={handleMainButtonClick}
                  loading={isSaving}
                  disabled={saveButtonDisabled}
                >
                  {/*{text needs to be wrapped in span or will throw an error when using Google Translate https://issues.chromium.org/issues/41407169}*/}
                  <span>{isEditing ? 'Update' : 'Create'}</span>
                </Button>
                <Button
                  variant="basic"
                  onClick={() => onOpenChange(false)}
                  data-segmentation-id={
                    isEditing
                      ? 'segment-builder-edit-cancel-button'
                      : 'segment-builder-create-cancel-button'
                  }
                >
                  Cancel
                </Button>
              </Box>
              {isEditing && displayDeleteButton && (
                <Button variant="subdued" onClick={() => setDeleteSegmentDialogOpen(true)}>
                  Delete Segment
                </Button>
              )}
            </Box>
          </StandardDialog.Footer>
        </StandardDialog.Content>
      </StandardDialog>
    </LiveRegionProvider>
  );
};

export default SegmentCreateEditModal;
