import _vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration from "./__generated__/vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration.graphql";
import { graphql, readInlineData } from '@attentive/data';
import { Vendor } from '../../../constants';
import { convertIntegrationsNodeIdToInternalId } from '../../../utils';
import { ConditionIcon, ConditionSource } from '../constants';
import CompanyVendorIntegrationFrag from './__generated__/vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration.graphql';
export let SOURCE_TYPES;

(function (SOURCE_TYPES) {
  SOURCE_TYPES["ENTITY_TYPE_COMPANY_APPLICATION"] = "ENTITY_TYPE_COMPANY_APPLICATION";
  SOURCE_TYPES["ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION"] = "ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION";
})(SOURCE_TYPES || (SOURCE_TYPES = {}));

export function getCustomEventSource(item) {
  const {
    label,
    name: iconName
  } = item;

  if (Array.isArray(label)) {
    const customSourceImageElement = label[0];
    return [customSourceImageElement.props.alt || ConditionSource.API];
  }

  switch (iconName) {
    case ConditionIcon.SEGMENT_IO:
      return [ConditionSource.SEGMENT_IO];

    default:
      return [ConditionSource.API];
  }
}
export function getVendorIcon(vendor) {
  switch (vendor) {
    case Vendor.SEGMENT_IO:
      return ConditionIcon.SEGMENT_IO;

    default:
      return null;
  }
}
export function getCustomEventSourceAppInfo(customEventOption, apps) {
  let imageUrl, appSource;
  const {
    customEventSourceId,
    customEventSource
  } = customEventOption;

  if (customEventSourceId && customEventSource === SOURCE_TYPES.ENTITY_TYPE_COMPANY_APPLICATION) {
    var _sourceApp$listing, _sourceApp$listing$im;

    const sourceApp = apps === null || apps === void 0 ? void 0 : apps.find(app => app && customEventSourceId === String(app.internalId));
    imageUrl = sourceApp === null || sourceApp === void 0 ? void 0 : (_sourceApp$listing = sourceApp.listing) === null || _sourceApp$listing === void 0 ? void 0 : (_sourceApp$listing$im = _sourceApp$listing.imageUrl) === null || _sourceApp$listing$im === void 0 ? void 0 : _sourceApp$listing$im.value;
    appSource = sourceApp === null || sourceApp === void 0 ? void 0 : sourceApp.name;
  }

  return {
    imageUrl,
    appSource
  };
}
_vendorUtils_getCustomEventSourceIntegrationInfo_TargetingCommon_CompanyVendorIntegration;
export function getCustomEventSourceIntegrationDisplayName(customEventOption, integrations, logError) {
  let displayName;
  const {
    customEventSourceId,
    customEventSource
  } = customEventOption;

  if (customEventSourceId && customEventSource === SOURCE_TYPES.ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION && integrations) {
    integrations.find(integration => {
      const {
        id,
        externalVendorApi
      } = readInlineData(CompanyVendorIntegrationFrag, integration);

      if (customEventSourceId === convertIntegrationsNodeIdToInternalId(id, logError)) {
        displayName = externalVendorApi === null || externalVendorApi === void 0 ? void 0 : externalVendorApi.displayName;
        return true;
      }

      return false;
    });
  }

  return displayName;
}