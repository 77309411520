import _useJourneys_TargetingCommon_JourneyConnection from "./__generated__/useJourneys_TargetingCommon_JourneyConnection.graphql";
import { useEffect, useState } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { usePaginatedJourneys } from '../../hooks/usePaginatedJourneys';
import JourneysFragment from './__generated__/useJourneys_TargetingCommon_JourneyConnection.graphql';
/**
 * This fragment should be spread on usePaginatedJourneys_TargetingCommon_Company
 */

_useJourneys_TargetingCommon_JourneyConnection;
/**
 * This hook relies on the paginated journeys fragment
 * usePaginatedJourneys_TargetingCommon_Company
 */

export function useFilteredJourneys(companyRef, channel, queryString) {
  const {
    data: journeysRef,
    isLoading
  } = usePaginatedJourneys(companyRef);
  const data = useFragment(JourneysFragment, journeysRef);
  const {
    edges = []
  } = data || {};
  const [edgesFilterByChannel, setEdgesFilterByChannel] = useState(edges);
  useEffect(() => {
    if (!edges.length) {
      setEdgesFilterByChannel([]);
      return;
    }

    const newFilteredEdges = edges.filter(_ref => {
      var _node$journeyRevision;

      let {
        node
      } = _ref;
      // Search revisions for messages with the specified channel
      return (_node$journeyRevision = node.journeyRevisions) === null || _node$journeyRevision === void 0 ? void 0 : _node$journeyRevision.some(_ref2 => {
        let {
          messages
        } = _ref2;
        return messages.some(_ref3 => {
          let {
            composeMessage
          } = _ref3;
          return (composeMessage === null || composeMessage === void 0 ? void 0 : composeMessage.channel) === channel;
        });
      });
    });
    setEdgesFilterByChannel(newFilteredEdges);
  }, [edges, channel]);
  let filteredJourneys = edgesFilterByChannel;

  if (queryString) {
    const formattedQueryString = queryString.toLocaleLowerCase();
    filteredJourneys = edgesFilterByChannel.filter(_ref4 => {
      let {
        node
      } = _ref4;
      return node.name.toLocaleLowerCase().includes(formattedQueryString);
    });
  }

  return {
    data: filteredJourneys,
    isLoading
  };
}