import { Source } from '../index';

import {
  BlankSegment,
  Segment,
  IndexPageSegment,
  ManualSegment,
  ThirdPartySegment,
} from './segments';

export function isManualSegment(
  testVar: Segment | IndexPageSegment | null | undefined
): testVar is ManualSegment {
  if (
    testVar &&
    (testVar as ManualSegment).manualUploadList &&
    (testVar as ManualSegment).source === Source.MANUAL_UPLOAD
  ) {
    return true;
  }
  return false;
}

export function isSavedSegment(testVar: Segment | BlankSegment): testVar is Segment {
  if (testVar && (testVar as Segment).id) {
    return true;
  }
  return false;
}

export function isSegment(
  testVar: Segment | IndexPageSegment | BlankSegment | null | undefined
): testVar is Segment {
  if (testVar && (testVar as Segment).expressions) {
    return true;
  }
  return false;
}

export function isThirdPartySegment(
  testVar: Segment | IndexPageSegment | null | undefined
): testVar is ThirdPartySegment {
  if (
    testVar &&
    (testVar as ThirdPartySegment).thirdPartyList &&
    (testVar as ThirdPartySegment).source === Source.THIRD_PARTY
  ) {
    return true;
  }
  return false;
}
