import React from 'react';

import { Box, Heading, Icon, IconPopover, Link, Text } from '@attentive/picnic';

import {
  CampaignUsingSegment,
  IntegrationUsingSegment,
  JourneyUsingSegment,
} from '../../constants/interfaces';
import { isSendingCampaign } from '../../utils';

interface OwnProps {
  campaigns?: CampaignUsingSegment[] | null;
  journeys?: JourneyUsingSegment[] | null;
  integrations?: IntegrationUsingSegment[] | null;
}

export const SegmentInUse = ({ campaigns, journeys, integrations }: OwnProps) => {
  const hasCampaigns = campaigns && campaigns.length > 0;
  const hasJourneys = journeys && journeys.length > 0;
  const hasIntegrations = integrations && integrations.length > 0;

  if (hasCampaigns || hasJourneys || hasIntegrations) {
    return (
      <>
        <Text css={{ mb: '$space4' }}>
          Before you can delete this segment, remove it from the following places:
        </Text>
        {hasJourneys && (
          <Box css={{ mb: '$space6' }}>
            <Heading variant="sm" css={{ mb: '$space3' }}>
              Active Journeys
            </Heading>
            {journeys
              ?.filter((journey) => journey.journeyActive)
              .map(({ journeyId, revisionId, name, url }, idx) => {
                const journeyKey = `${idx}-${journeyId}-${revisionId}`;
                return (
                  <Box key={journeyKey} css={{ mb: '$space3' }}>
                    <Link href={url} target="__blank">
                      {name}
                      <Icon name="NewWindow" size="extraSmall" css={{ ml: '$space2' }} />
                    </Link>
                  </Box>
                );
              })}
          </Box>
        )}
        {hasCampaigns && (
          <Box css={{ mb: '$space6' }}>
            <Heading variant="sm" css={{ mb: '$space3' }}>
              Campaigns
            </Heading>
            {campaigns?.map(({ campaignId, campaignName, campaignStatus }, idx) => {
              const campaignKey = `${idx}-${campaignId}`;
              return (
                <Box
                  key={campaignKey}
                  css={{ display: 'flex', alignItems: 'center', mb: '$space2' }}
                >
                  <Text>{campaignName}</Text>
                  {isSendingCampaign(campaignStatus) && (
                    <IconPopover size="extraSmall" css={{ ml: '$space2' }}>
                      You cannot replace segments once a campaign has started sending. Please cancel
                      this campaign or wait until it is done sending.
                    </IconPopover>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
        {hasIntegrations && (
          <>
            <Heading variant="sm" css={{ mb: '$space3' }}>
              Integrations
            </Heading>
            {integrations?.map(({ id, name, url }, idx) => {
              const integrationKey = `${idx}-integration-${id}`;
              return (
                <Box key={integrationKey} css={{ mb: '$space3' }}>
                  <Link href={url} target="__blank">
                    {name || url}
                    <Icon name="NewWindow" size="extraSmall" css={{ ml: '$space2' }} />
                  </Link>
                </Box>
              );
            })}
          </>
        )}
      </>
    );
  }

  return (
    <Text variant="lede" color="subdued" css={{ mb: '$space6' }}>
      We're experiencing an issue, so we can't verify whether this segment is in use. While we
      resolve this issue, you won't be able to edit or delete this segment. We expect to resolve
      this issue soon. Please check back later.
    </Text>
  );
};
