import React from 'react';

import { InputGroup, TextInput, Icon } from '@attentive/picnic';

type SearchFieldProps = {
  disabled?: boolean;
  onSearchTextChange: (searchText: string) => void;
  searchText: string;
};

export const SearchField = ({ searchText, onSearchTextChange, disabled }: SearchFieldProps) => {
  return (
    // margin left and width are set like this to shift the search bar over by 1px and reduce the width.
    // this ensures that the focus border doesn't overflow the dialog container and get cut off
    <InputGroup css={{ mb: '$space4', ml: '1px', width: 'calc(100% - 2px)' }}>
      <TextInput
        value={searchText}
        onChange={(e) => onSearchTextChange(e.target.value)}
        placeholder="Search"
        disabled={disabled}
      />
      <InputGroup.RightElement>
        <Icon name="MagnifyingGlass" size="small" color="default" />
      </InputGroup.RightElement>
    </InputGroup>
  );
};
