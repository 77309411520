import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import React from 'react';

import { Banner } from '@attentive/picnic';

import { useIsOperatorUIToggleDisplayed } from './hooks';

type OperatorUIToggleErrors = null | 'FLAG_TOGGLE_ERROR' | 'FEEDBACK_ERROR';

export const errorAtom = atomWithReset<OperatorUIToggleErrors>(null);

export const OperatorUIToggleError = () => {
  const isBannerDisplayed = useIsOperatorUIToggleDisplayed();
  const [hasError, setHasError] = useAtom(errorAtom);

  if (!hasError || !isBannerDisplayed) return null;

  const errorMessage =
    hasError === 'FLAG_TOGGLE_ERROR'
      ? 'Unable to switch UI at the moment. Please try again or reload page if problem persists.'
      : 'Unable to receive feedback at this time. Please try again later.';

  return (
    <Banner
      variant="error"
      css={{ mb: '$space6' }}
      dismissible
      onDismiss={() => {
        setHasError(null);
      }}
    >
      <Banner.Text>{errorMessage}</Banner.Text>
    </Banner>
  );
};
