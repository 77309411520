import {
  punchhFreeTextInputSelectors,
  punchhMultiValueSelectors,
  shopifyDynamicSelectors,
  yotpoSingleValueSelectors,
} from '../components/SegmentCreateEditModal/constants';
import {
  getSubscriberAttributeDataType,
  getVendorAttributeDataType,
  SubscriberAttributeDataTypeMap,
  VendorAttributeDataTypeMap,
} from '../components/SegmentCreateEditModal/utils/attributeDataTypeMap';
import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  EmailActionFilter,
  FilterType,
  KnownKlaviyoProfileAttr,
  KnownSegmentIoFilterAttrs,
  ShopifyAction,
  PropertiesFilter,
  PunchhFilterAttrs,
  SegmentIoSupportedProfileAttrs,
  SegmentParameters,
  ShopifyFilter,
  SubscriberPreference,
  TextActionFilter,
  UserPropertyType,
  Vendor,
  YotpoFilterAttrs,
  WellKnownPropertyTypes,
  knownRechargeFilterAttrs,
  RechargeFilterAttrs,
  WellKnownPropertyLocaleTypes,
  actionFiltersWithFrequency,
} from '../constants';

export function getAttributeFilterType(filterType?: FilterType) {
  return {
    isActionFilterType: filterType === FilterType.ACTION,
    isAttributeFilterType: filterType === FilterType.ATTRIBUTE,
    isSegmentMembershipFilterType: filterType === FilterType.MEMBERSHIP,
  };
}

export function getRechargeNamespacedFilter(
  { vendor, attribute, nestedPropertyFilters }: SegmentParameters,
  includeVendor?: boolean
) {
  if (!nestedPropertyFilters || !nestedPropertyFilters.length) {
    throw new Error('Missing nested properties for recharge attribute');
  }

  const childAttributes = knownRechargeFilterAttrs.map((item) => {
    const [, , attrName] = item.split(':');
    return attrName;
  });

  const selectedAttribute = nestedPropertyFilters.find((item) =>
    childAttributes.includes(item.fieldName)
  );

  if (!selectedAttribute) {
    throw new Error('Cannot find matching nested property for recharge attribute');
  }

  // getFilter expects the filter to be name spaced to a vendor.
  if (includeVendor) {
    return `${vendor}:${attribute}:${selectedAttribute.fieldName}`;
  }

  return `${attribute}:${selectedAttribute.fieldName}`;
}

export function getFilter(parameters: SegmentParameters) {
  const {
    profileAttribute,
    subscriberAction,
    subscriberActionChannel,
    subscriberPreferenceKey,
    subscriberProperty,
    userPropertyType,
    vendor,
  } = parameters;
  if (isLanguageType(parameters)) {
    return `${UserPropertyType.WELL_KNOWN_PROPERTY}:${WellKnownPropertyTypes.LOCALE}:${WellKnownPropertyLocaleTypes.LANGUAGE}`;
  }

  if (isSourceFilterType(parameters)) {
    return `${subscriberActionChannel}:${subscriberProperty}`;
  }

  if (isRechargeAttributeType(parameters)) {
    return getRechargeNamespacedFilter(parameters, true);
  }

  if (
    isTextEventType(parameters) ||
    isEmailEventType(parameters) ||
    isJoinedEventType(parameters)
  ) {
    return `${subscriberActionChannel}:${subscriberAction}`;
  }

  if (isShopifyActionType(parameters)) {
    // this check will be removed after the BE runs a migration to update ORDER_CONFIRMED segments to PURCHASED
    // @ts-ignore
    const actionValue =
      subscriberAction === 'ORDER_CONFIRMED' ? ActionFilter.PURCHASED : subscriberAction;
    return `${Vendor.SHOPIFY}:${actionValue}`;
  }

  if (isSupportedVendor(parameters)) {
    return `${vendor}:${profileAttribute}`;
  }

  if (subscriberPreferenceKey) {
    return SubscriberPreference.SUBSCRIBER_PREFERENCES;
  }

  return (
    subscriberAction || subscriberProperty || profileAttribute || userPropertyType || undefined
  );
}

export function isActionTypeFilter(parameters: SegmentParameters, filter?: string) {
  if (filter && Object.values(ActionFilter).includes(filter as ActionFilter)) {
    return true;
  }

  return (
    isShopifyActionType(parameters) ||
    isJoinedEventType(parameters) ||
    isTextEventType(parameters) ||
    isEmailEventType(parameters)
  );
}

export function isTextKeywordFilterType({
  subscriberAction,
  subscriberActionChannel,
}: SegmentParameters) {
  return (
    subscriberActionChannel === ActionChannel.TEXT && subscriberAction === ActionFilter.SENT_MESSAGE
  );
}

export function isBazaarvoiceEventType({ subscriberAction }: SegmentParameters) {
  return (
    subscriberAction &&
    [ActionFilter.COMPLETED_REVIEW, ActionFilter.LEFT_A_RATING].includes(
      subscriberAction as ActionFilter
    )
  );
}

export function isBirthdayMonthFilterType(filter?: string) {
  return filter === YotpoFilterAttrs.BIRTHDAY_MONTH;
}

export function isBooleanFilterType(
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const { attribute, profileAttribute, vendor } = parameters;

  if (isCustomAttributeType(parameters) && attribute) {
    const { isBoolean } = getSubscriberAttributeDataType(attribute, subscriberAttributeDataTypeMap);
    return isBoolean;
  }

  if (!vendor || !profileAttribute) return false;

  const { isBoolean } = getVendorAttributeDataType(
    vendor,
    profileAttribute,
    vendorAttributeDataTypeMap
  );

  return isBoolean;
}

export function isCarrierFilterType(filter?: string) {
  return filter === PropertiesFilter.CARRIER;
}

export function isCountryFilterType(filter?: string) {
  return filter === PropertiesFilter.COUNTRY;
}

export function isCustomAttributeType({ userPropertyType }: SegmentParameters) {
  return userPropertyType === UserPropertyType.CUSTOM_PROPERTY_ID;
}

export function isCustomEventType({ customEventOption }: SegmentParameters) {
  return Boolean(customEventOption);
}

export function isDeviceTypeFilterType(filter?: string) {
  return filter === PropertiesFilter.DEVICE_TYPE;
}

export function isDistanceFilterType(filter?: string) {
  return filter === PropertiesFilter.DISTANCE;
}

export function isEmailEventType({ subscriberActionChannel, subscriberAction }: SegmentParameters) {
  return (
    subscriberActionChannel === ActionChannel.EMAIL &&
    subscriberAction &&
    subscriberAction in EmailActionFilter
  );
}

export function isEventType({ subscriberAction }: SegmentParameters) {
  return Boolean(subscriberAction);
}

export function isIntegerFilterType(
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const { profileAttribute, attribute, vendor } = parameters;

  if ((isWellKnownProperty(parameters) || isCustomAttributeType(parameters)) && attribute) {
    const { isInteger } = getSubscriberAttributeDataType(attribute, subscriberAttributeDataTypeMap);
    return isInteger;
  }

  if (!profileAttribute || !vendor) return false;

  const { isFloat, isInteger } = getVendorAttributeDataType(
    vendor,
    profileAttribute,
    vendorAttributeDataTypeMap
  );

  return isFloat || isInteger;
}

export function isJoinedEventType({ subscriberAction }: SegmentParameters) {
  return subscriberAction === ActionFilter.JOINED;
}

export function isKlaviyoTextAttributeType(
  parameters: SegmentParameters,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const { profileAttribute, vendor } = parameters;

  if (!profileAttribute || !vendor) return false;

  if (!isSpecificVendorAttributeType(parameters, Vendor.PROFILE_VENDOR_KLAVIYO)) return false;

  const { isText } = getVendorAttributeDataType(
    vendor,
    profileAttribute,
    vendorAttributeDataTypeMap
  );

  return isText;
}

export function isKlaviyoEventType({ subscriberActionSource }: SegmentParameters) {
  return subscriberActionSource === ActionSource.KLAVIYO;
}

export function isKlaviyoOptInDateFilter(filter?: string) {
  return filter === KnownKlaviyoProfileAttr.CREATED;
}

export function isLocationFilterType(filter?: string) {
  return [
    PropertiesFilter.STATE,
    PropertiesFilter.CITY,
    PropertiesFilter.LEGACY_POSTAL_CODE,
  ].includes(filter as PropertiesFilter);
}

export function isMailExchangeType({ attribute }: SegmentParameters) {
  return attribute === WellKnownPropertyTypes.MAIL_EXCHANGE;
}

export function isWellKnownCountryType({ attribute }: SegmentParameters) {
  return attribute === WellKnownPropertyTypes.COUNTRY;
}

export function isWellKnownProperty({ userPropertyType }: SegmentParameters) {
  return userPropertyType === UserPropertyType.WELL_KNOWN_PROPERTY;
}

export function hasNestedAttribute(
  { nestedPropertyFilters }: SegmentParameters,
  attribute: string
) {
  return Boolean(getNestedAttribute({ nestedPropertyFilters }, attribute));
}

export function getNestedAttribute<T>(
  { nestedPropertyFilters }: SegmentParameters,
  attribute: string
) {
  if (!nestedPropertyFilters || nestedPropertyFilters.length === 0) {
    return;
  }

  return nestedPropertyFilters.find(({ fieldName }) => fieldName === attribute) as unknown as T;
}

export function isLanguageType(parameters: SegmentParameters) {
  return (
    parameters.attribute === WellKnownPropertyTypes.LOCALE &&
    hasNestedAttribute(parameters, WellKnownPropertyLocaleTypes.LANGUAGE)
  );
}

export function isNewLocationFilterType(filter?: string) {
  return filter === PropertiesFilter.BULK_POSTAL_CODES;
}

export function isOperatingSystemFilterType(filter?: string) {
  return filter === PropertiesFilter.OPERATING_SYSTEM;
}

export function isPunchhSingleValueAttributeType(parameters: SegmentParameters) {
  const { profileAttribute, vendor } = parameters;

  if (!profileAttribute || !vendor) return false;

  return (
    isSpecificVendorAttributeType(parameters, Vendor.PUNCHH) &&
    punchhFreeTextInputSelectors.has(`${vendor}:${profileAttribute}` as PunchhFilterAttrs)
  );
}

export function isPunchhMultiValueAttributeType(parameters: SegmentParameters) {
  const { profileAttribute, vendor } = parameters;

  if (!profileAttribute || !vendor) return false;

  return (
    isSpecificVendorAttributeType(parameters, Vendor.PUNCHH) &&
    punchhMultiValueSelectors.has(`${vendor}:${profileAttribute}` as PunchhFilterAttrs)
  );
}

export function isRechargeAttributeType(parameters: SegmentParameters) {
  return isSpecificVendorAttributeType(parameters, Vendor.RECHARGE);
}

export function isRechargeStatusAttribute(parameters: SegmentParameters) {
  return isSpecificRechargeAttribute(RechargeFilterAttrs.STATUS, parameters);
}

export function isRechargeTimestampType(
  parameters: SegmentParameters,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  if (!isRechargeAttributeType(parameters)) {
    return false;
  }

  const rootAttributeKey = getRechargeNamespacedFilter(parameters);
  const { isTimestamp } = getVendorAttributeDataType(
    Vendor.RECHARGE,
    rootAttributeKey,
    vendorAttributeDataTypeMap
  );

  return isTimestamp;
}

export function isSpecificRechargeAttribute(attributeName: string, parameters: SegmentParameters) {
  if (!isSpecificVendorAttributeType(parameters, Vendor.RECHARGE)) {
    return false;
  }

  const rootAttributeKey = getRechargeNamespacedFilter(parameters);
  const [, childAttributeName] = rootAttributeKey.split(':');

  return childAttributeName === attributeName;
}

export function isSegmentIoCustomAttributeType(parameters: SegmentParameters) {
  if (!isSpecificVendorAttributeType(parameters, Vendor.SEGMENT_IO)) {
    return false;
  }

  return !SegmentIoSupportedProfileAttrs.includes(
    `${Vendor.SEGMENT_IO}:${parameters.profileAttribute}` as KnownSegmentIoFilterAttrs
  );
}

export function isSegmentIoMultiValueType(
  parameters: SegmentParameters,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const { profileAttribute } = parameters;

  if (!profileAttribute) return false;

  if (!isSpecificVendorAttributeType(parameters, Vendor.SEGMENT_IO)) return false;

  const { isText } = getVendorAttributeDataType(
    Vendor.SEGMENT_IO,
    profileAttribute,
    vendorAttributeDataTypeMap
  );

  return isText;
}

export function isShopifyDynamicValueAttributeType(parameters: SegmentParameters) {
  const { profileAttribute } = parameters;

  if (!profileAttribute) return false;

  return (
    isSpecificVendorAttributeType(parameters, Vendor.SHOPIFY) &&
    shopifyDynamicSelectors.has(`${Vendor.SHOPIFY}:${profileAttribute}` as ShopifyFilter)
  );
}

export function isShopifyActionType({ subscriberAction, isGroupedByOrderId }: SegmentParameters) {
  return (
    (subscriberAction && subscriberAction in ShopifyAction) ||
    (subscriberAction === ActionFilter.PURCHASED && isGroupedByOrderId)
  );
}

export function isShopifyOptInDateFilter(filter?: string) {
  return filter === ShopifyFilter.ACCEPTS_MARKETING_UPDATED_TIME;
}

export function isSiteFilterType(filter?: string) {
  return filter === ActionFilter.VISITED_SITE;
}

export function isSourceFilterType({ subscriberProperty }: SegmentParameters) {
  return subscriberProperty === PropertiesFilter.SOURCE;
}

export function isSpecificVendorAttributeType(
  { vendor }: SegmentParameters,
  expectedVendor: Vendor
) {
  return vendor === expectedVendor;
}

export function isSubscriberPreferenceFilterType(filter?: string) {
  return filter === SubscriberPreference.SUBSCRIBER_PREFERENCES;
}

export function isSupportedVendor(parameters: SegmentParameters) {
  const vendors = [
    Vendor.MPARTICLE,
    Vendor.PROFILE_VENDOR_KLAVIYO,
    Vendor.PUNCHH,
    Vendor.RECHARGE,
    Vendor.SEGMENT_IO,
    Vendor.SHOPIFY,
    Vendor.SMILE,
    Vendor.YOTPO,
  ];

  return vendors.some((vendor) => isSpecificVendorAttributeType(parameters, vendor));
}

export function isTextEventType({ subscriberActionChannel, subscriberAction }: SegmentParameters) {
  return (
    subscriberActionChannel === ActionChannel.TEXT &&
    subscriberAction &&
    subscriberAction in TextActionFilter
  );
}

export function isTimestampFilterType(
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const { attribute, profileAttribute, vendor } = parameters;

  if ((isWellKnownProperty(parameters) || isCustomAttributeType(parameters)) && attribute) {
    const { isTimestamp } = getSubscriberAttributeDataType(
      attribute,
      subscriberAttributeDataTypeMap
    );
    return isTimestamp;
  }

  if (!profileAttribute || !vendor) return false;

  const { isTimestamp } = getVendorAttributeDataType(
    vendor,
    profileAttribute,
    vendorAttributeDataTypeMap
  );

  return isTimestamp;
}

export function isVendorAttributeType({ profileAttribute, vendor }: SegmentParameters) {
  return Boolean(profileAttribute && vendor);
}

function isVendorAttrTextValue(
  parameters: SegmentParameters,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  const { profileAttribute, vendor } = parameters;

  if (!profileAttribute || !vendor) return false;

  // Shopify and Klaviyo currently has specific handling for their text types
  if (vendor === Vendor.SHOPIFY || vendor === Vendor.PROFILE_VENDOR_KLAVIYO) return false;

  const { isText } = getVendorAttributeDataType(
    vendor,
    profileAttribute,
    vendorAttributeDataTypeMap
  );

  return isText;
}

export function isYotpoSingleValueAttributeType(parameters: SegmentParameters) {
  const { profileAttribute, vendor } = parameters;

  if (!profileAttribute || !vendor) return false;

  return (
    isSpecificVendorAttributeType(parameters, Vendor.YOTPO) &&
    yotpoSingleValueSelectors.has(`${vendor}:${profileAttribute}` as YotpoFilterAttrs)
  );
}

export function usesCustomAttributeText(
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap
) {
  const { attribute } = parameters;

  if (!attribute || !isCustomAttributeType(parameters)) {
    return false;
  }

  const { isEnum, isText } = getSubscriberAttributeDataType(
    attribute,
    subscriberAttributeDataTypeMap
  );

  return isEnum || isText;
}

export function usesCustomVendorAttr(
  parameters: SegmentParameters,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) {
  return [
    isSegmentIoMultiValueType,
    isPunchhMultiValueAttributeType,
    isYotpoSingleValueAttributeType,
    isVendorAttrTextValue,
  ].some((fn) => fn(parameters, vendorAttributeDataTypeMap));
}

export function usesFrequency(parameters: SegmentParameters, filter?: string) {
  if (filter && actionFiltersWithFrequency.includes(filter as ActionFilter)) {
    return true;
  }

  const { customEventOption } = parameters;

  return (
    Boolean(customEventOption) ||
    isShopifyActionType(parameters) ||
    isTextEventType(parameters) ||
    isEmailEventType(parameters)
  );
}

export function usesHasVerb(parameters: SegmentParameters, filter?: string) {
  return isActionTypeFilter(parameters, filter) && !isBazaarvoiceEventType(parameters);
}

export function usesIsVerb(
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap,
  filter?: string,
  segmentFilterType?: FilterType
) {
  // Boolean filters use a hardcoded 'is' verb
  if (isBooleanFilterType(parameters, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap)) {
    return false;
  }

  if (isRechargeStatusAttribute(parameters)) {
    return true;
  }

  // recharge timestamp attributes use a hardcoded 'is' verb
  if (isRechargeTimestampType(parameters, vendorAttributeDataTypeMap)) {
    return false;
  }

  // TODO: sc - this will be further cleaned up as we improve the handling of klaviyo attributes
  if (
    isIntegerFilterType(parameters, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap) ||
    isTimestampFilterType(parameters, subscriberAttributeDataTypeMap, vendorAttributeDataTypeMap)
  ) {
    return true;
  }

  if (
    filter &&
    !isActionTypeFilter(parameters, filter) &&
    !isSpecificVendorAttributeType(parameters, Vendor.PROFILE_VENDOR_KLAVIYO)
  ) {
    return true;
  }

  const { isSegmentMembershipFilterType } = getAttributeFilterType(segmentFilterType);

  return isKlaviyoOptInDateFilter(filter) || isSegmentMembershipFilterType;
}

export function usesTimeSelector(
  parameters: SegmentParameters,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap,
  filter?: string
) {
  if (isCustomEventType(parameters)) return true;

  if (isActionTypeFilter(parameters, filter)) {
    return true;
  }

  return isTimestampFilterType(
    parameters,
    subscriberAttributeDataTypeMap,
    vendorAttributeDataTypeMap
  );
}
