import React from 'react';

import { Box, Button, Icon, Text } from '@attentive/picnic';

const PU_BUTTON_MAX_WIDTH = '200px';

type ItemSelectDialogButtonProps = {
  content: string;
  disabled?: boolean;
  onClick: () => void;
};

export const ItemSelectDialogButton = ({
  content,
  disabled,
  onClick,
}: ItemSelectDialogButtonProps) => {
  return (
    <Button onClick={onClick} variant="basic" size="small" disabled={disabled}>
      <Box css={{ display: 'flex', alignItems: 'center', fontWeight: '$regular' }}>
        <Text
          variant="caption"
          css={{
            maxWidth: PU_BUTTON_MAX_WIDTH,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {content}
        </Text>
        <Icon name="PlusSign" size="extraSmall" css={{ ml: '$space2' }} />
      </Box>
    </Button>
  );
};
