import React, { FC } from 'react';

import { FormField, Select } from '@attentive/picnic';

import { StringValueDisplayableOption } from '../../../constants';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface PreferenceSelectorProps {
  value: string;
  preferences: StringValueDisplayableOption[];
  onChange: (value: string) => void;
}

const PreferenceSelector: FC<PreferenceSelectorProps> = ({ value, preferences, onChange }) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !value;

  return (
    <FormField>
      <Select
        value={value}
        placeholder="Choose a preference"
        state={error ? 'error' : 'normal'}
        onChange={(newValue: string) => onChange(newValue)}
        size="small"
      >
        {preferences.map(({ displayName, optionValue }) => (
          <Select.Item key={optionValue} value={optionValue}>
            {displayName}
          </Select.Item>
        ))}
      </Select>
      {error && <FormField.ErrorText>Please choose a preference.</FormField.ErrorText>}
    </FormField>
  );
};

export default PreferenceSelector;
