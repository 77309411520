import React from 'react';

import { useCurrentUser } from '@attentive/acore-utils';
import { CountryCode } from '@attentive/locale-utils';
import { FormField, Heading, MultiSelect, SearchableSelect, Separator } from '@attentive/picnic';

import { NumberValueDisplayableOption } from '../../../../constants';
import { separateAndSortCountries } from '../../utils/sortCountries';

type MultiCountrySelectorProps = {
  countries: NumberValueDisplayableOption[];
  locationValues?: string[];
  onChange: (countries: string[]) => void;
};

const PU_SINGLE_COUNTRY_MIN_WIDTH = '230px';

export const MultiCountrySelector: React.VFC<MultiCountrySelectorProps> = ({
  countries,
  locationValues,
  onChange,
}) => {
  const currentUser = useCurrentUser();
  const { allCountries, supportedCountries } = separateAndSortCountries(
    countries,
    currentUser.company.country as CountryCode,
    true
  );

  return (
    <FormField>
      <MultiSelect
        value={locationValues || []}
        onChange={onChange}
        size="small"
        placeholder="Choose countries"
      >
        {supportedCountries && (
          <Heading
            as="li"
            variant="subheading"
            css={{
              color: '$textSubdued',
              paddingBottom: '$space1',
              padding: '$space3',
              textTransform: 'uppercase',
            }}
          >
            My regions
          </Heading>
        )}
        {supportedCountries.map(({ displayName, optionValue }) => (
          <MultiSelect.Item key={optionValue} value={optionValue}>
            {displayName}
          </MultiSelect.Item>
        ))}
        {supportedCountries && <Separator />}
        {allCountries.map(({ displayName, optionValue }) => (
          <MultiSelect.Item key={optionValue} value={optionValue}>
            {displayName}
          </MultiSelect.Item>
        ))}
      </MultiSelect>
    </FormField>
  );
};

type SingleCountrySelectorProps = {
  countries: NumberValueDisplayableOption[];
  disabled?: boolean;
  onChange: (country: string) => void;
  selectedCountryId?: string;
};

export const SingleCountrySelector: React.VFC<SingleCountrySelectorProps> = ({
  countries,
  onChange,
  selectedCountryId,
  disabled,
}) => {
  const currentUser = useCurrentUser();
  const { allCountries, supportedCountries } = separateAndSortCountries(
    countries,
    currentUser.company.country as CountryCode,
    true
  );
  const selectedCountry = selectedCountryId || '';
  return (
    <FormField>
      <SearchableSelect
        onChange={onChange}
        value={selectedCountry}
        size="small"
        placeholder="Choose a country"
        css={{ minWidth: PU_SINGLE_COUNTRY_MIN_WIDTH }}
        disabled={disabled}
      >
        {supportedCountries.map(({ displayName, optionValue }) => (
          <SearchableSelect.Item key={optionValue} value={optionValue}>
            {displayName}
          </SearchableSelect.Item>
        ))}
        <Separator />
        {allCountries.map(({ displayName, optionValue }) => (
          <SearchableSelect.Item key={optionValue} value={optionValue}>
            {displayName}
          </SearchableSelect.Item>
        ))}
      </SearchableSelect>
    </FormField>
  );
};
