import React from 'react';

import {
  MenuItem,
  PublicSelectGroup,
  PublicSelectItem,
  PublicSelectIconItem,
  PublicSelectThirdPartyIconItem,
  MenuItemValueType,
} from '@attentive/picnic';

type MappedChildren = React.ReactNode & {
  props: {
    value: MenuItemValueType;
    children: React.ReactNode;
  };
};

enum SelectItemTypes {
  DEFAULT = 'default',
  ICON = 'icon',
  THIRD_PARTY_ICON = 'thirdPartyIcon',
}

export interface PinnedMenuItem extends MenuItem {
  pinned: boolean;
}

export type PinnedMenuItems = PinnedMenuItem[];

const SELECT_ITEM_TYPES = [PublicSelectItem, PublicSelectIconItem, PublicSelectThirdPartyIconItem];

const isSelectElement = (type: React.FC) => SELECT_ITEM_TYPES.includes(type);

// This component is forked from Picnic to add support for pinned menu items
const getChildProps = (child: MappedChildren) => {
  if (!React.isValidElement(child)) return undefined;

  const props: PinnedMenuItem = {
    value: child.props.value,
    label: child.props.children,
    disabled: child.props.disabled,
    css: child.props.css,
    name: undefined,
    type: SelectItemTypes.DEFAULT,
    pinned: Boolean(child.props.pinned),
  };

  if (child.type === PublicSelectIconItem) {
    props.name = child.props.name;
    props.type = SelectItemTypes.ICON;
  }

  if (child.type === PublicSelectThirdPartyIconItem) {
    props.name = child.props.name;
    props.type = SelectItemTypes.THIRD_PARTY_ICON;
  }

  return props;
};

export const useItems = (children: React.ReactNode) => {
  const items = React.useMemo(
    () =>
      React.Children.toArray(children as MappedChildren[])
        .map((child, _) => {
          if (React.isValidElement(child) && isSelectElement(child.type as React.FC)) {
            return getChildProps(child);
          }
          if (React.isValidElement(child) && child.type === PublicSelectGroup) {
            return React.Children.map(child.props.children as MappedChildren[], (labelledChild) => {
              if (
                React.isValidElement(labelledChild) &&
                isSelectElement(labelledChild.type as React.FC)
              ) {
                return getChildProps(labelledChild);
              }
            });
          }
          return undefined;
        })
        .filter(Boolean)
        .flat(),
    [children]
  );
  return items;
};
