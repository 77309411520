import { deserializeGQLDateTime } from '@attentive/data';
import { DateFormat, Locale, formatDateWithTimezone } from '@attentive/locale-utils';
import { ContainedLabelVariant } from '@attentive/picnic';

import { DisplayableOption } from '../../constants';

import {
  CampaignMessageGroup,
  ComposeMessage,
  ComposeCampaign,
  CampaignMessageGroupStatus,
  ComposeCampaignStatus,
  CampaignMessageGroups,
} from './constants';

export type MessageRowStatus = 'Scheduled' | 'Delivered' | 'Sending' | 'Paused' | 'Draft';

export const getCMGSentTimeString = (
  campaignMessageGroup: CampaignMessageGroup,
  companyTimezone = 'America/New_York',
  status?: MessageRowStatus
) => {
  const { startTime, scheduleType } = campaignMessageGroup;
  if (!startTime) return null;

  const timezoneRelative = scheduleType === 'CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_LOCAL_TIME';
  const sendTimeUTC = deserializeGQLDateTime(startTime);
  const dateFormat = timezoneRelative
    ? DateFormat.LONG_DATE_RELATIVE
    : DateFormat.LONG_DATE_TIMEZONE;

  const formattedTimestamp = formatDateWithTimezone(sendTimeUTC, dateFormat, {
    timezone: companyTimezone,
    locale: Locale.enUS,
  });

  return `${status === 'Delivered' ? 'Sent' : 'Updated'} on ${formattedTimestamp}`;
};

export const getMessageRowStatus = (
  status: CampaignMessageGroupStatus | ComposeCampaignStatus
): MessageRowStatus | undefined => {
  switch (status) {
    case 'COMPOSE_CAMPAIGN_STATUS_SCHEDULED':
    case 'CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED':
      return 'Scheduled';
    case 'CAMPAIGN_MESSAGE_GROUP_STATUS_SENT':
    case 'COMPOSE_CAMPAIGN_STATUS_SENT':
      return 'Delivered';
    case 'COMPOSE_CAMPAIGN_STATUS_SENDING_STARTED':
      return 'Sending';
    case 'CAMPAIGN_MESSAGE_GROUP_STATUS_PAUSED':
      return 'Paused';
    default:
      return undefined;
  }
};

export const getCampaignUpdatedTimeString = (
  campaign: ComposeCampaign,
  companyTimezone = 'America/New_York'
) => {
  const { updated, campaignStatus } = campaign;
  const sendTimeUTC = deserializeGQLDateTime(updated);
  const dateFormat = DateFormat.LONG_DATE_TIMEZONE;
  const status = getMessageRowStatus(campaignStatus);

  const formattedTimestamp = formatDateWithTimezone(sendTimeUTC, dateFormat, {
    timezone: companyTimezone,
    locale: Locale.enUS,
  });

  return `${status === 'Delivered' ? 'Sent' : 'Updated'} on ${formattedTimestamp}`;
};

export const getDisplayableOption = (
  cmg: CampaignMessageGroup,
  message: ComposeMessage
): DisplayableOption => ({
  optionValue: message.messageId,
  displayName: cmg.messageMembers.length === 1 ? cmg.name : `${cmg.name} - ${message.name}`,
});

export const getDisplayableOptionsFromMessageGroup = (
  cmg: CampaignMessageGroup
): DisplayableOption[] => {
  return cmg.messageMembers.map(({ composeMessage: message }) =>
    getDisplayableOption(cmg, message)
  );
};

export const StatusToVariantMapping: Record<MessageRowStatus, ContainedLabelVariant> = {
  Scheduled: 'decorative3',
  Delivered: 'decorative1',
  Sending: 'neutral',
  Draft: 'decorative4',
  Paused: 'informational',
};

export const getRowsFromCampaignMessageGroups = (cmgs: CampaignMessageGroups) => {
  return cmgs.reduce((total, { node: cmg }) => {
    // if there are > 1 message members, we will display a row for the message group + each message member
    // if there is only 1 message member, we will only display a row for the message group. it will have the same name and startTime as the message member
    const newRows = cmg.messageMembers.length > 1 ? cmg.messageMembers.length + 1 : 1;
    return total + newRows;
  }, 0);
};
