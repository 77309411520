/**
 * @generated SignedSource<<c5e1b8985ded3ace7a26d1347afde8f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"UsageTable_TargetingCommon_CustomPropertyDefinition">;
  readonly " $fragmentType": "CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition";
};
export type CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition$key = {
  readonly " $data"?: CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition">;
};

import CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery_graphql from './CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery_graphql,
      "identifierField": "id"
    }
  },
  "name": "CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UsageTable_TargetingCommon_CustomPropertyDefinition"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CustomPropertyDefinition",
  "abstractKey": null
};

(node as any).hash = "b4804eaa6d7a4fc1994978d97c801dd0";

export default node;
