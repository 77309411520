import { useQuery, UseQueryOptions } from '@attentive/data/react-query';

import { useTargetingCommonLogger } from '../../logger/useTargetingCommonLogger';

import { fetchKlaviyoProfileAttrValues } from './api';
import { KlaviyoAttrValueResponse } from './constants';

export const useKlaviyProfileAttrValues = (
  attr: string,
  queryOptions?: UseQueryOptions<KlaviyoAttrValueResponse | null, Error>
) => {
  const { logError } = useTargetingCommonLogger();

  return useQuery<KlaviyoAttrValueResponse | null, Error>(
    ['profileAttr', attr],
    () => {
      return fetchKlaviyoProfileAttrValues(attr).then((response) => {
        const { body, status } = response;
        if (!body) throw new Error('Unexpected empty response');
        if (status !== 200) {
          const errorMsg = body?.errors
            ? body.errors[0].message
            : 'Profile attribute options failed to load';

          // some of these attributes are 'restricted fields'. the endpoint responds 403, but the UI
          // should not treat this as an error, and should treat it like an attr with no options.
          if (status === 403 && errorMsg === `${attr} is a restricted field`) {
            return { keys: [] };
          }

          throw new Error(errorMsg);
        }
        return body;
      });
    },
    {
      ...queryOptions,
      onError: (err) => {
        logError(err);
        if (queryOptions?.onError) {
          queryOptions.onError(err);
        }
      },
    }
  );
};
