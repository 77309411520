import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import {
  SegmentParameters,
  TimeComparatorType,
  durationTimeComparators,
  relativeDateRangeTimeComparators,
  singleDateTimeComparators,
} from '../../../../constants';
import { useKlaviyoEmailFeatureValidDate, useCompany } from '../../../../hooks';
import { isCustomAttributeType, isKlaviyoEventType } from '../../../../utils/typeAssertions';
import { MAX_DAYS } from '../../../../utils/validator/time';
import { SegmentConditionContainer } from '../../../SegmentConditionContainer';

import { DurationFields } from './components/DurationFields';
import { MonthMultiSelect } from './components/MonthMultiSelect';
import { PartialDateToggle } from './components/PartialDateToggle';
import { RelativeDateRangeFields } from './components/RelativeDateRangeFields';
import { TimeComparatorSelect } from './components/TimeComparatorSelect';
import { TimeSelectorDatePicker } from './components/TimeSelectorDatePicker';
import { TimeSelectorDateRangePicker } from './components/TimeSelectorDateRangePicker';
import { TimeValue } from './constants';
import {
  DateStringToSecondsConfig,
  convertSelectedDateStringToSeconds,
  defaultGetDateValues,
  updateTimeValueTimestamps,
  usesUtcDate,
} from './utils';

interface TimeSelectorProps {
  parameters: SegmentParameters;
  onChange: (value: TimeValue) => void;
  allowPartialDateComparison?: boolean;
  getDateValues?: (parameters: SegmentParameters) => TimeValue;
}

export const TimeSelector = ({
  allowPartialDateComparison,
  parameters,
  onChange,
  getDateValues = defaultGetDateValues,
}: TimeSelectorProps) => {
  const values = getDateValues(parameters);
  const isPartialDate = Boolean(values.ignoreYear);

  const { timezone } = useCompany();
  const enablePartialDatesFeatureFlag = useCompanyFeatureFlag('ENABLE_SEGMENTATION_PARTIAL_DATES');

  const isKlaviyoEventFilter = isKlaviyoEventType(parameters);
  const isCustomAttribute = isCustomAttributeType(parameters);
  const useUtc = usesUtcDate(parameters);
  const { klaviyoEmailDaysMax } = useKlaviyoEmailFeatureValidDate(isKlaviyoEventFilter);
  const daysMax = isKlaviyoEventFilter ? klaviyoEmailDaysMax : MAX_DAYS;

  const enablePartialDates = allowPartialDateComparison && enablePartialDatesFeatureFlag;
  const defaultDateToStringConfig: DateStringToSecondsConfig = {
    timeZone: timezone,
    useUtc,
  };

  const onPartialDateChange = (newIsPartialDate: boolean) => {
    const updatedTimestamps = updateTimeValueTimestamps(values, newIsPartialDate);

    onChange({
      ...values,
      ...updatedTimestamps,
      ignoreYear: newIsPartialDate,
    });
  };

  return (
    <>
      <SegmentConditionContainer css={{ mr: '$space0' }}>
        <TimeComparatorSelect parameters={parameters} onChange={onChange} values={values} />
        {singleDateTimeComparators.includes(values.timeComparator) && (
          <TimeSelectorDatePicker
            parameters={parameters}
            values={values}
            onChange={(newDate) => {
              onChange({
                ...values,
                time: convertSelectedDateStringToSeconds(newDate, {
                  ...defaultDateToStringConfig,
                  isEndOfDay: values.timeComparator === TimeComparatorType.AFTER,
                }),
              });
            }}
          />
        )}
        {values.timeComparator === TimeComparatorType.BETWEEN && (
          <TimeSelectorDateRangePicker
            values={values}
            onChange={({ startDate, endDate }) =>
              onChange({
                ...values,
                startTime: convertSelectedDateStringToSeconds(startDate, {
                  ...defaultDateToStringConfig,
                  isEndOfDay: false,
                }),
                endTime: convertSelectedDateStringToSeconds(endDate, {
                  ...defaultDateToStringConfig,
                  isEndOfDay: true,
                }),
              })
            }
            parameters={parameters}
          />
        )}
        {durationTimeComparators.includes(values.timeComparator) && (
          <DurationFields
            allowHours={!isCustomAttribute}
            values={values}
            onChange={onChange}
            maxDays={daysMax}
          />
        )}
        {relativeDateRangeTimeComparators.includes(values.timeComparator) && (
          <RelativeDateRangeFields
            values={values}
            onChange={onChange}
            maxDays={daysMax}
            key={values.timeComparator}
          />
        )}
        {values.timeComparator === TimeComparatorType.IN_THE_MONTH && (
          <MonthMultiSelect values={values} onChange={onChange} />
        )}
      </SegmentConditionContainer>
      {enablePartialDates && (
        <PartialDateToggle value={isPartialDate} onChange={onPartialDateChange} />
      )}
    </>
  );
};
