import React, { FC } from 'react';

import { FormField } from '@attentive/picnic';

import { LocationType } from '../../../constants';
import { useLocationData } from '../../../hooks';
import { getPostalCodeLabel } from '../../../utils';

import { MultiSelectVirtualized as MultiSelect } from './MultiSelectVirtualized';
import PUFieldLoader from './PUFieldLoader';
import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

const OPTION_STALE_TIME = 30000;

interface OwnProps {
  locationType: LocationType;
  value?: string[];
  stateId: string;
  country: string | null;
  onChange: (newValue: string[]) => void;
}

const LocationSelector: FC<OwnProps> = ({ locationType, value, stateId, country, onChange }) => {
  const { data, isFetching } = useLocationData(stateId, LocationType.STATE, locationType, {
    staleTime: OPTION_STALE_TIME,
  });
  const showValidation = useShowExpressionValidationAtomValue();
  const errorState = showValidation && !value;

  const errorText =
    locationType === LocationType.CITY
      ? 'Please choose a city'
      : `Please choose a ${getPostalCodeLabel(country)}`;

  const noResults = Boolean(!isFetching && stateId !== '' && data && !data.length);

  const placeholder = getPlaceholderText(locationType, noResults, country);

  return (
    <FormField css={{ position: 'relative' }}>
      <MultiSelect
        value={value || []}
        onChange={onChange}
        size="small"
        maxTokens={10}
        placeholder={placeholder}
        disabled={stateId === '' || isFetching || noResults}
        state={errorState ? 'error' : 'normal'}
      >
        {(data || []).map(({ displayName, optionValue }) => (
          <MultiSelect.Item key={optionValue} value={`${optionValue}`}>
            {displayName}
          </MultiSelect.Item>
        ))}
      </MultiSelect>
      {errorState && <FormField.ErrorText>{errorText}</FormField.ErrorText>}
      {isFetching && <PUFieldLoader />}
    </FormField>
  );
};

function getPlaceholderText(
  locationType: LocationType,
  noResults: boolean,
  country: string | null
) {
  if (noResults) {
    return locationType === LocationType.CITY
      ? 'No cities available'
      : `No ${getPostalCodeLabel(country)}s available`;
  }

  return locationType === LocationType.CITY
    ? 'Choose city'
    : `Choose ${getPostalCodeLabel(country)}`;
}

export default LocationSelector;
