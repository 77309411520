/* eslint-disable no-case-declarations */
import { useCombobox } from 'downshift';
import React, { useState, useRef, useEffect } from 'react';

import {
  Box,
  List,
  Text,
  Icon,
  MenuItems,
  MenuItem,
  MenuItemValueType,
  getItemPropsType,
  MultiSelectProps,
  TagGroup,
  ArrowWrapper,
  MultiSelectTextInput,
  MultiSelectWrapper,
  PublicSelectGroup,
  PublicSelectItem,
  SelectPopout,
  useItems,
  useMatch,
} from '@attentive/picnic';

import { MultiSelectItemsList } from './NestedListComponents';

const BLUR_TIME_OFFSET = 30;
const itemToString = (item: MenuItem | null) => item?.label ?? '';

const MultiSelectComponent = ({
  placeholder = 'Search',
  children = [],
  css = {},
  value,
  size = 'medium',
  state = 'normal',
  disabled = false,
  onChange,
  maxTokens = 10,
}: MultiSelectProps) => {
  const [term, setTerm] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const allowBlur = useRef<boolean>(true);
  const allowBlurTimeoutId = useRef<number>();

  const handleChange = onChange as (value: MenuItemValueType[]) => void;

  const items = useItems(children) as MenuItems;
  const [selectedItems, setSelectedItems] = useState(
    items.filter((b) => (value as MenuItemValueType[]).includes(b.value))
  );

  useEffect(() => {
    setSelectedItems(items.filter((b) => (value as MenuItemValueType[]).includes(b.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, items]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => clearTimeout(allowBlurTimeoutId?.current);
  }, []);

  const filteredItems = useMatch(items, term);
  const itemsLookup = filteredItems.reduce(
    (prev: Record<MenuItemValueType, { item: MenuItem; index: number }>, curr, index) => {
      prev[curr.value] = {
        item: curr,
        index,
      };
      return prev;
    },
    {}
  );
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    openMenu,
    closeMenu,
  } = useCombobox({
    itemToString,
    inputValue: term,
    items: filteredItems,
    onStateChange: (comboboxState) => {
      const { inputValue, type, selectedItem } = comboboxState;
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setTerm(inputValue || '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          allowBlur.current = false;
          clearTimeout(allowBlurTimeoutId.current);
          setTimeout(() => (allowBlur.current = true), BLUR_TIME_OFFSET);
          if (selectedItem && !selectedItem.disabled) {
            let newValues;
            if (selectedItems.find((s) => s.value === selectedItem.value)) {
              newValues = selectedItems
                .filter((s) => s.value !== selectedItem.value)
                .map((s) => s.value);
              handleChange(newValues);
            } else {
              newValues = [...selectedItems.map((s) => s.value), selectedItem.value];
              handleChange(newValues);
              selectItem({ label: '', value: '' });
            }
            setTerm('');
          }
          break;
        default:
          break;
      }
    },
    // This keeps the select open on item click so that we can choose multiple options
    stateReducer: (comboboxState, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.InputBlur:
          const isOpenNew = allowBlur.current ? actionAndChanges.changes.isOpen : true;
          return { ...actionAndChanges.changes, isOpen: isOpenNew };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...actionAndChanges.changes,
            isOpen: true,
            highlightedIndex: comboboxState.highlightedIndex,
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });

  const focusOnInput = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current?.focus();
  };

  const toggleMenu = () => (isOpen ? closeMenu() : openMenu());

  const wrapperWidth = (wrapperRef.current?.offsetWidth || 0) as number;
  return (
    <MultiSelectWrapper
      ref={wrapperRef}
      onClick={focusOnInput}
      css={css}
      size={size}
      state={state}
      disabled={disabled}
    >
      <TagGroup
        wrapperWidth={wrapperWidth}
        selectedItems={selectedItems}
        removeSelectedItem={(removedValue) => {
          const newValues = value.filter((v) => v !== removedValue.value);
          handleChange(newValues);
        }}
        maxTokens={maxTokens}
        disabled={disabled}
      />

      <Box
        as="span"
        css={{ display: 'inline-flex', flexGrow: 1, alignItems: 'center' }}
        {...getComboboxProps()}
      >
        <MultiSelectTextInput
          placeholder={placeholder}
          data-testid="multi-select-input"
          disabled={disabled}
          {...getInputProps({ ref: inputRef, onClick: toggleMenu })}
        />
        <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
          <Icon
            css={{ display: 'block' }}
            name="ChevronDown"
            size="small"
            description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
            onClick={toggleMenu}
            data-testid="multi-select-icon"
          />
        </ArrowWrapper>
      </Box>

      <Box {...getMenuProps()} css={{ outline: 'none' }}>
        {isOpen && (
          <SelectPopout css={{ overflowY: 'hidden', padding: 0 }}>
            {filteredItems.length > 0 && (
              <List variant="unstyled">
                <MultiSelectItemsList
                  highlightedIndex={highlightedIndex}
                  value={value}
                  getItemProps={getItemProps as getItemPropsType}
                  itemsLookup={itemsLookup}
                  size={size}
                >
                  {children}
                </MultiSelectItemsList>
              </List>
            )}
            {filteredItems.length === 0 && (
              <Box css={{ p: '$space2' }}>
                <Text>No results</Text>
              </Box>
            )}
          </SelectPopout>
        )}
      </Box>
    </MultiSelectWrapper>
  );
};

// TODO: Extract `displayName` to a separate component
type ComponentType = typeof MultiSelectComponent & { displayName?: string };
interface CompositeComponent extends ComponentType {
  Group: typeof PublicSelectGroup;
  Item: typeof PublicSelectItem;
}

const MultiSelectVirtualized = MultiSelectComponent as CompositeComponent;
MultiSelectVirtualized.Group = PublicSelectGroup;
MultiSelectVirtualized.Item = PublicSelectItem;

MultiSelectVirtualized.displayName = 'MultiSelect';
MultiSelectVirtualized.Item.displayName = 'MultiSelect.Item';
MultiSelectVirtualized.Group.displayName = 'MultiSelect.Group';

export { MultiSelectVirtualized };
