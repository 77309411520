import React from 'react';

import { PicnicCss, Text } from '@attentive/picnic';

import {
  CustomAttributeValueGroup,
  IsVerbType,
  SegmentParameters,
  SelectableCampaignType,
} from '../../../../../../constants';
import { SegmentConditionContainer } from '../../../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../../../SegmentConditionItemContainer';
import VerbSelector, { VerbSelectorType } from '../../../VerbSelector';
import { getCampaignPropertyName } from '../../utils';
import { CampaignMessageSelector } from '../CampaignMessageSelector/CampaignMessageSelector';

interface OwnProps {
  parameters: SegmentParameters;
  setDialogIsOpen: (isOpen: boolean) => void;
  onChange: (parameters: SegmentParameters) => void;
  selectableCampaignType: SelectableCampaignType;
  css?: PicnicCss;
}

export const CampaignCondition: React.FC<OwnProps> = ({
  parameters,
  setDialogIsOpen,
  onChange,
  selectableCampaignType,
  css,
}) => {
  const msgCondition =
    selectableCampaignType === 'KLAVIYO_EMAIL'
      ? parameters.subscriberAttributeValueGroup
      : parameters.customAttributeValueGroup;

  if (!msgCondition) return null;

  const { isVerb, values } = msgCondition;

  const onChangeHandler = (newValues: CustomAttributeValueGroup) => {
    const propertyName = getCampaignPropertyName(selectableCampaignType);
    if (!parameters[propertyName]) return;

    onChange({
      ...parameters,
      [propertyName]: newValues,
    });
  };

  return (
    <SegmentConditionContainer css={css}>
      <SegmentConditionItemContainer>
        <Text>where campaign</Text>
      </SegmentConditionItemContainer>
      <VerbSelector
        verbType={VerbSelectorType.IS}
        value={isVerb || IsVerbType.IS}
        onChange={(verb) => onChangeHandler({ ...msgCondition, isVerb: verb as IsVerbType })}
      />
      <CampaignMessageSelector
        values={values}
        setDialogIsOpen={setDialogIsOpen}
        onSubmit={(newValues) => onChangeHandler({ ...msgCondition, values: newValues })}
        selectableCampaignType={selectableCampaignType}
      />
    </SegmentConditionContainer>
  );
};
