import React from 'react';

import { IconButton, Switch, Text, Tooltip } from '@attentive/picnic';

import { ActionFilter, SegmentParameters } from '../../../constants';
import { SegmentConditionContainer } from '../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';

import QuantitySelector from './QuantitySelector';

type ImageVideoToggleProps = {
  onChange: (newValue: boolean) => void;
  value: boolean;
};

const ImageVideoToggle: React.FC<ImageVideoToggleProps> = ({ onChange, value }) => {
  return (
    <SegmentConditionContainer css={{ width: '100%', alignContent: 'top' }}>
      <Switch checked={value} css={{ height: '$size9', mr: '$space0' }} onChange={onChange}>
        includes an image or video
      </Switch>
      <Tooltip>
        <Tooltip.Trigger>
          <IconButton
            iconName="CircleInformation"
            description="Image or video review information"
            size="small"
          />
        </Tooltip.Trigger>
        <Tooltip.Content align="center" side="right" variant="normal">
          <Text variant="caption">
            All reviews must include an image or video to be counted toward a match.
          </Text>
        </Tooltip.Content>
      </Tooltip>
    </SegmentConditionContainer>
  );
};

type ReviewStarsFieldProps = {
  onChange: (params: SegmentParameters) => void;
  parameters: SegmentParameters;
};

const ReviewStarsField: React.FC<ReviewStarsFieldProps> = ({ parameters, onChange }) => {
  const currentReviewFilter = parameters.reviewFilter || {
    vendor: 'VENDOR_BAZAARVOICE',
    onlyIncludeReviewsWithMedia: false,
  };
  const { ratingComparator = {} } = parameters.reviewFilter || {};

  return (
    <>
      <SegmentConditionItemContainer>
        <Text>of</Text>
      </SegmentConditionItemContainer>
      <QuantitySelector
        values={{ ...ratingComparator, quantityComparator: ratingComparator.comparator }}
        type="number"
        onChange={({ quantity, quantityComparator, quantityEnd }) =>
          onChange({
            ...parameters,
            reviewFilter: {
              ...currentReviewFilter,
              ratingComparator: {
                comparator: quantityComparator,
                quantity,
                quantityEnd,
              },
            },
          })
        }
        comparatorLabelOverrides={{ EQUAL_TO: 'Exactly', placeholder: 'rating' }}
      />
      <SegmentConditionItemContainer>
        <Text>stars</Text>
      </SegmentConditionItemContainer>
    </>
  );
};

type BazaarvoiceSelectorProps = {
  onChange: (params: SegmentParameters) => void;
  parameters: SegmentParameters;
};

export const BazaarvoiceSelector: React.FC<BazaarvoiceSelectorProps> = ({
  onChange,
  parameters,
}) => {
  const isCompletedReview = parameters.subscriberAction === ActionFilter.COMPLETED_REVIEW;

  return isCompletedReview ? (
    <ImageVideoToggle
      onChange={(onlyIncludeReviewsWithMedia) =>
        onChange({
          ...parameters,
          reviewFilter: {
            ...(parameters.reviewFilter || { vendor: 'VENDOR_BAZAARVOICE' }),
            onlyIncludeReviewsWithMedia,
          },
        })
      }
      value={Boolean(parameters.reviewFilter?.onlyIncludeReviewsWithMedia)}
    />
  ) : (
    <ReviewStarsField parameters={parameters} onChange={onChange} />
  );
};
