import React, { ChangeEvent, FC } from 'react';

import { Box, FormField, IconButton, PicnicCss, Text, TextInput } from '@attentive/picnic';

import { CustomEventValue, IsVerbType } from '../../../../constants';
import { SegmentConditionContainer } from '../../../SegmentConditionContainer';
import SegmentConditionItemContainer from '../../../SegmentConditionItemContainer';
import CustomEventKeySelector from '../../components/CustomEvent/CustomEventKeySelector';
import CustomEventValueSelector from '../../components/CustomEvent/CustomEventValueSelector';
import VerbSelector, { VerbSelectorType } from '../../components/VerbSelector';
import { useShowExpressionValidationAtomValue } from '../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

interface OwnProps {
  isFirst: boolean;
  customEventValue: CustomEventValue;
  customEventKeyOptions: string[];
  onDelete: () => void;
  onChange: (newValue: CustomEventValue) => void;
  css?: PicnicCss;
}

const CustomEventCondition: FC<OwnProps> = ({
  isFirst,
  customEventValue,
  customEventKeyOptions,
  onDelete,
  onChange,
  css,
}) => {
  const { isVerb, customEventKey, value } = customEventValue;
  const showValidation = useShowExpressionValidationAtomValue();

  return (
    <SegmentConditionContainer css={css}>
      <SegmentConditionItemContainer>
        <Text>{isFirst ? 'where' : 'and'}</Text>
      </SegmentConditionItemContainer>
      <Box>
        <CustomEventKeySelector
          customEventKey={customEventKey}
          options={customEventKeyOptions}
          onChange={(newKey) => onChange({ ...customEventValue, customEventKey: newKey })}
        />
      </Box>
      {customEventKey && (
        <>
          <VerbSelector
            verbType={VerbSelectorType.IS}
            value={isVerb || IsVerbType.IS}
            onChange={(verb) => onChange({ ...customEventValue, isVerb: verb as IsVerbType })}
          />
          <Box>
            <CustomEventValueSelector
              currentValue={value}
              onChange={(newValue: string | null) =>
                onChange({ ...customEventValue, value: newValue })
              }
            />
          </Box>
        </>
      )}
      {customEventKey && typeof value === 'string' && (
        <FormField>
          <TextInput
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange({ ...customEventValue, value: e.target.value })
            }
            placeholder="Enter a value"
            state={showValidation && !value ? 'error' : 'normal'}
            size="small"
          />
          {showValidation && !value && (
            <FormField.ErrorText>Please enter a value.</FormField.ErrorText>
          )}
        </FormField>
      )}
      <SegmentConditionItemContainer>
        <IconButton
          iconName="Delete"
          size="extraSmall"
          description="Remove product data condition"
          onClick={onDelete}
        />
      </SegmentConditionItemContainer>
    </SegmentConditionContainer>
  );
};

export default CustomEventCondition;
