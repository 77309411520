import React, { useMemo } from 'react';

import { FormField } from '@attentive/picnic';

import { SegmentParameters, StringValueDisplayableOption } from '../../../constants';

import { MultiSelectVirtualized as MultiSelect } from './MultiSelectVirtualized';

type ShopifyDynamicValueSelectorProps = {
  hasError: boolean;
  onChange: (updatedParameters: SegmentParameters) => void;
  options: StringValueDisplayableOption[];
  parameters: SegmentParameters;
};

export const ShopifyDynamicValueSelector: React.FC<ShopifyDynamicValueSelectorProps> = ({
  hasError,
  options,
  parameters,
  onChange,
}) => {
  const FormattedOptions = useMemo(() => {
    return options.filter(filterInvalidShopifyOptions).map(({ displayName, optionValue }) => (
      <MultiSelect.Item key={optionValue} value={optionValue}>
        {displayName}
      </MultiSelect.Item>
    ));
  }, [options]);

  return (
    <FormField>
      <MultiSelect
        value={parameters.textValues || []}
        onChange={(vals: string[]) => onChange({ ...parameters, textValues: vals })}
        size="small"
        maxTokens={10}
        placeholder="Choose values"
        state={hasError ? 'error' : 'normal'}
      >
        {FormattedOptions}
      </MultiSelect>
      {hasError && <FormField.ErrorText>Please choose values</FormField.ErrorText>}
    </FormField>
  );
};

function filterInvalidShopifyOptions({ displayName }: StringValueDisplayableOption) {
  const lastCharacterIndex = displayName.length - 1;
  return displayName[0] !== '[' && displayName[lastCharacterIndex] !== ']';
}
