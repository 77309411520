import React, { FC } from 'react';

import { Box, PicnicCss, SearchableSelect } from '@attentive/picnic';

import { PropertiesFilter, SegmentOptions, ThirdPartyList } from '../../../../constants';
import { FilterSelectPlaceholder } from '../../constants';

const PU_MINIMUM_FILTER_SELECTOR_WIDTH = '250px';

const RESET_PARAMETER_VALUES = {
  subscriberListMetadataId: undefined,
  subscriberProperty: undefined,
  tagKey: undefined,
  tagValue: undefined,
};

interface FilterSelectorProps {
  value: string | number | undefined;
  onChange: (filterParams: {}) => void;
  segmentOptions: SegmentOptions;
  disabled?: boolean;
}

const selectCss: PicnicCss = {
  '& [role=listbox] ul > ul:first-child [role=group]': { borderTop: 'none' },
};

const optionGroupCss: PicnicCss = {
  borderTop: '$borderWidths$borderWidth1 solid $borderDefault',
};

const FilterBySegmentSelector: FC<FilterSelectorProps> = ({
  value,
  onChange,
  segmentOptions,
  disabled,
}) => {
  const { subscriberTags, manualUploadLists, thirdPartyLists } =
    segmentOptions.subscriberMembership;

  const thirdPartyOptions =
    thirdPartyLists.length > 0 &&
    thirdPartyLists.reduce((allLists: { [key: string]: ThirdPartyList[] }, list) => {
      const { externalSource } = list;
      if (!allLists[externalSource]) {
        allLists[externalSource] = [];
      }
      allLists[externalSource].push(list);
      return allLists;
    }, {});

  const onFilterChange = (newValue: string | number) => {
    // subscriber tag values are strings; list values are numbers
    if (typeof newValue === 'string') {
      return onChange({
        ...RESET_PARAMETER_VALUES,
        subscriberProperty: PropertiesFilter.SUBSCRIBER_TAG,
        tagKey: newValue,
        tagValue: newValue,
      });
    }

    const isManualUploadList = manualUploadLists.find(
      (list) => list.subscriberListMetadataId === newValue
    );

    const subscriberProperty = isManualUploadList
      ? PropertiesFilter.MANUAL_UPLOAD_LIST
      : PropertiesFilter.THIRD_PARTY_LIST;

    return onChange({
      ...RESET_PARAMETER_VALUES,
      subscriberListMetadataId: newValue,
      subscriberProperty,
    });
  };

  return (
    <Box css={{ minWidth: PU_MINIMUM_FILTER_SELECTOR_WIDTH }}>
      <SearchableSelect
        placeholder={FilterSelectPlaceholder.MEMBERSHIP}
        value={value}
        onChange={onFilterChange}
        disabled={disabled}
        css={selectCss}
        size="small"
      >
        {manualUploadLists && manualUploadLists.length > 0 && (
          <SearchableSelect.Group label="Manual" css={optionGroupCss}>
            {manualUploadLists.map(({ name, subscriberListMetadataId }) => (
              <SearchableSelect.Item
                value={subscriberListMetadataId}
                key={subscriberListMetadataId}
                css={{ wordBreak: 'break-all' }}
              >
                {name}
              </SearchableSelect.Item>
            ))}
          </SearchableSelect.Group>
        )}
        {thirdPartyOptions &&
          Object.keys(thirdPartyOptions).map((thirdParty) => (
            <SearchableSelect.Group label={thirdParty} key={thirdParty} css={optionGroupCss}>
              {thirdPartyOptions[thirdParty].map(({ name, subscriberListMetadataId }) => (
                <SearchableSelect.Item
                  value={subscriberListMetadataId}
                  key={subscriberListMetadataId}
                  css={{ wordBreak: 'break-all' }}
                >
                  {name}
                </SearchableSelect.Item>
              ))}
            </SearchableSelect.Group>
          ))}
        {subscriberTags && subscriberTags.length > 0 && (
          <SearchableSelect.Group label="Subscriber Tag" css={optionGroupCss}>
            {subscriberTags.map((tag) => (
              <SearchableSelect.Item value={tag} key={tag} css={{ wordBreak: 'break-all' }}>
                {tag}
              </SearchableSelect.Item>
            ))}
          </SearchableSelect.Group>
        )}
      </SearchableSelect>
    </Box>
  );
};

export default FilterBySegmentSelector;
