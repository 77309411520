import React, { useState } from 'react';

import { Button, Icon, Text } from '@attentive/picnic';

import { StringValueDisplayableOption } from '../../../../constants';
import ProductDataDialog, { ProductAttribute, displayLabelMap } from '../../../ProductDataDialog';

const PU_PRODUCT_ATTR_BTN_MIN_WIDTH = '220px';

const getLabelCopy = (
  selectedValues: StringValueDisplayableOption[],
  attribute: ProductAttribute
) => {
  const displayName = displayLabelMap[attribute].toLowerCase();

  switch (selectedValues.length) {
    case 0:
      return `Choose ${displayName}`;
    case 1:
      return selectedValues[0].displayName;
    default:
      return `${selectedValues.length} ${displayName}`;
  }
};

interface OwnProps {
  attribute: ProductAttribute;
  attributeName?: string;
  selectedOptions: StringValueDisplayableOption[];
  onSubmit: (selection: StringValueDisplayableOption[]) => void;
  setDialogIsOpen: (isOpen: boolean) => void;
}

const ProductDataDialogButton: React.FC<OwnProps> = ({
  attribute,
  attributeName,
  selectedOptions,
  onSubmit,
  setDialogIsOpen,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const onCloseHandler = () => {
    setShowDialog(false);
    setDialogIsOpen(false);
  };

  const onOpenHandler = () => {
    setShowDialog(true);
    setDialogIsOpen(true);
  };

  const onSubmitHandler = (selections: StringValueDisplayableOption[]) => {
    onSubmit(selections);
    onCloseHandler();
  };

  return (
    <>
      {showDialog && (
        <ProductDataDialog
          onClose={onCloseHandler}
          queryAttribute={attribute as unknown as ProductAttribute}
          queryName={attributeName}
          onSubmit={onSubmitHandler}
          selectedOptions={selectedOptions}
        />
      )}
      <Button
        onClick={onOpenHandler}
        css={{ minWidth: PU_PRODUCT_ATTR_BTN_MIN_WIDTH, justifyContent: 'left' }}
        variant="basic"
        size="small"
      >
        <Text
          variant="caption"
          css={{ display: 'flex', alignItems: 'center', fontWeight: '$regular' }}
        >
          {getLabelCopy(selectedOptions, attribute)}
          <Icon name="PlusSign" size="extraSmall" css={{ ml: '$space2' }} />
        </Text>
      </Button>
    </>
  );
};

export default ProductDataDialogButton;
