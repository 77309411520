import {
  CampaignUsingSegment,
  IntegrationUsingSegment,
  JourneyUsingSegment,
  SegmentUsage,
} from '../constants';

export class SegmentInUseError extends Error {
  public campaigns?: CampaignUsingSegment[];
  public journeys?: JourneyUsingSegment[];
  public integrations?: IntegrationUsingSegment[];

  constructor({ campaigns, journeys, integrations }: SegmentUsage) {
    super('This segment is being used.');
    this.name = 'SegmentInUseError';
    this.campaigns = campaigns;
    this.journeys = journeys;
    this.integrations = integrations;
  }
}

export class SegmentUsageServicesUnavailableError extends Error {
  constructor() {
    super('Services to check segment usage are currently unavailable.');
    this.name = 'SegmentUsageServicesUnavailable';
  }
}

export class RESTRequestError extends Error {
  public statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.name = 'RESTRequestError';
    this.statusCode = statusCode;
  }
}
