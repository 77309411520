import { CustomAttributeOptionValue } from '../../constants';

export function userPropertyValuesAreInvalid(userPropertyValues?: CustomAttributeOptionValue[]) {
  if (!userPropertyValues || userPropertyValues.length === 0) {
    return true;
  }

  return userPropertyValues.some((item) => {
    if (typeof item !== 'object' || Array.isArray(item) || item === null) {
      return true;
    }

    const { optionValue, displayName } = item;

    return optionValue === undefined || displayName === undefined;
  });
}
