export const SELECTION_OPTIONS = {
  ANY: 'ANY',
  SPECIFIC: 'SPECIFIC',
} as const;

export type SelectionMode = (typeof SELECTION_OPTIONS)[keyof typeof SELECTION_OPTIONS];

export type SelectedMessages = {
  [journeyId: string]: string[];
};

export type OnMessageClickFn = (journeyId: string, messageName: string) => void;
