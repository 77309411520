import { differenceInCalendarDays } from 'date-fns';

import {
  TimeComparatorType,
  SegmentParameters,
  DurationUnit,
  durationTimeComparators,
  singleDateTimeComparators,
  relativeDateRangeTimeComparators,
} from '../../constants';

export const MAX_DAYS = 5000;

const comparatorsWithoutArugments = [TimeComparatorType.OVER_ALL_TIME, TimeComparatorType.IS_TODAY];

// We're using the max days for a leap year as a sanity check. The date range picker should prevent a user from selecting an invalid range
const PARTIAL_DATE_RANGE_MAX_DAYS = 366;

// When adding new time comparators, be sure to update the timeConditionIsInvalid validator for nested recharge attributes in recharge.ts
export const timeComparatorIsInvalid = (
  {
    timeComparator,
    durationTime,
    durationUnit,
    time,
    startTime,
    endTime,
    durationEnd,
    durationStart,
    months,
    ignoreYear,
  }: SegmentParameters,
  maxDays?: number
) => {
  if (!timeComparator) return true;

  // Comparators that don't take arguments are valid and don't require additional validation
  if (comparatorsWithoutArugments.includes(timeComparator)) return false;

  if (durationTimeComparators.includes(timeComparator)) {
    return Boolean(
      !durationTime ||
        !durationUnit ||
        (durationUnit === DurationUnit.DAYS && maxDays && durationTime > maxDays) ||
        (durationUnit === DurationUnit.HOURS && maxDays && durationTime > maxDays * 24)
    );
  }

  if (singleDateTimeComparators.includes(timeComparator)) {
    return !time;
  }

  if (timeComparator === TimeComparatorType.BETWEEN) {
    // Do not allow users to select partial date ranges that span more than a year
    if (ignoreYear && startTime && endTime && partialDateRangeIsInvalid(startTime, endTime)) {
      return true;
    }

    return !startTime || !endTime || startTime > endTime;
  }

  if (timeComparator === TimeComparatorType.IN_THE_MONTH) {
    return !months || !months.length;
  }

  if (relativeDateRangeTimeComparators.includes(timeComparator)) {
    const isReversed = timeComparator === TimeComparatorType.BETWEEN_THE_LAST;

    return Boolean(
      durationUnit !== DurationUnit.DAYS ||
        !durationStart ||
        !durationEnd ||
        (isReversed && durationStart <= durationEnd) ||
        (!isReversed && durationEnd <= durationStart) ||
        (maxDays && (durationStart > maxDays || durationEnd > maxDays))
    );
  }

  // Unknown time comparator
  return true;
};

export function partialDateRangeIsInvalid(
  startTime: number,
  endTime: number,
  maxDays = PARTIAL_DATE_RANGE_MAX_DAYS
) {
  const totalDays = Math.abs(differenceInCalendarDays(endTime * 1000, startTime * 1000));
  return totalDays >= maxDays;
}
