/**
 * @generated SignedSource<<051244d929fc3d5de902ac1ba5800264>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePaginatedJourneys_TargetingCommon_RefetchQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  id: string;
};
export type usePaginatedJourneys_TargetingCommon_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"usePaginatedJourneys_TargetingCommon_Company">;
  } | null;
};
export type usePaginatedJourneys_TargetingCommon_RefetchQuery = {
  response: usePaginatedJourneys_TargetingCommon_RefetchQuery$data;
  variables: usePaginatedJourneys_TargetingCommon_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePaginatedJourneys_TargetingCommon_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "usePaginatedJourneys_TargetingCommon_Company"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePaginatedJourneys_TargetingCommon_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "JourneyConnection",
                "kind": "LinkedField",
                "name": "journeys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JourneyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Journey",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "journeyRevisions",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JourneyMessage",
                                "kind": "LinkedField",
                                "name": "messages",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ComposeMessage",
                                    "kind": "LinkedField",
                                    "name": "composeMessage",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "channel",
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "publishedRevision",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publishStartTime",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JourneyRevision",
                            "kind": "LinkedField",
                            "name": "draftRevision",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastUpdatedTime",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "usePaginatedJourneys_TargetingCommon_connection_journeys",
                "kind": "LinkedHandle",
                "name": "journeys"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dd44d1ddcbeecf5c119ff25e50bb647",
    "id": null,
    "metadata": {},
    "name": "usePaginatedJourneys_TargetingCommon_RefetchQuery",
    "operationKind": "query",
    "text": "query usePaginatedJourneys_TargetingCommon_RefetchQuery(\n  $count: Int\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...usePaginatedJourneys_TargetingCommon_Company\n    id\n  }\n}\n\nfragment JourneyRow_TargetingCommon_Journey on Journey {\n  id\n  internalId\n  name\n  ...JourneyStatusPill_TargetingCommon_Journey\n  ...JourneyRow_getJourneySecondaryText_TargetingCommon_Journey\n}\n\nfragment JourneyRow_getJourneySecondaryText_TargetingCommon_Journey on Journey {\n  isActive\n  publishedRevision {\n    publishStartTime\n    id\n  }\n  draftRevision {\n    lastUpdatedTime\n    id\n  }\n}\n\nfragment JourneyStatusPill_TargetingCommon_Journey on Journey {\n  isActive\n  publishedRevision {\n    id\n  }\n}\n\nfragment useJourneyMessages_TargetingCommon_JourneyConnection on JourneyConnection {\n  edges {\n    node {\n      id\n      internalId\n      name\n      journeyRevisions {\n        id\n        messages {\n          composeMessage {\n            id\n            internalId\n            channel\n            name\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment useJourneys_TargetingCommon_JourneyConnection on JourneyConnection {\n  edges {\n    node {\n      ...JourneyRow_TargetingCommon_Journey\n      id\n      internalId\n      name\n      journeyRevisions {\n        messages {\n          composeMessage {\n            id\n            channel\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment usePaginatedJourneys_TargetingCommon_Company on Company {\n  journeys(first: $count, after: $cursor) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        __typename\n        id\n      }\n    }\n    ...useJourneyMessages_TargetingCommon_JourneyConnection\n    ...useJourneys_TargetingCommon_JourneyConnection\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "dc19c6ae21086c2423464ab1649901a7";

export default node;
