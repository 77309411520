import {
  SubscriberAttributeDataTypeMap,
  VendorAttributeDataTypeMap,
} from '../../components/SegmentCreateEditModal/utils/attributeDataTypeMap';
import {
  BlankSegment,
  CustomAttributeOptionValue,
  ProductDataAttributeValue,
  Segment,
  SegmentComponent,
  SegmentDuplicates,
  SegmentExpression,
  SegmentExpressionSubsetType,
  SegmentParameters,
  WHOLE_DUPLICATE_EXPRESSION,
} from '../../constants';
import { getSegmentType } from '../segment';

import { isValidSegmentCondition } from './validator';

const sortObjectKeys = (obj: object) => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key as keyof typeof obj],
      }),
      {}
    );
};

const sortProductData = (attrs: ProductDataAttributeValue[]) => {
  const attrsSortedVals = attrs.map((item) => ({ ...item, values: item.values.sort() }));
  return attrsSortedVals.sort((a, b) => {
    if (a.attribute < b.attribute) {
      return -1;
    }
    if (a.attribute > b.attribute) {
      return 1;
    }
    return 0;
  });
};

const sortCustomProperties = (values: CustomAttributeOptionValue[]) => {
  return values.sort((a, b) => {
    if (a.optionValue < b.optionValue) {
      return -1;
    }
    if (a.optionValue > b.optionValue) {
      return 1;
    }
    return 0;
  });
};

const getStringifiedComponent = (component: SegmentComponent): string => {
  const sortedProductData = sortProductData(component.parameters.productDataAttributeValues || []);
  const sortedCustomProperties = sortCustomProperties(
    component.parameters.userPropertyValues || []
  );
  const parameters = {
    ...component.parameters,
    productDataAttributeValues: sortedProductData,
    userPropertyValues: sortedCustomProperties,
  };

  // The BE includes an empty locations array on most every segment component
  // If we don' remove this extraneous field, the duplicate check won't work properly
  if (parameters?.locations?.length === 0) {
    delete parameters.locations;
  }

  const sortedParameters = sortObjectKeys(parameters);
  return JSON.stringify(sortedParameters);
};

export const validateExpressionDupes = (expression: SegmentExpression): Set<number> => {
  const { segmentComponents } = expression;
  if (!segmentComponents || segmentComponents.length < 2) {
    return new Set();
  }

  const stringComponents = segmentComponents.map((component) => getStringifiedComponent(component));
  const dupes = stringComponents.reduce((res, component, index) => {
    const foundIdx = stringComponents.indexOf(component);
    if (foundIdx !== index) {
      return res.concat([foundIdx, index]);
    }
    return res;
  }, [] as number[]);

  return new Set(dupes);
};

export const validateExpressionSubset = (
  firstExpression: SegmentExpression,
  secondExpression: SegmentExpression
): SegmentExpressionSubsetType => {
  const hasSameLogicOperator = firstExpression.operator === secondExpression.operator;
  const hasMoreThanOneComponent = firstExpression.segmentComponents.length > 1;

  // We can skip validation if one of the following conditions are true:
  // - The expressions do not have the same number of components
  // - The expressions have more than one component and different logic operators
  if (
    firstExpression.segmentComponents.length !== secondExpression.segmentComponents.length ||
    (!hasSameLogicOperator && hasMoreThanOneComponent)
  ) {
    return;
  }

  const firstComponents = firstExpression.segmentComponents.map((c) => getStringifiedComponent(c));
  const secondComponents = secondExpression.segmentComponents.map((c) =>
    getStringifiedComponent(c)
  );
  const dupes: number[] = [];

  firstComponents.forEach((testComponent, testIdx) => {
    const foundOtherIdx = secondComponents.indexOf(testComponent);
    if (foundOtherIdx >= 0) {
      dupes.push(testIdx);
    }
  });

  if (dupes.length === firstComponents.length) {
    return WHOLE_DUPLICATE_EXPRESSION;
  }
};

export const getDuplicates = (expressions: SegmentExpression[]): SegmentDuplicates => {
  const duplicates = new Map<number, typeof WHOLE_DUPLICATE_EXPRESSION | Set<number>>();

  for (let i = 0; i < expressions.length; i++) {
    // 1) check if expression is a subset of another expression
    for (let x = i + 1; x < expressions.length; x++) {
      const subsetResult = validateExpressionSubset(expressions[i], expressions[x]);
      if (subsetResult === WHOLE_DUPLICATE_EXPRESSION) {
        duplicates.set(i, subsetResult);
        duplicates.set(x, subsetResult);
      }
    }

    // 2) check for duplicate components within the expression
    const expressionDupes = validateExpressionDupes(expressions[i]);
    if (expressionDupes.size > 0) {
      duplicates.set(i, expressionDupes);
    }
  }

  return duplicates;
};

export const getValidSegmentComponents = (
  segment: Segment | BlankSegment,
  subscriberAttributeDataTypeMap: SubscriberAttributeDataTypeMap,
  vendorAttributeDataTypeMap: VendorAttributeDataTypeMap
) => {
  const stripIncompleteData = (parameters: SegmentParameters): SegmentParameters => {
    const { customAttributeValueGroup, customEventOption, productDataAttributeValues } = parameters;

    const newCustomAttr =
      customAttributeValueGroup && customAttributeValueGroup.values.length > 0
        ? customAttributeValueGroup
        : undefined;

    const newCustomEvent =
      customEventOption && customEventOption.customEventType
        ? {
            ...customEventOption,
            customEventValues: customEventOption.customEventValues.filter(
              ({ customEventKey, value }) => Boolean(customEventKey) && value !== ''
            ),
          }
        : undefined;

    const filteredProductData = productDataAttributeValues?.filter(({ attribute, values }) => {
      return Boolean(attribute) && values.length > 0;
    });

    const newProductData =
      filteredProductData && filteredProductData.length > 0 ? filteredProductData : undefined;

    return {
      ...parameters,
      customAttributeValueGroup: newCustomAttr,
      customEventOption: newCustomEvent,
      productDataAttributeValues: newProductData,
    };
  };

  const _getValidSegmentComponents = (components: SegmentComponent[]) => {
    return components.reduce((acc, component) => {
      const strippedParameters = stripIncompleteData(component.parameters);
      return isValidSegmentCondition(
        strippedParameters,
        subscriberAttributeDataTypeMap,
        vendorAttributeDataTypeMap
      )
        ? [
            ...acc,
            {
              ...component,
              parameters: strippedParameters,
              type: getSegmentType(strippedParameters),
            },
          ]
        : acc;
    }, [] as SegmentComponent[]);
  };

  return {
    ...segment,
    expressions: segment.expressions.reduce((acc, expression) => {
      const validComponents = _getValidSegmentComponents(expression.segmentComponents);
      return validComponents.length > 0
        ? [...acc, { ...expression, segmentComponents: validComponents }]
        : acc;
    }, [] as SegmentExpression[]),
  };
};

export const getSegmentComponentCount = (segment: Segment | BlankSegment) => {
  return segment.expressions.reduce(
    (acc, { segmentComponents }) => acc + segmentComponents.length,
    0
  );
};
