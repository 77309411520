import React from 'react';

import { Box, ContainedLabel } from '@attentive/picnic';

export const ConnectorPill: React.FC<{ operator: 'OR' | 'AND' }> = ({ operator }) => {
  return (
    <Box
      css={{
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&::after': {
          content: '',
          display: 'block',
          position: 'absolute',
          borderLeft: '$borderWidths$borderWidth1 solid $borderDefault',
          height: '100%',
        },
      }}
    >
      <ContainedLabel
        css={{
          fontSize: '$fontSize1',
          minHeight: '$size6',
          margin: '$space3 $space0',
          zIndex: 1,
          textTransform: 'capitalize',
          width: '$size13',
        }}
        variant={operator === 'OR' ? 'decorative3' : 'success'}
      >
        {operator.toLowerCase()}
      </ContainedLabel>
    </Box>
  );
};
