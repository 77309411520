import { ATTRIBUTE_INPUT_TYPES } from '../constants';
import { CustomAttributeFormState } from '../form';

import { PostCustomPropertyDefinitionInput } from './ExistingCustomAttributeDialog/__generated__/UpdateCustomAttributeTargetingCommonMutation.graphql';

export function buildNewFieldsVariable(values: CustomAttributeFormState) {
  if (values.dataType === '') {
    throw new Error('Custom attribute data type is not set');
  }

  const newFields: PostCustomPropertyDefinitionInput = {
    isDeleted: false,
    name: values.attributeName,
    propertyType: values.dataType,
  };

  // Handle enum values
  if (
    values.inputType === ATTRIBUTE_INPUT_TYPES.SINGLE_SELECT ||
    values.inputType === ATTRIBUTE_INPUT_TYPES.CHECKBOX // Checkbox is a temporary solution to support creatives workaround for lack of true multi-select support
  ) {
    newFields.enumValues = values.options;
    // The UI stores string as the data type for ENUMs so we need to explicitly set this
    newFields.propertyType = 'TYPE_ENUM';
  }

  return newFields;
}
