import React, { FC } from 'react';

import { Tracker, TrackerEvents } from '@attentive/acore-utils';
import { Box, Icon, styled, Text } from '@attentive/picnic';

import {
  SegmentOptions,
  SegmentParameters,
  AvailableSegmentData,
  SegmentDuplicates,
  SegmentableAttributes,
  WHOLE_DUPLICATE_EXPRESSION,
  SegmentExpression as SegmentExpressionType,
  OperatorType,
} from '../../../../../constants';
import { ConnectorPill } from '../../../../ConnectorPill';
import { useAnimatedEntry } from '../../../useAnimatedEntry';
import {
  HighlightConditionConfigs,
  isComponentHighlighted,
} from '../../../utils/highlightConditions';
import { NewSegmentCondition } from '../../SegmentCondition';
import { SegmentOperator } from '../../SegmentOperator';
import { useShowExpressionValidationAtomValue } from '../hooks/useShowExpressionValidationAtom';
import { useShowExpressionValidationHandlers } from '../hooks/useShowExpressionValidationHandlers';

const ExpressionBox = styled('div', {
  border: '1px solid $borderDefault',
  borderRadius: '$radius1',
  padding: '$space4',
  width: '100%',
  variants: {
    hasError: {
      true: {
        borderColor: '$borderInputError',
      },
      false: {
        borderColor: '$borderDefault',
      },
    },
  },
});

interface SegmentExpressionProps {
  duplicates: SegmentDuplicates;
  expression: SegmentExpressionType;
  expressionIndex: number;
  validateDuplicates: boolean;
  availableSegmentData: AvailableSegmentData;
  highlightConditions?: HighlightConditionConfigs;
  segmentableAttributesByVendor: SegmentableAttributes;
  segmentOptions: SegmentOptions;
  deleteComponent: (expressionIdx: number, componentIdx: number) => void;
  resetComponent: (expressionIdx: number, componentIdx: number) => void;
  addComponent: (expressionIdx: number) => void;
  updateComponent: (
    expressionIdx: number,
    componentIdx: number,
    parameters: SegmentParameters
  ) => void;
  segmentId: number | undefined;
  onExpressionOperatorChange: (expressionIdx: number, operator: OperatorType) => void;
}

export const SegmentExpression: FC<SegmentExpressionProps> = ({
  duplicates,
  expression,
  expressionIndex,
  validateDuplicates,
  availableSegmentData,
  highlightConditions,
  segmentOptions,
  segmentableAttributesByVendor,
  deleteComponent,
  addComponent,
  resetComponent,
  updateComponent,
  segmentId,
  onExpressionOperatorChange,
}) => {
  const { segmentComponents, operator } = expression;
  const { handleFocus, handleBlur, setDialogIsOpen } = useShowExpressionValidationHandlers();
  const showValidation = useShowExpressionValidationAtomValue();

  const expDupes = duplicates.get(expressionIndex);
  const expressionIsDupe = expDupes === WHOLE_DUPLICATE_EXPRESSION;
  const displayExpressionError = showValidation && expressionIsDupe;
  const displayOperatorSelector = segmentComponents.length > 1;
  const { isMounted: isSegmentOperatorDisplayed, elementCss: segmentOperatorCss } =
    useAnimatedEntry({
      animationDuration: 200,
      isVisible: displayOperatorSelector,
      hiddenCss: { height: '0', opacity: 0 },
      visibleCss: { height: '40px', opacity: 1 },
    });
  const { elementCss: expressionCss } = useAnimatedEntry({
    animationDuration: 200,
    isVisible: true,
    hiddenCss: { opacity: 0 },
    visibleCss: { opacity: 1 },
  });

  return (
    <Box
      css={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        css={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {displayExpressionError && (
          <Box
            css={{
              height: '$size9',
              display: 'flex',
              alignItems: 'center',
              mr: '$space2',
            }}
          >
            <Icon name="CircleExclamation" color="error" />
          </Box>
        )}
        <ExpressionBox
          onFocus={handleFocus}
          onBlur={handleBlur}
          css={{
            transition: 'opacity 0.2s ease-in',
            ...expressionCss,
          }}
          hasError={displayExpressionError}
        >
          {isSegmentOperatorDisplayed && (
            <SegmentOperator
              operator={operator}
              onChange={(newOperator) => {
                onExpressionOperatorChange(expressionIndex, newOperator);
              }}
              css={{ mb: '$space4', transition: 'all 0.2s ease-in-out', ...segmentOperatorCss }}
            />
          )}
          {segmentComponents.map((component, componentIdx) => {
            const isLastComponent = componentIdx === segmentComponents.length - 1;
            const componentIsDupe = expDupes instanceof Set && expDupes.has(componentIdx);
            const showDupeText = validateDuplicates && componentIsDupe;
            const isHighlightedComponent = isComponentHighlighted(component, highlightConditions);
            const uniqueId =
              component.metadata?.id || component.id || JSON.stringify(component.parameters);

            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`condition-${expressionIndex}-${componentIdx}-${uniqueId}`}>
                {componentIdx !== 0 && <ConnectorPill operator={operator} />}
                <Box>
                  <NewSegmentCondition
                    availableData={availableSegmentData}
                    setDialogIsOpen={setDialogIsOpen}
                    showDupeText={showDupeText}
                    higlightCondition={isHighlightedComponent}
                    component={component}
                    segmentOptions={segmentOptions}
                    segmentableAttributesByVendor={segmentableAttributesByVendor}
                    onChange={(params) => {
                      Tracker.track({
                        eventName: TrackerEvents.SEGMENT_CREATE_CONDITIONS_DEFINED,
                      });
                      updateComponent(expressionIndex, componentIdx, params);
                    }}
                    onClickOrButton={() => addComponent(expressionIndex)}
                    onClickDeleteButton={() => {
                      Tracker.track({
                        eventName: TrackerEvents.SEGMENT_CREATE_CONDITION_DELETED,
                      });
                      deleteComponent(expressionIndex, componentIdx);
                    }}
                    onClickFilterButton={(filterType) => {
                      Tracker.track({
                        eventName: TrackerEvents.SEGMENT_CREATE_FILTER_ADDED,
                        properties: { segmentId, filterType },
                      });
                    }}
                    resetComponent={() => resetComponent(expressionIndex, componentIdx)}
                    showAddButton={isLastComponent}
                  />
                </Box>
              </React.Fragment>
            );
          })}
        </ExpressionBox>
      </Box>
      {displayExpressionError && (
        <Text variant="caption" color="critical" css={{ mt: '$space2', ml: '$space9' }}>
          This segment expression is a duplicate.
        </Text>
      )}
    </Box>
  );
};
