import React from 'react';

import { Box, FormField, Select, Text, TextInput } from '@attentive/picnic';

import { DurationUnit } from '../../../../../constants';
import SegmentConditionItemContainer from '../../../../SegmentConditionItemContainer';
import { useShowExpressionValidationAtomValue } from '../../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';
import { TimeValue } from '../constants';
import { getErrorMessage } from '../utils';

const PU_NUMBER_INPUT_MAX_WIDTH = '100px';
const PU_NUMBER_INPUT_MIN_WIDTH = '60px';

interface DurationFieldsProps {
  allowHours: boolean;
  maxDays: number;
  onChange: (value: TimeValue) => void;
  values: TimeValue;
}

export const DurationFields = ({ allowHours, maxDays, onChange, values }: DurationFieldsProps) => {
  const showValidation = useShowExpressionValidationAtomValue();

  const errorMessage = getErrorMessage(values, maxDays);

  return (
    <>
      <FormField css={{ maxWidth: PU_NUMBER_INPUT_MAX_WIDTH }}>
        <TextInput
          type="number"
          placeholder="Number"
          value={values.durationTime !== 0 ? values.durationTime : ''}
          state={showValidation && !values.durationTime ? 'error' : 'normal'}
          onChange={(event) => {
            const time = !Number.isNaN(Number(event.target.value))
              ? Number(event.target.value)
              : values.durationTime || 0;
            onChange({ ...values, durationTime: Math.max(0, time) });
          }}
          size="small"
          css={{ minWidth: PU_NUMBER_INPUT_MIN_WIDTH }}
        />
        {showValidation && errorMessage && (
          <FormField.ErrorText>{errorMessage}</FormField.ErrorText>
        )}
      </FormField>
      <Box>
        {allowHours && (
          <Select
            value={values.durationUnit}
            onChange={(newValue: string) => {
              onChange({
                ...values,
                durationUnit: newValue as DurationUnit,
              });
            }}
            size="small"
          >
            <Select.Item value={DurationUnit.DAYS}>day(s)</Select.Item>
            <Select.Item value={DurationUnit.HOURS}>hour(s)</Select.Item>
          </Select>
        )}
        {!allowHours && (
          <SegmentConditionItemContainer>
            <Text>day(s)</Text>
          </SegmentConditionItemContainer>
        )}
      </Box>
    </>
  );
};
