import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';

import { TrackerEvents } from '@attentive/acore-utils';
import { Button, Heading, Popover, Stack, Text } from '@attentive/picnic';

import { useTargetingCommonLogger } from '../../logger/useTargetingCommonLogger';
import { operatorFeedbackDialogOpenAtom } from '../OperatorUIFeedbackDialog';

import { errorAtom } from './OperatorUIToggleError';
import { useIsOperatorUIToggleDisplayed, useOperatorUIToggle } from './hooks';

export const OperatorUIToggleComponent = () => {
  const { isOperatorUIEnabled, toggleOperatorUI } = useOperatorUIToggle();
  const hasErrors = useAtomValue(errorAtom);
  const { logAction } = useTargetingCommonLogger();
  const setIsFeedbackOpen = useSetAtom(operatorFeedbackDialogOpenAtom);

  useEffect(() => {
    return () => {
      setIsFeedbackOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFlag = () => {
    logAction(TrackerEvents.SEGMENT_BUILDER_OPERTATOR_UI_TOGGLE, {
      flagEnabled: !isOperatorUIEnabled,
    });
    toggleOperatorUI();
  };

  return (
    <Stack
      css={{
        backgroundColor: '$bgGuidanceDefault',
        padding: '$space4',
        borderRadius: '$radius2',
        alignItems: 'center',
        mt: '$space4',
      }}
      spacing="$space2"
    >
      <Heading as="h3" variant="sm">
        New Segment UI
      </Heading>
      <Stack css={{ mb: '$space4', alignItems: 'center' }} spacing="$space0">
        <Text css={{ textAlign: 'center' }} variant="caption">
          Try out the updated builder.
        </Text>
        <Popover>
          <Popover.Trigger>
            <Text
              variant="micro"
              css={{ borderBottom: '$borderWidths$borderWidth1 dotted $borderActionBasic' }}
            >
              What’s changed?
            </Text>
          </Popover.Trigger>
          <Popover.Content side="top" align="end">
            <Heading variant="sm">What’s changed?</Heading>
            <Text>
              We’ve added more flexibility and control when connecting multiple segment conditions
              or groups of conditions. ANY | ALL dropdowns now let you change how your segment is
              built at any time.
            </Text>
          </Popover.Content>
        </Popover>
      </Stack>
      <Button
        variant="basic"
        size="small"
        css={{ width: '100%' }}
        disabled={hasErrors === 'FEEDBACK_ERROR'}
        onClick={() => setIsFeedbackOpen(true)}
      >
        Give feedback
      </Button>
      <Button variant="basic" size="small" css={{ width: '100%' }} onClick={toggleFlag}>
        {isOperatorUIEnabled ? 'Switch to old UI' : 'Switch to new UI'}
      </Button>
    </Stack>
  );
};

export const OperatorUIToggle = () => {
  const isToggleEnabled = useIsOperatorUIToggleDisplayed();

  if (!isToggleEnabled) return null;

  return <OperatorUIToggleComponent />;
};
