import React from 'react';

import { FormField, LoadingIndicator, MultiSelect, Text } from '@attentive/picnic';

import { Vendor } from '../../constants';
import SegmentConditionItemContainer from '../SegmentConditionItemContainer';
import { useShowExpressionValidationAtomValue } from '../SegmentCreateEditModal/components/SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

import { AttrError } from './constants';
import { useVendorAttrValues } from './hooks';

interface OwnProps {
  onChange: (values: string[]) => void;
  textValues?: string[];
  attr: string;
  vendor: Vendor;
}

function VendorAttrSelector({ onChange, textValues = [], attr, vendor }: OwnProps) {
  const { data, isLoading, error } = useVendorAttrValues(vendor, attr);
  const attrKeys = data?.keys;
  const showValidation = useShowExpressionValidationAtomValue();
  const showError = showValidation && !textValues.length;

  const multiSelectOnChange = (newValues: string[]) => {
    onChange(newValues);
  };

  if (isLoading) {
    return (
      <SegmentConditionItemContainer>
        <LoadingIndicator />
      </SegmentConditionItemContainer>
    );
  }

  if (error) {
    return (
      <SegmentConditionItemContainer>
        <Text>{(error as AttrError).message}</Text>
      </SegmentConditionItemContainer>
    );
  }

  if (!attrKeys || attrKeys.length === 0) {
    return (
      <SegmentConditionItemContainer>
        <Text>No values were found</Text>
      </SegmentConditionItemContainer>
    );
  }

  return (
    <FormField>
      <MultiSelect
        value={textValues}
        onChange={multiSelectOnChange}
        placeholder="Choose values"
        state={showError ? 'error' : 'normal'}
        size="small"
      >
        {attrKeys.map((key) => (
          <MultiSelect.Item key={key} value={key}>
            {key}
          </MultiSelect.Item>
        ))}
      </MultiSelect>
      {showError && <FormField.ErrorText>Please choose values.</FormField.ErrorText>}
    </FormField>
  );
}

export default VendorAttrSelector;
