import React from 'react';

import { FormField, MultiSelect } from '@attentive/picnic';

import { birthMonthNumberOptions } from '../../../constants';
import { useShowExpressionValidationAtomValue } from '../../SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';
import { TimeValue } from '../constants';

interface MonthMultiSelectProps {
  values: TimeValue;
  onChange: (value: TimeValue) => void;
}

export const MonthMultiSelect = ({ onChange, values }: MonthMultiSelectProps) => {
  const { months = [] } = values;
  const showValidation = useShowExpressionValidationAtomValue();
  const hasErrors = showValidation && !months.length;

  return (
    <FormField>
      <MultiSelect
        value={months}
        onChange={(newValues: number[]) =>
          onChange({
            ...values,
            months: newValues,
          })
        }
        placeholder="Select months"
        size="small"
      >
        {birthMonthNumberOptions.map(({ optionValue, displayName }) => (
          <MultiSelect.Item key={optionValue} value={optionValue}>
            {displayName}
          </MultiSelect.Item>
        ))}
      </MultiSelect>
      {hasErrors && <FormField.ErrorText>Please select one or more months.</FormField.ErrorText>}
    </FormField>
  );
};
