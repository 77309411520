import { VendorAttribute } from '../../../constants/interfaces';

export const deletedPropertyRegex = new RegExp(/-deleted-\d*/);

export const getSentenceCase = (displayName: string) => {
  const label = displayName.trim().toLowerCase();

  if (label.length === 0) return '';

  return label[0].toUpperCase() + label.slice(1);
};

export const isKlaviyoEmailAttr = (attr: VendorAttribute) => {
  return attr.attributeName === 'created';
};

export const isKlaviyoCoreAttr = (attr: VendorAttribute) => {
  return attr.attributeName.startsWith('$');
};

// An attribute with a name like "ice cream: what kind do you like" can cause errors in the UI because we split based on ':'
// This function splits the string by the separator and then joins together everything with an index of 1 or higher
export const splitNameSpacedAttribute = (attr: string, separator = ':') => {
  const [vendor, ...attributeNameParts] = attr.split(separator);

  return [vendor, attributeNameParts.join(separator)];
};

// This is a similar function to splitNameSpacedAttribute but returns three separate values instead of two
export const splitNamedSpacedAttributeWithDisplayName = (attr: string, separator = ':') => {
  const [vendor, optionValue, ...attributeNameParts] = attr.split(separator);

  return [vendor, optionValue, attributeNameParts.join(separator)];
};
