import {
  ActionChannel,
  ActionFilter,
  ActionSource,
  BlankSegment,
  UserPropertyType,
  EmailActionFilter,
  IntegrationAttributes,
  OperatorType,
  PropertiesFilter,
  SegmentComponentType,
  SegmentParameters,
  ShopifyActionFilter,
  Source,
  SubscriberPreference,
  SyncStatus,
  Vendor,
  SegmentComponent,
  SegmentComponentMetaData,
} from '../constants';

import { isRechargeAttributeType, isShopifyActionType } from './typeAssertions';

export const getNewBlankSegment = (): BlankSegment => {
  return {
    name: '',
    description: '',
    operator: OperatorType.AND,
    source: Source.DYNAMIC,
    syncStatus: SyncStatus.SYNCED,
    templateId: null,
    expressions: [
      {
        operator: OperatorType.OR,
        segmentComponents: [
          {
            parameters: {},
            type: SegmentComponentType.CUSTOM,
            description: '',
            metadata: createSegmentComponentMetadata(),
          },
        ],
      },
    ],
  };
};

// TODO: sc - need test coverage for this
export const getSegmentType = (parameters: SegmentParameters) => {
  if (parameters.customEventOption) {
    return SegmentComponentType.CUSTOM_EVENT;
  }
  const isEmailEvent = parameters.subscriberActionChannel === ActionChannel.EMAIL;
  const isShopifyAction = isShopifyActionType(parameters);
  const isRechargeAction = isRechargeAttributeType(parameters);

  const filterType =
    (isEmailEvent && `${parameters.subscriberActionChannel}:${parameters.subscriberAction}`) ||
    (isShopifyAction && `${Vendor.SHOPIFY}:${parameters.subscriberAction}`) ||
    (isRechargeAction && Vendor.RECHARGE) ||
    parameters.subscriberAction ||
    parameters.subscriberProperty ||
    parameters.userPropertyType ||
    (parameters.profileAttribute ? IntegrationAttributes.PROFILE_ATTRIBUTE : undefined) ||
    (parameters.subscriberPreferenceKey ? SubscriberPreference.SUBSCRIBER_PREFERENCES : undefined);

  switch (filterType) {
    case ActionFilter.VISITED_SITE:
      return SegmentComponentType.URL_VIEWED;

    case ActionFilter.ORDER_SUBSCRIPTION_CHARGE_PAID:
    case ActionFilter.ORDER_SUBSCRIPTION_PROCESSED:
    case ActionFilter.COMPLETED_REVIEW:
    case ActionFilter.LEFT_A_RATING:
      return SegmentComponentType.USER_EVENT;

    case PropertiesFilter.SUBSCRIBER_TAG:
      return SegmentComponentType.SUBSCRIBER_TAG;

    case PropertiesFilter.STATE:
    case PropertiesFilter.CITY:
    case PropertiesFilter.LEGACY_POSTAL_CODE:
    case PropertiesFilter.COUNTRY:
      return SegmentComponentType.LOCATION;

    case PropertiesFilter.BULK_POSTAL_CODES:
    case PropertiesFilter.DISTANCE:
      return SegmentComponentType.BULK_LOCATION;

    case SubscriberPreference.SUBSCRIBER_PREFERENCES:
      return SegmentComponentType.SUBSCRIBER_PREFERENCES;

    case IntegrationAttributes.PROFILE_ATTRIBUTE:
      return SegmentComponentType.PROFILE;

    case PropertiesFilter.THIRD_PARTY_LIST:
    case PropertiesFilter.MANUAL_UPLOAD_LIST:
      return SegmentComponentType.SUBSCRIBER_LIST;

    case PropertiesFilter.DEVICE_TYPE:
    case PropertiesFilter.OPERATING_SYSTEM:
      return SegmentComponentType.DEVICE;

    case PropertiesFilter.CARRIER:
      return SegmentComponentType.CARRIER;

    case UserPropertyType.CUSTOM_PROPERTY_ID:
    case UserPropertyType.WELL_KNOWN_PROPERTY:
    case Vendor.RECHARGE:
      return SegmentComponentType.USER_PROPERTY;

    case EmailActionFilter.CLICKED:
    case EmailActionFilter.OPENED:
    case EmailActionFilter.SENT:
      return parameters.subscriberActionSource === ActionSource.ATTENTIVE
        ? SegmentComponentType.CUSTOM
        : SegmentComponentType.USER_EVENT;

    case ShopifyActionFilter.REFUND_SUCCEEDED:
    case ShopifyActionFilter.CHECKOUT_ABANDONED:
      return SegmentComponentType.USER_EVENT;

    default:
      return parameters.productDataAttributeValues &&
        parameters.productDataAttributeValues.length > 0
        ? SegmentComponentType.PRODUCT_DATA
        : SegmentComponentType.CUSTOM;
  }
};

// This should be used for storing transient data that is only important for use on the client inside the segment builder
// It will *not* be persisted on the BE and will be created anew each time the segment builder is opened
export function createSegmentComponentMetadata(
  existingSegment?: SegmentComponent
): SegmentComponentMetaData {
  if (existingSegment && existingSegment.metadata) {
    return existingSegment.metadata;
  }

  const id = existingSegment?.id || Date.now();

  return {
    id,
  };
}
