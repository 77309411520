import { atom, useAtom } from 'jotai';
import React, { useState } from 'react';

import { useCompanyFeatureFlag, TrackerEvents } from '@attentive/acore-utils';
import { Box, PicnicCss } from '@attentive/picnic';

import { useTargetingCommonLogger } from '../../logger/useTargetingCommonLogger';
import { useIsOperatorUIEnabled } from '../OperatorUIToggle';

import { FeedbackForm, FeedbackSubmitted } from './components';

type FeedbackFormProps = {
  css?: PicnicCss;
};

export const operatorFeedbackDialogOpenAtom = atom(false);

export const OperatorUIFeedbackDialog: React.FC<FeedbackFormProps> = ({ css }) => {
  const isEnabled = useCompanyFeatureFlag('ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE');
  const isOperatorUIEnabled = useIsOperatorUIEnabled();

  const { logAction } = useTargetingCommonLogger();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useAtom(operatorFeedbackDialogOpenAtom);

  if (!isEnabled || !isOpen) return null;

  const onClose = () => {
    setIsOpen(false);
    setIsSubmitted(false);
  };

  const onSubmit = (feedback: string) => {
    setIsSubmitted(true);
    logAction(TrackerEvents.SEGMENT_BUILDER_OPERATOR_UI_FEEDBACK, {
      feedback,
      isOperatorUIEnabled,
    });
  };

  return (
    <Box css={{ ...css, width: 450 }}>
      {!isSubmitted && <FeedbackForm onClose={onClose} onSubmit={onSubmit} />}
      {isSubmitted && <FeedbackSubmitted onClose={onClose} />}
    </Box>
  );
};
