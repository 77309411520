import React, { FC } from 'react';

import { Box, PicnicCss, Select, Text } from '@attentive/picnic';

import { OperatorType } from '../../../../constants';

// Set a min-width to prevent the select from changing size when switching between "Any" and "All"
const SELECT_MIN_WIDTH = '88px';

interface SegmentOperatorProps {
  css?: PicnicCss;
  'data-testid'?: string;
  onChange: (operator: OperatorType) => void;
  operator: OperatorType;
}

export const SegmentOperator: FC<SegmentOperatorProps> = ({
  css,
  'data-testid': dataTestid,
  onChange,
  operator,
}) => {
  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'flex-start',
        alignItems: 'center',
        ...css,
      }}
    >
      <Select
        onChange={(val: string) => onChange(val as OperatorType)}
        value={operator}
        css={{ width: 'auto', minWidth: SELECT_MIN_WIDTH, mr: '$space2' }}
        size="small"
        placeholder="Select a condition operator"
        data-testid={dataTestid}
      >
        <Select.Item value={OperatorType.AND}>All</Select.Item>
        <Select.Item value={OperatorType.OR}>Any</Select.Item>
      </Select>
      <Text css={{ fontSize: '$fontSize3', fontWeight: '$bold' }}>
        of these conditions must match a subscriber
      </Text>
    </Box>
  );
};
