import { Visibility } from '../constants';

import { IndexPageSegment } from './segments';

export interface ErrorMsg {
  message: string;
  code: string;
}

export interface ErrorResponse {
  message: string;
  errors: ErrorMsg[];
}

export interface ExternalVendorApi {
  externalVendorApiId: number;
  vendor: string;
  displayName: string;
  status: { enabled: boolean };
  visibility: Visibility;
  features: Array<{ type: string; externalVendorFeatureId: number }>;
  companyVendorIntegrationId?: number;
}

export interface IntegrationsResponse {
  externalVendorApis: ExternalVendorApi[];
}

export interface SegmentsResponse {
  segments?: IndexPageSegment[] | null;
  totalSegmentsCount?: number | null;
}

export interface SegmentSubscriberCount {
  activeSubscribers: number;
  activeSubscribersNotFatigued: number;
}

export interface SegmentSubscriberCountV2 {
  email: SegmentSubscriberCount;
  sms: SegmentSubscriberCount;
  smsAndEmail: SegmentSubscriberCount;
}

export const MAX_ZIP_CODES_PER_FIELD = 6000;
