import { getLabelText } from '@attentive/picnic';

import { PinnedMenuItems } from './extractItemsHook';

// This is forked from Picnic to add support for the pinned attribute.
export const useMatch = (options: PinnedMenuItems, term: string) => {
  const lowerCaseTerm = term.toLocaleLowerCase();
  return options.filter((b) => {
    // Pinned attributes should *always* be displayed.
    if (b.pinned) return true;

    const label = getLabelText(b.label);
    return label && label.toLocaleLowerCase().includes(lowerCaseTerm);
  });
};
