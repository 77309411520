import React from 'react';

import { Button, StandardDialog, Text } from '@attentive/picnic';

import { JourneyUsingSegment } from '../../../../constants';

import { WarningDialogListItems } from './WarningDialogWithList';

export const ConfirmSegmentUpdateDialog: React.FC<{
  isSaving?: boolean;
  journeys?: JourneyUsingSegment[];
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ isSaving, journeys, onClose, onConfirm, open }) => {
  if (!journeys?.length) return null;

  const collapsedJourneys = collapseJourneys(journeys);

  return (
    <StandardDialog open={open} onOpenChange={onClose}>
      <StandardDialog.Content>
        <StandardDialog.Header>
          <StandardDialog.Heading>
            Before you save these changes, please review
          </StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <WarningDialogListItems
            type="journey"
            items={collapsedJourneys.map((item) => ({ ...item, href: item.url }))}
          >
            <Text>
              This segment is currently <strong>in use within one or more active journeys</strong>.
              Editing the conditions of the segment could have an immediate impact on which
              subscribers qualify to receive these journeys.
            </Text>
          </WarningDialogListItems>
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <Button onClick={onClose} variant="basic" disabled={isSaving}>
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm()}
            variant="primary"
            disabled={isSaving}
            loading={isSaving}
          >
            I understand
          </Button>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

// Take an array of journey revisions and return an array of one item per journey representing the newest revision
function collapseJourneys(journeys: JourneyUsingSegment[]) {
  const journeyMap = journeys.reduce((acc, journey) => {
    const { revisionId, journeyId } = journey;

    // Store the journey if there's no current journey for this ID or if the currently stored journey is an older revision
    if (!acc[journeyId] || acc[journeyId].revisionId < revisionId) {
      acc[journeyId] = journey;
    }

    return acc;
  }, {} as Record<string, JourneyUsingSegment>);

  return Object.values(journeyMap);
}
