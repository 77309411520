import React, { FC } from 'react';

import { ContainedLabel, PicnicCss } from '@attentive/picnic';

import { OperatorType } from '../../../constants';

interface SegmentConnectorPillProps {
  operatorType: OperatorType;
  css?: PicnicCss;
}

const SegmentConnectorPill: FC<SegmentConnectorPillProps> = ({ operatorType, css }) => (
  <ContainedLabel
    variant={operatorType === OperatorType.OR ? 'decorative3' : 'success'}
    css={{
      position: 'relative',
      width: '$size14',
      padding: '0 $space4',
      textTransform: 'capitalize',
      ...css,
    }}
  >
    {operatorType.toLowerCase()}
  </ContainedLabel>
);

export default SegmentConnectorPill;
