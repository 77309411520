import React, { FC } from 'react';

import { Box, Separator } from '@attentive/picnic';

import { OperatorType } from '../../../constants';

import SegmentConnectorPill from './SegmentConnectorPill';

interface OwnProps {
  error: boolean;
  operator: OperatorType;
}

const SegmentComponentSeparator: FC<OwnProps> = ({ error, operator }) => {
  return (
    <Box css={{ height: 0, display: 'flex', alignItems: 'center' }}>
      <SegmentConnectorPill operatorType={operator} css={{ mr: '$space4', ml: '$space4' }} />
      <Separator css={{ backgroundColor: error ? '$borderInputError' : '$borderDefault' }} />
    </Box>
  );
};

export default SegmentComponentSeparator;
