import React from 'react';

import { Box, FormField, Select, TextInput, Text } from '@attentive/picnic';

import { QuantityComparator, QuantityTypes } from '../../../constants';
import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

const PU_QUANTITY_MIN_WIDTH = '60px';

type QuantityComparatorLabelOverrides = Partial<Record<QuantityComparator | 'placeholder', string>>;

type QuantityValue = {
  quantityComparator?: QuantityComparator;
  quantity?: number;
  quantityEnd?: number;
};

interface QuantitySelectorProps {
  values: QuantityValue;
  type: QuantityTypes;
  onChange: (value: QuantityValue) => void;
  comparatorLabelOverrides?: QuantityComparatorLabelOverrides;
}

const typesToLabelMappings: Record<QuantityTypes, string> = {
  amount: 'Amount',
  number: 'Number',
  year: 'Year',
};

const typesToErrorLabelMappings: Record<QuantityTypes, string> = {
  amount: 'Please enter an amount',
  number: 'Please enter a number',
  year: 'Please enter a year',
};

function isValidBetweenValues({ quantity, quantityEnd }: QuantityValue) {
  if (quantity === undefined || quantityEnd === undefined) return false;
  return quantity < quantityEnd;
}

const QuantitySelector = ({
  values,
  onChange,
  type,
  comparatorLabelOverrides = {},
}: QuantitySelectorProps) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const quantityError = showValidation && values.quantity === undefined;

  const isBetweenComparator = values.quantityComparator === QuantityComparator.BETWEEN;
  const quantityEndError = isBetweenComparator && values.quantityEnd === undefined;
  const quantityRangeError = isBetweenComparator && !isValidBetweenValues(values);
  const showBetweenError = showValidation && (quantityEndError || quantityRangeError);

  const emptyErrorMsg = typesToErrorLabelMappings[type] || typesToErrorLabelMappings.number;
  const betweenErrorMsg = 'Please enter a larger value';
  const placeholderText =
    comparatorLabelOverrides.placeholder ||
    typesToLabelMappings[type] ||
    typesToLabelMappings.number;

  return (
    <>
      <Box>
        <Select
          value={values.quantityComparator}
          onChange={(newValue: string) =>
            onChange({ quantityComparator: newValue as QuantityComparator })
          }
          size="small"
        >
          <Select.Item value={QuantityComparator.EQUAL_TO}>
            {comparatorLabelOverrides.EQUAL_TO || 'Equal to'}
          </Select.Item>
          <Select.Item value={QuantityComparator.LESS_THAN}>
            {comparatorLabelOverrides.LESS_THAN || 'Less than'}
          </Select.Item>
          <Select.Item value={QuantityComparator.MORE_THAN}>
            {comparatorLabelOverrides.MORE_THAN || 'More than'}
          </Select.Item>
          <Select.Item value={QuantityComparator.BETWEEN}>
            {comparatorLabelOverrides.BETWEEN || 'Between'}
          </Select.Item>
        </Select>
      </Box>
      <FormField>
        <TextInput
          type="number"
          placeholder={placeholderText}
          value={values.quantity !== undefined ? `${values.quantity}` : ''}
          state={quantityError ? 'error' : 'normal'}
          onChange={(event) => {
            onChange({
              ...values,
              quantity: convertUserInputToNumber(event.target.value, values.quantity),
            });
          }}
          size="small"
          css={{ minWidth: PU_QUANTITY_MIN_WIDTH }}
        />
        {quantityError && <FormField.ErrorText>{emptyErrorMsg}</FormField.ErrorText>}
      </FormField>
      {isBetweenComparator && (
        <>
          <SegmentConditionItemContainer>
            <Text>and</Text>
          </SegmentConditionItemContainer>
          <FormField>
            <TextInput
              type="number"
              placeholder={placeholderText}
              value={values.quantityEnd !== undefined ? `${values.quantityEnd}` : ''}
              state={showBetweenError ? 'error' : 'normal'}
              onChange={(event) => {
                onChange({
                  ...values,
                  quantityEnd: convertUserInputToNumber(event.target.value, values.quantityEnd),
                });
              }}
              size="small"
              css={{ minWidth: PU_QUANTITY_MIN_WIDTH }}
            />
            {showBetweenError && (
              <FormField.ErrorText>
                {quantityEndError ? emptyErrorMsg : betweenErrorMsg}
              </FormField.ErrorText>
            )}
          </FormField>
        </>
      )}
    </>
  );
};

function convertUserInputToNumber(userInput: string, currentValue: number | undefined) {
  // The user has cleared the field
  if (userInput === '') return undefined;

  const numberUserInput = Number(userInput);
  return !Number.isNaN(numberUserInput) ? Math.abs(Math.floor(numberUserInput)) : currentValue;
}

export default QuantitySelector;
