import React from 'react';

import { Icon, styled } from '@attentive/picnic';

const SPACE_KEY = 32;
const DELETE_KEY = 8;
const ENTER_KEY = 13;

const TagPrimitive = styled('span', {
  color: '$textDefault',
  display: 'inline-flex',
  padding: '0 $space2',
  backgroundColor: '$bgActionSecondary',
  alignItems: 'center',
  borderRadius: '$radius1',
  cursor: 'pointer',
  '&:hover': { backgroundColor: '$bgActionSecondaryHover' },
  '&:active': { backgroundColor: '$bgActionSecondaryPressed' },
  focusVisible: '$focus',
  variants: {
    size: {
      // TO-DO: Can we change the icon size based on the size of the component?
      small: {
        fontSize: '$fontSize2',
        minHeight: '$size5',
      },
      normal: {
        fontSize: '$fontSize3',
        minHeight: '$size7',
      },
    },
    variant: {
      default: {},
      error: {
        backgroundColor: '$bgCriticalDefault',
        color: '$textCritical',
      },
    },
    disabled: {
      true: {
        color: '$textDisabled',
        cursor: 'not-allowed',
        backgroundColor: '$bgActionSecondaryDisabled',
        '&:hover': { backgroundColor: '$bgActionSecondaryDisabled' },
        '&:active': { backgroundColor: '$bgActionSecondaryDisabled' },
        focusVisible: 'none',
      },
    },
  },
  defaultVariants: {
    size: 'normal',
    variant: 'default',
  },
});

interface TagProps extends React.ComponentProps<typeof TagPrimitive> {
  onDelete: () => void;
  content: string;
  disabled?: boolean;
}

export const Tag = React.forwardRef<HTMLSpanElement, TagProps>((props, ref) => {
  const { onDelete, content, disabled, variant, ...rest } = props;

  return (
    <TagPrimitive
      onClick={() => !disabled && onDelete()}
      onKeyUp={(e) => {
        if (!disabled && [SPACE_KEY, DELETE_KEY, ENTER_KEY].includes(e.keyCode)) {
          onDelete();
        }
      }}
      role="button"
      tabIndex={0}
      disabled={disabled}
      ref={ref}
      variant={variant}
      {...rest}
    >
      {variant === 'error' && (
        <>
          <Icon name="CircleExclamation" size="small" color="error" />
          &nbsp;
        </>
      )}
      {content}
      &nbsp;&nbsp;
      <Icon name="Delete" size="small" color={disabled ? 'disabled' : undefined} />
    </TagPrimitive>
  );
});
