import { SubscriberAttributeDataType } from '../index';

export interface DisplayableOption {
  optionValue: number | string; // this is the parameter value of the option
  displayName: string; // renders the display name for the option
}

export interface NumberValueDisplayableOption extends DisplayableOption {
  optionValue: number;
}

export interface StringValueDisplayableOption extends DisplayableOption {
  optionValue: string;
}

export interface CustomAttributeOptionValue extends StringValueDisplayableOption {
  deleted?: boolean;
}

export interface CustomAttributeOption extends StringValueDisplayableOption {
  deleted: boolean;
  values: CustomAttributeOptionValue[];
  type: SubscriberAttributeDataType;
}

export interface SubscriberPreferenceGroup extends StringValueDisplayableOption {
  preferences: StringValueDisplayableOption[];
}

export interface WellKnownPropertyOptionValue extends StringValueDisplayableOption {
  deleted?: boolean;
}

export interface WellKnownPropertyOption extends StringValueDisplayableOption {
  nestedOptions?: WellKnownPropertyNestedOption[];
  type: SubscriberAttributeDataType;
  values?: WellKnownPropertyOptionValue[];
}

export interface WellKnownPropertyNestedOption
  extends Omit<StringValueDisplayableOption, 'optionValue'> {
  attributeName: string;
  type: SubscriberAttributeDataType;
  values?: WellKnownPropertyOptionValue[];
}
