import { SegmentCountDisplay, SegmentSubscriberCountV2 } from '../constants';

import { formatNumberWithCommas } from './formatters';

export const getSegmentCountDisplay = (
  segmentCount?: SegmentSubscriberCountV2 | null
): SegmentCountDisplay => {
  return segmentCount
    ? {
        sms: {
          activeSubscribers: formatNumberWithCommas(segmentCount.sms.activeSubscribers),
          activeSubscribersNotFatigued: formatNumberWithCommas(
            segmentCount.sms.activeSubscribersNotFatigued
          ),
        },
        email: {
          activeSubscribers: formatNumberWithCommas(segmentCount.email.activeSubscribers),
          activeSubscribersNotFatigued: formatNumberWithCommas(
            segmentCount.email.activeSubscribersNotFatigued
          ),
        },
        smsAndEmail: {
          activeSubscribers: formatNumberWithCommas(segmentCount.smsAndEmail.activeSubscribers),
          activeSubscribersNotFatigued: formatNumberWithCommas(
            segmentCount.smsAndEmail.activeSubscribersNotFatigued
          ),
        },
      }
    : {
        sms: {
          activeSubscribers: '--',
          activeSubscribersNotFatigued: '--',
        },
        email: {
          activeSubscribers: '--',
          activeSubscribersNotFatigued: '--',
        },
        smsAndEmail: {
          activeSubscribers: '--',
          activeSubscribersNotFatigued: '--',
        },
      };
};
