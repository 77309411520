import React from 'react';

import { Box, LoadingIndicator } from '@attentive/picnic';

export const DialogLoadingIndicator = () => {
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <LoadingIndicator css={{ p: '$space2' }} />
    </Box>
  );
};
