import React from 'react';

import { Heading } from '@attentive/picnic';

import { OperatorType } from '../../../constants';
import { useIsOperatorUIEnabled } from '../../OperatorUIToggle';
import { useAnimatedEntry } from '../useAnimatedEntry';

import { SegmentOperator } from './SegmentOperator';

interface GlobalSegmentOperatorType {
  onChange: (operator: OperatorType) => void;
  operator: OperatorType;
  showField?: boolean;
}

const GlobalSegmentOperator: React.FC<GlobalSegmentOperatorType> = ({ showField, ...props }) => {
  const { elementCss, isMounted } = useAnimatedEntry({
    animationDuration: 200,
    isVisible: Boolean(showField),
    hiddenCss: { opacity: 0, height: 0 },
    visibleCss: { opacity: 1, height: '40px' },
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMounted && (
        <SegmentOperator
          {...props}
          css={{ mb: '$space4', transition: 'all 0.2s ease-in-out', ...elementCss }}
        />
      )}
    </>
  );
};

interface SegmentBuilderHeaderProps {
  contentHeader?: string;
}

export const SegmentBuilderHeader: React.FC<
  SegmentBuilderHeaderProps & GlobalSegmentOperatorType
> = ({ contentHeader, ...props }) => {
  const enableOperatorUI = useIsOperatorUIEnabled();

  if (enableOperatorUI) {
    return <GlobalSegmentOperator {...props} />;
  }

  return (
    <Heading variant="md">
      {contentHeader || 'Add subscribers who match the following conditions:'}
    </Heading>
  );
};
