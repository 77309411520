import React, { FC } from 'react';

import { Box, Select } from '@attentive/picnic';

import { HasVerbType, IsVerbType } from '../../../constants';

const PU_HAS_VERB_MIN_WIDTH = '112px';
const PU_IS_VERB_MIN_WIDTH = '98px';

export enum VerbSelectorType {
  HAS = 'has',
  HAS_ONLY = 'has-only',
  IS = 'is',
  IS_ONLY = 'is-only',
}

interface VerbSelectorProps {
  verbType: VerbSelectorType;
  value: HasVerbType | IsVerbType;
  onChange: (value: HasVerbType | IsVerbType) => void;
  overrideNot?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const VerbSelector: FC<VerbSelectorProps> = ({
  verbType,
  value,
  overrideNot,
  onChange,
  disabled,
  size = 'small',
}) => (
  <Box css={{ minWidth: value in IsVerbType ? PU_IS_VERB_MIN_WIDTH : PU_HAS_VERB_MIN_WIDTH }}>
    <Select
      value={value}
      onChange={(newVerb: string) => onChange(newVerb as HasVerbType | IsVerbType)}
      disabled={disabled}
      size={size}
    >
      {(verbType === VerbSelectorType.IS || verbType === VerbSelectorType.IS_ONLY) && (
        <Select.Item value={IsVerbType.IS}>Is</Select.Item>
      )}
      {overrideNot !== true && verbType === VerbSelectorType.IS && (
        <Select.Item value={IsVerbType.IS_NOT}>Is not</Select.Item>
      )}
      {(verbType === VerbSelectorType.HAS || verbType === VerbSelectorType.HAS_ONLY) && (
        <Select.Item value={HasVerbType.HAS}>Has</Select.Item>
      )}
      {verbType === VerbSelectorType.HAS && (
        <Select.Item value={HasVerbType.HAS_NOT}>Has not</Select.Item>
      )}
    </Select>
  </Box>
);

export default VerbSelector;
