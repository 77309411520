import React, { useEffect, useState } from 'react';

import { Box, Heading, IconPopover, LoadingIndicator, PicnicCss, Text } from '@attentive/picnic';

import desertImg from '../../../../assets/desert.png';
import { BlankSegment, Segment } from '../../../../constants';
import { useWorkingSegmentCount } from '../../../../hooks';
import { formatNumberWithCommas, getSegmentComponentCount } from '../../../../utils';

import { segmentCountTextTransition } from './SegmentCountContentNew';
import { MANUAL_RECALCULATE_CONDITION_THRESHOLD } from './SegmentCountSidePane';
import { ImgContainer, SidePaneImg } from './SegmentCountStyledImgComponents';
import { UpdateSubscriberCount } from './UpdateSubscriberCount';

const LIVE_COUNT_STALE_TIME = 8000;

interface OwnProps {
  segment: Segment | BlankSegment;
  isWorking?: boolean;
  isSegmentInvalid?: boolean;
  isStale?: boolean;
  isAutoUpdateEnabled: boolean;
  onUpdateCountClick?: () => void;
  onCountUpdate?: () => void;
}

const SegmentCountContent = ({
  segment,
  isWorking,
  onCountUpdate,
  isAutoUpdateEnabled,
  isStale,
  isSegmentInvalid,
  onUpdateCountClick,
}: OwnProps) => {
  const [isSegmentCountEnabled, setIsSegmentCountEnabled] = useState(isAutoUpdateEnabled);
  const { data, isFetching, error } = useWorkingSegmentCount(segment, {
    enabled: isSegmentCountEnabled,
    onSuccess: onCountUpdate,
    staleTime: LIVE_COUNT_STALE_TIME,
  });
  const [segmentCountResponse, setSegmentCountResponse] = useState(data);

  useEffect(() => {
    if (data) {
      setSegmentCountResponse(data);
    }
  }, [data]);

  useEffect(() => {
    // This handles the case where a user loads an existing segment that has 5+ conditions and then deletes
    // a condition without requesting a segment count. It ensures that we only call the segment count endpoint once
    // with the newest segment. Without this check, we would call the segment count endpoint twice, once with a stale segment
    if (
      isAutoUpdateEnabled &&
      !isSegmentCountEnabled &&
      getSegmentComponentCount(segment) < MANUAL_RECALCULATE_CONDITION_THRESHOLD
    ) {
      setIsSegmentCountEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoUpdateEnabled, segment]);

  const isLoading = isWorking || isFetching;
  const displayLoadingIndicators = isAutoUpdateEnabled && (isLoading || !data);
  const isDataStale = !isAutoUpdateEnabled && isStale;
  const isUpdateButtonDisabled = !isStale || isSegmentInvalid || isLoading;

  if (error) {
    return (
      <>
        <ImgContainer>
          <SidePaneImg src={desertImg} />
        </ImgContainer>
        <Text css={{ textAlign: 'center' }} color="subdued">
          We had some trouble loading your results
        </Text>
      </>
    );
  }

  return (
    <>
      <SegmentCountItem
        count={segmentCountResponse?.activeSubscribers}
        isStale={isDataStale}
        isLoading={displayLoadingIndicators}
        label="Segment size"
        helpText="The number of subscribers that match your segment's conditions"
      />
      <SegmentCountItem
        count={segmentCountResponse?.activeSubscribersNotFatigued}
        isStale={isDataStale}
        isLoading={displayLoadingIndicators}
        label="Eligible recipients"
        helpText="The number of subscribers that can receive a message right now, based on your Smart Sending settings"
      />
      {!isAutoUpdateEnabled && (
        <UpdateSubscriberCount
          isLoading={isLoading}
          onUpdateCountClick={() => {
            setIsSegmentCountEnabled(true);
            onUpdateCountClick && onUpdateCountClick();
          }}
          disabled={isUpdateButtonDisabled}
        />
      )}
    </>
  );
};

interface SegmentCountItemProps {
  count: number | undefined;
  css?: PicnicCss;
  helpText: string;
  isStale?: boolean;
  isLoading: boolean;
  label: string;
}

function SegmentCountItem({
  count,
  css,
  helpText,
  isStale,
  isLoading,
  label,
}: SegmentCountItemProps) {
  const headlineCss: PicnicCss = {
    color: isStale ? '$textDisabled' : '$textDefault',
    transition: segmentCountTextTransition,
  };
  const textCss: PicnicCss = {
    color: isStale ? '$textDisabled' : '$textSubdued',
    transition: segmentCountTextTransition,
  };

  const displayedCount = count !== undefined ? formatNumberWithCommas(count) : '--';

  return (
    <Box css={css}>
      <Heading variant="lg" css={headlineCss}>
        {isLoading ? <LoadingIndicator css={{ height: 'initial' }} /> : displayedCount}
      </Heading>
      <Text css={{ mb: '$space6', display: 'flex', alignItems: 'center', ...textCss }}>
        {label}
        <IconPopover
          size="extraSmall"
          side="bottom"
          css={{ ml: '$space1' }}
          iconColor={isStale ? 'disabled' : undefined}
        >
          <Text variant="caption">{helpText}</Text>
        </IconPopover>
      </Text>
    </Box>
  );
}

export default SegmentCountContent;
