import { StringValueDisplayableOption } from '../../constants';

export const dialogWidth = 544;
export const rowHeight = 50;
export const selectedWrapperHeight = 140;

export enum ProductAttribute {
  CATEGORY = 'PRODUCT_ATTRIBUTE_CATEGORY',
  TAG = 'PRODUCT_ATTRIBUTE_TAG',
  NAME = 'PRODUCT_ATTRIBUTE_NAME',
  COLOR = 'PRODUCT_ATTRIBUTE_COLOR',
  BRAND = 'PRODUCT_ATTRIBUTE_BRAND',
  SIZE = 'PRODUCT_ATTRIBUTE_SIZE',
  COLLECTION = 'PRODUCT_ATTRIBUTE_COLLECTION',
  OPTION = 'PRODUCT_ATTRIBUTE_OPTION',
}

export const displayLabelMap: Record<ProductAttribute, string> = {
  [ProductAttribute.CATEGORY]: 'Categories',
  [ProductAttribute.TAG]: 'Tags',
  [ProductAttribute.NAME]: 'Products',
  [ProductAttribute.COLOR]: 'Colors',
  [ProductAttribute.BRAND]: 'Brands',
  [ProductAttribute.SIZE]: 'Sizes',
  [ProductAttribute.COLLECTION]: 'Collections',
  [ProductAttribute.OPTION]: 'Options',
};

export interface CompleteProductDataOption extends StringValueDisplayableOption {
  graphID?: string;
}

export interface RESTResponseProductDataOption extends CompleteProductDataOption {
  // deleted will be a date string if the product has been deleted
  deleted?: string;
}
