import { CountryCode, countryNamesMap, Locale } from '@attentive/locale-utils';

import { NumberValueDisplayableOption, UNITED_STATES, UNSPECIFIED_VALUE } from '../../../constants';

const countryNames = countryNamesMap(Locale.enUS);
countryNames[CountryCode.US] = UNITED_STATES;

export function getCountryId(
  selectedCountry: CountryCode | null,
  countries: NumberValueDisplayableOption[]
) {
  if (!selectedCountry) {
    return undefined;
  }

  // The BE returns "United States" while locale utils uses "United States of America". We can drop the ternary when we make the two consistent
  const countryName = countryNames[selectedCountry];
  const currentCountry = countries.find(({ displayName }) => displayName === countryName);

  return currentCountry?.optionValue ? `${currentCountry.optionValue}` : undefined;
}

export function getCountryCode({
  countryId,
  companyCountry,
  countries,
}: {
  countryId?: string;
  companyCountry: string;
  countries: NumberValueDisplayableOption[];
}) {
  if (!countryId) {
    return companyCountry;
  }

  const currentCountry = countries.find(({ optionValue }) => String(optionValue) === countryId);

  if (!currentCountry) {
    return companyCountry;
  }

  const countryObj = Object.entries(countryNames).find(
    ([_, name]) => name === currentCountry.displayName
  );

  return countryObj ? countryObj[0] : UNSPECIFIED_VALUE;
}
