/**
 * @generated SignedSource<<77cccb4c69cc0979f0ce221a2c4ca55a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneyStatusPill_TargetingCommon_Journey$data = {
  readonly isActive: boolean;
  readonly publishedRevision: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "JourneyStatusPill_TargetingCommon_Journey";
};
export type JourneyStatusPill_TargetingCommon_Journey$key = {
  readonly " $data"?: JourneyStatusPill_TargetingCommon_Journey$data;
  readonly " $fragmentSpreads": FragmentRefs<"JourneyStatusPill_TargetingCommon_Journey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JourneyStatusPill_TargetingCommon_Journey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JourneyRevision",
      "kind": "LinkedField",
      "name": "publishedRevision",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Journey",
  "abstractKey": null
};

(node as any).hash = "3eec4146a77baedc0b949add976c790d";

export default node;
