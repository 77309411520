import deburr from 'lodash/deburr';
import { useEffect, useState } from 'react';

import { API, ToastType, useToast } from '@attentive/acore-utils';

import { KlaviyoCampaign } from '../constants';

async function fetchKlaviyoCampaigns() {
  // GMRU: GET /user-profile/v1/segment-options/third-party-campaigns
  const response = await API.get<KlaviyoCampaign[]>(
    '/user-profile/v1/segment-options/third-party-campaigns'
  );
  const { body, status } = response;
  if (status !== 200) {
    throw new Error('Klaviyo campaigns failed to load');
  }
  if (!body) {
    throw new Error('Unexpected empty response');
  }
  return body;
}

const filterKlaviyoCampaigns = (searchString: string | undefined, campaigns: KlaviyoCampaign[]) => {
  return searchString
    ? campaigns.filter((campaign) => {
        const query = searchString.toLowerCase();
        return deburr(campaign.name.toLowerCase()).includes(query);
      })
    : campaigns;
};

export const useKlaviyoCampaigns = (searchString?: string) => {
  const [createToast] = useToast();

  const [klaviyoCampaigns, setKlaviyoCampaigns] = useState<KlaviyoCampaign[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const klaviyoCampaignMessageGroups = await fetchKlaviyoCampaigns();
        setKlaviyoCampaigns(klaviyoCampaignMessageGroups);
      } catch {
        createToast({
          type: ToastType.Error,
          title: 'Unable to fetch Klaviyo campaigns',
          text: 'Unexpected empty response',
        });
      }
    })();
  }, [createToast]);

  return {
    filteredCampaigns: filterKlaviyoCampaigns(searchString, klaviyoCampaigns),
    totalCount: klaviyoCampaigns.length,
  };
};
