/**
 * @generated SignedSource<<afe2612a92c29f37046a8d1d737100b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneyRow_getJourneySecondaryText_TargetingCommon_Journey$data = {
  readonly draftRevision: {
    readonly lastUpdatedTime: SerializedDateTime;
  } | null;
  readonly isActive: boolean;
  readonly publishedRevision: {
    readonly publishStartTime: SerializedDateTime | null;
  } | null;
  readonly " $fragmentType": "JourneyRow_getJourneySecondaryText_TargetingCommon_Journey";
};
export type JourneyRow_getJourneySecondaryText_TargetingCommon_Journey$key = {
  readonly " $data"?: JourneyRow_getJourneySecondaryText_TargetingCommon_Journey$data;
  readonly " $fragmentSpreads": FragmentRefs<"JourneyRow_getJourneySecondaryText_TargetingCommon_Journey">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "JourneyRow_getJourneySecondaryText_TargetingCommon_Journey"
};

(node as any).hash = "5347ccfe25dcb24b695d69c9f71339eb";

export default node;
