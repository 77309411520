import _formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition from "./__generated__/formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition.graphql";
import _formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition from "./__generated__/formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition.graphql";
import { graphql, readInlineData } from '@attentive/data';
import { isSupportedType, PROPERTY_TYPE_TO_DATA_TYPE_MAP, PROPERTY_TYPE_TO_INPUT_MAP } from '../../constants';
import { getInitialCustomAttributeFormValues } from '../../form';
import CustomAtttributeFragment from './__generated__/formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition.graphql';
import CustomAttributeEnumValuesFragment from './__generated__/formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition.graphql';
_formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition;

function formatOptions(fragRef) {
  if (fragRef === null) return [];
  const {
    enumeratedValues
  } = readInlineData(CustomAttributeEnumValuesFragment, fragRef);
  return enumeratedValues.filter(item => !item.isDeleted).map(item => item.value);
}

_formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition;
export function buildInitialFormState(fragRef) {
  const data = readInlineData(CustomAtttributeFragment, fragRef);
  const defaultValues = getInitialCustomAttributeFormValues();
  const {
    name,
    propertyType,
    typeDefinition
  } = data;

  if (!isSupportedType(propertyType)) {
    throw new Error("Unsupported property type: ".concat(propertyType));
  }

  const options = propertyType === 'TYPE_ENUM' ? formatOptions(typeDefinition) : defaultValues.options;
  return { ...defaultValues,
    attributeName: name,
    dataType: PROPERTY_TYPE_TO_DATA_TYPE_MAP[propertyType],
    inputType: PROPERTY_TYPE_TO_INPUT_MAP[propertyType],
    options
  };
}