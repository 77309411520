import uniqBy from 'lodash/uniqBy';
import React, { FC, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { LoadingIndicator, Box, PicnicCss, Banner } from '@attentive/picnic';

import { DisplayableOption, Optional, StringValueDisplayableOption } from '../../constants';
import { ItemSelectDialog } from '../ItemSelectDialog';

import { ProductDataDialogRow, ProductDataDialogSearchHeader } from './ProductDataDialogRow';
import { useProductData } from './hooks';
import {
  rowHeight,
  ProductAttribute,
  displayLabelMap,
  CompleteProductDataOption,
} from './interfaces';

const PU_PRODUCT_DATA_LIST_WIDTH = 494;
// This limit governs how many items a user can select. It also enables/disables the select all functionality
const MAX_SELECTED_ITEMS = 1000;

type UnknownProductDataOption = Optional<CompleteProductDataOption, 'graphID'>;

export interface ProductDataDialogProps {
  /**
   * Function to fire on close of the dialog (parent element must control either render or not the component)
   */
  onClose: () => void;
  /**
   * Header of the top of the dialog
   * @default Uses queryAttribute to display `Add ${queryAttribute} to your segment`
   */
  header?: string;
  /**
   * The attribute that is used to fetch relevant product data
   */
  queryAttribute: ProductAttribute;
  /**
   * The name that is used to fetch relevant product data for PRODUCT_ATTRIBUTE_OPTION
   */
  queryName?: string | undefined;
  /**
   * Options that have already been selected
   */
  selectedOptions: UnknownProductDataOption[];
  /**
   * Function that is fired on submit. It is passed the selected options that happened inside the dialog
   * - selections only includes optionValue and displayName of the selected options; it will not update with new values from the API
   * - rawSelections includes all of the data sent by the API. The returned data will change over time as over time as more attributes
   *     are added to the API response so consuming services should be tolerant of new attributes on the returned data
   */
  onSubmit: (
    selections: StringValueDisplayableOption[],
    rawSelections: CompleteProductDataOption[]
  ) => void;
  /**
   * These are options that have been selected, but should not be `unselectable` in this dialog instance
   * For example: You select one group of products for one path of a journey, and you want them to visible for
   * another path, but not selectable.
   */
  outsideSelections?: Map<string, UnknownProductDataOption[]>;
  /**
   * A flag to toggle showing each options' ID as subtext under its name
   */
  showOptionIdSubtext?: boolean;
}

const loadingCss: PicnicCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

export const ProductDataDialog: FC<ProductDataDialogProps> = ({
  header,
  onClose,
  selectedOptions,
  onSubmit,
  queryAttribute,
  queryName,
  outsideSelections = undefined,
  showOptionIdSubtext,
}) => {
  const enableSelectAllResults = useCompanyFeatureFlag('ENABLE_PRODUCT_DATA_DIALOG_SELECT_ALL_UI');

  const [selections, setSelections] = useState(removeExtraProperties(selectedOptions));
  const [rawSelections, setRawSelections] = useState<CompleteProductDataOption[]>(
    addExtraProperties(selectedOptions)
  );
  const [displayLimitWarning, setDisplayLimitWarning] = useState(false);

  const {
    state,
    isEmptyLoadingState,
    noResultsReturned,
    userInput,
    handleChange,
    isItemLoaded,
    loadMoreItems,
    isLoading,
  } = useProductData(queryAttribute, queryName);
  const itemCount = state.options.length;
  const hasLoadedAllResults = itemCount === state.totalResults;
  const resultsExceedsLimit = state.totalResults > MAX_SELECTED_ITEMS;

  // This check ensures that the search header is not visible until the first page of search results have been returned.
  const displaySearchHeader = Boolean(enableSelectAllResults && userInput && state.totalResults);
  const selectAllChecked = Boolean(
    displaySearchHeader &&
      hasLoadedAllResults &&
      areAllVisibleItemsSelected(rawSelections, state.options)
  );

  React.useEffect(() => {
    // automatically fetch all search results in batches when a user enters a search term
    // we do not automatically fetch the results if the total exceeds the limit
    if (
      enableSelectAllResults &&
      userInput &&
      !isLoading &&
      !hasLoadedAllResults &&
      !resultsExceedsLimit
    ) {
      loadMoreItems();
    }
    // this hook watches the itemCount value because we only want to fire another request once the previous request has completed and been added to state.options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCount]);

  const displayedHeader =
    header || `Add ${displayLabelMap[queryAttribute].toLowerCase()} to your segment`;

  const handleClose = () => {
    onClose();
  };

  const handleDoneClick = () => {
    onSubmit(dedupeProperties(selections), dedupeProperties(rawSelections));
  };

  const handleClearAll = () => {
    setSelections([]);
    setRawSelections([]);
    setDisplayLimitWarning(false);
  };

  const handleSelectAll = () => {
    if (resultsExceedsLimit) {
      setDisplayLimitWarning(true);
      return;
    }

    setDisplayLimitWarning(false);
    const updatedRawSelections = selectAllChecked
      ? removeItemsFromArray(rawSelections, state.options)
      : combineAndDedupeSelections(rawSelections, state.options);

    if (updatedRawSelections.length > MAX_SELECTED_ITEMS) {
      setDisplayLimitWarning(true);
      return;
    }

    setSelections(removeExtraProperties(updatedRawSelections));
    setRawSelections(updatedRawSelections);
  };

  const handleAddSelection = (selection: CompleteProductDataOption) => {
    if (selections.length + 1 > MAX_SELECTED_ITEMS) {
      setDisplayLimitWarning(true);
      return;
    }

    setDisplayLimitWarning(false);
    setSelections([
      ...selections,
      { optionValue: selection.optionValue, displayName: selection.displayName },
    ]);
    setRawSelections([...rawSelections, selection]);
  };

  const handleRemoveSelection = (selection: DisplayableOption) => {
    setDisplayLimitWarning(false);

    const updatedSelections = selections.filter(
      (currentOption) => currentOption.optionValue !== selection.optionValue
    );
    setSelections(updatedSelections);

    const updatedRawSelections = rawSelections.filter(
      (currentOption) => currentOption.optionValue !== selection.optionValue
    );
    setRawSelections(updatedRawSelections);
  };

  return (
    <ItemSelectDialog
      heading={displayedHeader}
      open
      onClose={handleClose}
      onSubmit={handleDoneClick}
      submitDisabled={!selections.length || selections.length > MAX_SELECTED_ITEMS}
      rowHeight={rowHeight}
    >
      <Box>
        {displayLimitWarning && (
          <Banner
            variant="warning"
            css={{ mb: '$space6' }}
            dismissible
            onDismiss={() => setDisplayLimitWarning(false)}
          >
            <Banner.Text>Selection is limited to a maximum of 1,000 items</Banner.Text>
          </Banner>
        )}
        <ItemSelectDialog.SearchField searchText={userInput} onSearchTextChange={handleChange} />
        {displaySearchHeader && (
          <ProductDataDialogSearchHeader
            onClick={handleSelectAll}
            isChecked={selectAllChecked}
            // We only disable the select all checkbox if the search query matches less than the max limit of items and it's still auto-loading results
            disabled={!hasLoadedAllResults && !resultsExceedsLimit}
          />
        )}
      </Box>
      <Box>
        {noResultsReturned && <Box css={{ mt: '$space6' }}>No results</Box>}
        <AutoSizer>
          {({ height }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={state.totalResults}
              loadMoreItems={loadMoreItems}
              threshold={12}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  data-testid="infinite-list-wrapper"
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  itemSize={rowHeight}
                  height={height}
                  width={PU_PRODUCT_DATA_LIST_WIDTH}
                >
                  {(listProps) => (
                    <ProductDataDialogRow
                      {...listProps}
                      option={state.options[listProps.index]}
                      onRemoveSelection={handleRemoveSelection}
                      onAddSelection={handleAddSelection}
                      selectedOptions={rawSelections}
                      outsideSelections={outsideSelections}
                      searchQuery={userInput}
                      showOptionIdSubtext={showOptionIdSubtext}
                    />
                  )}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
        {isEmptyLoadingState && (
          <Box css={loadingCss}>
            <LoadingIndicator css={{ p: '$space2' }} />
          </Box>
        )}
      </Box>
      <ItemSelectDialog.SelectedItems
        itemTypeLabel={displayLabelMap[queryAttribute]}
        selectedOptions={selections}
        onClearAll={handleClearAll}
        onRemoveSelection={handleRemoveSelection}
      />
    </ItemSelectDialog>
  );
};

function removeExtraProperties(
  properties: UnknownProductDataOption[]
): StringValueDisplayableOption[] {
  return dedupeProperties(properties).map((prop: StringValueDisplayableOption) => ({
    optionValue: prop.optionValue,
    displayName: prop.displayName,
  }));
}

// When a consumer passes in selectedOptions, we can't be sure if the options are StringValueDisplayableOption or CompleteProductDataOption
// Our typing guarantees that the extra properties will exist but they might be undefined depending on what the consumer passed in
function addExtraProperties(properties: UnknownProductDataOption[]): CompleteProductDataOption[] {
  return dedupeProperties(properties).map((prop: UnknownProductDataOption) => {
    return {
      optionValue: prop.optionValue,
      displayName: prop.displayName,
      graphID: prop.graphID ?? undefined,
    };
  });
}

function dedupeProperties(
  properties: StringValueDisplayableOption[] | CompleteProductDataOption[]
) {
  return uniqBy(properties, 'optionValue');
}

function areAllVisibleItemsSelected(
  selectedProperties: CompleteProductDataOption[],
  visibleProperties: CompleteProductDataOption[]
) {
  if (selectedProperties.length < visibleProperties.length) {
    return false;
  }
  const selectedValues = selectedProperties.map(({ optionValue }) => optionValue);
  return visibleProperties.every(({ optionValue }) => selectedValues.includes(optionValue));
}

function removeItemsFromArray(
  arr: CompleteProductDataOption[],
  itemsToRemove: CompleteProductDataOption[]
) {
  const valuesToRemove = itemsToRemove.map(({ optionValue }) => optionValue);
  return arr.filter(({ optionValue }) => !valuesToRemove.includes(optionValue));
}

function combineAndDedupeSelections(
  currentSelections: CompleteProductDataOption[],
  newSelections: CompleteProductDataOption[]
) {
  return [...currentSelections, ...removeItemsFromArray(newSelections, currentSelections)];
}

export default ProductDataDialog;
