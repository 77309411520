import React, { ChangeEventHandler, useState } from 'react';

import { FormField, TextInput, Text } from '@attentive/picnic';

import { SiteCondition } from '../../../constants';
import SegmentConditionItemContainer from '../../SegmentConditionItemContainer';

import { useShowExpressionValidationAtomValue } from './SegmentExpressionBuilder/hooks/useShowExpressionValidationAtom';

export interface SiteConditionValue {
  propertyAttributeCondition: SiteCondition;
  pattern?: string;
}

interface OwnProps {
  values: SiteConditionValue;
  onChange: (data: SiteConditionValue) => void;
}

const SiteSelector = ({ values, onChange }: OwnProps) => {
  const showValidation = useShowExpressionValidationAtomValue();
  const error = showValidation && !values.pattern;
  const [currentPatternValue, setCurrentPatternValue] = useState(values.pattern || '');

  const handlePatternChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value;
    setCurrentPatternValue(newValue);
    onChange({ ...values, pattern: newValue });
  };

  return (
    <>
      <FormField>
        <TextInput
          placeholder="Enter a URL"
          value={currentPatternValue}
          state={error ? 'error' : 'normal'}
          onChange={handlePatternChange}
          size="small"
        />
        {error && <FormField.ErrorText>Please enter a URL.</FormField.ErrorText>}
      </FormField>
      <SegmentConditionItemContainer>
        <Text>At least once</Text>
      </SegmentConditionItemContainer>
    </>
  );
};

export default SiteSelector;
