import _useJourneyMessages_TargetingCommon_JourneyConnection from "./__generated__/useJourneyMessages_TargetingCommon_JourneyConnection.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { usePaginatedJourneys } from '../../hooks/usePaginatedJourneys';
import JourneysFragment from './__generated__/useJourneyMessages_TargetingCommon_JourneyConnection.graphql';
/**
 * This fragment should be spread on usePaginatedJourneys_TargetingCommon_Company
 */

_useJourneyMessages_TargetingCommon_JourneyConnection;
/**
 * Fetch a list of all journey messages across all revisions for the selected journeys and channel
 *
 *  Journey messages are grouped by name. selectedJourneys should always contain at least one message in the specified channel
 *
 * This hook relies on the paginated journeys fragment
 * usePaginatedJourneys_TargetingCommon_Company
 *
 * @param companyRef
 * @param selectedJourneys - an array of journey IDs
 * @param channel
 * @param queryText - the user's search text to use when filtering
 * @returns
 */

export function useJourneyMessages(companyRef, selectedJourneys, channel, queryText) {
  const {
    data: journeysRef,
    isLoading
  } = usePaginatedJourneys(companyRef);
  const data = useFragment(JourneysFragment, journeysRef);
  const {
    edges = []
  } = data || {};

  if (!selectedJourneys || !edges.length) {
    return {
      data: [],
      isLoading
    };
  }

  const journeyMessages = edges.reduce((all, _ref) => {
    let {
      node
    } = _ref;

    if (!selectedJourneys.includes(node.internalId) || !node.journeyRevisions) {
      return all;
    }

    const {
      name,
      journeyRevisions
    } = node; // Within journeys, the message name is treated as the unique identifier. We use a set to ensure that our list of messages is unique.

    const foundMessages = new Set();
    journeyRevisions.forEach(_ref2 => {
      let {
        messages
      } = _ref2;
      messages.forEach(_ref3 => {
        let {
          composeMessage
        } = _ref3;

        if (!composeMessage || composeMessage.channel !== channel) {
          return;
        }

        foundMessages.add(composeMessage.name);
      });
    });

    if (!foundMessages.size) {
      return all;
    }

    return [...all, {
      name,
      journeyId: node.internalId,
      messages: [...foundMessages]
    }];
  }, []);
  let filteredJourneyMessages = journeyMessages;

  if (queryText) {
    const formattedQueryString = queryText.toLocaleLowerCase();
    filteredJourneyMessages = journeyMessages // Filter journey messages to find matching messages
    .map(journey => {
      const {
        messages
      } = journey;
      return { ...journey,
        messages: messages.filter(messageName => messageName.toLocaleLowerCase().includes(formattedQueryString))
      };
    }) // Filter out journeys without messages
    .filter(_ref4 => {
      let {
        messages
      } = _ref4;
      return messages.length;
    });
  }

  return {
    data: filteredJourneyMessages,
    isLoading
  };
}